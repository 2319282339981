<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Bar</span>
    </div>
    <j-alert
      v-model="msgOpen"
      :title="msgInfo.title"
      :type="msgInfo.type"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
    <div class="chart_properties_contents">
      <div class="header">
        <span>
          Bar
          <span data-grid-quickbtn="btn" class="label jcon_temp_plus type_header" style="color: #4298c6;" @click="appendAttr('Bar')"><small style="padding-left: 0.1rem;">Add</small></span>
        </span>
        <a class="close" @click="collapsed['bar'] = !collapsed['bar']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['bar']">
        <div v-for="(attr, i) in localFloatBarAttrs" :key="`_header_${i}`">
          <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'Bar')">{{ i + 1 }}{{ surfix(i) }}</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">X / Y</label>
              <div class="row">
                <input v-model="attr.BarX" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BarX', null, 'num')" />
                <input v-model="attr.BarY" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BarY', null, 'num')" />
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Width / Height</label>
              <div class="row">
                <input v-model="attr.BarWidth" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BarWidth', null, 'num')" />
                <input v-model="attr.BarHeight" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BarHeight', null, 'num')" />
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Fill / Background Color</label>
              <div class="row">
                <j-color-picker 
                  v-model="attr.BarColor" 
                  :gradation="true"
                  :gradation-type="attr.BarColorType"
                  @gradate="v => setGradient(attr, v)"
                  @input="updateAttr"
                />
                <j-color-picker v-model="attr.BarBgColor" @input="updateAttr"/>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Border / Border Color</label>
              <div class="row">
                <input v-model="attr.BarBorder" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BarBorder', null, 'num')" />
                <j-color-picker v-model="attr.BarBorderColor" @input="updateAttr"/>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Radius/ Opacity</label>
              <div class="row">
                <input v-model="attr.BarRadius" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BarRadius', null, 'num')" />
                <input v-model="attr.BarOpacity" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BarOpacity', null, 'num')" />
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        <span>
          Bar Series
          <span data-grid-quickbtn="btn" class="label jcon_temp_plus type_header" style="color: #4298c6;" @click="appendAttr('BarSeries')"><small style="padding-left: 0.1rem;">Add</small></span>
        </span>
        <a class="close" @click="collapsed['barseries'] = !collapsed['barseries']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['barseries']">
        <div v-for="(attr, i) in localFloatBarSeriesAttrs" :key="`_header_${i}`">
          <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'BarSeries')">{{ i + 1 }}{{ surfix(i) }}</span>
          <div class="type_inner">
            <div v-for="(key, i_) in seriesKeys" :key="`_key_${i_}`">
              <label>{{  key.charAt(0) ? key.charAt(0).toUpperCase() + key.slice(1) : key}}</label>
              <div class="type_inner">
                <div class="type_common_input">
                  <label class="type_gray second_depth">Display</label>
                  <div class="row">
                    <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                      <input v-model="attr[key].BarSeriesDisplay" type="radio" dir="rtl" :value="option.value" :name="`attr_display_${i}__${key}`" @input="(e) => updateAttr(e.target.value, i, 'BarSeriesDisplay', key)"/>
                      {{option.text}}
                    </label>
                  </div>
                </div>
                <div class="type_common_input">
                  <label class="type_gray second_depth">X / Y</label>
                  <div class="row">
                    <input v-model="attr[key].BarSeriesX" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BarSeriesX', key, 'num')" />
                    <input v-model="attr[key].BarSeriesY" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BarSeriesY', key, 'num')" />
                  </div>
                </div>
                <div class="type_common_select">
                  <label class="type_gray second_depth">Font</label>
                  <select v-model="attr[key].BarSeriesFont" dir="rtl" @input="(e) => { updateAttr(e.target.value, i, 'BarSeriesFont', key) }">
                    <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                  </select>
                </div>
                <div class="type_common_select">
                  <label class="type_gray second_depth">Style</label>
                  <select v-model="attr[key].BarSeriesClass" class="type70" dir="rtl" @input="(e) => { updateAttr(e.target.value, i, 'BarSeriesClass', key) }">
                    <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                  </select>
                </div>
                <div class="type_common_input">
                  <label class="type_gray second_depth">Font Size / Color</label>
                  <div class="row">
                    <input v-model="attr[key].BarSeriesSize" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BarSeriesSize', key, 'num')" />
                    <j-color-picker v-model="attr[key].BarSeriesColor" @input="updateAttr"/>
                  </div>
                </div>
                <div class="type_common_input">
                  <label class="type_gray second_depth">Alignment</label>
                  <div class="row">
                    <label v-for="option in alignHOptions" :key="option.index" class="type_many">
                      <input v-model="attr[key].BarSeriesAlign" type="radio" dir="rtl" :value="option.value" :name="`attr_align_${i}__${key}`" @input="(e) => updateAttr(e.target.value, i, 'BarSeriesAlign', key)"/>
                      {{option.text}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div style="height: 10rem;"/>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import _ChartDataDefault from '@/primitives/_chartFloatingDefault'
import { mapState, mapMutations } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-bar',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      bar: true,
      barseries: true,
      line: true,
    },
    msgInfo: {
      type: "",
      title: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"],
    },

    localFloatBarAttrs: [],
    localFloatBarSeriesAttrs: [],

    tabActivator: null,
    msgOpen: false,
  }),
  watch: {
    'chartItem.FloatBar': {
      handler(val) {
        if (!val) return
        this.localFloatBarAttrs = JSON.parse(JSON.stringify(val))
      },
      deep: true
    },
    'chartItem.FloatBarSeries': {
      handler(val) {
        if (!val) return
        this.localFloatBarSeriesAttrs = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    
    seriesKeys() {
      return Object.keys(this.localFloatBarSeriesAttrs[0])
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
    
    appendAttr(type) {
      this.localFloatBarAttrs.push(_ChartDataDefault.FloatBar[0])
      this.localFloatBarSeriesAttrs.push(_ChartDataDefault.FloatBarSeries[0])
      
      this.updateAttr()
    },
    clickRemoveBtn(i, type) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    removeAttr(index, type) {
      this.localFloatBarAttrs[index] = null
      this.localFloatBarAttrs = this.localFloatBarAttrs.filter(attr => !!attr)

      this.localFloatBarSeriesAttrs[index] = null
      this.localFloatBarSeriesAttrs = this.localFloatBarSeriesAttrs.filter(attr => !!attr)
      
      this.updateAttr()
    },
    setGradient(code, v) {
      code.BarColorType = v
      this.updateAttr()
    },
    surfix(i) {
      let surfix_ = ['st', 'nd', 'rd', 'th']
      if (i < 3) return surfix_[i]
      return surfix_[3]
    },
    updateAttr(val, i, el, key, dataType) {
      if(el) {
          if(key) {
            dataType == 'num' ? this.localFloatBarSeriesAttrs[i][key][el] = parseFloat(val) : this.localFloatBarSeriesAttrs[i][key][el] = val
          } else {
            dataType == 'num' ? this.localFloatBarAttrs[i][el] = parseFloat(val) : this.localFloatBarAttrs[i][el] = val
          }
      }

      this.setChartItem({
        FloatBar: JSON.parse(JSON.stringify(this.localFloatBarAttrs)),
        FloatBarSeries: JSON.parse(JSON.stringify(this.localFloatBarSeriesAttrs)),
      })
    },
  }
}
</script>
