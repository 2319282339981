import JChartTabNavigation from '@/components/chart/tabs/JChartTabNavigation'
import JChartTabDatabase from '@/components/chart/tabs/JChartTabDatabase'
import JChartTabCanvas from '@/components/chart/tabs/JChartTabCanvas'
import JChartTabTitle from '@/components/chart/tabs/JChartTabTitle'
import JChartTabNote from '@/components/chart/tabs/JChartTabNote'

import JChartTabTable from './JChartTabTable'
import JChartTabStyle from './JChartTabStyle'
import JChartTabColumn from './JChartTabColumn'

export default {
  JChartTabNavigation,
  JChartTabDatabase,
  JChartTabCanvas,
  JChartTabTable,
  JChartTabColumn,
  JChartTabStyle,
  JChartTabTitle,
  JChartTabNote,
}
