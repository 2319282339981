<template>
  <div>
    <div class="dash_wrapper">
      <div class="j_dash_selector" @click="onOpen">
        <div>
          <svg class="dash_box" viewBox="0 0 20 16">
            <rect x="0" y="0" rx="2" ry="2" width="20" height="16" fill="#fff" />
            <line
              x1="5"
              y1="7"
              x2="16"
              y2="7"
              :stroke="`${color ? color : '#000'}`"
              stroke-width="0.5"
              :stroke-dasharray="`${dash.length != 0 ? dash : 4 } 2`"
            />
          </svg>
        </div>
      </div>
      <div class="dash_list" v-if="openList">
        <div
          v-for="(item, i) in items"
          :key="`_dash_${i}`"
          @click="setDash(item.val, item.interval)"
        >
          <svg class="dash_box" viewBox="0 0 20 16">
            <rect x="0" y="0" rx="2" ry="2" width="20" height="16" fill="#fff" />
            <line
              x1="5"
              y1="7"
              x2="16"
              y2="7"
              stroke="black"
              stroke-width="0.5"
              :stroke-dasharray="`${item.val} 2`"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "j-select-dash",
  data: () => ({
    opened: false,
    openList: false,
    items: [
      { val: 1, interval: 1 },
      { val: 2, interval: 1 },
      { val: 6, interval: 2 },
      { val: 8, interval: 2 }
    ],
    dash: [],
  }),
  props: {
    color: { type: String, default: '#00000' },
  },
  computed: {
    defaultDash: {
      get() { return this.localStyleAttrs.Default.StatusCategory.value },
      set(val) {
        this.localStyleAttrs.Default.StatusCategory.value = val
        this.updateAttr()
      }
    }
  },
  methods: {
    onOpen() {
      this.openList = !this.openList;
    },
    setDash(val) {
      this.dash = val;
      this.$emit('input', this.dash)
      this.openList = false;
    },
  }
}
</script>
<style lang="stylus">
.dash_wrapper {
  position: relative;
}

.j_dash_selector {
  &:hover {
    background-color: '#aaa';
  }
}

.dash_list {
  position: absolute;
  left: 20px;
  top: 0;
  width: 20px;
  z-index: 204;
  background-color: #fff;
  border: 1px solid #ddd;

  >div {
    height: 16px;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
}

svg.dash_box {
  fill: #fff;
}
</style>