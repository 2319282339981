<template>
  <v-flex v-model="bar_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Axes</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div v-if="axisXAva" class="chart_properties_contents">
      <div class="header">
        Axis-X
        <a class="close" v-on:click="collapsed['xAxis'] = !collapsed['xAxis']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['xAxis']">
        <div>
          <span class="label">Line</span>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <span class="label">Display</span>
              <div class="row">
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input
                    v-model="AxisXLineDisplay"
                    type="radio"
                    name="AxisXLineDisplay"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Stroke</label>
              <input v-model="AxisXLineWeight" type="number" />
            </div>
            <div class="type_common_input type_gray">
              <j-color-picker v-model="AxisXLineColor" title="Color"></j-color-picker>
            </div>
          </div>
        </div>
        <div>
          <span class="label">Level</span>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <span class="label">Display</span>
              <div class="row">
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input
                    v-model="AxisXLevelDisplay"
                    type="radio"
                    name="AxisXLevelDisplay"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Space</label>
              <input v-model="AxisXLevelSpace" type="number" />
            </div>
            <span class="label type_gray second_depth">Text</span>
            <div class="type_inner">
              <div class="type_common_select">
                <label class="type_gray third_depth">Font</label>
                <select v-model="AxisXLevelFont" dir="rtl">
                  <option
                    v-for="option in fontOptions"
                    :key="option.index"
                    :value="option.value"
                  >{{option.text}}</option>
                </select>
              </div>
              <div class="type_common_select">
                <label class="type_gray third_depth">Style</label>
                <select v-model="AxisXLevelStyle" dir="rtl" class="type70">
                  <option
                    v-for="option in fontStyleOptions"
                    :key="option.index"
                    :value="option.value"
                  >{{option.text}}</option>
                </select>
              </div>
              <div class="type_common_input">
                <label class="type_gray third_depth">Size</label>
                <input v-model="AxisXLevelSize" type="number" />
              </div>
              <div class="type_common_input type_gray third_depth">
                <label v-if="axisXLevelAutoColorAva" class="type_auto type_ab right25">
                  <input
                    v-model="AxisXLevelAutoColor"
                    type="checkbox"
                    true-value="Y"
                    false-value="N"
                  />Auto
                </label>
                <j-color-picker v-model="AxisXLevelColor" title="Color"></j-color-picker>
              </div>
              <div v-if="axisXLevelDirectionAva" class="type_common_input column type_gray third_depth">
                <span class="label ">Direction</span>
                <div class="row justify_end">
                  <label v-for="option in directionOptions" :key="option.index" class="type_many">
                    <input
                      v-model="AxisXLevelDirection"
                      type="radio"
                      name="AxisXLevelDirection"
                      :value="option.value"
                    />
                    {{option.text}}
                  </label>
                </div>
              </div>
            </div>
            <div v-if="axisXLevelUnitAva">
              <span class="label type_gray second_depth">Unit</span>
              <div class="type_inner">
                <div class="type_common_input">
                  <label class="type_gray third_depth">Size</label>
                  <input v-model="AxisXLevelUnitSize" type="number" />
                </div>
              </div>
              <div class="type_inner">
                <div class="type_common_input">
                  <label class="type_gray third_depth">Format</label>
                  <input v-model="AxisXLevelUnitFormat" type="text" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div v-if="axisYAva" class="chart_properties_contents">
      <div class="header">
        Axis-Y
        <a class="close" v-on:click="collapsed['yAxis'] = !collapsed['yAxis']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['yAxis']">
        <div>
          <span class="label">Position</span>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <span class="label">Alignment</span>
              <div class="row">
                <label v-for="option in axisYPosition" :key="option.index" class="type_many">
                  <input
                    v-model="AxisYLevelPosition"
                    type="radio"
                    name="AxisYLevelPosition"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span class="label">Line</span>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <span class="label">Display</span>
              <div class="row">
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input
                    v-model="AxisYLineDisplay"
                    type="radio"
                    name="AxisYLineDisplay"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Stroke</label>
              <input v-model="AxisYLineWeight" type="number" />
            </div>
            <div class="type_common_input type_gray">
              <j-color-picker v-model="AxisYLineColor" title="Color"></j-color-picker>
            </div>
          </div>
        </div>
        <div>
          <span class="label">Level</span>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <span class="label">Display</span>
              <div class="row">
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input
                    v-model="AxisYLevelDisplay"
                    type="radio"
                    name="AxisYLevelDisplay"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Space</label>
              <input v-model="AxisYLevelSpace" type="number" />
            </div>
            <span class="label type_gray second_depth">Text</span>
            <div class="type_inner">
              <div class="type_common_select">
                <label class="type_gray third_depth">Font</label>
                <select v-model="AxisYLevelFont" dir="rtl">
                  <option
                    v-for="option in fontOptions"
                    :key="option.index"
                    :value="option.value"
                  >{{option.text}}</option>
                </select>
              </div>
              <div class="type_common_select">
                <label class="type_gray third_depth">Style</label>
                <select v-model="AxisYLevelStyle" dir="rtl" class="type70">
                  <option
                    v-for="option in fontStyleOptions"
                    :key="option.index"
                    :value="option.value"
                  >{{option.text}}</option>
                </select>
              </div>
              <div class="type_common_input">
                <label class="type_gray third_depth">Size</label>
                <input v-model="AxisYLevelSize" type="number" />
              </div>
              <div class="type_common_input type_gray third_depth">
                <label v-if="axisYLevelAutoColorAva" class="type_auto type_ab right25">
                  <input
                    v-model="AxisYLevelAutoColor"
                    type="checkbox"
                    true-value="Y"
                    false-value="N"
                  />Auto
                </label>
                <j-color-picker v-model="AxisYLevelColor" title="Color"></j-color-picker>
              </div>
            </div>
            <div v-if="axisYLevelUnitAva">
              <span class="label type_gray second_depth">Unit</span>
              <div class="type_inner">
                <div class="type_common_input">
                  <label class="type_gray third_depth">Size</label>
                  <input v-model="AxisYLevelUnitSize" type="number" />
                </div>
              </div>
              <div class="type_inner">
                <div class="type_common_input">
                  <label class="type_gray third_depth">Format</label>
                  <input v-model="AxisYLevelUnitFormat" type="text" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div v-if="boundAva" class="chart_properties_contents">
      <div class="header">
        Bounds
        <a class="close" v-on:click="collapsed['bound'] = !collapsed['bound']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['bound']">
        <div class="type_common_input">
          <label>Maximum</label>
          <label class="type_auto type_ab right45">
            <input v-model="AxisYBoundsAuto" type="checkbox" true-value="Y" false-value="N" />Auto
          </label>
          <input v-model="AxisYBoundsMax" type="number" />
        </div>
      </v-flex>
    </div>
    <div v-if="gridAva" class="chart_properties_contents">
      <div class="header">
        Grids
        <a class="close" v-on:click="collapsed['grid'] = !collapsed['grid']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['grid']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="AxisYGridDisplay"
                type="radio"
                name="AxisYGridDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Stroke</label>
          <input v-model="AxisYGridWeight" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="AxisYGridColor" title="Color"></j-color-picker>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'


export default {
  name: 'j-chart-tab-axis',
  mixins: [
    StaticOptions
  ],
  props: {
    // -------------------------------------------------------   Elements displayed received from modal
    axisX: { type: Boolean, default: true },
    axisXLevelDirection: { type: Boolean, default: true },
    axisXLevelAutoColor: { type: Boolean, default: true },
    axisXLevelUnit: { type: Boolean, default: true },
    axisY: { type: Boolean, default: true },
    axisYLevelAutoColor: { type: Boolean, default: true },
    axisYLevelUnit: { type: Boolean, default: true },
    bound: { type: Boolean, default: true },
    grid: { type: Boolean, default: true },
    axisColorAuto: { type: Boolean, default: true },
  },
  data: () => ({
    collapsed: {
      xAxis: false,
      yAxis: false,
      bound: false,
      grid: false,
    },
    bar_general: null,
    currentIcon: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['axis']),

    // -------------------------------------------------------------------  display & default data setting
    axisXAva() {
      if (!this.chartItem.ChartType) return
      if (this.chartItem.ChartNo === void 0)
        this.axisX ? this.setChartItem({ AxisXLevelDisplay: 'Y', AxisXLineDisplay: 'Y' }) :
          this.setChartItem({ AxisXLevelDisplay: 'N', AxisXLineDisplay: 'N' })
      return this.axisX
    },
    axisXLevelDirectionAva() {
      if (!this.chartItem.ChartType) return
      if (this.chartItem.ChartNo === void 0)
        this.setChartItem({ AxisXLevelDirection: 'Horizontal' })
      return this.axisXLevelDirection
    },
    axisXLevelAutoColorAva() {
      if (!this.chartItem.ChartType) return
      if (this.chartItem.ChartNo === void 0)
        this.axisXLevelAutoColor ? this.setChartItem({ AxisXLevelAutoColor: 'Y' })
          : this.setChartItem({ AxisXLevelAutoColor: 'N' })
      return this.axisXLevelAutoColor
    },
    axisXLevelUnitAva() {
      return this.axisXLevelUnit
    },
    axisYAva() {
      if (!this.chartItem.ChartType) return
      if (this.chartItem.ChartNo === void 0)
        this.axisY ? this.setChartItem({ AxisYLevelDisplay: 'Y', AxisYLineDisplay: 'Y' }) :
          this.setChartItem({ AxisYLevelDisplay: 'N', AxisYLineDisplay: 'N' })
      return this.axisY
    },
    axisYLevelAutoColorAva() {
      if (!this.chartItem.ChartType) return
      if (this.chartItem.ChartNo === void 0)
        (this.axisYLevelAutoColor) ? this.setChartItem({ axisYLevelAutoColor: 'Y' })
          : this.setChartItem({ axisYLevelAutoColor: 'N' })
      return this.axisYLevelAutoColor * this.axisColorAuto
    },
    axisYLevelUnitAva() {
      return this.axisYLevelUnit
    },
    boundAva() {
      return this.bound
    },
    gridAva() {
      if (!this.chartItem.ChartType) return
      if (this.chartItem.ChartNo === void 0)
        this.grid ? this.setChartItem({ AxisYGridDisplay: 'Y' })
          : this.setChartItem({ AxisYGridDisplay: 'N' })
      return this.grid
    },

    //----------------------------------------------------------------- Vuex Data
    AxisXLevelDisplay: {
      get() { return this.axis.AxisXLevelDisplay },
      set(val) { this.setChartItem({ AxisXLevelDisplay: val }) }
    },
    AxisXLevelPosition: {
      get() { return this.axis.AxisXLevelPosition },
      set(val) { this.setChartItem({ AxisXLevelPosition: val }) }
    },
    AxisXLevelSpace: {
      get() { return this.axis.AxisXLevelSpace || 0 },
      set(val) { this.setChartItem({ AxisXLevelSpace: parseFloat(val || 0) }) }
    },
    AxisXLevelFont: {
      get() { return this.axis.AxisXLevelFont },
      set(val) { this.setChartItem({ AxisXLevelFont: val }) }
    },
    AxisXLevelStyle: {
      get() { return this.axis.AxisXLevelStyle },
      set(val) { this.setChartItem({ AxisXLevelStyle: val }) }
    },
    AxisXLevelSize: {
      get() { return this.axis.AxisXLevelSize || 0 },
      set(val) { this.setChartItem({ AxisXLevelSize: parseFloat(val || 0) }) }
    },
    AxisXLevelWeight: {
      get() { return this.axis.AxisXLevelWeight || 0 },
      set(val) { this.setChartItem({ AxisXLevelWeight: parseFloat(val || 0) }) }
    },
    AxisXLevelColor: {
      get() { return this.axis.AxisXLevelColor },
      set(val) { this.setChartItem({ AxisXLevelColor: val }) }
    },
    AxisXLevelAutoColor: {
      get() { return this.axis.AxisXLevelAutoColor },
      set(val) { this.setChartItem({ AxisXLevelAutoColor: val }) }
    },
    AxisXLevelUnitSize: {
      get() { return this.axis.AxisXLevelUnitSize || 0 },
      set(val) { this.setChartItem({ AxisXLevelUnitSize: parseFloat(val || 0) }) }
    },
    AxisXLevelUnitFormat: {
      get() { return this.axis.AxisXLevelUnitFormat },
      set(val) { this.setChartItem({ AxisXLevelUnitFormat: val }) }
    },
    AxisXLevelDirection: {
      get() { return this.axis.AxisXLevelDirection },
      set(val) { this.setChartItem({ AxisXLevelDirection: val }) }
    },

    AxisXLineDisplay: {
      get() { return this.axis.AxisXLineDisplay },
      set(val) { this.setChartItem({ AxisXLineDisplay: val }) }
    },
    AxisXLineWeight: {
      get() { return this.axis.AxisXLineWeight || 0 },
      set(val) { this.setChartItem({ AxisXLineWeight: parseFloat(val || 0) }) }
    },
    AxisXLineColor: {
      get() { return this.axis.AxisXLineColor },
      set(val) { this.setChartItem({ AxisXLineColor: val }) }
    },


    AxisYLevelDisplay: {
      get() { return this.axis.AxisYLevelDisplay },
      set(val) { this.setChartItem({ AxisYLevelDisplay: val }) }
    },
    AxisYLevelPosition: {
      get() { return this.axis.AxisYLevelPosition },
      set(val) { this.setChartItem({ AxisYLevelPosition: val }) }
    },
    AxisYLevelSpace: {
      get() { return this.axis.AxisYLevelSpace || 0 },
      set(val) { this.setChartItem({ AxisYLevelSpace: parseFloat(val || 0) }) }
    },
    AxisYLevelFont: {
      get() { return this.axis.AxisYLevelFont },
      set(val) { this.setChartItem({ AxisYLevelFont: val }) }
    },
    AxisYLevelStyle: {
      get() { return this.axis.AxisYLevelStyle },
      set(val) { this.setChartItem({ AxisYLevelStyle: val }) }
    },
    AxisYLevelSize: {
      get() { return this.axis.AxisYLevelSize || 0 },
      set(val) { this.setChartItem({ AxisYLevelSize: parseFloat(val || 0) }) }
    },
    AxisYLevelWeight: {
      get() { return this.axis.AxisYLevelWeight || 0 },
      set(val) { this.setChartItem({ AxisYLevelWeight: parseFloat(val || 0) }) }
    },
    AxisYLevelAutoColor: {
      get() { return this.axis.AxisYLevelAutoColor },
      set(val) {
        this.setChartItem({ AxisYLevelAutoColor: val })      }
    },
    AxisYLevelColor: {
      get() { return this.axis.AxisYLevelColor },
      set(val) { this.setChartItem({ AxisYLevelColor: val }) }
    },
    AxisYLevelUnitSize: {
      get() { return this.axis.AxisYLevelUnitSize || 0 },
      set(val) { this.setChartItem({ AxisYLevelUnitSize: parseFloat(val || 0) }) }
    },
    AxisYLevelUnitFormat: {
      get() { return this.axis.AxisYLevelUnitFormat },
      set(val) { this.setChartItem({ AxisYLevelUnitFormat: val }) }
    },
    AxisYLineDisplay: {
      get() { return this.axis.AxisYLineDisplay },
      set(val) { this.setChartItem({ AxisYLineDisplay: val }) }
    },
    AxisYLineWeight: {
      get() { return this.axis.AxisYLineWeight || 0 },
      set(val) { this.setChartItem({ AxisYLineWeight: parseFloat(val || 0) }) }
    },
    AxisYLineColor: {
      get() { return this.axis.AxisYLineColor },
      set(val) { this.setChartItem({ AxisYLineColor: val }) }
    },

    // 공통으로 쓰여야해서 명칭 변경해야함
    AxisYBoundsAuto: {
      get() { return this.axis.AxisYBoundsAuto },
      set(val) { this.setChartItem({ AxisYBoundsAuto: val }) }
    },
    AxisYBoundsMax: {
      get() { return this.axis.AxisYBoundsMax || 0 },
      set(val) { this.setChartItem({ AxisYBoundsMax: parseFloat(val || 0) }) }
    },

    AxisYGridDisplay: {
      get() { return this.axis.AxisYGridDisplay },
      set(val) { this.setChartItem({ AxisYGridDisplay: val }) }
    },
    AxisYGridWeight: {
      get() { return this.axis.AxisYGridWeight || 0 },
      set(val) { this.setChartItem({ AxisYGridWeight: parseFloat(val || 0) }) }
    },
    AxisYGridColor: {
      get() { return this.axis.AxisYGridColor },
      set(val) { this.setChartItem({ AxisYGridColor: val }) }
    },

  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
  }

}
</script>
