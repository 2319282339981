<template>
  <div>
    <j-chart-tab-text v-show="mode == 'Text'"/>
    <j-chart-tab-color v-show="mode == 'Coloring'"/>
    <j-chart-tab-bar v-show="mode == 'Bar'"/>
    <j-chart-tab-icon v-show="mode == 'Icon'"/>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from 'vuex'
import JChartTabProps from './TabStyles'


export default {
  name: 'j-chart-tab-style',
  components: {
    ...JChartTabProps
  },
  props: {
    mode: String,
    text: Boolean,
    color: Boolean,
    bar: Boolean,
    icon: Boolean,
  },
  data: () => ({
    collapsed: {
      text: true,
      color: false,
      progress: false,
      bar: false,
      icon: false,
    },
    localStyleAttrs: {
      Default: {
        TextColor: { code: '', color: '#bdbcbc',},
        CellColor: { code: '', color: '#FFF',},
        CellProg: { code: '', color: '#FFF',},
        BarStyle: { code: '', color: '#FFF',},
        Files: { code: '', name: '' },
      },
      TextColor: [],
      CellColor: [],
      CellProg: [],
      BarChart: { 
        BarLength: Number,
        BarThickness: Number,
        BarRadius: Number,
        Background: String,
        Unit: String,
        BarStyle: [], 
      },
      Icons: {  
        Size: Number,
        Files: [],
      },
    },
  }),
  watch: {
    'chartItem.Style': {
      handler(val) {
        if (!val) return
        this.localStyleAttrs = JSON.parse(JSON.stringify(val))
      },
      deep: true
    },
    // text: {
    //   handler() {
      
    //   },
    //   deep: true
    // },
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    

    defaultTexColor: {
      get() { return this.localStyleAttrs.TextColor.color },
      set(val) {
        this.localStyleAttrs.TextColor.color = val
        this.updateAttr()
      }
    },
    defaultCellColor: {
      get() { return this.localStyleAttrs.CellColor.color },
      set(val) {
        this.localStyleAttrs.CellColor.color = val
        this.updateAttr()
      }
    },
    defaultCellProg: {
      get() { return this.localStyleAttrs.CellProg.color },
      set(val) {
        this.localStyleAttrs.CellProg.color = val
        this.updateAttr()
      }
    },
    
    BarLength: {
      get() { return this.localStyleAttrs.BarChart.BarLength || 0 },
      set(val) {
        this.localStyleAttrs.BarChart.BarLength = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    BarThickness: {
      get() { return this.localStyleAttrs.BarChart.BarThickness || 0 },
      set(val) {
        this.localStyleAttrs.BarChart.BarThickness = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    BarRadius: {
      get() { return this.localStyleAttrs.BarChart.BarRadius || 0 },
      set(val) {
        this.localStyleAttrs.BarChart.BarRadius = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    Background: {
      get() { return this.localStyleAttrs.BarChart.Background },
      set(val) {
        this.localStyleAttrs.BarChart.Background = val
        this.updateAttr()
      }
    },
    Unit: {
      get() { return this.localStyleAttrs.BarChart.Unit },
      set(val) {
        this.localStyleAttrs.BarChart.Unit = val
        this.updateAttr()
      }
    },
    CircleIconName() { return this.chartItem.CircleIconName },
    IconSize: {
      get() { return this.localStyleAttrs.Icons.Size || 0 },
      set(val) {
        this.localStyleAttrs.Icons.Size = parseFloat(val || 0)
        this.updateAttr()
      }
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    appendAttr(typeName) {
      let default_ = this.localStyleAttrs.Default[typeName]

      if(typeName == 'BarStyle') this.localStyleAttrs.BarChart[typeName].push(default_)
      else if(typeName == 'Files') this.localStyleAttrs.Icons[typeName].push(default_)
      else this.localStyleAttrs[typeName].push(default_)
      
      this.updateAttr()
    },
    removeAttr(typeName, index) {
      if(typeName == 'BarStyle') {
        this.localStyleAttrs.BarChart[typeName][index] = ''
        this.localStyleAttrs.BarChart[typeName] = this.localStyleAttrs.BarChart[typeName].filter(v => !!v)
      } else if(typeName == 'Files') {
        this.localStyleAttrs.Icons[typeName][index] = ''
        this.localStyleAttrs.Icons[typeName] = this.localStyleAttrs.Icons[typeName].filter(v => !!v)
      } else {
        this.localStyleAttrs[typeName][index] = ''
        this.localStyleAttrs[typeName] = this.localStyleAttrs[typeName].filter(v => !!v)
      }
      this.updateAttr()
    },
    updateAttr() {
      this.setChartItem({ Style: this.localStyleAttrs })
    }
  }
}
</script>
