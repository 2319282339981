import JChartTabNavigation from '@/components/chart/tabs/JChartTabNavigation'
import JChartTabDatabase from '@/components/chart/tabs/JChartTabDatabase'
import JChartTabCanvas from '@/components/chart/tabs/JChartTabCanvas'
import JChartTabTitle from '@/components/chart/tabs/JChartTabTitle'
import JChartTabNote from '@/components/chart/tabs/JChartTabNote'
import JChartTabCircle from './JChartTabCircle'
import JChartTabCircleMulti from './JChartTabCircleMulti'
import JChartTabLegend from './JChartTabLegend'
import JChartTabValue from './JChartTabValue'
import JChartTabPackage from './JChartTabPackage'

export default {
  JChartTabNavigation,
  JChartTabDatabase,
  JChartTabCanvas,
  JChartTabCircle,
  JChartTabCircleMulti,
  JChartTabTitle,
  JChartTabValue,
  JChartTabLegend,
  JChartTabNote,
  JChartTabPackage,
}
