<template>
  <v-flex v-model="bar3d_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Legend</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        General
        <a class="close" v-on:click="collapsed['general'] = !collapsed['general']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['general']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="LegendDisplay"
                type="radio"
                name="LegendDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="LegendX" type="text" class="type_double" />
          <input v-model="LegendY" type="text" />
        </div>
        <div class="type_common_input column">
          <span class="label">Direction</span>
          <div class="row justify_end">
            <label v-for="option in directionOptions" :key="option.index" class="type_many">
              <input
                v-model="LegendDirection"
                type="radio"
                name="LegendDirection"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input column">
          <span class="label">
            Base Point
            <small class="type_ref03">(Horizontal only)</small>
          </span>
          <div class="row justify_end">
            <label v-for="option in alignHOptions" :key="option.index" class="type_many">
              <input
                v-model="LegendBasepoint"
                type="radio"
                name="LegendBasepoint"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
      </v-flex>
    </div>
    <div v-if="hideProperty" class="chart_properties_contents">
      <div class="header">
        Title
        <a class="close" v-on:click="collapsed['title'] = !collapsed['title']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['title']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="LegendTitleDisplay"
                type="radio"
                name="LegendTitleDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="LegendTitleFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="LegendTitleStyle" dir="rtl" class="type70">
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="LegendTitleSize" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="LegendTitleColor" title="Color"></j-color-picker>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Series
        <a class="close" v-on:click="collapsed['series'] = !collapsed['series']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['series']">
        <div>
          <span class="label">Bullets</span>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <span class="label">Display</span>
              <div class="row">
                <label
                  v-for="option in yesnoOptions"
                  :key="option.index"
                  class="type_many type_gray"
                >
                  <input
                    v-model="LegendBulletDisplay"
                    type="radio"
                    name="LegendBulletDisplay"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
            <div v-if="hideProperty" class="type_common_input type_gray">
              <span class="label">Type</span>
              <div class="row">
                <label
                  v-for="option in bulletTypeOptions"
                  :key="option.index"
                  class="type_many type_gray"
                >
                  <input
                    v-model="LegendBulletType"
                    type="radio"
                    name="LegendBulletType"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Size</label>
              <input v-model="LegendBulletSize" type="number" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">
                Space
                <small class="type_ref03">(after bullet)</small>
              </label>
              <input v-model="LegendBulletSpace" type="number" />
            </div>
          </div>
        </div>
        <div>
          <span class="label">Series Text</span>
          <div class="type_inner">
            <div class="type_common_select">
              <label class="type_gray second_depth">Font</label>
              <select v-model="LegendSeriesFont" dir="rtl">
                <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Style</label>
              <select v-model="LegendSeriesStyle" dir="rtl" class="type70">
                <option
                  v-for="option in fontStyleOptions"
                  :key="option.index"
                  :value="option.value"
                >{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Size</label>
              <input v-model="LegendSeriesSize" type="number" />
            </div>
            <div class="type_common_input type_gray">
              <j-color-picker v-model="LegendSeriesColor" title="Color"></j-color-picker>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">
                Space
                <small class="type_ref03">(after text)</small>
              </label>
              <input v-model="LegendSeriesSpace" type="number" />
            </div>
          </div>
        </div>
        <div v-if="hideProperty">
          <span class="label">Value</span>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <span class="label">Display</span>
              <div class="row">
                <label
                  v-for="option in yesnoOptions"
                  :key="option.index"
                  class="type_many type_gray"
                >
                  <input
                    v-model="LegendValueDisplay"
                    type="radio"
                    name="LegendValueDisplay"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Size</label>
              <input v-model="LegendValueSize" type="number" />
            </div>
            <div class="type_common_input type_gray">
              <j-color-picker v-model="LegendValueColor" title="Color"></j-color-picker>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Round</label>
              <input v-model="LegendValueRound" type="number" />
            </div>
          </div>
        </div>
        <div v-if="hideProperty">
          <span class="label">Unit</span>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <span class="label">Display</span>
              <div class="row">
                <label
                  v-for="option in yesnoOptions"
                  :key="option.index"
                  class="type_many type_gray"
                >
                  <input
                    v-model="LegendUnitDisplay"
                    type="radio"
                    name="LegendUnitDisplay"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Format</label>
              <input v-model="LegendUnitFormat" type="text" class="type22" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Size</label>
              <input v-model="LegendUnitSize" type="number" />
            </div>
            <div class="type_common_input type_gray">
              <j-color-picker v-model="LegendUnitColor" title="Color"></j-color-picker>
            </div>
          </div>
        </div>
        <div>
          <span class="label">
            Line Space
            <small class="type_ref03">(Vertical only)</small>
          </span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">
                Space
                <small class="type_ref03">(between line & line)</small>
              </label>
              <input v-model="LegendLineSpace" type="number" />
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-legend',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      general: true,
      title: false,
      series: false
    },

    bar3d_general: null,
  }),
  props: {
    chartName: { type: String, default: "bar" }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['legend']),
    hideProperty() {
      let hideValue 
      this.chartName == 'history' ? hideValue = false : hideValue = true
      return hideValue
    },
    LegendDisplay: {
      get() { return this.legend.LegendDisplay },
      set(val) { this.setChartItem({ LegendDisplay: val }) }
    },
    LegendX: {
      get() { return this.legend.LegendX || 0 },
      set(val) { this.setChartItem({ LegendX: parseFloat(val || 0) }) }
    },
    LegendY: {
      get() { return this.legend.LegendY || 0 },
      set(val) { this.setChartItem({ LegendY: parseFloat(val || 0) }) }
    },
    LegendDirection: {
      get() { return this.legend.LegendDirection },
      set(val) { this.setChartItem({ LegendDirection: val }) }
    },
    LegendBasepoint: {
      get() { return this.legend.LegendBasepoint },
      set(val) { this.setChartItem({ LegendBasepoint: val }) }
    },
    LegendTitleDisplay: {
      get() { return this.legend.LegendTitleDisplay },
      set(val) { this.setChartItem({ LegendTitleDisplay: val }) }
    },
    LegendTitleFont: {
      get() { return this.legend.LegendTitleFont },
      set(val) { this.setChartItem({ LegendTitleFont: val }) }
    },
    LegendTitleStyle: {
      get() { return this.legend.LegendTitleStyle },
      set(val) { this.setChartItem({ LegendTitleStyle: val }) }
    },
    LegendTitleSize: {
      get() { return this.legend.LegendTitleSize || 0 },
      set(val) { this.setChartItem({ LegendTitleSize: parseFloat(val || 0) }) }
    },
    LegendTitleColor: {
      get() { return this.legend.LegendTitleColor },
      set(val) { this.setChartItem({ LegendTitleColor: val }) }
    },
    LegendBulletDisplay: {
      get() { return this.legend.LegendBulletDisplay },
      set(val) { this.setChartItem({ LegendBulletDisplay: val }) }
    },
    LegendBulletType: {
      get() { return this.legend.LegendBulletType },
      set(val) { this.setChartItem({ LegendBulletType: val }) }
    },
    LegendBulletSize: {
      get() { return this.legend.LegendBulletSize || 0 },
      set(val) { this.setChartItem({ LegendBulletSize: parseFloat(val || 0) }) }
    },
    LegendBulletSpace: {
      get() { return this.legend.LegendBulletSpace || 0 },
      set(val) { this.setChartItem({ LegendBulletSpace: parseFloat(val || 0) }) }
    },
    LegendSeriesFont: {
      get() { return this.legend.LegendSeriesFont },
      set(val) { this.setChartItem({ LegendSeriesFont: val }) }
    },
    LegendSeriesStyle: {
      get() { return this.legend.LegendSeriesStyle },
      set(val) { this.setChartItem({ LegendSeriesStyle: val }) }
    },
    LegendSeriesSize: {
      get() { return this.legend.LegendSeriesSize || 0 },
      set(val) { this.setChartItem({ LegendSeriesSize: parseFloat(val || 0) }) }
    },
    LegendSeriesColor: {
      get() { return this.legend.LegendSeriesColor },
      set(val) { this.setChartItem({ LegendSeriesColor: val }) }
    },
    LegendSeriesSpace: {
      get() { return this.legend.LegendSeriesSpace || 0 },
      set(val) { this.setChartItem({ LegendSeriesSpace: parseFloat(val || 0) }) }
    },
    LegendValueDisplay: {
      get() { return this.legend.LegendValueDisplay },
      set(val) { this.setChartItem({ LegendValueDisplay: val }) }
    },
    LegendValueSize: {
      get() { return this.legend.LegendValueSize || 0 },
      set(val) { this.setChartItem({ LegendValueSize: parseFloat(val || 0) }) }
    },
    LegendValueColor: {
      get() { return this.legend.LegendValueColor },
      set(val) { this.setChartItem({ LegendValueColor: val }) }
    },
    LegendValueRound: {
      get() { return this.legend.LegendValueRound || 0 },
      set(val) { this.setChartItem({ LegendValueRound: parseFloat(val || 0) }) }
    },
    LegendUnitDisplay: {
      get() { return this.legend.LegendUnitDisplay },
      set(val) { this.setChartItem({ LegendUnitDisplay: val }) }
    },
    LegendUnitFormat: {
      get() { return this.legend.LegendUnitFormat },
      set(val) { this.setChartItem({ LegendUnitFormat: val }) }
    },
    LegendUnitSize: {
      get() { return this.legend.LegendUnitSize || 0 },
      set(val) { this.setChartItem({ LegendUnitSize: parseFloat(val || 0) }) }
    },
    LegendUnitColor: {
      get() { return this.legend.LegendUnitColor },
      set(val) { this.setChartItem({ LegendUnitColor: val }) }
    },
    LegendLineSpace: {
      get() { return this.legend.LegendLineSpace || 0 },
      set(val) { this.setChartItem({ LegendLineSpace: parseFloat(val || 0) }) }
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
  }
}
</script>
