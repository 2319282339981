<template>
  <v-flex v-model="bar3d_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Legend</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        General
        <a class="close" v-on:click="collapsed['general'] = !collapsed['general']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['general']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="LegendDisplay"
                type="radio"
                name="LegendDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>
            Coordinates
            <small class="light_blue">(Multi only)</small>
          </label>
          <input v-model="LegendX" type="number" class="type_double" />
          <input v-model="LegendY" type="number" />
        </div>
        <div class="type_common_input">
          <label>
            Space(y)
            <small class="light_blue">from the center</small>
          </label>
          <input v-model="LegendSpaceY" type="number" />
        </div>
        <div class="type_common_input">
          <label>Line Space</label>
          <input v-model="LegendLineSpace" type="number" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Bullet
        <a class="close" v-on:click="collapsed['bullet'] = !collapsed['bullet']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['bullet']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="LegendBulletDisplay"
                type="radio"
                name="LegendBulletDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="LegendBulletSize" type="number" />
        </div>
        <div class="type_common_input">
          <label>Space</label>
          <input v-model="LegendBulletSpace" type="number" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Series
        <a class="close" v-on:click="collapsed['series'] = !collapsed['series']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['series']">
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="LegendSeriesFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="LegendSeriesStyle" dir="rtl" class="type70">
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input type_color_float">
          <label>Size / Color</label>
          <div class="input_flex_wrap_end">
            <input v-model="LegendSeriesSize" type="number" />
            <j-color-picker v-model="LegendSeriesColor"></j-color-picker>
          </div>
        </div>
        <div class="type_common_input">
          <label>Space</label>
          <input v-model="LegendSeriesSpace" type="number" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Value
        <a class="close" v-on:click="collapsed['value'] = !collapsed['value']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['value']">
        <div class="type_common_input">
          <span class="label">Data</span>
          <div class="row">
            <label v-for="option in valueTypeOptions" :key="option.index" class="type_many">
              <input
                v-model="LegendValueData"
                name="LegendValueData"
                type="radio"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="LegendValueSize" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="LegendValueColor" title="Color"></j-color-picker>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Unit
        <a class="close" v-on:click="collapsed['unit'] = !collapsed['unit']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['unit']">
        <div class="type_common_input">
          <label>Format</label>
          <input v-model="LegendUnitFormat" type="text" />
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="LegendUnitSize" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="LegendUnitColor" title="Color"></j-color-picker>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-legend',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      general: true,
      bullet: false,
      series: false,
      value: false,
      unit: false,
    },
    valueTypeOptions: [
      { text: "Quantity", value: "Quantity" },
      { text: "Percentage", value: "Percentage" },
    ],

    bar3d_general: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['legend']),

    LegendDisplay: {
      get() { return this.legend.LegendDisplay },
      set(val) { this.setChartItem({ LegendDisplay: val }) }
    },
    LegendX: {
      get() { return this.legend.LegendX || 0 },
      set(val) { this.setChartItem({ LegendX: parseFloat(val || 0) }) }
    },
    LegendY: {
      get() { return this.legend.LegendY || 0 },
      set(val) { this.setChartItem({ LegendY: parseFloat(val || 0) }) }
    },
    LegendSpaceY: {
      get() { return this.legend.LegendSpaceY || 0 },
      set(val) { this.setChartItem({ LegendSpaceY: parseFloat(val || 0) }) }
    },
    LegendLineSpace: {
      get() { return this.legend.LegendLineSpace || 0 },
      set(val) { this.setChartItem({ LegendLineSpace: parseFloat(val || 0) }) }
    },
    LegendBulletDisplay: {
      get() { return this.legend.LegendBulletDisplay },
      set(val) { this.setChartItem({ LegendBulletDisplay: val }) }
    },
    LegendBulletSize: {
      get() { return this.legend.LegendBulletSize || 0 },
      set(val) { this.setChartItem({ LegendBulletSize: parseFloat(val || 0) }) }
    },
    LegendBulletSpace: {
      get() { return this.legend.LegendBulletSpace || 0 },
      set(val) { this.setChartItem({ LegendBulletSpace: parseFloat(val || 0) }) }
    },
    LegendSeriesFont: {
      get() { return this.legend.LegendSeriesFont },
      set(val) { this.setChartItem({ LegendSeriesFont: val }) }
    },
    LegendSeriesStyle: {
      get() { return this.legend.LegendSeriesStyle },
      set(val) { this.setChartItem({ LegendSeriesStyle: val }) }
    },
    LegendSeriesSize: {
      get() { return this.legend.LegendSeriesSize || 0 },
      set(val) { this.setChartItem({ LegendSeriesSize: parseFloat(val || 0) }) }
    },
    LegendSeriesColor: {
      get() { return this.legend.LegendSeriesColor },
      set(val) { this.setChartItem({ LegendSeriesColor: val }) }
    },
    LegendSeriesSpace: {
      get() { return this.legend.LegendSeriesSpace || 0 },
      set(val) { this.setChartItem({ LegendSeriesSpace: parseFloat(val || 0) }) }
    },
    LegendValueData: {
      get() { return this.legend.LegendValueData },
      set(val) { this.setChartItem({ LegendValueData: val }) }
    },
    LegendValueSize: {
      get() { return this.legend.LegendValueSize || 0 },
      set(val) { this.setChartItem({ LegendValueSize: parseFloat(val || 0) }) }
    },
    LegendValueColor: {
      get() { return this.legend.LegendValueColor },
      set(val) { this.setChartItem({ LegendValueColor: val }) }
    },
    LegendValueRound: {
      get() { return this.legend.LegendValueRound || 0 },
      set(val) { this.setChartItem({ LegendValueRound: parseFloat(val || 0) }) }
    },
    LegendUnitFormat: {
      get() { return this.legend.LegendUnitFormat },
      set(val) { this.setChartItem({ LegendUnitFormat: val }) }
    },
    LegendUnitSize: {
      get() { return this.legend.LegendUnitSize || 0 },
      set(val) { this.setChartItem({ LegendUnitSize: parseFloat(val || 0) }) }
    },
    LegendUnitColor: {
      get() { return this.legend.LegendUnitColor },
      set(val) { this.setChartItem({ LegendUnitColor: val }) }
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
  }
}
</script>
