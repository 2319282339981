<template>
  <div>
    <v-dialog 
      v-model="formOpened" 
      fullscreen hide-overlay persistent
      content-class="j_modal dark_theme"
      transition="slide-full-modal-transition" 
    >
      <v-card>
        <div class="modal__header full_title pd_left_3">
          <button class="hamberger_menu" type="button"  @click="onAction('cancel')">
            <v-icon>mdi-menu</v-icon>
          </button>
          <span>Format Donut Chart <span class="sub_title">{{ `${idxNum} ${subTitle}` }}</span></span>
        </div>

        <v-card-text class="modal__formControl properties9 type4 dark_theme">
          <!-- <v-form v-model="valid" ref="form"> -->
            <v-container>
              <v-card-actions class="modal_toolbar">
                <div class="tool_menus">
                  <div>
                  <div
                    data-donut-quickbtn="single"
                    @click="onChartType(__C_.TYPE_CODE_SINGLE)"
                    title="single"
                    :class="chartItem.ChartType == __C_.TYPE_CODE_SINGLE ? 'selected': ''"
                  ></div></div>
                  <div>
                  <div
                    data-donut-quickbtn="double"
                    @click="onChartType(__C_.TYPE_CODE_DOUBLE)"
                    title="double"
                    :class="chartItem.ChartType == __C_.TYPE_CODE_DOUBLE ? 'selected': ''"
                  ></div></div>
                  <div>
                  <div
                    data-donut-quickbtn="triple"
                    @click="onChartType(__C_.TYPE_CODE_TRIPLE)"
                    title="triple"
                    :class="chartItem.ChartType == __C_.TYPE_CODE_TRIPLE ? 'selected': ''"
                  ></div></div>
                  <!-- <div>
                    <div
                      data-donut-quickbtn="multi"
                      title="multi"
                      :class="chartItem.ChartType == __C_.TYPE_CODE_MULTI ? 'selected': ''"
                      @click="onChartType(__C_.TYPE_CODE_MULTI)"
                    ></div>
                  </div> -->
                </div>
              </v-card-actions>
              <div class="modal_dark_btn">
                <j-button
                  class="type01 sky  type_full"
                  :class="{ disabled: !resetable }"
                  :disabled="!resetable"
                  @click="onAction('save')"
                  >Save
                </j-button>
                <j-button
                  class="type01 sky  type_full"
                  :class="{ disabled: !resetable }"
                  :disabled="!resetable"
                  @click="onAction('saveas')" 
                  >SaveAs
                </j-button>
                <j-button v-if="modeMod" 
                  class="type01 delete  type_full" 
                  @click="onAction('delete')"
                  >Delete
                </j-button>
                <div class="check_wrapper">
                  <v-checkbox v-model="update" :label="'Update'"/>
                  <v-checkbox :label="'Page Svg'" />
                  <v-checkbox :label="'Fixed'" />
                </div>
                <button class="help" @click="(e) => { helper=!helper }"></button>
              </div>
              <div class="modal__formControl_inside">
                <v-tabs v-model="stepper" class="wrap__modal_wfix">
                  <v-tab data-type="navigation" title="navigation"></v-tab>
                  <v-tab data-type="database" title="database"></v-tab>
                  <v-tab data-type="canvas" title="canvas"></v-tab>
                  <v-tab data-type="circle" title="circle"></v-tab>
                  <v-tab data-type="title" title="title"></v-tab>
                  <v-tab data-type="value" title="value"></v-tab>
                  <v-tab data-type="legend" title="legend"></v-tab>
                  <v-tab data-type="note" title="note"></v-tab>
                  <v-tab data-type="package" title="package"></v-tab>

                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-navigation ref="general" @import-item="onImport" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-database ref="database" @save="onSave()" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-canvas/>
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-circle
                      v-if="chartItem.ChartType != __C_.TYPE_CODE_MULTI"
                      ref="circle"
                    />
                    <!-- <j-chart-tab-circle-multi v-if="chartItem.ChartType == __C_.TYPE_CODE_MULTI" /> -->
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-title @save="onSave()" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-value />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-legend />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-note />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-package />
                  </v-tab-item>
                </v-tabs>
                <j-chart-donut
                  class="canvasAreaLine"
                  :style="`--area-width: ${this.canvas.CanvasWidth + 1}px; --area-height: ${this.canvas.CanvasHeight + 1}px;`"
                  :ChartType="chartItem.ChartType"
                  :Database="database"
                  :Canvas="canvas"
                  :Circle="circle"
                  :Title="title"
                  :Legends="legend"
                  :Note="note"
                  :Package="package_"
                  :Value="chartItem.Values"
                  :DataItems="chartData"
                  @moved="onMoved"
                  @to-xml-string="onXmlString"
                />
                <div v-if="!isPreviewChart" id="j-chart-table">
                  <div style="display: inline-block;">
                    <j-data-grid
                      hide-actions
                      :headers="previewHeaders"
                      :items="previewItems"
                    >
                      <template #items="props">
                        <tr :active="props.selected">
                          <td>{{ props.item.groupName }}</td>
                          <td style="text-align: center;">{{ props.item[`COL0//title`] }}</td>
                          <td style="text-align: center;">{{ props.item[`COL0//percentage`] }}</td>
                          <td style="text-align: center;">{{ props.item[`COL0//value`] }}</td>
                          <td style="text-align: center;">{{ props.item[`COL1//title`] }}</td>
                          <td style="text-align: center;">{{ props.item[`COL1//percentage`] }}</td>
                          <td style="text-align: center;">{{ props.item[`COL1//value`] }}</td>
                          <td style="text-align: center;">{{ props.item[`COL2//title`] }}</td>
                          <td style="text-align: center;">{{ props.item[`COL2//percentage`] }}</td>
                          <td style="text-align: center;">{{ props.item[`COL2//value`] }}</td>
                          <td style="text-align: center;">{{ props.item[`COL3//title`] }}</td>
                          <td style="text-align: center;">{{ props.item[`COL3//percentage`] }}</td>
                          <td style="text-align: center;">{{ props.item[`COL3//value`] }}</td>
                        </tr>
                      </template>
                    </j-data-grid>
                  </div>
                </div>
                
                <div v-if="helper" id="j-chart-helper">
                  <img :src="helperUrl" />
                </div>

              </div>
            </v-container>
          <!-- </v-form> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- 
      TO DO: Implement the vue extend feature to inherit 'j-alert' 
    -->

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :title-description="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import _ChartDataDefault from '@/primitives/_chartDonutDefault'
import ChartModalMixin from "@/mixins/chart.modal.common"
import JChartTabProps from './SysenvChartlibDonut'

export default {
  name: 'sysenv-chartlib-donut-modal',
  mixins: [
    ChartModalMixin
  ],
  components: {
    ...JChartTabProps
  },
  data: () => ({
    previewHeaders: [
      { type: 'text', text: 'groupName', value: 'groupName', sortable: false },
      { type: 'text', text: '1ST//title', value: 'COL1//title', sortable: false },
      { type: 'text', text: '1ST//percentage', value: 'COL1//percentage', sortable: false },
      { type: 'text', text: '1ST//value', value: 'COL1//value', sortable: false },
      { type: 'text', text: '2ND//title', value: 'COL2//title', sortable: false },
      { type: 'text', text: '2ND//percentage', value: 'COL2//percentage', sortable: false },
      { type: 'text', text: '2ND//value', value: 'COL2//value', sortable: false },
      { type: 'text', text: '3RD//title', value: 'COL3//title', sortable: false },
      { type: 'text', text: '3RD//percentage', value: 'COL3//percentage', sortable: false },
      { type: 'text', text: '3RD//value', value: 'COL3//value', sortable: false },
      { type: 'text', text: '4TH//title', value: 'COL4//title', sortable: false },
      { type: 'text', text: '4TH//percentage', value: 'COL4//percentage', sortable: false },
      { type: 'text', text: '4TH//value', value: 'COL4//value', sortable: false },
    ],
    idxNum: '',
    subTitle: ''
  }),
  computed: {
    helperUrl() { 
      return this.chartItem.ChartNo && this.helper ? require(`../../../assets/helpDoc/logo.png`) : '' },
    previewItems() {
      if(!this.isChartDataAvailable) return []

      let items_ = []
      this.chartData.forEach((data, i) => {
        let item_ = {}
        item_['groupName'] = data[0]['groupName']

        data.forEach((item__, j) => {
          item_[`COL${j}//title`] = item__.title
          item_[`COL${j}//percentage`] = item__.percentage
          item_[`COL${j}//value`] = item__.value
        })
        items_.push(item_)
      })
      return items_
    },
  },
  watch: {
    // for the default chart-type setting
    chartData: {
      handler(val) {
        if (!val || val.length === 0) return
        // if the chart-type is not selected yet, 
        // set the default chart-type with 'SGL-A'.
        // if (!this.chartItem['chartItem.ChartType']) this.onChartType('DNT-SGL-X')
      },
      deep: true
    },
    formOpened(val) {
      if (!val) return

      this.stepper = 0
      this.setEmpty()

      if (this.modeNew) {
        this.setChartItem({
          ChartDb: 'Chart',
          CatCode: __C.CHART.CAT_CODE_DONUT,
          JsonString: JSON.stringify(_ChartDataDefault.DataItems),

          ..._ChartDataDefault.Database,
          ..._ChartDataDefault.Canvas,
          ..._ChartDataDefault.Circle,
          ..._ChartDataDefault.Title,
          ..._ChartDataDefault.Legends,
          ..._ChartDataDefault.Note,
          ..._ChartDataDefault.Package,

          QApplied: 'J',
          Values: _ChartDataDefault.Value,
        })

        this.setChartData(_ChartDataDefault.DataItems)
        this.onChartType(__C.CHART.TYPE_CODE_SINGLE)

        setTimeout(() => {
          this.$refs.general.setData(this.formMode)
          this.$refs.circle.appendGauge(true)
        })

      } else {
        this.getChart()
      }
    }
  },
  methods: {
    itemIdx(Idx, chartNo) {
      this.idxNum = `(${Idx} / ${chartNo})`
    },
    itemName(val) {
      this.subTitle = val
    },
    onChartType(typeName) {
      if (!typeName) return

      let updater = {
        ChartType: typeName,
        CircleType: typeName,
      }
      if (typeName == __C.CHART.TYPE_CODE_MULTI) updater['PackageApply'] = 'N'

      this.setChartItem(updater)

      if (typeName != __C.CHART.TYPE_CODE_MULTI) setTimeout(() => {
        this.$refs.circle.resetValues(typeName)
      })
    },
    
    // CRUD Process
    getChart(code=null) {
      return new Promise(resolve => {
        this.chartLibraryService.getChartDonut(code || this.keyValue, res => {
          if(Object.keys(res).length === 0) {
            console.warn(`[Chart Donut: ${this.keyValue}] No data found.`)
            return
          }

          this.setChartItem(res)
          this.onChartType(this.chartItem.ChartType)
          this.setChartData(this.chartItem.QApplied == 'J' ? JSON.parse(this.chartItem.JsonString) : this.chartItem.QResultSummary)

          this.$refs.general.setData(this.formMode)
          if(this.chartItem.ChartType != __C.CHART.TYPE_CODE_MULTI) this.$refs.circle.appendGauge(true)

          this.itemIdx(res.Idx, res.ChartNo)
          this.itemName(res.Name)

          resolve(res)
        })
      })
    },
    putChart(reqItem, callback) {
      this.chartLibraryService.putChartDonut(reqItem, callback)
    },
    sasChart(reqItem, callback) {
      this.chartLibraryService.sasChartDonut(reqItem, callback)
    },
    updChart(reqItem, callback) {
      this.chartLibraryService.updChartDonut(reqItem, callback)
    },
    delChart(chartNo, callback) {
      this.chartLibraryService.delChartDonut(chartNo, callback)
    },
  }
}
</script>
