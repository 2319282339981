export default {
  // ----------------------------------------------- DATABASE
  Database: {
    DatabasePreviewMode: 'Chart',
    DatabaseAccessMode: 'Json',
    DatabaseJson: '',
    DatabaseQuery: '',
    DatabaseSP: ''
  },

  // ----------------------------------------------- CANVAS
  Canvas: {
    CanvasWidth: 1600,
    CanvasHeight: 800,
    CanvasBorderWeight: 0.5,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: 'transparent',
    CanvasOpacity: 0.2,

    // CanvasChartAreaAllowed must be 'Y' in the Skyline-Chart.
    // Chart will be placed in the chart-area to invoke the 
    // zoom-features.
    CanvasChartAreaAllowed: 'Y',
    
    CanvasChartX: 100,
    CanvasChartY: 450,
    CanvasChartWidth: 0,
    CanvasChartHeight: 0,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto'
  },

  // ----------------------------------------------- TITLE
  Title: {
    TitleMainDisplay: 'Y',
    TitleMainText: 'Skyline for Spool Installation',
    TitleMainX: 45,
    TitleMainY: 36,
    TitleMainFont: 'roboto',
    TitleMainStyle: 'regular',
    TitleMainSize: 20,
    TitleMainColor: '#44A9DF',
    TitleMainAlign: 'start',

    TitleSubDisplay: 'Y',
    TitleSubText: 'MD2 Project',
    TitleSubX: 45,
    TitleSubY: 52,
    TitleSubFont: 'roboto',
    TitleSubStyle: 'regular',
    TitleSubSize: 12,
    TitleSubColor: '#BDBCBC',
    TitleSubAlign: 'start',

    TitleExtraDisplay: 'N',
    TitleExtraText: 'Discipline',
    TitleExtraX: 400,
    TitleExtraY: 430,
    TitleExtraFont: 'roboto',
    TitleExtraStyle: 'regular',
    TitleExtraSize: 14,
    TitleExtraColor: 'gray',
    TitleExtraAlign: 'middle',

    TitleSqlDisplay: 'N',
    TitleSqlText: 'SQL Query...',
    TitleSqlQuery: 'SQL Query...',
    TitleSqlX: 400,
    TitleSqlY: 90,
    TitleSqlFont: 'roboto',
    TitleSqlStyle: 'regular',
    TitleSqlSize: 11,
    TitleSqlColor: 'pink',
    TitleSqlAlign: 'middle',

    TitleLineDisplay: 'N',
    TitleLineX: 30,
    TitleLineY: 30,
    TitleLineLength: 100,
    TitleLineWeight: 1,
    TitleLineColor: 'black'
  },

  // ----------------------------------------------- NOTE
  Note: {
    NoteDisplay: 'Y',
    NoteX: 100,
    NoteY: 740,
    NoteAlign: 'start',
    NoteTitleText: 'Notes.',
    NoteTitleFont: 'roboto',
    NoteTitleStyle: 'regular',
    NoteTitleSize: 11,
    NoteTitleColor: '#757575',

    NoteContentsText: 'Note Contents Here!',
    NoteContentsFont: 'roboto',
    NoteContentsStyle: 'regular',
    NoteContentsSize: 10,
    NoteContentsColor: '#BDBCBC'
  },

  // ----------------------------------------------- TIMELINE
  Timeline: {
    TimelineType: 'TL-A', //TL-A,TL-B

    // 0 value will not be processedl.
    TimelinePeriodStart: 0,
    // 0 value will not be processedl.
    TimelinePeriodCutoff: 0,
    // If both of the TimelinePeriodStart & TimelinePeriodCutoff are 0,
    // and if both of the TimelinePeriodFixedStart & TimelinePeriodFixedEnd
    // has date value, it will be processed.
    TimelinePeriodFixedStart: '',
    TimelinePeriodFixedEnd: '',
    // TimelinePeriodFixedStart: '2019-08-30',
    // TimelinePeriodFixedEnd: '2019-10-18',
    // If all the conditions are not matched with, the process
    // of truncating the data will not be executed.

    TimelineLineStroke: 0.5,
    TimelineLineColor: '#000',

    TimelineWeekLineHeight: 3,
    TimelineWeekLineStroke: 0.5,
    TimelineWeekLineSpace: 5,
    TimelineWeekLineColor: '#000',
    TimelineWeekTextDisplay: 'Y',
    TimelineWeekTextFont: 'roboto',
    TimelineWeekTextStyle: 'regular',
    TimelineWeekTextSize: 11,
    TimelineWeekTextColor: '#000',

    TimelineMonthDisplay: 'Y',
    TimelineMonthLineHeight: 0,
    TimelineMonthLineStroke: 0.5,
    TimelineMonthLineSpace: 5,
    TimelineMonthLineColor: '#000',
    TimelineMonthTextFont: 'roboto',
    TimelineMonthTextStyle: 'regular',
    TimelineMonthTextSize: 11,
    TimelineMonthTextColor: 'gray',

    TimelineYearDisplay: 'Y',
    TimelineYearLineHeight: 0,
    TimelineYearLineSpace: 5,
    TimelineYearLineStroke: 0.5,
    TimelineYearLineColor: '#000',
    TimelineYearTextFont: 'roboto',
    TimelineYearTextStyle: 'regular',
    TimelineYearTextSize: 12,
    TimelineYearTextColor: '#000'
  },

  // ----------------------------------------------- AXIS
  AxisY: {
    AxisYLevelDisplay: 'Y',
    AxisYLevelPosition: 'left', // left, right
    AxisYLevelSpace: 10,
    AxisYLevelFont: 'roboto',
    AxisYLevelStyle: 'regular',
    AxisYLevelSize: 12,
    AxisYLevelWeight: 400,
    AxisYLevelColor: '#E0E0E0',
    AxisYLevelUnitSize: 10,
    AxisYLevelUnitFormat: '%',

    AxisYLineDisplay: 'N',
    AxisYLineWeight: 0.5,
    AxisYLineColor: '#E0E0E0',

    AxisYBoundsAuto: 'Y',
    AxisYBoundsMax: 100,

    AxisYGridDisplay: 'Y',
    AxisYGridWeight: 0.5,
    AxisYGridColor: '#E0E0E0'
  },

  // ----------------------------------------------- BOXES
  Boxes: {
    BoxesChartType: 'Auto',
    BoxesChartFixed: 300,

    BoxesBoxWidth: 45,
    BoxesBoxHeight: 17,
    BoxesBoxRadius: 3,
    BoxesBoxStroke: 1,
    BoxesBoxSpace: 2,

    BoxesTextFont: 'roboto',
    BoxesTextStyle: 'regular',
    BoxesTextSize: 11,
    BoxesTextColor: '#000000',

    BoxesValueDisplay: 'Y',
    BoxesValueFont: 'roboto',
    BoxesValueStyle: 'regular',
    BoxesValueSize: 11,
    BoxesValueColor: '#000000'
  },

  // ----------------------------------------------- CHARTS
  Line: {
    LineStyleDisplay: 'Y',
    LineStyleHeight: 300,
    LineStyles: [{ name: 'Engineering', type: 'A_', stroke: 0.5, color: '#EC407A' }, { name: 'Construction', type: 'A', stroke: 0.5, color: '#F7BACF' }, { name: 'Procurement', type: 'B', stroke: 0.5, color: '#44A9DF' }],

    LineMarkerDisplay: 'Y',
    LineMarkerType: 'circle',
    LineMarkerSize: 4,
    LineMarkerStroke: 2,

    LineValueDisplay: 'Y',
    LineValueFont: 'roboto',
    LineValueStyle: 'regular',
    LineValueSize: 13,
    LineValueColor: 'red',
    LineValueSpace: 10,
    LineValueFormat: '%'
  },

  // ----------------------------------------------- MILESTONE
  Milestone: {
    MilestoneDisplay: 'Y', // Y, N
    MilestoneLineStyle: 0, // length of dash (0, 1, 2, 3, ... : 0 = solid),
    MilestoneLineWeight: 0.5,
    MilestoneLineHeight: 350,
    MilestoneLineColor: '#ff8a80',

    MilestoneTextFont: 'roboto',
    MilestoneTextStyle: 'regular',
    MilestoneTextSize: 9,
    MilestoneTextColor: '#ff8a80',

    MilestoneQApplied: '', // J: json, S: sql, sp
    MilestoneQJson: '',
    MilestoneQSql: '',
    MilestoneQSp: ''
  },

  // Method of that the embeded query result is sent.
  // While the main query result is set to the 'DataItems',
  // Milestone, Note, or Floating table's embeded query result
  // will be sent to the chart through the 'Values'. It could
  // be a explicit way to know where the result of the additional
  // queries come.
  Values: {
    milestone: [
      { CDATE: '2019-08-16', desc: 'Steel Cutting' },
      { CDATE: '2019-09-06', desc: 'Assembly' },
      { CDATE: '2019-10-25', desc: 'Pipe Piece Fabrication' }
    ],
    note: '2018-10-11'
  },

  // ----------------------------------------------- LEGENDS
  Legends: {
    LegendDisplay: 'Y',
    LegendDirection: 'Horizontal',
    LegendX: 45,
    LegendY: 80,
    LegendTitleText: 'Legends',
    LegendTitleFont: 'roboto',
    LegendTitleStyle: 'regular',
    LegendTitleSize: 13,
    LegendTitleColor: '#000',
    LegendTitleSpace: 20,
    LegendTapSpace: 10,

    LegendGroupCatDisplay: 'Y',
    LegendGroupCatText: 'Not yet completed Punch',
    LegendGroupStatusDisplay: 'Y',
    LegendGroupStatusText: 'Subsystem Status',
    LegendGroupFont: 'roboto',
    LegendGroupStyle: 'regular',
    LegendGroupSize: 11,
    LegendGroupColor: '#cfd8dc',
    LegendGroupSpace: 10,

    LegendBulletSize: 10,
    LegendBulletSpace: 15,

    LegendTextFont: 'roboto',
    LegendTextStyle: 'regular',
    LegendTextSize: 10,
    LegendTextColor: '#000',
    LegendTextSpace: 15,

    LegendValueDisplay: 'Y',
    LegendValueStyle: 'regular',
    LegendValueSize: 9,
    LegendValueColor: 'pink'
  },

  // ----------------------------------------------- STATUS
  Status: {
    Default: {
      StatusCategory: { name: 'Default', code: '', value: '#E0E0E0', display: 'Y' },
      StatusStage: { name: 'Not Started', code: 'NOT', value: 'transparent', prog1: '', prog2: '', display: 'Y' },
    },
    StatusCategory: [{ name: 'Default', code: '', value: '#E0E0E0', display: 'Y' },
      { name: 'Category A', code: 'A', value: '#F7BACF', display: 'Y' },
      { name: 'Category B', code: 'B', value: '#44A9DF', display: 'Y' }
    ],
    StatusStage: [
      { name: 'Not Started', code: 'NOT', value: '#FFFFFF', prog1: '', prog2: '', display: 'Y' },
      { name: 'In Progress', code: 'PRO', value: '#B4E3FA', prog1: 'Y', prog2: '', display: 'Y' },
      { name: 'All ITRs Completed', code: 'ITR', value: '#44A9DF', prog1: '', prog2: '', display: 'Y' },
      { name: 'WD Completed', code: 'WD', value: '#CDDC37', prog1: '', prog2: 'Y', display: 'Y' },
      { name: 'MC Completed', code: 'MC', value: '#8BC248', prog1: '', prog2: '', display: 'Y' }
    ]
  },

  DataItems: [
    { cdate: '2019-08-02', line1: 2.3, values: [{ name: '245-01', cat1: '', cat2: 'WD', prog1: 10, prog2: 50 }] },

    { cdate: '2019-08-09', line1: 3.8, values: [{ name: '245-02', cat1: 'A', cat2: 'PRO', prog1: 35, prog2: 0 }, { name: '350-01', cat1: 'B', cat2: 'WD', prog1: 10, prog2: 20 }] },

    {
      cdate: '2019-08-16',
      line1: 5.7,
      values: [
        { name: '350-12', cat1: 'B', cat2: 'WD', prog1: 20, prog2: 75 },
        { name: '245-03', cat1: 'B', cat2: 'PRO', prog1: 85, prog2: 0 },
        { name: '350-03', cat1: 'B', cat2: 'PRO', prog1: 50, prog2: 0 },
        { name: '350-04', cat1: 'B', cat2: 'WD', prog1: 20, prog2: 56 },
        { name: '800-01', cat1: 'A', cat2: 'MC', prog1: 0, prog2: 0 },
        { name: '800-03', cat1: 'A', cat2: 'PRO', prog1: 10, prog2: 0 }
      ]
    },

    {
      cdate: '2019-08-23',
      line1: 11.3,
      values: [
        { name: '800-02', cat1: 'A', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '450-01', cat1: 'A', cat2: 'MC', prog1: 0, prog2: 0 },
        { name: '780-01', cat1: 'A', cat2: 'PRO', prog1: 10, prog2: 0 },
        { name: '900-01', cat1: '', cat2: 'PRO', prog1: 30, prog2: 0 },
        { name: '900-05', cat1: '', cat2: 'ITR', prog1: 0, prog2: 0 },
        { name: '678-01', cat1: '', cat2: 'PRO', prog1: 40, prog2: 0 },
        { name: '678-10', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '780-02', cat1: '', cat2: 'PRO', prog1: 80, prog2: 0 }
      ]
    },

    {
      cdate: '2019-08-30',
      line1: 15.5,
      values: [
        { name: '900-12', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '450-03', cat1: '', cat2: 'PRO', prog1: 100, prog2: 0 },
        { name: '780-04', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '800-12', cat1: '', cat2: 'ITR', prog1: 0, prog2: 0 },
        { name: '450-11', cat1: '', cat2: 'PRO', prog1: 10, prog2: 0 },
        { name: '780-13', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '900-02', cat1: '', cat2: 'PRO', prog1: 30, prog2: 0 },
        { name: '900-11', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '678-12', cat1: '', cat2: 'PRO', prog1: 40, prog2: 0 },
        { name: '678-02', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '780-03', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }
      ]
    },

    {
      cdate: '2019-09-06',
      line1: 20.6,
      values: [
        { name: '450-02', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '900-21', cat1: '', cat2: 'PRO', prog1: 40, prog2: 0 },
        { name: '900-22', cat1: '', cat2: 'ITR', prog1: 0, prog2: 0 },
        { name: '678-03', cat1: '', cat2: 'PRO', prog1: 30, prog2: 0 },
        { name: '678-04', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '780-05', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }
      ]
    },

    {
      cdate: '2019-09-13',
      line1: 27.8,
      values: [
        { name: '450-12', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '900-04', cat1: '', cat2: 'PRO', prog1: 10, prog2: 0 },
        { name: '900-13', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '678-14', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '350-02', cat1: 'B', cat2: 'WD', prog1: 10, prog2: 0 },
        { name: '245-04', cat1: 'B', cat2: 'PRO', prog1: 85, prog2: 0 },
        { name: '350-13', cat1: 'B', cat2: 'PRO', prog1: 50, prog2: 0 },
        { name: '350-14', cat1: 'B', cat2: 'WD', prog1: 10, prog2: 0 },
        { name: '800-11', cat1: 'A', cat2: 'MC', prog1: 0, prog2: 0 },
        { name: '800-22', cat1: 'A', cat2: 'PRO', prog1: 10, prog2: 0 }
      ]
    },

    {
      cdate: '2019-09-20',
      line1: 35.4,
      values: [
        { name: '350-33', cat1: 'B', cat2: 'WD', prog1: 10, prog2: 0 },
        { name: '245-05', cat1: 'B', cat2: 'PRO', prog1: 85, prog2: 0 },
        { name: '350-34', cat1: 'B', cat2: 'PRO', prog1: 50, prog2: 0 },
        { name: '350-15', cat1: 'B', cat2: 'WD', prog1: 10, prog2: 0 },
        { name: '800-04', cat1: 'A', cat2: 'MC', prog1: 0, prog2: 0 },
        { name: '800-13', cat1: 'A', cat2: 'PRO', prog1: 10, prog2: 0 },
        { name: '900-32', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '450-13', cat1: '', cat2: 'PRO', prog1: 100, prog2: 0 },
        { name: '780-43', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }
      ]
    },

    {
      cdate: '2019-09-27',
      line1: 38.9,
      values: [
        { name: '800-44', cat1: 'A', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '450-04', cat1: 'A', cat2: 'MC', prog1: 0, prog2: 0 },
        { name: '780-06', cat1: 'A', cat2: 'PRO', prog1: 10, prog2: 0 },
        { name: '900-06', cat1: '', cat2: 'PRO', prog1: 30, prog2: 0 },
        { name: '900-16', cat1: '', cat2: 'WD', prog1: 10, prog2: 0 },
        { name: '678-56', cat1: '', cat2: 'PRO', prog1: 40, prog2: 0 },
        { name: '678-15', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '780-16', cat1: '', cat2: 'PRO', prog1: 80, prog2: 0 }
      ]
    },

    {
      cdate: '2019-10-04',
      line1: 45.3,
      values: [
        { name: '800-05', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '450-05', cat1: '', cat2: 'PRO', prog1: 10, prog2: 0 },
        { name: '780-66', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '900-07', cat1: '', cat2: 'PRO', prog1: 30, prog2: 0 },
        { name: '780-17', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '900-08', cat1: '', cat2: 'PRO', prog1: 30, prog2: 0 }
      ]
    },

    {
      cdate: '2019-10-11',
      line1: 57.4,
      values: [
        { name: '450-47', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '900-09', cat1: '', cat2: 'PRO', prog1: 10, prog2: 0 },
        { name: '900-17', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '678-07', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '800-06', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '450-06', cat1: '', cat2: 'PRO', prog1: 10, prog2: 0 },
        { name: '780-07', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '900-10', cat1: '', cat2: 'PRO', prog1: 30, prog2: 0 }
      ]
    },

    {
      cdate: '2019-10-18',
      line1: 65.2,
      values: [
        { name: '900-24', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '450-15', cat1: '', cat2: 'PRO', prog1: 100, prog2: 0 },
        { name: '780-25', cat1: 'A', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '800-07', cat1: 'A', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '450-07', cat1: '', cat2: 'PRO', prog1: 10, prog2: 0 },
        { name: '780-08', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '900-93', cat1: '', cat2: 'PRO', prog1: 30, prog2: 0 },
        { name: '900-18', cat1: 'A', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '678-08', cat1: '', cat2: 'PRO', prog1: 40, prog2: 0 },
        { name: '678-06', cat1: 'A', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '780-37', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }
      ]
    },

    {
      cdate: '2019-10-25',
      line1: 0,
      values: [
        { name: '450-95', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '900-23', cat1: '', cat2: 'PRO', prog1: 40, prog2: 0 },
        { name: '900-19', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '780-09', cat1: 'B', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '900-33', cat1: '', cat2: 'PRO', prog1: 30, prog2: 0 },
        { name: '678-09', cat1: 'B', cat2: 'PRO', prog1: 30, prog2: 0 },
        { name: '678-77', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '780-98', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }
      ]
    },

    {
      cdate: '2019-11-01',
      line1: 0,
      values: [
        { name: '800-08', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '450-08', cat1: '', cat2: 'PRO', prog1: 10, prog2: 0 },
        { name: '780-10', cat1: 'B', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '900-34', cat1: '', cat2: 'PRO', prog1: 30, prog2: 0 },
        { name: '780-11', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '900-55', cat1: '', cat2: 'PRO', prog1: 30, prog2: 0 }
      ]
    },

    { cdate: '2019-11-08', line1: 0, values: [{ name: '245-12', cat1: 'A', cat2: 'PRO', prog1: 35, prog2: 0 }, { name: '351-01', cat1: 'B', cat2: 'WD', prog1: 10, prog2: 0 }] },

    { cdate: '2019-11-15', line1: 0, values: [{ name: '245-09', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }] },

    { cdate: '2019-11-22', line1: 0, values: [{ name: '245-13', cat1: 'A', cat2: 'NOT', prog1: 0, prog2: 0 }, { name: '352-01', cat1: 'B', cat2: 'NOT', prog1: 0, prog2: 0 }, { name: '987-55', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }] },

    {
      cdate: '2019-11-29',
      line1: 0,
      values: [
        { name: '987-08', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '986-08', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '987-10', cat1: 'B', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '987-34', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '987-11', cat1: 'B', cat2: 'NOT', prog1: 0, prog2: 0 }
      ]
    },

    {
      cdate: '2019-12-06',
      line1: 0,
      values: [
        { name: '876-08', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '876-01', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '876-10', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '876-34', cat1: 'B', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '876-21', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '900-85', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '779-08', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 },
        { name: '779-01', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }
      ]
    },

    {
      cdate: '2019-12-13',
      line1: 0,
      values: [{ name: '779-10', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }, { name: '779-34', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }, { name: '779-21', cat1: 'B', cat2: 'NOT', prog1: 0, prog2: 0 }, { name: '779-85', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }]
    },

    { cdate: '2019-12-20', line1: 0, values: [{ name: '398-08', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }, { name: '398-21', cat1: 'A', cat2: 'NOT', prog1: 0, prog2: 0 }, { name: '398-85', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }] },

    {
      cdate: '2019-12-27',
      line1: 0,
      values: [{ name: '109-08', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }, { name: '109-01', cat1: 'A', cat2: 'NOT', prog1: 0, prog2: 0 }, { name: '109-10', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }, { name: '109-34', cat1: 'B', cat2: 'NOT', prog1: 0, prog2: 0 }]
    },

    { cdate: '2020-01-03', line1: 0, values: [{ name: '109-21', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }, { name: '109-85', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }] },

    { cdate: '2020-01-10', line1: 0, values: [{ name: '398-01', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }, { name: '398-10', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }] },

    { cdate: '2020-01-17', line1: 0, values: [{ name: '689-34', cat1: '', cat2: 'NOT', prog1: 0, prog2: 0 }] }
  ]
}
