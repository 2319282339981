<template>
  <v-flex v-model="activator__" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Axes</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        General
        <small class="type_ref03">(Left)</small>
        <a class="close" v-on:click="collapsed['general'] = !collapsed['general']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['general']">
        <div>
          <span class="label">Line</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">Stroke</label>
              <input v-model="AxisYLineWeight" type="text" />
            </div>
            <div class="type_common_input type_gray">
              <j-color-picker v-model="AxisYLineColor" title="Color"></j-color-picker>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Primary Axis
        <small class="type_ref03">(Left)</small>
        <a class="close" v-on:click="collapsed['primary'] = !collapsed['primary']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['primary']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="AxisPrLevelDisplay"
                type="radio"
                name="AxisPrLevelDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Space</label>
          <input v-model="AxisPrLevelSpace" type="text" />
        </div>
        <div>
          <span class="label">Bounds</span>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <span class="label ">Centralized</span>
              <div class="row">
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input
                    v-model="AxisPrBoundsCenter"
                    type="radio"
                    name="AxisPrBoundsCenter"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
            <div v-if="AxisPrBoundsCenter!='Y'" class="type_common_input type_gray">
              <span class="label ">Allow Minus Value</span>
              <div class="row">
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input
                    v-model="AxisPrAllowMinusValue"
                    type="radio"
                    name="AxisPrAllowMinusValue"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Min.</label>
              <label class="type_auto type_ab right45">
                <j-check-box v-model="AxisPrBoundsMinAuto" true-value="Y" false-value="N" />Auto
              </label>
              <input v-model="AxisPrBoundsMin" type="text" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Max.</label>
              <label class="type_auto type_ab right45">
                <j-check-box v-model="AxisPrBoundsMaxAuto" true-value="Y" false-value="N" />Auto
              </label>
              <input v-model="AxisPrBoundsMax" type="text" />
            </div>
          </div>
        </div>
        <div>
          <span class="label">Text</span>
          <div class="type_inner">
            <div class="type_common_select">
              <label class="type_gray second_depth">Font</label>
              <select v-model="AxisPrLevelFont" dir="rtl">
                <option
                  v-for="option in fontOptions"
                  :key="option.index"
                  :value="option.value"
                >{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Style</label>
              <select v-model="AxisPrLevelStyle" dir="rtl" class="type70">
                <option
                  v-for="option in fontStyleOptions"
                  :key="option.index"
                  :value="option.value"
                >{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_input type_color_float type_gray">
              <label>Size / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="AxisPrLevelSize"  type="text" />
                <j-color-picker v-model="AxisPrLevelColor"></j-color-picker>
              </div>
            </div>
          </div>
        </div>
        <div>
          <label>Unit</label>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <label>Size</label>
              <input v-model="AxisPrLevelUnitSize"  type="text" />
            </div>
            <div class="type_common_input type_gray">
              <label>Format</label>
              <input v-model="AxisPrLevelUnitFormat"  type="text" />
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Secondary Axis
        <small class="type_ref03">(Right)</small>
        <a class="close" v-on:click="collapsed['secondary'] = !collapsed['secondary']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['secondary']">
        <div class="type_common_input">
          <span class="label ">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="AxisSeLevelDisplay"
                type="radio"
                name="AxisSeLevelDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Space</label>
          <input v-model="AxisSeLevelSpace" type="text" />
        </div>
        <div>
          <span class="label">Bounds</span>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <span class="label ">Centralized</span>
              <div class="row">
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input
                    v-model="AxisSeBoundsCenter"
                    type="radio"
                    name="AxisSeBoundsCenter"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
            <div v-if="AxisSeBoundsCenter!='Y'" class="type_common_input type_gray">
              <span class="label ">Allow Minus Value</span>
              <div class="row">
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input
                    v-model="AxisSeAllowMinusValue"
                    type="radio"
                    name="AxisSeAllowMinusValue"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Min.</label>
              <label class="type_auto type_ab right45">
                <j-check-box v-model="AxisSeBoundsMinAuto" true-value="Y" false-value="N" />Auto
              </label>
              <input v-model="AxisSeBoundsMin" type="text" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Max.</label>
              <label class="type_auto type_ab right45">
                <j-check-box v-model="AxisSeBoundsMaxAuto" true-value="Y" false-value="N" />Auto
              </label>
              <input v-model="AxisSeBoundsMax" type="text" />
            </div>
          </div>
        </div>
        <div>
          <span class="label">Text</span>
          <div class="type_inner">
            <div class="type_common_select">
              <label class="type_gray second_depth">Font</label>
              <select v-model="AxisSeLevelFont" dir="rtl">
                <option
                  v-for="option in fontOptions"
                  :key="option.index"
                  :value="option.value"
                >{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Style</label>
              <select v-model="AxisSeLevelStyle" dir="rtl" class="type70">
                <option
                  v-for="option in fontStyleOptions"
                  :key="option.index"
                  :value="option.value"
                >{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_input type_color_float type_gray">
              <label>Size / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="AxisSeLevelSize"  type="text" />
                <j-color-picker v-model="AxisSeLevelColor"></j-color-picker>
              </div>
            </div>
          </div>
        </div>
        <div>
          <label>Unit</label>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <label>Size</label>
              <input v-model="AxisSeLevelUnitSize"  type="text" />
            </div>
            <div class="type_common_input type_gray">
              <label>Format</label>
              <input v-model="AxisSeLevelUnitFormat"  type="text" />
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Grids
        <a class="close" v-on:click="collapsed['grid'] = !collapsed['grid']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['grid']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="AxisGridDisplay"
                type="radio"
                name="AxisGridDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Lines</label>
          <input v-model="AxisGridNum"  type="text" />
        </div>
        <div class="type_common_input type_color_float">
          <label>Line Weight / Color</label>
          <div class="input_flex_wrap_end">
            <input v-model="AxisGridWeight"  type="number" />
            <j-color-picker v-model="AxisGridColor"></j-color-picker>
          </div>
        </div>
        <div class="type_common_input">
          <label>Opacity</label>
          <input v-model="AxisGridOpacity" type="number" />
        </div>
        <div>
          <span class="label">Zero Line</span>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <span class="label">Display</span>
              <div class="row">
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input
                    v-model="AxisZeroLineDisplay"
                    type="radio"
                    name="AxisZeroLineDisplay"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
            <div class="type_common_input type_color_float type_gray">
              <label>Weight / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="AxisZeroLineWeight"  type="number" />
                <j-color-picker v-model="AxisZeroLineColor"></j-color-picker>
              </div>
            </div>
            <div class="type_common_input type_gray">
              <label>Opacity</label>
              <input v-model="AxisZeroLineOpacity" type="number" />
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-axis',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    activator__: null,
    collapsed: {
      general: true,
      primary: true,
      secondary: false,
      grid: false,
    },
    currentIcon: null,

    localAxisAttrs: {}
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    AxisYLineWeight: {
      get() { return this.localAxisAttrs.AxisYLineWeight || 0 },
      set(val) {
        this.localAxisAttrs.AxisYLineWeight = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    AxisYLineColor: {
      get() { return this.localAxisAttrs.AxisYLineColor || '' },
      set(val) {
        this.localAxisAttrs.AxisYLineColor = val
        this.updateAttr()
      }
    },
    AxisPrLevelDisplay: {
      get() { return this.localAxisAttrs.AxisPrLevelDisplay || '' },
      set(val) {
        this.localAxisAttrs.AxisPrLevelDisplay = val
        this.updateAttr()
      }
    },
    AxisPrLevelSpace: {
      get() { return this.localAxisAttrs.AxisPrLevelSpace || 0 },
      set(val) {
        this.localAxisAttrs.AxisPrLevelSpace = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    AxisPrLevelFont: {
      get() { return this.localAxisAttrs.AxisPrLevelFont || '' },
      set(val) {
        this.localAxisAttrs.AxisPrLevelFont = val
        this.updateAttr()
      }
    },
    AxisPrLevelStyle: {
      get() { return this.localAxisAttrs.AxisPrLevelStyle || '' },
      set(val) {
        this.localAxisAttrs.AxisPrLevelStyle = val
        this.updateAttr()
      }
    },
    AxisPrLevelSize: {
      get() { return this.localAxisAttrs.AxisPrLevelSize || 0 },
      set(val) {
        this.localAxisAttrs.AxisPrLevelSize = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    AxisPrLevelWeight: {
      get() { return this.localAxisAttrs.AxisPrLevelWeight || 0 },
      set(val) {
        this.localAxisAttrs.AxisPrLevelWeight = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    AxisPrLevelColor: {
      get() { return this.localAxisAttrs.AxisPrLevelColor || '' },
      set(val) {
        this.localAxisAttrs.AxisPrLevelColor = val
        this.updateAttr()
      }
    },
    AxisPrLevelUnitSize: {
      get() { return this.localAxisAttrs.AxisPrLevelUnitSize || 0 },
      set(val) {
        this.localAxisAttrs.AxisPrLevelUnitSize = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    AxisPrLevelUnitFormat: {
      get() { return this.localAxisAttrs.AxisPrLevelUnitFormat || '' },
      set(val) {
        this.localAxisAttrs.AxisPrLevelUnitFormat = val
        this.updateAttr()
      }
    },
    AxisPrBoundsCenter: {
      get() { return this.localAxisAttrs.AxisPrBoundsCenter || 'N' },
      set(val) {
        this.localAxisAttrs.AxisPrBoundsCenter = val
        this.updateAttr()
      }
    },
    AxisPrAllowMinusValue: {
      get() { return this.localAxisAttrs.AxisPrAllowMinusValue || 'N' },
      set(val) {
        this.localAxisAttrs.AxisPrAllowMinusValue = val
        this.updateAttr()
      }
    },
    AxisPrBoundsMinAuto: {
      get() { return this.localAxisAttrs.AxisPrBoundsMinAuto || '' },
      set(val) {
        this.localAxisAttrs.AxisPrBoundsMinAuto = val
        this.updateAttr()
      }
    },
    AxisPrBoundsMin: {
      get() { return this.localAxisAttrs.AxisPrBoundsMin || 0 },
      set(val) {
        this.localAxisAttrs.AxisPrBoundsMin = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    AxisPrBoundsMaxAuto: {
      get() { return this.localAxisAttrs.AxisPrBoundsMaxAuto || '' },
      set(val) {
        this.localAxisAttrs.AxisPrBoundsMaxAuto = val
        this.updateAttr()
      }
    },
    AxisPrBoundsMax: {
      get() { return this.localAxisAttrs.AxisPrBoundsMax || 0 },
      set(val) {
        this.localAxisAttrs.AxisPrBoundsMax = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    AxisSeLevelDisplay: {
      get() { return this.localAxisAttrs.AxisSeLevelDisplay || '' },
      set(val) {
        this.localAxisAttrs.AxisSeLevelDisplay = val
        this.updateAttr()
      }
    },
    AxisSeLevelSpace: {
      get() { return this.localAxisAttrs.AxisSeLevelSpace || 0 },
      set(val) {
        this.localAxisAttrs.AxisSeLevelSpace = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    AxisSeLevelFont: {
      get() { return this.localAxisAttrs.AxisSeLevelFont || '' },
      set(val) {
        this.localAxisAttrs.AxisSeLevelFont = val
        this.updateAttr()
      }
    },
    AxisSeLevelStyle: {
      get() { return this.localAxisAttrs.AxisSeLevelStyle || '' },
      set(val) {
        this.localAxisAttrs.AxisSeLevelStyle = val
        this.updateAttr()
      }
    },
    AxisSeLevelSize: {
      get() { return this.localAxisAttrs.AxisSeLevelSize || 0 },
      set(val) {
        this.localAxisAttrs.AxisSeLevelSize = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    AxisSeLevelWeight: {
      get() { return this.localAxisAttrs.AxisSeLevelWeight || 0 },
      set(val) {
        this.localAxisAttrs.AxisSeLevelWeight = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    AxisSeLevelColor: {
      get() { return this.localAxisAttrs.AxisSeLevelColor || '' },
      set(val) {
        this.localAxisAttrs.AxisSeLevelColor = val
        this.updateAttr()
      }
    },
    AxisSeLevelUnitSize: {
      get() { return this.localAxisAttrs.AxisSeLevelUnitSize || 0 },
      set(val) {
        this.localAxisAttrs.AxisSeLevelUnitSize = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    AxisSeLevelUnitFormat: {
      get() { return this.localAxisAttrs.AxisSeLevelUnitFormat || '' },
      set(val) {
        this.localAxisAttrs.AxisSeLevelUnitFormat = val
        this.updateAttr()
      }
    },
    AxisSeBoundsCenter: {
      get() { return this.localAxisAttrs.AxisSeBoundsCenter || 'N' },
      set(val) {
        this.localAxisAttrs.AxisSeBoundsCenter = val
        this.updateAttr()
      }
    },
    AxisSeAllowMinusValue: {
      get() { return this.localAxisAttrs.AxisSeAllowMinusValue || 'N' },
      set(val) {
        this.localAxisAttrs.AxisSeAllowMinusValue = val
        this.updateAttr()
      }
    },
    AxisSeBoundsMinAuto: {
      get() { return this.localAxisAttrs.AxisSeBoundsMinAuto || '' },
      set(val) {
        this.localAxisAttrs.AxisSeBoundsMinAuto = val
        this.updateAttr()
      }
    },
    AxisSeBoundsMin: {
      get() { return this.localAxisAttrs.AxisSeBoundsMin || 0 },
      set(val) {
        this.localAxisAttrs.AxisSeBoundsMin = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    AxisSeBoundsMaxAuto: {
      get() { return this.localAxisAttrs.AxisSeBoundsMaxAuto || '' },
      set(val) {
        this.localAxisAttrs.AxisSeBoundsMaxAuto = val
        this.updateAttr()
      }
    },
    AxisSeBoundsMax: {
      get() { return this.localAxisAttrs.AxisSeBoundsMax || 0 },
      set(val) {
        this.localAxisAttrs.AxisSeBoundsMax = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    AxisGridDisplay: {
      get() { return this.localAxisAttrs.AxisGridDisplay || '' },
      set(val) {
        this.localAxisAttrs.AxisGridDisplay = val
        this.updateAttr()
      }
    },
    AxisGridNum: {
      get() { return this.localAxisAttrs.AxisGridNum || 1 },
      set(val) {
        this.localAxisAttrs.AxisGridNum = parseFloat(val || 1)
        this.updateAttr()
      }
    },
    AxisGridWeight: {
      get() { return this.localAxisAttrs.AxisGridWeight || 0 },
      set(val) {
        this.localAxisAttrs.AxisGridWeight = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    AxisGridColor: {
      get() { return this.localAxisAttrs.AxisGridColor || '' },
      set(val) {
        this.localAxisAttrs.AxisGridColor = val
        this.updateAttr()
      }
    },
    AxisGridOpacity: {
      get() { return this.localAxisAttrs.AxisGridOpacity || 0 },
      set(val) {
        this.localAxisAttrs.AxisGridOpacity = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    AxisZeroLineDisplay: {
      get() { return this.localAxisAttrs.AxisZeroLineDisplay || 'N' },
      set(val) {
        this.localAxisAttrs.AxisZeroLineDisplay = val
        this.updateAttr()
      }
    },
    AxisZeroLineWeight: {
      get() { return this.localAxisAttrs.AxisZeroLineWeight || 0 },
      set(val) {
        this.localAxisAttrs.AxisZeroLineWeight = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    AxisZeroLineColor: {
      get() { return this.localAxisAttrs.AxisZeroLineColor || '' },
      set(val) {
        this.localAxisAttrs.AxisZeroLineColor = val
        this.updateAttr()
      }
    },
    AxisZeroLineOpacity: {
      get() { return this.localAxisAttrs.AxisZeroLineOpacity || 0 },
      set(val) {
        this.localAxisAttrs.AxisZeroLineOpacity = parseFloat(val || 0)
        this.updateAttr()
      }
    },
  },
  watch: {
    'chartItem.Axis': {
      handler(val) {
        if (!val) return
        this.localAxisAttrs = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    updateAttr() {
      this.setChartItem({ Axis: this.localAxisAttrs })
    },
  }
}
</script>
