<template>
  <v-flex v-model="bar3d_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Circle</span>
      <small class="type_ref01">Properties</small>
    </div>

    <div class="chart_properties_contents">
      <div class="header">
        Circles
        <a class="close" v-on:click="collapsed['circles'] = !collapsed['circles']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['circles']">
        <div class="type_common_input">
          <label>Center Coordinates</label>
          <input v-model="CircleCenterX" type="number" class="type_double" />
          <input v-model="CircleCenterY" type="number" />
        </div>
        <div class="type_common_input">
          <label>Radius</label>
          <input
            v-for="(radius, i) in CircleRadius"
            type="number"
            :class="classMultiInput(CircleRadius, i)"
            :key="`CircleRadius__${i}`"
            :value="radius"
            @input="(e) => { updateAttr(e.target.value, i, 'CircleRadius', 'general') }"
          />
        </div>
        <div class="type_common_input">
          <label for>Stroke</label>
          <input
            v-for="(stroke, i) in CircleStroke"
            type="number"
            :class="classMultiInput(CircleStroke, i)"
            :key="`CircleStroke__${i}`"
            :value="stroke"
            @input="(e) => { updateAttr(e.target.value, i, 'CircleStroke', 'general') }"
          />
        </div>
        <div class="type_common_input">
          <j-color-picker
            v-for="(color, i) in CircleBkColor"
            :style="CircleBkColor.length > 1 && i === 0 ? 'margin-right: 1px;' : ''"
            :key="`CircleBkColor__${i}`"
            :title="i != 0 ? '' : 'Background Color'"
            :value="color"
            @input="(val) => { updateAttr(val, i, 'CircleBkColor', 'general') }"
          />
        </div>
        <div class="type_common_input">
          <j-color-picker
            v-for="(color, i) in CircleFtColor"
            :style="CircleFtColor.length > 1 && i === 0 ? 'margin-right: 1px;' : ''"
            :key="`CircleFtColor__${i}`"
            :title="i != 0 ? '' : 'Color'"
            :value="color"
            @input="(val) => { updateAttr(val, i, 'CircleFtColor', 'general') }"
          />
        </div>
        <!-- <div class="type_common_input">
          <span class="label">Cap Style</span>
          <div class="row">
            <label v-for="option in capStyleOptions" :key="option.index" class="type_many">
              <input
                v-model="CircleCapStyle"
                type="radio"
                name="CircleCapStyle"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div> -->
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        <div class="row">
          Gauge
          <div class="row" style="margin-left: 0.5rem;">
            <div v-if="gaugeAppendable" class="jcon_temp_plus" @click="appendGauge()"></div>
            <div v-if="gaugeRemovable" class="jcon_temp_minus type_pink" @click="removeGauge"></div>
          </div>
        </div>
        <a class="close" v-on:click="collapsed['gauge'] = !collapsed['gauge']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['gauge']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="CircleGaugeDisplay"
                type="radio"
                name="CircleGaugeDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label for>Radius</label>
          <input
            v-for="(radius, i) in CircleGaugeRadius"
            type="number"
            :class="classMultiInput(CircleGaugeRadius, i)"
            :key="`CircleGaugeRadius__${i}`"
            :value="radius"
            @input="(e) => { updateAttr(e.target.value, i, 'CircleGaugeRadius', 'gauge') }"
          />
        </div>
        <div class="type_common_input">
          <label for>Count No.</label>
          <input
            v-for="(count, i) in CircleGaugeCount"
            type="number"
            :class="classMultiInput(CircleGaugeCount, i)"
            :key="`CircleGaugeCount__${i}`"
            :value="count"
            @input="(e) => { updateAttr(e.target.value, i, 'CircleGaugeCount', 'gauge') }"
          />
        </div>
        <div class="type_common_input">
          <label for>Length</label>
          <input
            v-for="(length, i) in CircleGaugeLength"
            type="number"
            :class="classMultiInput(CircleGaugeLength, i)"
            :key="`CircleGaugeLength__${i}`"
            :value="length"
            @input="(e) => { updateAttr(e.target.value, i, 'CircleGaugeLength', 'gauge') }"
          />
        </div>
        <div class="type_common_input">
          <label for>Stroke</label>
          <input
            v-for="(stroke, i) in CircleGaugeStroke"
            type="number"
            :class="classMultiInput(CircleGaugeStroke, i)"
            :key="`CircleGaugeStroke__${i}`"
            :value="stroke"
            @input="(e) => { updateAttr(e.target.value, i, 'CircleGaugeStroke', 'gauge') }"
          />
        </div>
        <div class="type_common_input">
          <j-color-picker
            v-for="(color, i) in CircleGaugeColor"
            :key="`CircleGaugeColor__${i}`"
            :style="CircleGaugeColor.length > 1 && i === 0 ? 'margin-right: 1px;' : ''"
            :title="i != 0 ? '' : 'Color'"
            :value="color"
            @input="(val) => { updateAttr(val, i, 'CircleGaugeColor', 'gauge') }"
          />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Grid
        <a class="close" v-on:click="collapsed['grid'] = !collapsed['grid']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['grid']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="CircleGridDisplay"
                type="radio"
                name="CircleGridDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>
            Coordinate
            <small>(y)</small>
          </label>
          <input v-model="CircleGridY" type="number" />
        </div>
        <div class="type_common_input">
          <label>
            Length
            <small>(H)</small>
          </label>
          <input v-model="CircleGridLenH" type="number" />
        </div>
        <div class="type_common_input">
          <label>
            Length
            <small>(V)</small>
          </label>
          <input v-model="CircleGridLenV" type="number" />
        </div>
        <div class="type_common_input">
          <label>Stroke</label>
          <input v-model="CircleGridStroke" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="CircleGridColor" title="Color"></j-color-picker>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Icon
        <a class="close" v-on:click="collapsed['icon'] = !collapsed['icon']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['icon']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="CircleIconDisplay"
                type="radio"
                name="CircleIconDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>
            Coordinate
            <small>(y)</small>
          </label>
          <input v-model="CircleIconY" type="number" />
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="CircleIconSize" type="number" />
        </div>
        <div class="type_common_input">
          <j-icon-picker cat-code="FNT" :offset-y="true" :value="CircleIconName" @input="setIconName" />
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import __F from '@/primitives/_function_'
import _ChartDataDefault from '@/primitives/_chartDonutDefault'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'
import JIconPicker from "@/components/floating/JIconPicker"

export default {
  name: 'j-chart-tab-circle',
  mixins: [
    StaticOptions
  ],
  components: {
    JIconPicker
  },
  data: () => ({
    collapsed: {
      circles: true,
      gauge: true,
      grid: false,
      icon: false,
    },
    elementsNumByType: {
      Single: 1,
      Double: 2,
      Triple: 3,
    },
    gaugeNums: 1,
    exp: {
      sql: true,
    },
    // local static options
    capStyleOptions: [],
    LegendPositionOptions: [
      { text: "Inside", val: "inside" },
      { text: "Outside", val: "outside" },
    ],
    generalValues: {
      CircleRadius: [],
      CircleStroke: [],
      CircleBkColor: [],
      CircleFtColor: [],
    },
    gaugeValues: {
      CircleGaugeRadius: [],
      CircleGaugeCount: [],
      CircleGaugeLength: [],
      CircleGaugeStroke: [],
      CircleGaugeColor: [],
    },
    rule: {
      CircleCenterX: (val) => val || 0,
      CircleCenterY: (val) => val || 0,
      CircleRadius: (val) => val || 0,
      CircleStroke: (val) => val || 0,
      CircleBkColor: (val) => val || '',
      CircleFtColor: (val) => val || '',
      CircleCapStyle: (val) => val || '',
      CircleGaugeDisplay: (val) => val || 'Y',
      CircleGaugeRadius: (val) => val || 0,
      CircleGaugeCount: (val) => val || 0,
      CircleGaugeLength: (val) => val || 0,
      CircleGaugeStroke: (val) => val || 0,
      CircleGaugeColor: (val) => val || '',
      CircleGridDisplay: (val) => val || 'Y',
      CircleGridY: (val) => val || 0,
      CircleGridLenH: (val) => val || 0,
      CircleGridLenV: (val) => val || 0,
      CircleGridStroke: (val) => val || 0,
      CircleGridColor: (val) => val || '',
      CircleIconDisplay: (val) => val || 'Y',
      CircleIconName: (val) => val || '',
      CircleIconY: (val) => val || 0,
      CircleIconSize: (val) => val || 0,
    },

    bar3d_general: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    CircleType: {
      get() { return this.chartItem.CircleType },
      set(val) { this.setChartItem({ CircleType: this.rule['CircleType'](val) }) }
    },
    CircleCenterX: {
      get() { return this.chartItem.CircleCenterX },
      set(val) { this.setChartItem({ CircleCenterX: parseFloat(this.rule['CircleCenterX'](val)) }) }
    },
    CircleCenterY: {
      get() { return this.chartItem.CircleCenterY },
      set(val) { this.setChartItem({ CircleCenterY: parseFloat(this.rule['CircleCenterY'](val)) }) }
    },
    CircleRadius() { return this.generalValues.CircleRadius },
    CircleStroke() { return this.generalValues.CircleStroke },
    CircleBkColor() { return this.generalValues.CircleBkColor },
    CircleFtColor() { return this.generalValues.CircleFtColor },

    CircleCapStyle: {
      get() { return this.chartItem.CircleCapStyle },
      set(val) { this.setChartItem({ CircleCapStyle: this.rule['CircleCapStyle'](val) }) }
    },
    CircleGaugeDisplay: {
      get() { return this.chartItem.CircleGaugeDisplay },
      set(val) { this.setChartItem({ CircleGaugeDisplay: this.rule['CircleGaugeDisplay'](val) }) }
    },
    CircleGaugeRadius() { return this.gaugeValues.CircleGaugeRadius },
    CircleGaugeCount() { return this.gaugeValues.CircleGaugeCount },
    CircleGaugeLength() { return this.gaugeValues.CircleGaugeLength },
    CircleGaugeStroke() { return this.gaugeValues.CircleGaugeStroke },
    CircleGaugeColor() { return this.gaugeValues.CircleGaugeColor },

    CircleGridDisplay: {
      get() { return this.chartItem.CircleGridDisplay },
      set(val) { this.setChartItem({ CircleGridDisplay: this.rule['CircleGridDisplay'](val) }) }
    },
    CircleGridY: {
      get() { return this.chartItem.CircleGridY },
      set(val) { this.setChartItem({ CircleGridY: parseFloat(this.rule['CircleGridY'](val)) }) }
    },
    CircleGridLenH: {
      get() { return this.chartItem.CircleGridLenH },
      set(val) { this.setChartItem({ CircleGridLenH: parseFloat(this.rule['CircleGridLenH'](val)) }) }
    },
    CircleGridLenV: {
      get() { return this.chartItem.CircleGridLenV },
      set(val) { this.setChartItem({ CircleGridLenV: parseFloat(this.rule['CircleGridLenV'](val)) }) }
    },
    CircleGridStroke: {
      get() { return this.chartItem.CircleGridStroke },
      set(val) { this.setChartItem({ CircleGridStroke: parseFloat(this.rule['CircleGridStroke'](val)) }) }
    },
    CircleGridColor: {
      get() { return this.chartItem.CircleGridColor },
      set(val) { this.setChartItem({ CircleGridColor: this.rule['CircleGridColor'](val) }) }
    },
    CircleIconDisplay: {
      get() { return this.chartItem.CircleIconDisplay },
      set(val) { this.setChartItem({ CircleIconDisplay: this.rule['CircleIconDisplay'](val) }) }
    },
    CircleIconName() { return this.chartItem.CircleIconName },
    CircleIconY: {
      get() { return this.chartItem.CircleIconY },
      set(val) { this.setChartItem({ CircleIconY: parseFloat(this.rule['CircleIconY'](val)) }) }
    },
    CircleIconSize: {
      get() { return this.chartItem.CircleIconSize },
      set(val) { this.setChartItem({ CircleIconSize: parseFloat(this.rule['CircleIconSize'](val)) }) }
    },

    gaugeAppendable() { 
      if(!this.chartItem || !this.chartItem.ChartType) return false
      return (
        this.chartItem.CircleGaugeRadius &&
        typeof this.chartItem.CircleGaugeRadius == 'object' &&
        this.chartItem.CircleGaugeRadius.length <= 3 &&
        this.chartItem.CircleGaugeRadius.length != this.elementsNumByType[this.chartItem.ChartType]
      )
    },
    gaugeRemovable() { return (
      this.chartItem.CircleGaugeRadius &&
      typeof this.chartItem.CircleGaugeRadius == 'object' &&
      this.chartItem.CircleGaugeRadius.length > 1
    )}
  },
  created() {
    this.capStyleOptions = [
      { text: 'Section', value: 'section' },
      { text: 'Round', value: __C.SVG.STROKE_LINECAP_ROUND },
    ]
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, [
      'setChartItem'
    ]),

    classMultiInput(item, i) {
      let len = item.length
      let className = ''

      if (len > 1 && i === 0) className = 'type_double'
      else if (len > 1 && i != len - 1) className = 'type_middle'

      return className
    },
    removeGauge() {
      if(
        !this.chartItem.CircleGaugeRadius || 
        typeof this.chartItem.CircleGaugeRadius != 'object' || 
        this.chartItem.CircleGaugeRadius.length <= 1
      ) return

      Object.keys(this.gaugeValues).forEach(k => { this.gaugeValues[k].pop() })

      this.setChartItem(JSON.parse(JSON.stringify(this.gaugeValues)))
    },
    appendGauge(reset=false) {
      if (Object.keys(__F.extractEls(this.chartItem, 'canvas')) === 0) return

      let targets = {
        CircleGaugeRadius: _ChartDataDefault.Circle.CircleGaugeRadius,
        CircleGaugeCount: _ChartDataDefault.Circle.CircleGaugeCount,
        CircleGaugeLength: _ChartDataDefault.Circle.CircleGaugeLength,
        CircleGaugeStroke: _ChartDataDefault.Circle.CircleGaugeStroke,
        CircleGaugeColor: _ChartDataDefault.Circle.CircleGaugeColor,
      }

      // let array_ = (
      //   !this.chartItem.CircleGaugeRadius || 
      //   typeof this.chartItem.CircleGaugeRadius != 'object' || 
      //   !this.chartItem.CircleGaugeRadius.length === 0 ?
      //   Array.from({ length: 1 }, () => null) :
      //   Array.from({ length: this.chartItem.CircleGaugeRadius.length + (reset ? 0 : 1) }, () => null)
      // )
      let array_ = Array.from({ length: this.chartItem.CircleGaugeRadius.length + (reset ? 0 : 1) }, () => null)
      array_ = (
        array_.length > this.elementsNumByType[this.chartItem.ChartType] ?
        array_.slice(0, this.elementsNumByType[this.chartItem.ChartType]) :
        array_
      )
      let values_ = {}

      Object.keys(targets).forEach(k => {
        values_[k] = [...array_]
        array_.forEach((_, i) => {
          values_[k][i] = !this.chartItem[k][i] && this.chartItem[k][i] !== 0 ? targets[k][i] : this.chartItem[k][i]
        })
      })

      this.gaugeValues = values_
      this.setChartItem(JSON.parse(JSON.stringify(values_)))
    },
    resetValues(typeName) {
      let elNum = this.elementsNumByType[typeName] || 0

      if (Object.keys(__F.extractEls(this.chartItem, 'canvas')) === 0) return
      if (this.generalValues.CircleRadius.length == elNum) return

      let targets = {
        CircleRadius: _ChartDataDefault.Circle.CircleRadius,
        CircleStroke: _ChartDataDefault.Circle.CircleStroke,
        CircleBkColor: _ChartDataDefault.Circle.CircleBkColor,
        CircleFtColor: _ChartDataDefault.Circle.CircleFtColor,
      }

      let array_ = Array.from({ length: elNum }, () => null)
      let values_ = {}

      Object.keys(targets).forEach(k => {
        values_[k] = [...array_]
        array_.forEach((_, i) => {
          values_[k][i] = !this.chartItem[k][i] && this.chartItem[k][i] !== 0 ? targets[k][i] : this.chartItem[k][i]
        })
      })

      this.generalValues = JSON.parse(JSON.stringify(values_))
      this.setChartItem(JSON.parse(JSON.stringify(values_)))

    },
    setIconName(val) {
      this.setChartItem({ 
        CircleIconUrl: `${__C.HOST_NAME}${val.filePath}` ,
        CircleIconName: val.iconCode 
      })
    },
    updateAttr(val, i, el, typeName) {
      let values_ = JSON.parse(JSON.stringify(this[`${typeName}Values`]))
      values_[el][i] = this.rule[el](val)
      
      this[`${typeName}Values`] = values_
      this.setChartItem({ ...values_ })
    }
  },
}
</script>
