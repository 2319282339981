<template>
  <v-flex v-model="bar_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Milestone</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Line
        <a class="close" v-on:click="collapsed['lines'] = !collapsed['lines']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['lines']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="MilestoneDisplay"
                type="radio"
                name="MilestoneDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div>
          <span class="label">Line</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">Style</label>
              <input v-model="MilestoneLineStyle" class="apply_spin" min="0" max="8" type="number" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Weight</label>
              <input v-model="MilestoneLineWeight" type="number" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Height</label>
              <input v-model="MilestoneLineHeight" type="number" />
            </div>
            <div class="type_common_input type_gray">
              <j-color-picker v-model="MilestoneLineColor" title="Color"></j-color-picker>
            </div>
          </div>
        </div>
        <div>
          <span class="label">Text</span>
          <div class="type_inner">
            <div class="type_common_select">
              <label class="type_gray second_depth">Font</label>
              <select v-model="MilestoneTextFont" dir="rtl">
                <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Style</label>
              <select v-model="MilestoneTextStyle" dir="rtl" class="type70">
                <option
                  v-for="option in fontStyleOptions"
                  :key="option.index"
                  :value="option.value"
                >{{ option.text }}</option>
              </select>
            </div>
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Size / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="MilestoneTextSize" type="number" />
                <j-color-picker v-model="MilestoneTextColor"></j-color-picker>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Access Database
        <a class="close" v-on:click="collapsed['access'] = !collapsed['access']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['access']">
        <div class="radio">
          <label v-for="item in databaseAccessOptions" :key="item.id">
            <input
              v-model="MilestoneQApplied"
              name="MilestoneQApplied"
              type="radio"
              :value="item.val"
            />
            {{item.text}}
          </label>
        </div>
        <div class="column align_end">
          <div class="column align_end">
            <label class="type_gray alignself_start type_trans20minus">JSON</label>
            <div justify="center">
              <v-btn @click.stop="exp.J=true" class="type_openTextarea">JSON Properties/Data</v-btn>
              <j-modal-scripter 
                v-model="MilestoneQJson" 
                sub-title="JSON Properties/Data"
                title="Access Database"
                :message="validated.MilestoneQJson.message"
                :opened="exp.J"
                :valid="validated.MilestoneQJson.valid"
                @close="exp.J=false;"
                @save="exp.J=false;onSave();"
                @validate="onValidate('MilestoneQJson')"
              />
            </div>
          </div>
          <div class="column align_end">
            <label class="type_gray alignself_start type_trans20minus">SQL Query</label>
            <div justify="center">
              <v-btn @click.stop="exp.Q=true" class="type_openTextarea">Direct Query Script</v-btn>
              <j-modal-scripter 
                v-model="MilestoneQSql" 
                sub-title="SQL Query"
                title="Access Database"
                :message="validated.MilestoneQSql.message"
                :opened="exp.Q"
                :valid="validated.MilestoneQSql.valid"
                @close="exp.Q=false;"
                @save="exp.Q=false;onSave();"
                @validate="onValidate('MilestoneQSql')"
              />
            </div>
          </div>
          <div class="column align_end">
            <label class="type_gray alignself_start type_trans20minus">Stored Procedure</label>
            <div justify="center">
              <v-btn @click.stop="exp.S=true" class="type_openTextarea">T-SQL Procedure Script</v-btn>
              <j-modal-scripter 
                v-model="MilestoneQSp" 
                sub-title="Stored Procedure"
                title="Access Database"
                :message="validated.MilestoneQSp.message"
                :opened="exp.S"
                :valid="validated.MilestoneQSp.valid"
                @close="exp.S=false;"
                @save="exp.S=false;onSave();"
                @validate="onValidate('MilestoneQSp')"
              />
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import { ChartLibraryService, SystemService } from "@/services"
import { QueryValidationMixin } from "@/mixins/sqlValidator"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-milestone',
  mixins: [
    QueryValidationMixin,
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      lines: true,
      access: false,
    },
    exp: {
      J: false,
      Q: false,
      S: false,
    },
    validated: {
      MilestoneQJson: { valid: true, message: '' },
      MilestoneQSql: { valid: true, message: '' },
      MilestoneQSp: { valid: true, message: '' },
    },

    dataImport: [
      { text: 'option01', val: 'option01' },
      { text: 'option02', val: 'option02' },
      { text: 'option03', val: 'option03' }
    ],
    // Service Instances -------------
    chartLibraryService: null,
    systemService: null,
    // Other component owned ---------
    bar_general: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    MilestoneDisplay: {
      get() { return this.chartItem.MilestoneDisplay },
      set(val) { this.setChartItem({ MilestoneDisplay: val }) }
    },
    MilestoneLineStyle: {
      get() { return this.chartItem.MilestoneLineStyle },
      set(val) { this.setChartItem({ MilestoneLineStyle: val }) }
    },
    MilestoneLineWeight: {
      get() { return this.chartItem.MilestoneLineWeight || 0 },
      set(val) { this.setChartItem({ MilestoneLineWeight: parseFloat(val || 0) }) }
    },
    MilestoneLineHeight: {
      get() { return this.chartItem.MilestoneLineHeight || 0 },
      set(val) { this.setChartItem({ MilestoneLineHeight: parseFloat(val || 0) }) }
    },
    MilestoneLineColor: {
      get() { return this.chartItem.MilestoneLineColor },
      set(val) { this.setChartItem({ MilestoneLineColor: val }) }
    },
    MilestoneTextFont: {
      get() { return this.chartItem.MilestoneTextFont },
      set(val) { this.setChartItem({ MilestoneTextFont: val }) }
    },
    MilestoneTextStyle: {
      get() { return this.chartItem.MilestoneTextStyle },
      set(val) { this.setChartItem({ MilestoneTextStyle: val }) }
    },
    MilestoneTextSize: {
      get() { return this.chartItem.MilestoneTextSize || 0 },
      set(val) { this.setChartItem({ MilestoneTextSize: parseFloat(val || 0) }) }
    },
    MilestoneTextColor: {
      get() { return this.chartItem.MilestoneTextColor },
      set(val) { this.setChartItem({ MilestoneTextColor: val }) }
    },
    MilestoneQApplied: {
      get() { return this.chartItem.MilestoneQApplied },
      set(val) { this.setChartItem({ MilestoneQApplied: val }) }
    },
    MilestoneQJson: {
      get() { return this.chartItem.MilestoneQJson },
      set(val) { this.setChartItem({ MilestoneQJson: val }) }
    },
    MilestoneQSql: {
      get() { return this.chartItem.MilestoneQSql },
      set(val) { this.setChartItem({ MilestoneQSql: val }) }
    },
    MilestoneQSp: {
      get() { return this.chartItem.MilestoneQSp },
      set(val) { this.setChartItem({ MilestoneQSp: val }) }
    },

  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
    this.systemService = new SystemService()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem', 'setMilestoneSqlValue']),

    onSave() {
      this.$emit('save')
    },
    onValidate(typeName) {
      if(typeName == 'MilestoneQJson') {
        this.validated.MilestoneQJson = this.validateJson(this.MilestoneQJson)
        if(this.validated.MilestoneQJson.valid) this.MilestoneQJson = this.validated.MilestoneQJson.json
      } else {
        this.validateSql(this[typeName]).then(res => {
          this.validated[typeName] = res
        })
      }
    },
  }
}
</script>
