<template>
  <v-flex v-model="bar3d_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Values</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div v-for="(el, i) in localValue" class="chart_properties_contents" :key="`value__${i}`">
      <div class="header">
        {{ i + 1 }}{{ surfix(i) }} Value
        <a
          class="close"
          v-on:click="collapsed[surfix(i)] = !collapsed[surfix(i)]"
        ></a>
      </div>
      <v-flex class="control" v-show="collapsed[surfix(i)]">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="el.ValueDisplay" type="radio" :name="`ValueDisplay__${i}`" :value="option.value" @input="(e) => { updateAttr(e.target.value, i, 'ValueDisplay') }"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Data</label>
          <input v-model="el.ValueData" class="apply_spin" type="number" min="0" max="3" @input="updateAttr"
          />
        </div>
        <div class="type_common_input">
          <span class="label">Type</span>
          <div class="row">
            <label v-for="option in valueTypeOptions" :key="option.index" class="type_many">
              <input v-model="el.ValueType" type="radio" :name="`ValueType__${i}`" :value="option.value" @input="(e) => { updateAttr(e.target.value, i, 'ValueType') }"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Coordinate(y)</label>
          <input v-model="el.ValueY" type="text" @input="updateAttr" />
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select
            v-model="el.ValueFont"
            dir="rtl"
            @input="(e) => { updateAttr(e.target.value, i, 'ValueFont') }"
          >
            <option
              v-for="option in fontOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="el.ValueStyle" dir="rtl" class="type70" @input="(e) => { updateAttr(e.target.value, i, 'ValueStyle') }"
          >
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <span class="label">Text</span>
        <div class="type_inner">
          <div class="type_common_input type_color_float">
            <label class="type_gray second_depth">Size / Color</label>
            <div class="input_flex_wrap_end">
              <input v-model="el.ValuetSize" type="number" @input="updateAttr" />
              <j-color-picker v-model="el.ValueColor" @input="updateAttr" />
            </div>
          </div>
        </div>
        <div class="type_common_input column">
          <span class="label">Alignment</span>
          <div class="row justify_end">
            <label v-for="option in alignCssHOptions" :key="option.index" class="type_many">
              <input
                v-model="el.ValueAlign" type="radio" :name="`ValueAlign__${i}`" :value="option.value" @input="(e) => { updateAttr(e.target.value, i, 'ValueAlign') }"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <span class="label">Unit</span>
        <div class="type_inner">
          <div class="type_common_input type_color_float">
            <label class="type_gray second_depth">Format / Size</label>
            <div class="input_flex_wrap_end">
              <input class="rightMg" style="margin-right: 0.3rem;" v-model="el.ValueUnitFormat" type="text" @input="updateAttr"/>
              <input v-model="el.ValueUnitSize" type="number" @input="updateAttr" />
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>
<script>
import __C from '@/primitives/_constant_'
import _ChartDataDefault from '@/primitives/_chartDonutDefault'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-value',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    elementsNumByType: {
      Single: 3,
      Double: 3,
      Triple: 3,
      Multi: 1,
    },
    collapsed: {
      st: true,
      nd: false,
      rd: false,
    },
    valueTypeOptions: [
      { text: "Value", value: "Value" },
      { text: "Percentage", value: "Percentage" },
    ],
    localValue: [],
    localValuePrev: [],

    bar3d_general: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
  },
  watch: {
    'chartItem.CircleType'(val) {
      if (!val) return
      this.resetValues(val)
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    resetValues(typeName) {
      let elNum = this.elementsNumByType[typeName] || 0

      if (this.localValue.length == elNum) return

      let target = this.localValuePrev.length > 0 ? this.localValuePrev : _ChartDataDefault.Value
      let value_ = Array.from({ length: elNum }, () => null)

      value_.forEach((_, i) => {
        value_[i] = this.chartItem.Values[i] || target[i]
      })

      this.setChartItem({ Values: value_ })
      this.localValue = JSON.parse(JSON.stringify(value_))

      // elNum == 1 : Multi type donut chart
      if(elNum > 1) this.localValuePrev = JSON.parse(JSON.stringify(value_))
    },
    surfix(i) {
      let surfix_ = ['st', 'nd', 'rd', 'th']
      if (i < 3) return surfix_[i]
      return surfix_[3]
    },
    updateAttr(v, i, el) {
      let values_ = JSON.parse(JSON.stringify(this.localValue))
      if(el) values_[i][el] = v
      if(values_.length > 1) this.localValuePrev = JSON.parse(JSON.stringify(values_))

      this.setChartItem({ Values: values_ })
    }
  }
}
</script>