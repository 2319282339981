<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Coloring</span>
      <div>
        <span data-grid-quickbtn="btn" class="label jcon_temp_plus type_header btn_after_more" style="color: #4298c6;" @click="appendAttr()"><small class="type_ref01 ">Add Style</small></span>
      </div>
    </div>
    <div class="chart_properties_contents">
      <div class="chart_properties_wrapper">
        <j-alert
          v-model="msgOpen"
          :title="msgInfo.title"
          :type="msgInfo.type"
          :message="msgInfo.message"
          :button="msgInfo.button"
          :button-text="msgInfo.buttonText"
          @yes="yes()"
          @cancel="msgOpen = false"
        />
        <div v-for="(attr, i) in localColorAttrs" class="content_list" :key="`_header_${i}`">
          <div class="header">
            <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header align_center" style="color: #4298c6;" @click="clickRemoveBtn(i)">{{ i + 1 }}{{ surfix(i) }}</span>
            <a class="close" @click.stop="editorOpen(i)"></a>
          </div>
          <v-flex class="control" v-show="editorOpended[i]">
            <div>
              <div class="type_inner">
                <span class="label">Ref. Column
                  <small class="type_ref03">(code column)</small>
                </span>
                <div class="type_common_input">
                  <label></label>
                  <input v-model="attr.refColumn" class="type120" type="text" placeholder="select file...." @input="(e) => { updateAttr(e.target.value, i, 'refColumn') }">
                </div>
                <div class="type_common_input">
                  <span class="label">Data Column 
                    <small class="type_ref03" style="font-size: 70%; color:#ff1944">(progress only)</small>
                  </span>
                  <div class="row">
                    <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                      <input v-model="attr.progress" type="radio" :name="`progress_${i}`" :value="option.value" @input="(e) => { updateAttr(e.target.value, i, 'progress') }" />
                      {{option.text}}
                    </label>
                  </div>
                </div>
                <div class="type_common_input">
                  <label></label>
                  <input v-model="attr.dataColumn"  class="type120" type="text" placeholder="select file...." @input="(e) => { updateAttr(e.target.value, i, 'dataColumn') }" >
                </div>
                <div class="type_common_input">
                  <label class="type_gray">Text Size</label>
                  <input type="number" v-model="attr.textSize" @input="(e) => { updateAttr(e.target.value, i, 'textSize', 'num') }"/>
                </div>
                <div class="type_common_input">
                  <label class="type_gray">Radius</label>
                  <div class="row">
                      <input type="number" v-model="attr.radius" @input="(e) => { updateAttr(e.target.value, i, 'radius', 'num') }"/>
                  </div>
                </div>
                <div class="type_common_input">
                  <label class="type_gray">Stroke(width/color)</label>
                  <div class="row">
                    <input class="type_mgl0 type_double" type="number" v-model="attr.strokeWidth" @input="(e) => { updateAttr(e.target.value, i, 'strokeWidth', 'num') }"/>
                    <j-color-picker v-model="attr.strokeColor" @input="updateAttr"></j-color-picker>
                  </div>
                </div>
                <div class="type_common_input">
                  <label class="type_gray">Cell Padding(x,y)</label>
                  <div class="row">
                    <input class="type_mgl0 type_double" type="number" v-model="attr.paddingX" @input="(e) => { updateAttr(e.target.value, i, 'paddingX', 'num') }"/>
                    <input type="number" v-model="attr.paddingY" @input="(e) => { updateAttr(e.target.value, i, 'paddingY', 'num') }"/>
                  </div>
                </div>
                <div class="type_common_input">
                  <label class="type_gray">Linked</label>
                  <div class="row">
                    <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                      <input v-model="attr.linked" type="radio" :value="option.value" :name="`linked_${i}`" @input="(e) => { updateAttr(e.target.value, i, 'linked') }"/>
                      {{option.text}}
                    </label>
                  </div>
                </div>
                <div class="type_inner">
                  <div class="type_common_input">
                    <label class="type_gray second_depth">hovor Text(+)</label>
                    <input type="number" v-model="attr.hovorText" @input="(e) => { updateAttr(e.target.value, i, 'hovorText', 'num') }"/>
                  </div>
                </div>
                <label>{{"Codes & Colors"}}</label>
                <div class="type_inner_more_over">
                  <div class="input_flex_wrap code_title_wrapper">
                    <div class="jcon_temp_plus" data-grid-quickbtn="btn" @click="appendAttr(i, 'codes')">
                      <label class="default_code type_gray">Default Code</label>
                    </div>
                  </div>
                  <div
                    class="type_common_input type_color_float has_gradient_picker"
                    v-for="(code, j) in attr.codes"
                    :key="`_cell_${i}_${j}`"
                  >
                    <div class="input_flex_wrap picker_gradient">
                      <div class="jcon_temp_minus" data-grid-quickbtn="btn" @click="clickRemoveBtn(i, j, 'codes')"></div>
                      <input class="type_gray w100_rem" v-model="code.code" type="text" @focusout="updateAttr" />
                      <j-color-picker v-model="code.tColor" @input="updateAttr" title>
                        <template #action="{ action }">
                          <div
                            v-ripple
                            v-on="action.on"
                            class="j_button_color_picker w34"
                            :style="`color: ${code.tColor}; background: linear-gradient(to right, ${code.bColor} 50%, #fff 50%); margin-left: 3px;`"
                            @click="action.open"
                          >TEXT</div>
                        </template>
                      </j-color-picker>
                      <j-color-picker 
                        v-model="code.bColor" 
                        :gradation="true"
                        :gradation-type="code.colorType"
                        @gradate="v => { setGradient(code, v) }"
                        @input="updateAttr"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </div>
      </div>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import _ChartDataDefault from '@/primitives/_chartTableSummaryDefault'
import { mapState, mapMutations } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-summary-color',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    editorOpended: [],
    localStyleAttrs: [],
    localColorAttrs: [],
    tabActivator: null,
    firstOpened : true,

    msgOpen: false,
    yes: null
  }),
  watch: {
    'chartItem.Style': {
      handler(val) {
        if (!val) return
        this.localStyleAttrs = JSON.parse(JSON.stringify(val))
        this.localColorAttrs = this.localStyleAttrs.filter(attr => attr.type == 'Coloring')
        if(this.firstOpened) {
          this.editorOpended.push(true)
          this.editorOpended.push(...Array.from({ length: this.localColorAttrs.length - 1  }, _ => false))
        }
        this.firstOpened = false
      },
      deep: true
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    headerAttrs() {
      return Array.from({ length: this.localColorAttrs.length  }, (_, i) => i)
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    appendAttr(i, el) {
      if(el) this.localColorAttrs[i][el].push(_ChartDataDefault._dataForm.Styles.Coloring.codes[0])
      else {
        this.localColorAttrs.push(_ChartDataDefault._dataForm.Styles.Coloring)
        this.editorOpended.push(false)
        this.reSerializing(this.localColorAttrs)
      }
      this.updateAttr()
    },
    clickRemoveBtn(i, j, el) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    editorOpen(i) {
      let opened_ = JSON.parse(JSON.stringify(this.editorOpended))
      opened_[i] = !opened_[i]

      // // For updating the changes on validating, because of auto closing
      // // after updateAttr.
      if(!opened_[i]) this.updateAttr()
      
      this.editorOpended = opened_

      return this.editorOpended[i]
    },
    removeAttr(i, j, el) {
      if(el) {
        this.localColorAttrs[i][el][j] = null
        this.localColorAttrs[i][el] = this.localColorAttrs[i][el].filter(code => !!code)
      } else {
        this.localColorAttrs[i] = null
        this.editorOpended[i] = null
        this.localColorAttrs = this.localColorAttrs.filter(attr => !!attr)
        this.editorOpended = this.editorOpended.filter( v => v !== null)
        this.reSerializing(this.localColorAttrs)
      }
      this.updateAttr()
    },
    reSerializing(datas) {
      datas.forEach((data, i_) => { data.series = i_+1 })
    },
    setGradient(code, v) {
      code.colorType = v
      this.updateAttr()
    },
    surfix(i) {
      let surfix_ = ['st', 'nd', 'rd', 'th']
      if (i < 3) return surfix_[i]
      return surfix_[3]
    },
    updateAttr(val, i, el, type) {
      if(el) { 
        if(type == 'num') {
          this.localColorAttrs[i][el] = parseFloat(val);
        } else {
          this.localColorAttrs[i][el] = val
        }
      }

      this.localStyleAttrs = this.localStyleAttrs.map(attr => attr.type == 'Coloring' ? null : attr)
      this.localStyleAttrs = this.localStyleAttrs.filter(attr => !!attr)

      this.setChartItem({ Style: [ 
        ...JSON.parse(JSON.stringify(this.localStyleAttrs)), 
        ...JSON.parse(JSON.stringify(this.localColorAttrs))
      ]})
    }
  }
}
</script>
