export default {
  // ----------------------------------------------- DATABASE
  Database: {
    DatabasePreviewMode: 'Chart',
    DatabaseAccessMode: 'Json',
    DatabaseJson: '',
    DatabaseQuery: '',
    DatabaseSP: ''
  },

  // ----------------------------------------------- CANVAS
  Canvas: {
    CanvasWidth: 600,
    CanvasHeight: 400,
    CanvasBorderWeight: 0.5,
    CanvasBorderColor: '#757575',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,
    CanvasChartAreaAllowed: 'N',
    CanvasChartX: 150,
    CanvasChartY: 120,
    CanvasChartWidth: 0,
    CanvasChartHeight: 0,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0.0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto'
  },

  // ----------------------------------------------- TITLE
  Title: {
    TitleMainDisplay: 'Y',
    TitleMainText: 'Spool Installation',
    TitleMainX: 300,
    TitleMainY: 50,
    TitleMainFont: 'roboto',
    TitleMainStyle: 'regular',
    TitleMainSize: 14,
    TitleMainColor: 'rgb(69, 90, 100)',
    TitleMainAlign: 'middle',
    TitleSubDisplay: 'N',
    TitleSubText: 'MD2 Project',
    TitleSubX: 400,
    TitleSubY: 70,
    TitleSubFont: 'roboto',
    TitleSubStyle: 'regular',
    TitleSubSize: 12,
    TitleSubColor: '#BDBCBC',
    TitleSubAlign: 'middle',
    TitleExtraDisplay: 'N',
    TitleExtraText: 'Discipline',
    TitleExtraX: 400,
    TitleExtraY: 430,
    TitleExtraFont: 'roboto',
    TitleExtraStyle: 'regular',
    TitleExtraSize: 14,
    TitleExtraColor: 'gray',
    TitleExtraAlign: 'middle',
    TitleSqlDisplay: 'N',
    TitleSqlText: 'SQL Query...',
    TitleSqlQuery: 'SQL Query...',
    TitleSqlX: 400,
    TitleSqlY: 90,
    TitleSqlFont: 'roboto',
    TitleSqlStyle: 'regular',
    TitleSqlSize: 11,
    TitleSqlColor: 'pink',
    TitleSqlAlign: 'middle',
    TitleLineDisplay: 'N',
    TitleLineX: 30,
    TitleLineY: 30,
    TitleLineLength: 100,
    TitleLineWeight: 1,
    TitleLineColor: 'black'
  },

  // ----------------------------------------------- LEGENDS
  Legends: {
    LegendDisplay: 'N',
    LegendX: 461,
    LegendY: 160,
    LegendDirection: 'Vertical',
    LegendBasepoint: 'end',
    LegendTitleDisplay: 'Y',
    LegendTitleFont: 'roboto',
    LegendTitleStyle: 'regular',
    LegendTitleSize: 12,
    LegendTitleColor: '#757575',
    LegendBulletDisplay: 'Y',
    LegendBulletType: 'square',
    LegendBulletSize: 10,
    LegendBulletSpace: 5,
    LegendSeriesFont: 'roboto',
    LegendSeriesStyle: 'regular',
    LegendSeriesSize: 10,
    LegendSeriesColor: '#757575',
    LegendSeriesSpace: 10,
    LegendValueDisplay: 'Y',
    LegendValueSize: 10,
    LegendValueColor: '#44A9DF',
    LegendValueRound: 1,
    LegendUnitDisplay: 'Y',
    LegendUnitFormat: '%',
    LegendUnitSize: 8,
    LegendUnitColor: '#44A9DF',
    LegendLineSpace: 5
  },

  // ----------------------------------------------- NOTE
  Note: {
    NoteDisplay: 'Y',
    NoteX: 100,
    NoteY: 350,
    NoteAlign: 'start',
    NoteTitleText: 'Notes.',
    NoteTitleFont: 'roboto',
    NoteTitleStyle: 'regular',
    NoteTitleSize: 11,
    NoteTitleColor: '#757575',
    NoteContentsText: 'Note Contents Here!',
    NoteContentsFont: 'roboto',
    NoteContentsStyle: 'regular',
    NoteContentsSize: 10,
    NoteContentsColor: '#BDBCBC'
  },

  // ----------------------------------------------- Circle
  Circle: {
    CircleX: 300,
    CircleY: 200,
    CircleDiameter: 100,
    CircleColorType: 'LinearA2',
    CircleColorSet: ['LightBlue', 'LightGray','Gray','DarkGreen' ,'SkyBlue', 'YellowGreen'],
    CircleRadius: 0,
    CircleHoleSize: 0,
    CircleStackSize: 5,
    CircleSliceSize: 0,
    CircleShadowDisplay: 'Y',

    CircleDropshadowDisplay: 'Y',
    CircleDropshadowDeviation: 1,
    CircleDropshadowOpacity: 0.1,
    CircleDropshadowX: 1,
    CircleDropshadowY: 1,

  },

  // ----------------------------------------------- Guideline
  Guideline: {
    GuideDisplay: 'Y',
    GuideWeight: 0.5,
    GuideColor: 'gray',
    GuideInside: 5,
    GuideOutside: 15,
    GuideExtend: 30,

    GuideBulletSize: 3,
    GuideBulletAutoColor: 'Y',
    GuideBulletColor: 'gray',

    GuideTextFont: 'roboto',
    GuideTextStyle: 'regular',
    GuideTextSize: 11,
    GuideTextColor: 'gray',

    GuideValueRound: 1,
    GuideValueSize: 11,
    GuideValueColor: '#F7BACF',

    GuideUnitFormat: '%',
    GuideUnitSize: 10,
    GuideUnitColor: '#F7BACF'
  },

  DataItems: [
    // { value: 22.5, title: 'Thing' }, 
    // { value: 42.5, title: 'Thing Two' }, 
    // { value: 18.2, title: 'Another Thing' }, 
    // { value: 16.8, title: 'Pineapple' }

    { value: 46.8, title: 'Electrical' }, 
    { value: 44.5, title: 'Mechanical' }, 
    { value: 0, title: 'Other' },
    { value: 31.1, title: 'Instrumemt' }, 
    { value: 0, title: 'Piping' },
    
    { value: 31.1, title: 'HVAC' },
    
    
  ],

//   DataItems: [
//     { value: 150, title: 'A' }, 
//     { value: 50, title: 'B' }, 
//     { value: 0, title: 'C' }, 
//   ]
}
