import JChartTabNavigation from '@/components/chart/tabs/JChartTabNavigation'
import JChartTabDatabase from '@/components/chart/tabs/JChartTabDatabase'
import JChartTabCanvas from '@/components/chart/tabs/JChartTabCanvas'
import JChartTabText from '@/components/chart/tabs/JChartTabText'
import JChartTabUrl from '../SysenvChartlibCustom/JChartTabUrl'

import JSvglibTabTabs from './JSvglibTabTabs'
import JSvglibTabDraw from './JSvglibTabDraw'

export default {
  JChartTabNavigation,
  JChartTabDatabase,
  JChartTabCanvas,
  JChartTabText,
  JChartTabUrl,

  JSvglibTabTabs,
  JSvglibTabDraw,
}
