<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Text Only</span>
      <div>
        <span data-grid-quickbtn="btn" class="label jcon_temp_plus type_header btn_after_more" style="color: #4298c6;" @click="appendAttr()"><small class="type_ref01 ">Add Style</small></span>
      </div>
    </div>
    <div class="chart_properties_contents">
      <div class="chart_properties_wrapper">
        <j-alert
          v-model="msgOpen"
          :title="msgInfo.title"
          :type="msgInfo.type"
          :message="msgInfo.message"
          :button="msgInfo.button"
          :button-text="msgInfo.buttonText"
          @yes="yes()"
          @cancel="msgOpen = false"
        ></j-alert>
        <div v-for="(attr, i) in localTextAttrs" class="content_list" :key="attr.index">
          <div class="header">
            <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header align_center" style="color: #4298c6;" @click="clickRemoveBtn(i)">{{ i + 1 }}{{ surfix(i) }}</span>
            <a class="close" @click.stop="editorOpen(i)"></a>
          </div>
          <v-flex class="control" v-show="editorOpended[i]">
            <div>
              <div>
                <span class="label">Text</span>
                <div class="type_inner">
                  <div class="type_common_input">
                    <label class="type_gray second_depth">Size</label>
                    <input v-model="attr.textSize" type="number" @input="(e) => updateAttr(e.target.value, i, 'textSize', 'num')" />
                  </div>
                  <div class="type_common_input type_color_float">
                    <label class="type_gray second_depth">Color</label>
                    <div class="input_flex_wrap_end">
                      <j-color-picker v-model="attr.textColor" @input="updateAttr()" />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="type_common_input">
                  <span class="label">Linked</span>
                  <div class="row">
                    <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                      <input v-model="attr.linked" type="radio" :name="`attr_linked_${i}`" :value="option.value" @input="(e) => updateAttr(e.target.value, i, 'linked')" />
                      {{option.text}}
                    </label>
                  </div>
                </div>
                <div class="type_inner">
                  <div class="type_common_input">
                    <label class="type_gray second_depth">Hovor Text(+)</label>
                    <input v-model="attr.hovorText" type="number" @input="(e) => updateAttr(e.target.value, i, 'hovorText', 'num')" />
                  </div>
                  <div class="type_common_input type_color_float">
                    <label class="type_gray second_depth">Hover Color</label>
                    <div class="input_flex_wrap_end">
                      <j-color-picker v-model="attr.hovorColor" @input="updateAttr()"/>
                    </div>
                  </div>
                  <div class="type_common_input">
                    <label class="type_gray second_depth">Zero Display</label>
                    <div class="row">
                      <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                        <input v-model="attr.zeroDisplay" type="radio" :name="`attr_zeroDisplay_${i}`" :value="option.value" @input="(e) => updateAttr(e.target.value, i, 'zeroDisplay')" />
                        {{option.text}}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </div>
      </div>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import _ChartDataDefault from '@/primitives/_chartTableSummaryDefault'
import { mapState, mapMutations } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-summary-text',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    msgOpen: false,
    yes: null,

    editorOpended: [],
    localStyleAttrs: [],
    localTextAttrs: [],
    tabActivator: null,
    firstOpened : true
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    headerAttrs() {
      return Array.from({ length: this.localTextAttrs.length  }, (_, i) => i) 
    },
  },
  watch: {
    'chartItem.Style': {
      handler(val) {
        if (!val) return
        this.localStyleAttrs = JSON.parse(JSON.stringify(val))
        this.localTextAttrs = this.localStyleAttrs.filter(attr => attr.type == 'Text')
        if(this.firstOpened) {
          this.editorOpended.push(true)
          this.editorOpended.push(...Array.from({ length: this.localTextAttrs.length - 1 }, _ => false))
        }
        this.firstOpened = false
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
    appendAttr(i, el) {
      // _ChartDataDefault._dataForm.Styles.Text.series = this.localTextAttrs.length + 1

      this.localTextAttrs.push(_ChartDataDefault._dataForm.Styles.Text)
      this.editorOpended.push(false)
      this.reSerializing(this.localTextAttrs)

      this.updateAttr()
    },
    clickRemoveBtn(i, j, el) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    editorOpen(i) {
      let opened_ = JSON.parse(JSON.stringify(this.editorOpended))
      opened_[i] = !opened_[i]

      // // For updating the changes on validating, because of auto closing
      // // after updateAttr.
      // Fix Me
      if(!opened_[i]) this.updateAttr()
      
      this.editorOpended = opened_

      return this.editorOpended[i]
    },
    removeAttr(index) {
      this.localTextAttrs[index] = null
      this.editorOpended[index] = null
      this.localTextAttrs = this.localTextAttrs.filter(attr => !!attr)
      this.editorOpended = this.editorOpended.filter( v => v !== null)
      this.reSerializing(this.localTextAttrs)
      this.updateAttr()
    },
    reSerializing(datas) {
      datas.forEach((data, i_) => { data.series = i_+1 })
    },
    surfix(i) {
      let surfix_ = ['st', 'nd', 'rd', 'th']
      if (i < 3) return surfix_[i]
      return surfix_[3]
    },
    updateAttr(val, i, el, type) {
      if(el) { 
        if(type == 'num') {
          this.localTextAttrs[i][el] = parseFloat(val);
        } else {
          this.localTextAttrs[i][el] = val
        }
      }
      
      this.localStyleAttrs = this.localStyleAttrs.map(attr => attr.type == 'Text' ? null : attr)
      this.localStyleAttrs = this.localStyleAttrs.filter(attr => !!attr)

      this.setChartItem({ Style: [
        ...JSON.parse(JSON.stringify(this.localStyleAttrs)), 
        ...JSON.parse(JSON.stringify(this.localTextAttrs))
      ]})
    }
  }
}
</script>
