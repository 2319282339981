import JChartTabNavigation from '@/components/chart/tabs/JChartTabNavigation'
import JChartTabDatabase from '@/components/chart/tabs/JChartTabDatabase'
import JChartTabCanvas from '@/components/chart/tabs/JChartTabCanvas'
import JChartTabAxis from '@/components/chart/tabs/JChartTabAxis'
import JChartTabTitle from '@/components/chart/tabs/JChartTabTitle'
import JChartTabNote from '@/components/chart/tabs/JChartTabNote'
import JChartTabLegend from '@/components/chart/tabs/JChartTabLegend'
import JChartTabBar from '@/components/chart/tabs/JChartTabBar'

export default {
  JChartTabNavigation,
  JChartTabDatabase,
  JChartTabCanvas,
  JChartTabAxis,
  JChartTabTitle,
  JChartTabNote,
  JChartTabLegend,
  JChartTabBar
}
