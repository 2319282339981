<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Types</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Header (Disc/Punch)
        <a class="close" v-on:click="collapsed['header'] = !collapsed['header']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['header']">
        <div class="type_common_input type_color_float">
          <label>Size / Color</label>
          <div class="input_flex_wrap_end">
            <input v-model="ChtTypeHeaderSize" type="number" />
            <j-color-picker v-model="ChtTypeHeaderColor"/>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Table(Data)
        <a class="close" v-on:click="collapsed['table'] = !collapsed['table']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['table']">
        <div class="type_common_input">
          <label>y</label>
          <input v-model="ChtTypeTableY" type="text" />
        </div>
        <div class="type_common_input">
          <label>Font Size</label>
          <input v-model="ChtTypeTableSize" type="number" />
        </div>
        <div class="type_common_input">
          <label>Line Height</label>
          <input v-model="ChtTypeTableHeight" type="number" />
        </div>
        <div class="type_common_input">
          <label>Cell Space</label>
          <input v-model="ChtTypeTableCellSpace" type="number" />
        </div>
      </v-flex>
    </div>    
    <div class="chart_properties_contents">
      <div class="header">
        Bar Chart
        <a class="close" v-on:click="collapsed['chart'] = !collapsed['chart']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['chart']">
        <div class="type_common_input">
          <label>Width</label>
          <input v-model="ChtTypeChartWidth" type="number" />
        </div>
        <div class="input_flex_wrap_end">
          <j-color-picker v-model="ChtTypeChartColor" title="Color"></j-color-picker>
        </div>
        <div class="type_common_input">
          <label>Opacity</label>
          <input v-model="ChtTypeChartOpacity" type="number" />
        </div> 
      </v-flex>     
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-type',
  mixins: [
    StaticOptions
  ],
  components: {
  },
  data: () => ({
    collapsed: {
      header: true,
      table: true,
      chart: true,
    },
    localChttype: {
      header:{
        tSize: 10.5,
        tColor: '#000'
      },
      table:{
        y: 30,
        tSize: 10,
        lineHeight: 15,
        cellSpace: 4
      },
      barChart:{
        width:100,
        color:'#B4E3FA',
        opacity:0.5
      }
    }
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    
    // ChtType Header
    ChtTypeHeaderSize: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.header.tSize ) },
      set(val) { 
        this.localChttype.header.tSize = parseFloat(val || 0) 
        this.updateAttr() 
      }
    },
    ChtTypeHeaderColor: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.header.tColor ) },
      set(val) { 
        this.localChttype.header.tColor = val 
        this.updateAttr() 
      }
    },
    
    // ChtType Table
    ChtTypeTableY: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.table.y ) },
      set(val) { 
        this.localChttype.table.y  = parseFloat(val || 0)  
        this.updateAttr() 
      }
    },

    ChtTypeTableSize: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.table.tSize },
      set(val) { 
        this.localChttype.table.tSize  = parseFloat(val || 0)  
        this.updateAttr() 
      }

    },
    ChtTypeTableHeight: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.table.lineHeight },
      set(val) { 
        this.localChttype.table.lineHeight  = parseFloat(val || 0)  
        this.updateAttr() 
      }
    },

    ChtTypeTableCellSpace: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.table.cellSpace },
      set(val) { 
        this.localChttype.table.cellSpace  = parseFloat(val || 0)  
        this.updateAttr() 
      }
    },

    // ChtType Bar Chart
    ChtTypeChartWidth: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.barChart.width },
      set(val) { 
        this.localChttype.barChart.width  = parseFloat(val || 0)  
        this.updateAttr() 
      }
    },
    ChtTypeChartColor: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.barChart.color },
      set(val) { 
        this.localChttype.barChart.color  = val
        this.updateAttr() 
      }
    },
    ChtTypeChartOpacity: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.barChart.opacity },
      set(val) { 
        this.localChttype.barChart.opacity  = parseFloat(val || 0)  
        this.updateAttr() 
      }
    },

  },
  watch: {
    'chartItem.Chttype': {
      handler(val) {
        if (!val) return
        // console.log('Chttype', val)
        this.localChttype = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    updateAttr() {
      this.setChartItem({ Chttype: JSON.parse(JSON.stringify(this.localChttype)) })
    },
  },
}
</script>
