export default {
  ChartType: 'DISC',

  Database: {
    DatabasePreviewMode: 'Chart',
    DatabaseAccessMode: 'Json',
    DatabaseJson: '',
    DatabaseQuery: '',
    DatabaseSP: '',
  },
  
  Canvas: {
    CanvasWidth: 360,
    CanvasHeight: 120,
    CanvasBorderWeight: .5,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 400,
    CanvasChartHeight: 200,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Common: {
    x: 10, 
    y: 10,
    font: 'roboto',
  },

  CTypes:
  { 
    header: {
      tSize: 10.5,
      tColor: '#000', 
    },
    table: {
      y: 30,
      tSize: 10,
      lineHeight: 15,
      cellSpace: 4,
    },
    barChart: {
      width:    100,
      color:    '#B4E3FA',
      opacity:  0.5,
    },
  },

  DataCols: {
    Columns: [
      // DISC
      {code: 'DISC',    name: 'Disc.',    width: 25,  tColor: '#EC407A',  align: 'end',     link: 'N', jMap: '',      jLink: '',      filter: ''},
      {code: 'TOTAL',   name: 'Total',    width: 45,  tColor: '#757575',  align: 'end',     link: 'Y', jMap: 'KEY1',  jLink: 'JSON1', filter: ''},
      {code: 'ACTUAL',  name: 'Closed',   width: 45,  tColor: '#757575',  align: 'end',     link: 'Y', jMap: 'KEY1',  jLink: 'JSON1', filter: ''},
      {code: 'REMAIN',  name: 'Remain',   width: 45,  tColor: '#757575',  align: 'end',     link: 'Y', jMap: 'KEY1',  jLink: 'JSON1', filter: ''},
      {code: 'PROG',    name: 'Progress', width: 100, tColor: '#EC407A',  align: 'middle',  link: 'N', jMap: '',      jLink: '',      filter: ''},
    ],
    Filters: [
      {code: 'UNIT',    jMap: 'FKEY1'},
    ],
  },

  
  DataItems: [
    // DISC
    {DISC:"ELE", TOTAL:220, ACTUAL:59, REMAIN:161, PROG:25},
    {DISC:"INS", TOTAL:250, ACTUAL:99, REMAIN:59,  PROG:50},
    {DISC:"INS", TOTAL:152, ACTUAL:35, REMAIN:117, PROG:75},
    {DISC:"ROT", TOTAL:278, ACTUAL:38, REMAIN:240, PROG:15},
    {DISC:"PRO", TOTAL:99,  ACTUAL:250,REMAIN:50,  PROG:95},

    // PUNCH
    // {DISC:"ELE", TOTAL:220, CLOSED:59, OPEN:161, },
    // {DISC:"HVA", TOTAL:250, CLOSED:99, OPEN:59,  },
    // {DISC:"INS", TOTAL:152, CLOSED:35, OPEN:117, },
    // {DISC:"ROT", TOTAL:278, CLOSED:38, OPEN:240, },
    // {DISC:"PRO", TOTAL:99,  CLOSED:250,OPEN:50,  },
  ],

}