<template>
  <div>
    <v-dialog 
      fullscreen 
      hide-overlay 
      transition="slide-full-modal-transition" 
      v-model="formOpened" content-class="j_modal" persistent>
      <v-card>
        <div class="modal__header full_title pd_left_3">
          <button class="hamberger_menu" type="button"  @click="onAction('close')">
            <v-icon>mdi-menu</v-icon>
          </button>         
          <span>{{ title }} <span class="sub_title">{{ `${idxNum} ${subTitle}` }}</span></span>
        </div>
        <v-card-text :class="classTab">
          <!-- <v-form v-model="valid" ref="form"> -->
            <v-container>

              <v-card-actions class="modal_toolbar">
                <div class="tool_menus">
                  <div>
                    <div
                      data-common-quickbtn='normal'
                      title="normal"
                      :class="chartItem.ChartType == __C_.TYPE_CODE_SINGLE ? 'selected': ''"
                      @click="onChartType(__C_.TYPE_CODE_SINGLE)"
                    >
                      <v-icon>mdi-content-copy</v-icon>
                    </div>
                  </div>
                </div>
              </v-card-actions>
              <div class="modal_dark_btn">
                <j-button
                  class="type01 sky  type_full "
                  :class="{ disabled: !resetable }"
                  :disabled="!resetable"
                  @click="onAction('save')"
                  >Save
                </j-button>
                <j-button
                  class="type01 sky  type_full "
                  :class="{ disabled: !resetable }"
                  :disabled="!resetable"
                  @click="onAction('saveas')"
                  >SaveAs
                </j-button>
                <j-button v-if="modeMod" 
                  class="type01 delete  type_full" 
                  @click="onAction('delete')"
                  >Delete
                </j-button>
                <div class="check_wrapper">
                  <v-checkbox v-model="update" :label="'Update'"/>
                  <v-checkbox :label="'Page Svg'" />
                  <v-checkbox :label="'Fixed'" />
                </div>
                <button class="help" @click="(e) => { helper=!helper }"></button>
              </div>
              <div class="modal__formControl_inside">
                <v-tabs v-model="stepper" class="wrap__modal_wfix">
                  <v-tab data-type="navigation" title="navigation"></v-tab>
                  <v-tab data-type="database" title="database"></v-tab>
                  <v-tab data-type="canvas" title="canvas"></v-tab>
                  <v-tab data-type="text" title="text"></v-tab>
                  <v-tab data-type="note" title="note"></v-tab>
                  <v-tab data-type="action" title="action" v-if="modePageSvg || modeController"><v-icon>mdi-send</v-icon></v-tab>
                  <v-tab data-type="url" title="url"><v-icon>mdi-link</v-icon></v-tab>

                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-navigation ref="general" :define-nav="defineNav" @import-item="onImport" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-database ref="database" :mode-page-svg="modePageSvg" :use-queries="useQueries" @save="onSave()" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-canvas :chart="false" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-text />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-note />
                  </v-tab-item>
                  <v-tab-item v-if="modePageSvg || modeController" :transition="false" :reverse-transition="false">
                    <j-chart-tab-action />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-url @import-chart="onImportChart" />
                  </v-tab-item>
                </v-tabs>

                <component
                  class="canvasAreaLine"
                  ref="chart__"

                  :is="chartComponentName"
                  :style="`--area-width: ${this.canvas.CanvasWidth + 1}px; --area-height: ${this.canvas.CanvasHeight + 1}px;`"

                  :ChartType="chartItem.ChartType"
                  :Canvas="canvas"
                  :Text="chartItem.Text"
                  :Note="note"

                  :Border="border"
                  :ChartData="chartData_"
                  :Status="status"
                  
                  :ColumnProps="columnProps"
                  :DataItems="chartData"
                  :Queries="qResultExtras"

                  @moved="onMoved"
                  @to-xml-string="onXmlString"
                  @request-action="onRequestedAction"
                  @resize="onResizeCanvas"
                />

                <div v-if="!isPreviewChart" id="j-chart-table">
                  <div style="display: inline-block;">
                    <j-data-grid
                      hide-actions
                      :headers="previewHeaders"
                      :items="previewItems"
                    >
                      <template #items="props">
                        <tr :active="props.selected">
                          <td v-for="h_ in previewHeaders" :key="h_.index" style="text-align: center;">{{ props.item[h_.value] }}</td>
                        </tr>
                      </template>
                    </j-data-grid>
                  </div>
                </div>
                  <div v-if="helper" id="j-chart-helper">
                    <img :src="helperUrl" />
                  </div>
              </div>
            </v-container>
          <!-- </v-form> -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :title-description="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import ChartModalMixin from '@/mixins/chart.modal.common'
import { mapGetters } from 'vuex'
import JSvgCustoms from '@/components/JLibSvgCustomComponents'
import JChartTabProps from './SysenvChartlibCustom'

export default {
  name: 'sysenv-chartlib-custom-modal',
  mixins: [
    ChartModalMixin
  ],
  components: {
    ...JSvgCustoms.Component,
    ...JChartTabProps
  },
  props: {
    catCodeInit: String
  },
  data: () => ({
    chartComponentName: '',
    previewHeaders: [],
    idxNum: '',
    subTitle: ''
  }),
  computed: {
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, [
      'note'
    ]),
    border() { return this.chartItem.Border ? this.chartItem.Border : [] },
    columnProps() { return this.chartItem.JsonProps ? JSON.parse(this.chartItem.JsonProps) : [] },
    chartData_() { return this.chartItem.ChartData ? this.chartItem.ChartData : [] },
    qResultExtras() { return this.chartItem.QResultExtras ? this.chartItem.QResultExtras : {} },
    status() { return this.chartItem.Status ? this.chartItem.Status : [] },

    defineNav() {
      return ![
        __C.CHART.CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER
      ].includes(this.catCodeInit)
    },

    helperUrl() { return this.chartItem.ChartNo && this.helper ? require(`../../../assets/helpDoc/logo.png`) : '' },
    previewItems() { 
      // let items_ = this.chartData.slice(0, 30) 
      // let colNames = []
      // items_.forEach(item => { colNames = Array.from(new Set([ ...colNames, ...Object.keys(item)])) })

      // this.previewHeaders = colNames.map(colName_ => ({ type: 'text', text: colName_, value: colName_, align: 'center', sortable: false }))

      // return items_

      return []
    },
    modeController() { 
      return [
        __C.CHART.CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER,
        __C.CHART.CAT_CODE_COMPLIB_SVG_NAVIGATION
      ].includes(this.catCodeInit)
    },
    modePageSvg() { 
      return [
        __C.CHART.CAT_CODE_COMPLIB_SVG_PAGE
      ].includes(this.catCodeInit)
    },
    title() {
      switch(this.catCodeInit) {
        case __C.CHART.CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER: var title_ = 'Form SVG Chart Controller'; break;
        case __C.CHART.CAT_CODE_COMPLIB_SVG_NAVIGATION: title_ = 'Form SVG Navigation'; break;
        case __C.CHART.CAT_CODE_COMPLIB_SVG_TAB: title_ = 'Form SVG Tab Component'; break;
        case __C.CHART.CAT_CODE_COMPLIB_SVG_PAGE: title_ = 'Form SVG Page Component'; break;
        default: title_ = 'Form SVG Chart Customized'
      }
      return title_
    },

    classTab() { return `modal__formControl dark_theme type1 properties${this.modePageSvg||this.modeController?7:6}` },

  },
  watch: {
    formOpened(val) {
      if (!val) return

      this.stepper = 0
      this.useQueriesTimeoutCurrent = 0

      if(this.modeNew) {
        this.setChartItem({
          ChartDb: 'Chart',
          CatCode: this.catCodeInit,
          ChartType: __C.CHART.TYPE_CODE_NORMAL,
          QApplied: 'J',
        })
        this.$refs.general.setData(this.formMode)

      } else {
        this.getChart()
      }
    }
  },
  methods: {
    itemIdx(Idx, chartNo) {
      this.idxNum = `(${Idx} / ${chartNo})`
    },
    itemName(val) {
      this.subTitle = val
    },
    onImportChart() {
      this.chartComponentName = this.chartItem.UrlEntrance
      this.checkQueriesUsed()
      
      let defaultData = JSvgCustoms.Data[this.chartItem.UrlDefaultData]
      if(!defaultData || Object.keys(defaultData).length === 0) {
        console.log('[Build Custom Chart] No default data found.')
        return
      }
    
      if (this.formMode == __C.FORM.EDIT_MODE_MOD) {      
        defaultData.Canvas.CanvasBorderColor = this.chartItem.CanvasBorderColor
        defaultData.Canvas.CanvasFillColor = this.chartItem.CanvasFillColor
        defaultData.Text = this.chartItem.Text
        defaultData.Note = this.chartItem.Note
      }
      
      // Database Queries ---
      if(defaultData.Queries) {
        var queries_ = []

        Object.keys(defaultData.Queries).forEach((k,n) => {

          if(defaultData.Queries[k].length === 0) {
            queries_.push({ name: k, script: '' }) 
            return
          }

          let colData_ = defaultData.Queries[k][0]
          let colNames_ = Object.keys(colData_)

          let withString = colNames_.map(n_ => {
            if(typeof colData_[n_] == 'number') var dataType_ = 'numeric(18,4)'
            else dataType_ = 'varchar(100)'
            return `[${n_}] ${dataType_} '$.${n_}'`
          })
        
          if (this.formMode == __C.FORM.EDIT_MODE_MOD && this.chartItem.Queries[n])  {
            queries_.push({
              name: this.chartItem.Queries[n].name,
              script : this.chartItem.Queries[n].script
            })
          } else {
            queries_.push({
              name: k,
              script: `
                SELECT  ${colNames_.map(n_ => `[${n_}]`).toString()}
                FROM    OPENJSON('${JSON.stringify(defaultData.Queries[k])}') WITH (
                  ${withString.toString()}
                )
              `
            })
          }
        })

        if(queries_.length > 0) this.useQueries = true
      }
      
      this.setChartItem({
        ...defaultData.Canvas,

        Border: defaultData.Border,
        ChartData: defaultData.ChartData,
        Queries: queries_ ? queries_ : [],
        Status: defaultData.Status,
        Text: defaultData.Text,
        Note: defaultData.Note,

        JsonString: JSON.stringify(defaultData.DataItems),

        QResultExtras: defaultData.Queries
      })

      // Set DataItems, not ChartData
      this.setChartData(defaultData.DataItems)
    },
    onRequestedAction(request) {
      // console.log(request)
    },
    onResizeCanvas(data) {
      let canvas__ = JSON.parse(JSON.stringify(this.canvas))
      this.setChartItem({ CanvasWidth: data.width, CanvasHeight: data.height })
    },

    // CRUD Process
    getChart(code=null) {
      return new Promise(resolve => {
        this.chartLibraryService.getChartCustom(code || this.keyValue, null, res => {
          this.setChartItem(res)
          
          // Set DataItems, not ChartData
          this.setChartData(
            this.chartItem.QApplied == 'Q' ? (
              this.chartItem.QResultSummary && 
              this.chartItem.QResultSummary.length > 0 ?
              this.chartItem.QResultSummary :
              []
            ) : (
              this.chartItem.JsonString ?
              JSON.parse(this.chartItem.JsonString) :
              []
            )
          )
          this.chartComponentName = this.chartItem.UrlEntrance
          this.$refs.general.setData(this.formMode)

          this.itemIdx(res.Idx, res.ChartNo)
          this.itemName(res.Name)

          resolve(res)
        })
      })
    },
    putChart(reqItem, callback) {
      this.chartLibraryService.putChartCustom(reqItem, callback)
    },
    sasChart(reqItem, callback) {
      this.chartLibraryService.sasChartCustom(reqItem, callback)
    },
    updChart(reqItem, callback) {
      this.chartLibraryService.updChartCustom(reqItem, callback)
    },
    delChart(chartNo, callback) {
      this.chartLibraryService.delChartCustom(chartNo, callback)
    },
  }
}
</script>
