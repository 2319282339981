<template>
  <v-flex v-model="tabActivator" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Line Chart</span>
      <small class="type_ref01">Properties</small>
    </div>

    <div class="chart_properties_contents">
      <j-alert
        v-model="msgOpen"
        :title="msgInfo.title"
        :type="msgInfo.type"
        :message="msgInfo.message"
        :button="msgInfo.button"
        :button-text="msgInfo.buttonText"
        @yes="yes()"
        @cancel="msgOpen = false"
      />
      <div class="header">
        Style
        <a class="close" v-on:click="collapsed['style'] = !collapsed['style']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['style']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="LineStyleDisplay"
                type="radio"
                name="LineStyleDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Height</label>
          <input v-model="LineStyleHeight" type="number" />
        </div>
        <div class="type_noDefault">
          <label>Style</label>
          <div class="jcon_temp_plus" data-grid-quickbtn="btn" @click="appendAttr()"></div>
        </div>
        <div
          class="type_common_input type_color_float equl_intervals"
          v-for="(attr, i) in localStyleAttrs"
          :key="`Style_attrs_${i}`"
        >
          <div class="input_flex_wrap">
            <div class="jcon_temp_minus" data-grid-quickbtn="btn" @click="clickRemoveBtn(i)"></div>
            <input v-model="attr.name" class="type_gray w90" type="text" @focusout="updateAttr" />
            <input v-model="attr.type" class="type_30 margin_0" type="text" @focusout="updateAttr" />
            <input v-model="attr.stroke" type="hidden" />
            <j-select-dash v-model="attr.stroke" @input="updateAttr" :color="attr.color"></j-select-dash>
            <j-color-picker v-model="attr.color" @input="updateAttr" title="Color"></j-color-picker>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Marker
        <a class="close" v-on:click="collapsed['marker'] = !collapsed['marker']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['marker']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="LineMarkerDisplay"
                type="radio"
                name="LineMarkerDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <span class="label">Type</span>
          <div class="row">
            <label v-for="option in bulletTypeOptions" :key="option.index" class="type_many">
              <input
                v-model="LineMarkerType"
                type="radio"
                name="LineMarkerType"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="LineMarkerSize" type="number" />
        </div>
        <div class="type_common_input">
          <label>Stroke</label>
          <input v-model="LineMarkerStroke" type="number" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Value
        <a class="close" v-on:click="collapsed['value'] = !collapsed['value']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['value']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="LineValueDisplay"
                type="radio"
                name="LineValueDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="LineValueFont" dir="rtl">
            <option
              v-for="option in fontOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="LineValueStyle" dir="rtl" class="type70">
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input type_color_float">
          <label>Size / Color</label>
          <div class="input_flex_wrap_end">
            <input v-model="LineValueSize"   type="number" />
            <j-color-picker v-model="LineValueColor"></j-color-picker>
          </div>
        </div>
        <div class="type_common_input">
          <label>Space</label>
          <input v-model="LineValueSpace" type="number" />
        </div>
        <div class="type_common_input">
          <label>Unit Format</label>
          <input v-model="LineValueFormat" type="text" />
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'
import JSelectDash from '@/components/popup/JSelectDash'

export default {
  name: 'j-chart-tab-line',
  mixins: [
    StaticOptions
  ],
  components: {
    JSelectDash
  },
  data: () => ({
    collapsed: {
      style: true,
      marker: false,
      value: false,
    },
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    localStyleAttrs: [],
    tabActivator: null,
    dashData: null,

    msgOpen: false,
    yes: null
  }),
  watch: {
    'chartItem.LineStyles': {
      handler(val) {
        if (!val) return
        this.localStyleAttrs = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    LineStyleDisplay: {
      get() { return this.chartItem.LineStyleDisplay },
      set(val) { this.setChartItem({ LineStyleDisplay: val }) }
    },
    LineStyleHeight: {
      get() { return this.chartItem.LineStyleHeight || 0 },
      set(val) { this.setChartItem({ LineStyleHeight: parseFloat(val || 0) }) }
    },
    LineStyles: {
      get() { return this.chartItem.LineStyles },
      set(val) { this.setChartItem({ LineStyles: val }) 
      }
    },
    LineMarkerDisplay: {
      get() { return this.chartItem.LineMarkerDisplay },
      set(val) { this.setChartItem({ LineMarkerDisplay: val }) }
    },
    LineMarkerType: {
      get() { return this.chartItem.LineMarkerType },
      set(val) { this.setChartItem({ LineMarkerType: val }) }
    },
    LineMarkerSize: {
      get() { return this.chartItem.LineMarkerSize || 0 },
      set(val) { this.setChartItem({ LineMarkerSize: parseFloat(val || 0) }) }
    },
    LineMarkerStroke: {
      get() { return this.chartItem.LineMarkerStroke || 0 },
      set(val) { this.setChartItem({ LineMarkerStroke: parseFloat(val || 0) }) }
    },
    LineValueDisplay: {
      get() { return this.chartItem.LineValueDisplay },
      set(val) { this.setChartItem({ LineValueDisplay: val }) }
    },
    LineValueFont: {
      get() { return this.chartItem.LineValueFont },
      set(val) { this.setChartItem({ LineValueFont: val }) }
    },
    LineValueStyle: {
      get() { return this.chartItem.LineValueStyle },
      set(val) { this.setChartItem({ LineValueStyle: val }) }
    },
    LineValueSize: {
      get() { return this.chartItem.LineValueSize || 0 },
      set(val) { this.setChartItem({ LineValueSize: parseFloat(val || 0) }) }
    },
    LineValueColor: {
      get() { return this.chartItem.LineValueColor },
      set(val) { this.setChartItem({ LineValueColor: val }) }
    },
    LineValueSpace: {
      get() { return this.chartItem.LineValueSpace || 0 },
      set(val) { this.setChartItem({ LineValueSpace: parseFloat(val || 0) }) }
    },
    LineValueFormat: {
      get() { return this.chartItem.LineValueFormat },
      set(val) { this.setChartItem({ LineValueFormat: val }) }
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    appendAttr() {
      this.localStyleAttrs.push({ name: '', type: '', stroke: '', color: '' })
      this.updateAttr()
    },
    clickRemoveBtn(i, j, el) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    removeAttr(index) {
      this.localStyleAttrs[index] = ''
      this.localStyleAttrs = this.localStyleAttrs.filter(v => !!v)
      this.updateAttr()
    },
    updateAttr() {
      this.setChartItem({ LineStyles: JSON.parse(JSON.stringify(this.localStyleAttrs)) })
    }
  }

}
</script>
