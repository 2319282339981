import JChartTabColor from './JChartTabColor'
import JChartTabBar from './JChartTabBar'
import JChartTabIcon from './JChartTabIcon'
import JChartTabText from './JChartTabText'


export default {
  JChartTabColor,
  JChartTabBar,
  JChartTabIcon,
  JChartTabText
}
