export default {
  name: '',
  no: '',
  action: {
    target: '',
    component: '',
    id: 0,
    no: '',
  }
}
