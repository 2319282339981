import JChartTabNavigation from '@/components/chart/tabs/JChartTabNavigation'
import JChartTabDatabase from '@/components/chart/tabs/JChartTabDatabase'
import JChartTabCanvas from '@/components/chart/tabs/JChartTabCanvas'
import JChartTabTitle from '@/components/chart/tabs/JChartTabTitle'
import JChartTabLegend from '@/components/chart/tabs/JChartTabLegend'
import JChartTabNote from '@/components/chart/tabs/JChartTabNote'
import JChartTabCircle from './JChartTabCircle'
import JChartTabGuideline from './JChartTabGuideline'

export default {
  JChartTabNavigation,
  JChartTabDatabase,
  JChartTabCanvas,
  JChartTabTitle,
  JChartTabLegend,
  JChartTabNote,
  JChartTabCircle,
  JChartTabGuideline,
}
