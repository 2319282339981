export default {

  ChartType: 'CUBE-A',

  Database: {
    DatabasePreviewMode: 'Chart',
    DatabaseAccessMode: 'Json',
    DatabaseJson: '',
    DatabaseQuery: '',
    DatabaseSP: '',
  },


  Canvas: {
    CanvasWidth: 250,
    CanvasHeight: 300,
    CanvasBorderWeight: 0.5,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 220,
    CanvasChartHeight: 300,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  

  Common: {
    area: {
      x:               0,
      y:               0,
      font:           'roboto',
    },
    texts: {
      title: {
        visible:      'Y',
        tColor:       '#333',
        tSize:        11,
      },
      header:{
        visible:      'Y',
        y:            12,
        tColor:       '#333',
        tSize:        10,
      },
      value:{
        y:            33,
      },
    },
  },

  CTypes: {
    // only Tube
    tube: { 
      height:         150,
      tickness:       60,
      color:          'LightBlue',
      base:           30,      
    },
    
    // only Cubes
    cubes: {
      title: {
        name:         'ITRs Summary',
        padding:      5,
        radius:       3,
        bgColor:      '#bcbcbc',
        opacity:      .5,
      },
      table:{
        y:            28,
        width:        200,
        height:       45,
        radius:       3,
        bgColor:      '#B4E3FA',
        opacity:      .5,
      },
    },

  },


  DataCols: {
    Columns: [
      {code: 'TOTAL',  head: 'Total',    width: 60, format: '$', tSize: 10, tColor: '#757575', link: 'Y', jMap: '', jLink: 'JSON1', filter: '',},
      {code: 'ACTUAL', head: 'Actual',   width: 60, format: '$', tSize: 10, tColor: '#757575', link: 'Y', jMap: '', jLink: 'JSON1', filter: '',},
      {code: 'REMAIN', head: 'Remain',   width: 60, format: '$', tSize: 10, tColor: '#757575', link: 'Y', jMap: '', jLink: 'JSON1', filter: '',},
      {code: 'PROG',   head: 'Progress', width: 60, format: '%', tSize: 15, tColor: '#44A9DF', link: 'N', jMap: '', jLink: ''     , filter: '',},
    ],
    Filters: [
      {code: 'UNIT',   jMap: 'FKEY1'},
    ],
  },



  DataItems: [
    {TOTAL: 1563,  ACTUAL: 965,  REMAIN: 657,  PROG: 15, },
  ],

}