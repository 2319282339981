<template>
  <v-flex v-model="tabActivator" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Status</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <j-alert
        v-model="msgOpen"
        :title="msgInfo.title"
        :type="msgInfo.type"
        :message="msgInfo.message"
        :button="msgInfo.button"
        :button-text="msgInfo.buttonText"
        @yes="yes()"
        @cancel="msgOpen = false"
      />
      <div class="header">
        Category
        <small class="type_ref03">(Border Color)</small>
        <a class="close" v-on:click="collapsed['category'] = !collapsed['category']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['category']">
        <div class="input_flex_wrap code_title_wrapper">
          <div
            class="jcon_temp_plus"
            data-grid-quickbtn="btn"
            @click="appendAttr('StatusCategory')"
          >
            <label>Default Code</label>
          </div>
          <j-color-picker v-model="defaultCategory">
            <template #action="{ action }">
              <v-btn
                v-on="action.on"
                class="type16"
                :style="`background-color: #fff; border-color: ${ defaultCategory }; margin-left: 3px;`"
                @click="action.open"
              ></v-btn>
            </template>
          </j-color-picker>
        </div>
        <div
          class="type_common_input type_color_float"
          v-for="(attr, i) in localStyleAttrs.StatusCategory"
          :key="`_cell_${i}`"
        >
          <div class="input_flex_wrap">
            <div
              class="jcon_temp_minus"
              data-grid-quickbtn="btn"
              @click="clickRemoveBtn('StatusCategory', i)"
            ></div>
            <input
              class="type_110 type_gray"
              v-model="attr.name"
              type="text"
              @focusout="updateAttr"
            />
            <input class="type_53" v-model="attr.code" type="text" @focusout="updateAttr" />
            <j-color-picker v-model="attr.value" @input="updateAttr">
              <template #action="{ action }">
                <v-btn
                  v-on="action.on"
                  class="type16"
                  :style="`background-color: #fff; border-color: ${attr.value}; margin-left: 3px;`"
                  @click="action.open"
                ></v-btn>
              </template>
            </j-color-picker>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Stage
        <small class="type_ref03">(Fill Color)</small>
        <a class="close" v-on:click="collapsed['stage'] = !collapsed['stage']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['stage']">
        <div class="input_flex_wrap code_title_wrapper">
          <div class="jcon_temp_plus" data-grid-quickbtn="btn" @click="appendAttr('StatusStage')">
            <label>Default Code</label>
          </div>
          <j-color-picker v-model="defaultStage" />
        </div>
        <div
          class="type_common_input type_color_float"
          v-for="(attr, i) in localStyleAttrs.StatusStage"
          :key="`_color_${i}`"
        >
          <div class="input_flex_wrap">
            <div
              class="jcon_temp_minus"
              data-grid-quickbtn="btn"
              @click="clickRemoveBtn('StatusStage', i)"
            ></div>
            <input
              class="type_110 type_gray"
              v-model="attr.name"
              type="text"
              @focusout="updateAttr"
            />
            <input class="type_53" v-model="attr.code" type="text" @focusout="updateAttr" />
            <j-color-picker v-model="attr.value" @input="updateAttr" />
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Progress
        <small class="type_ref03">(Bar Chart)</small>
        <a class="close" v-on:click="collapsed['progress'] = !collapsed['progress']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['progress']">
        <div class="progress_btn">
          <div class="row order_btn">
            <span>1</span>
            <span>2</span>
          </div>
        </div>
        <div
          v-for="(progs, i) in localStyleAttrs.StatusStage"
          class="progress_list"
          :key="`progress_list_${i}`"
        >
          <div class="row">
            <div>
              <div class="color_preview" :style="`background-color: ${progs.value};`" />
              <span>{{ progs.name }}</span>
            </div>
            <div class="item_preview row">
              <input
                v-model="progs.prog1"
                type="radio"
                name="progress_1st"
                value="Y"
                @input="updateProgress('prog1', i)"
              />
              <input
                v-model="progs.prog2"
                type="radio"
                name="progress_2nd"
                value="Y"
                @input="updateProgress('prog2', i)"
              />
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-status',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      category: true,
      stage: true,
      progress: true,
    },
    msgInfo: {
      type: "WARN",
      title: "Remove Style",
      message: "Do you want to remove style?",
      button: [true, false, true],
      buttonText: ["Remove", "No", "Cancel"],
      yes: null
    },
    localStyleAttrs: {
      Default: {
        StatusCategory: { name: 'Default', code: '', value: 'tansparent', display: 'Y' },
        StatusStage: { name: 'Not Started', code: 'NOT', value: 'tansparent', prog1: '', prog2: '', display: 'Y' },
      },
      StatusCategory: [],
      StatusStage: [],
    },
    prevProg: {
      prog1: -1,
      prog2: -1
    },
    tabActivator: null,
    removeIndex: [],
    msgOpen: false,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    defaultCategory: {
      get() { return this.localStyleAttrs.Default.StatusCategory.value },
      set(val) {
        this.localStyleAttrs.Default.StatusCategory.value = val
        this.updateAttr()
      }
    },
    defaultStage: {
      get() { return this.localStyleAttrs.Default.StatusStage.value },
      set(val) {
        this.localStyleAttrs.Default.StatusStage.value = val
        this.updateAttr()
      }
    },
  },
  watch: {
    'chartItem.StyleAttrs': {
      handler(val) {
        if (!val) return
        this.localStyleAttrs = JSON.parse(JSON.stringify(val))

        this.prevProg.prog1 = this.localStyleAttrs.StatusStage.findIndex(status => status.prog1 == 'Y')
        this.prevProg.prog2 = this.localStyleAttrs.StatusStage.findIndex(status => status.prog2 == 'Y')
      },
      deep: true
    },
    'msgInfo.yes': {
      handler(val) {
        if(!val) return
        this.removeAttr(...this.removeIndex)
      }
    }
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    appendAttr(typeName) {
      this.localStyleAttrs[typeName].push(this.localStyleAttrs.Default[typeName])
      this.updateAttr()
    },
    clickRemoveBtn(i, j, el) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    removeAttr(typeName, index) {
      this.localStyleAttrs[typeName][index] = ''
      this.localStyleAttrs[typeName] = this.localStyleAttrs[typeName].filter(v => !!v)
      this.updateAttr()
    },
    updateAttr() {
      this.setChartItem({ StyleAttrs: this.localStyleAttrs })
    },
    updateProgress(name, i) {
      if(name == 'prog1') var index_ = this.prevProg.prog2
      else index_ = this.prevProg.prog1

      if(index_ === i) {
        this.localStyleAttrs.StatusStage[i][name] = ''
        if(this.prevProg[name] >= 0) this.localStyleAttrs.StatusStage[this.prevProg[name]][name] = 'Y'

      } else this.prevProg[name] = i

      this.localStyleAttrs.StatusStage.forEach((stage, j) => { stage[name] = (i === j ? 'Y' : '') })
      this.updateAttr()
    }
  }
}
</script>
