<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Table</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Table
        <a class="close" v-on:click="collapsed['table'] = !collapsed['table']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['table']">
        <div class="type_common_input">
          <label>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="TableX" type="number" class="type_mgl0 type_double" />
          <input v-model="TableY" type="number" />
        </div>
        <!-- <div class="type_common_input type_color_float">
          <label class="type_gray">Border (Stroke / Color)</label>
          <div class="input_flex_wrap_end">
            <input v-model="TableStroke" class="type30" type="number" />
            <j-color-picker v-model="TableStrokeColor"></j-color-picker>
          </div>
        </div> -->
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Header
        <a class="close" v-on:click="collapsed['header'] = !collapsed['header']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['header']">
        <div class="type_common_input">
          <label class="type_gray label">Auto Apply To Column</label>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="AutoApplyToColumns" type="radio" name="AutoApplyToColumns" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <span class="label">JSON</span>
          <div class="row">
            <div justify="center" style="align-items: center">
              <v-btn @click.stop="exp['H']=true" class="type_openTextarea">{{ getQueryParts('headerJson') }}</v-btn>
              <j-modal-scripter 
                v-model="headerJson" 
                title="Table Header JSON"
                sub-title="JSON Properties"
                :buttons="{validate: true, save:false}"
                :message="validated.headerJson.message"
                :opened="exp.H"
                :valid="validated.headerJson.valid"
                @close="exp.H=false;updateAttr();$emit('json-updated')"
                @save="exp.H=false"
                @validate="onValidate('headerJson')"
              />
            </div>
          </div>
        </div>
        <div class="type_common_select" style="margin-bottom: -1rem;">
          <label>Font</label>
          <select v-model="TableHeadFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div v-if="headerAttrs.length === 0">
          <span data-grid-quickbtn="btn" class="label jcon_temp_plus type_header" @click="appendAttr()">1st</span>
        </div>
        <div v-for="(_, i) in headerAttrs" :key="`_header_${i}`">
          <span v-if="i === 0" data-grid-quickbtn="btn" class="label jcon_temp_plus type_header" @click="appendAttr()">1st</span>
          <span v-else data-grid-quickbtn="btn" class="label jcon_temp_minus type_header" @click="removeAttr(i)">{{ i + 1 }}{{ surfix(i) }}</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">Height</label>
              <input type="number" :value="TableHeadHeight[i]" @input="(e) => { updateAttr(e.target.value, i, 'TableHeadHeight') }" />
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Style</label>
              <select dir="rtl" class="type70" :value="TableHeadFontStyle[i]" @input="(e) => { updateAttr(e.target.value, i, 'TableHeadFontStyle') }">
                <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Size / Color</label>
              <div class="input_flex_wrap_end">
                <input type="number" class="type30" :value="TableHeadFontSize[i]" @input="(e) => { updateAttr(e.target.value, i, 'TableHeadFontSize') }" />
                <j-color-picker v-model="TableHeadFontColor[i]" @input="updateAttr"></j-color-picker>
              </div>
            </div>
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Stroke / Color</label>
              <div class="input_flex_wrap_end">
                <input type="number" class="type30" :value="TableHeadStroke[i]" @input="(e) => { updateAttr(e.target.value, i, 'TableHeadStroke') }" />
                <j-color-picker v-model="TableHeadStrokeColor[i]" @input="updateAttr"></j-color-picker>
              </div>
            </div>
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Opacity / Fill Color</label>
              <div class="input_flex_wrap_end has_gradient_picker">
                <input type="number" class="type30" :value="TableHeadOpacity[i]" @input="(e) => { updateAttr(e.target.value, i, 'TableHeadOpacity') }" />
                <j-color-picker
                  v-model="TableHeadFillColor[i]" 
                  :gradation="true"
                  :gradation-type="TableHeadColorType[i]"
                  @gradate="v => TableHeadColorType[i] = v"
                  @input="updateAttr"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="type_common_input" style="padding-top: 0.5rem;">
          <label class="type_gray label">Header Border Auto</label>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="HeaderBorderAuto" type="radio" name="HeaderBorderAuto" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_select" >
          <label>Border Display</label>
          <div class="row border_header">
            <svg class="btn_border_box" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
              <line class="border_top"  y1="0" y2="20" transform="translate(20) rotate(90)" :class="{ active: clicked['borderHeader']['top']}" @click="setBorder('borderHeader', 'top', 'HeadBorderTop')"/>
              <line class="border_right" y1="0" y2="20" x1="0" x2="0" transform="translate(20)" :class="{ active: clicked['borderHeader']['right']}" @click="setBorder('borderHeader', 'right', 'HeadBorderRight')"/>
              <line class="border_bottom" y1="0" y2="20" transform="translate(20,20) rotate(90)" :class="{ active: clicked['borderHeader']['bottom']}" @click="setBorder('borderHeader', 'bottom', 'HeadBorderBottom')"/>
              <line class="border_left" y1="0" y2="20" x1="0" x2="0" transform="translate(0)" :class="{ active: clicked['borderHeader']['left']}" @click="setBorder('borderHeader', 'left', 'HeadBorderLeft')"/>
            </svg>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Cells
        <a class="close" v-on:click="collapsed['cells'] = !collapsed['cells']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['cells']">
        <div class="type_common_select">
          <label class="type_gray">Font Family</label>
          <select v-model="TableCellFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label class="type_gray">Style</label>
          <select v-model="TableCellStyle" dir="rtl" class="type70">
            <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label class="type_gray">Padding(x)</label>
          <input v-model="TableCellPadding" type="number" />
        </div>
        <span class="label">Line <small class="type_ref03">(Horizontal)</small></span>
        <div class="type_inner">
          <div class="type_common_input">
            <label class="type_gray second_depth">Height</label>
            <input v-model="TableCellHeight" type="number" />
          </div>
          <div class="type_common_input type_color_float">
            <label class="type_gray second_depth">Stroke / Color</label>
            <div class="input_flex_wrap_end">
              <input v-model="TableCellStroke" class="type30" type="number" />
              <j-color-picker v-model="TableCellStrokeColor"></j-color-picker>
            </div>
          </div>
        </div>
        <span class="label">Line</span>
        <div class="type_inner">
          <div class="type_common_input">
            <label class="type_gray label second_depth">Horizontal</label>
            <div class="row">
              <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                <input v-model="TableCellHLineDisplay" type="radio" name="TableCellHLineDisplay" :value="option.value" />
                {{option.text}}
              </label>
            </div>
          </div>
          <div class="type_common_input">
            <label class="type_gray label second_depth">Vertical</label>
            <div class="row">
              <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                <input v-model="TableCellVLineDisplay" type="radio" name="TableCellVLineDisplay" :value="option.value" />
                {{option.text}}
              </label>
            </div>
          </div>
          <div class="type_common_input">
            <label class="type_gray label second_depth">Outline</label>
            <div class="row">
              <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                <input v-model="TableCellOutlineDisplay" type="radio" name="TableCellOutlineDisplay" :value="option.value" />
                {{option.text}}
              </label>
            </div>
          </div>
        </div>
        <span class="label">Hovoring Function</span>
        <div class="type_inner">
          <div class="type_common_input">
            <label class="type_gray second_depth">Opacity</label>
            <input v-model="TableCellHoverOpacity" type="number" />
          </div>
          <div class="type_common_input type_color_float">
            <label class="type_gray second_depth">Color</label>
            <div class="input_flex_wrap_end">
              <j-color-picker v-model="TableCellHoverColor"></j-color-picker>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Footer
        <a class="close" v-on:click="collapsed['footer'] = !collapsed['footer']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['footer']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="TableFootDisplay" type="radio" name="TableFootDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_select">
          <label class="type_gray">Title</label>
          <input v-model="TableFootTitle" type="text" placeholder="Title" class="type147 algin_left algin_left">
        </div>
        <div class="type_common_input">
          <label class="type_gray label">Line</label>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="TableFootLineDisplay" type="radio" name="TableFootLineDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input type_color_float">
          <label class="type_gray">Line Height</label>
          <input v-model="TableFootHeight" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="TableFootFillColor" title="Fill Color" />
        </div>
        <span class="label">Text</span>
        <div class="type_inner">
          <div class="type_common_select">
            <label class="type_gray second_depth">Style</label>
            <select v-model="TableFootTextStyle" dir="rtl" class="type70">
              <option
                v-for="option in fontStyleOptions"
                :key="option.index"
                :value="option.value"
              >{{option.text}}</option>
            </select>
          </div>
          <div class="type_common_input type_color_float">
            <label class="type_gray second_depth">Size / Color</label>
            <div class="input_flex_wrap_end">
              <input v-model="TableFootTextSize" type="number" />
              <j-color-picker v-model="TableFootTextColor"></j-color-picker>
            </div>
          </div>
        </div>
        <div class="type_common_select">
          <label class="type_gray">Merge Cell</label>
          <input v-model="TableFootMergeCell" type="text" />
        </div>
        <span class="label" style="margin-bottom: 0;">SQL</span>
        <div class="type_common_input" style="justify-content: flex-end">
          <div class="row">
            <div style="align-items: center">
              <v-btn @click.stop="exp['F']=true" class="type_openTextarea type160 margin_bt_2">{{ getQueryParts('TableFootSQL') }}</v-btn>
              <j-modal-scripter 
                v-model="TableFootSQL" 
                title="Table Footer SQL Query"
                sub-title="Access Database"
                :buttons="{validate: true, save:false}"
                :message="validated.TableFootSQL.message"
                :opened="exp.F"
                :valid="validated.TableFootSQL.valid"
                @close="exp.F=false"
                @save="exp.F=false"
                @validate="onValidate('TableFootSQL')"
              />
            </div>
          </div>
        </div>
        <span class="label">Reference Styles</span>
        <div class="type_inner">
          <div class="type_common_select">
            <label class="type_gray second_depth">Icon</label>
            <input v-model="TableFootRefIcon" type="text" />
          </div>
          <div class="type_common_input type_color_float">
            <label class="type_gray second_depth">Bar Chart</label>
            <input v-model="TableFootRefBarChart" type="text" />
          </div>
        </div>
        <div class="type_common_select" style="padding-top: 1rem;">
          <label>Border Display</label>
          <div class="row border_bottom">
            <svg class="btn_border_box" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
              <line class="border_top"  y1="0" y2="20" transform="translate(20) rotate(90)" :class="{ active: clicked['borderBottom']['top']}" @click="setBorder('borderBottom', 'top', 'FootBorderTop')"/>
              <line class="border_right" y1="0" y2="20" x1="0" x2="0" transform="translate(20)" :class="{ active: clicked['borderBottom']['right']}" @click="setBorder('borderBottom', 'right', 'FootBorderRight')"/>
              <line class="border_bottom" y1="0" y2="20" transform="translate(20,20) rotate(90)" :class="{ active: clicked['borderBottom']['bottom']}" @click="setBorder('borderBottom', 'bottom', 'FootBorderBottom')"/>
              <line class="border_left" y1="0" y2="20" x1="0" x2="0" transform="translate(0)" :class="{ active: clicked['borderBottom']['left']}" @click="setBorder('borderBottom', 'left', 'FootBorderLeft')"/>
            </svg>
          </div>
        </div>
        <div style="height: 100px;"></div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from "vuex"
import { ChartLibraryService } from "@/services"
import { QueryValidationMixin } from "@/mixins/sqlValidator"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-summary-table',
  mixins: [
    QueryValidationMixin,
    StaticOptions
  ],
  data: () => ({
    chartLibraryService: null,
    
    collapsed: {
      table: true,
      header: true,
      cells: true,
      footer: true,
    },
    localTableAttrs: {},
    arrayNames: [
      'TableHeadHeight',
      'TableHeadFontStyle',
      'TableHeadFontSize',
      'TableHeadFontColor',
      'TableHeadStroke',
      'TableHeadStrokeColor',
      'TableHeadOpacity',
      'TableHeadFillColor',
      'TableHeadColorType'
    ],
    tabActivator: null,
    localHeaderAttrs: {
      item: [],
    },

    exp: {
      H: false,
      F: false,
    },
    validated: {
      headerJson: { valid: true, message: '' },
      TableFootSQL: { valid: true, message: '' }
    },
    clicked: {
      borderHeader: {
        top: true,
        right: true,
        bottom: true,
        left: true,
      },
      borderBottom: {
        top: true,
        right: true,
        bottom: true,
        left: true,
      },
    }
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    headerAttrs() { 
      let length_ = this.localTableAttrs.TableHeadHeight ? this.localTableAttrs.TableHeadHeight.length : 0
      return Array.from({ length: length_  }, (_, i) => i) 
    },

    headerJson: {
      get() { return this.chartItem.Header || '' },
      set(val) { this.setChartItem({ Header: val }) }
    },

    TableX: {
      get() { return this.localTableAttrs.TableX || 0 },
      set(val) { this.localTableAttrs.TableX = parseFloat(val || 0); this.updateAttr(); }
    },
    TableY: {
      get() { return this.localTableAttrs.TableY || 0 },
      set(val) { this.localTableAttrs.TableY = parseFloat(val || 0); this.updateAttr(); }
    },
    TableStroke: {
      get() { return this.localTableAttrs.TableStroke || 0 },
      set(val) { this.localTableAttrs.TableStroke = parseFloat(val || 0); this.updateAttr(); }
    },
    TableStrokeColor: {
      get() { return this.localTableAttrs.TableStrokeColor },
      set(val) { this.localTableAttrs.TableStrokeColor = val; this.updateAttr(); }
    },

    // ### -------------- Header --------------------
    AutoApplyToColumns: {
      get() { return this.localTableAttrs.AutoApplyToColumns },
      set(val) { this.localTableAttrs.AutoApplyToColumns = val; this.updateAttr(); }
    },
    TableHeadFont: {
      get() { return this.localTableAttrs.TableHeadFont },
      set(val) { this.localTableAttrs.TableHeadFont = val; this.updateAttr(); }
    },
    TableHeadHeight() { return this.localTableAttrs.TableHeadHeight || [] },
    TableHeadFontStyle() { return this.localTableAttrs.TableHeadFontStyle || [] },
    TableHeadFontSize() { return this.localTableAttrs.TableHeadFontSize || []},
    TableHeadFontColor() { return this.localTableAttrs.TableHeadFontColor || [] },
    TableHeadStroke() { return this.localTableAttrs.TableHeadStroke || [] },
    TableHeadStrokeColor() { return this.localTableAttrs.TableHeadStrokeColor || [] },
    TableHeadOpacity() { return this.localTableAttrs.TableHeadOpacity || [] },
    TableHeadFillColor() { return this.localTableAttrs.TableHeadFillColor || [] },
    TableHeadColorType() { return this.localTableAttrs.TableHeadColorType || [] },

    HeaderBorderAuto: {
      get() { return this.localTableAttrs.HeaderBorderAuto },
      set(val) { this.localTableAttrs.HeaderBorderAuto = val; this.updateAttr(); }
    },
    
    HeadBorderTop: { 
      get() { return this.localTableAttrs.HeadBorderTop },
    },
    HeadBorderBottom: {
      get() { return this.localTableAttrs.HeadBorderBottom },
    },
    HeadBorderLeft: {
      get() { return this.localTableAttrs.HeadBorderLeft },
    },
    HeadBorderRight: {
      get() { return this.localTableAttrs.HeadBorderRight },
    },

    // ### -------------- Cell --------------------
    TableCellFont: {
      get() { return this.localTableAttrs.TableCellFont },
      set(val) { this.localTableAttrs.TableCellFont = val; this.updateAttr(); }
    },
    TableCellStyle: {
      get() { return this.localTableAttrs.TableCellStyle },
      set(val) { this.localTableAttrs.TableCellStyle = val; this.updateAttr(); }
    },
    TableCellPadding: {
      get() { return this.localTableAttrs.TableCellPadding },
      set(val) { this.localTableAttrs.TableCellPadding = parseFloat(val || 0); this.updateAttr(); }
    },
    TableCellHeight: {
      get() { return this.localTableAttrs.TableCellHeight },
      set(val) { this.localTableAttrs.TableCellHeight = parseFloat(val || 0); this.updateAttr(); }
    },
    TableCellStroke: {
      get() { return this.localTableAttrs.TableCellStroke },
      set(val) { this.localTableAttrs.TableCellStroke = parseFloat(val || 0); this.updateAttr(); }
    },
    TableCellStrokeColor: {
      get() { return this.localTableAttrs.TableCellStrokeColor },
      set(val) { this.localTableAttrs.TableCellStrokeColor = val; this.updateAttr(); }
    },
    TableCellHLineDisplay: {
      get() { return this.localTableAttrs.TableCellHLineDisplay },
      set(val) { this.localTableAttrs.TableCellHLineDisplay = val; this.updateAttr(); }
    },
    TableCellVLineDisplay: {
      get() { return this.localTableAttrs.TableCellVLineDisplay },
      set(val) { this.localTableAttrs.TableCellVLineDisplay = val; this.updateAttr(); }
    },
    TableCellOutlineDisplay: {
      get() { return this.localTableAttrs.TableCellOutlineDisplay },
      set(val) { this.localTableAttrs.TableCellOutlineDisplay = val; this.updateAttr(); }
    },
    TableCellHoverOpacity: {
      get() { return this.localTableAttrs.TableCellHoverOpacity },
      set(val) { this.localTableAttrs.TableCellHoverOpacity = parseFloat(val || 0); this.updateAttr(); }
    },
    TableCellHoverColor: {
      get() { return this.localTableAttrs.TableCellHoverColor },
      set(val) { this.localTableAttrs.TableCellHoverColor = val; this.updateAttr(); }
    },

    // ### -------------- Footer --------------------
    TableFootDisplay: {
      get() { return this.localTableAttrs.TableFootDisplay },
      set(val) { this.localTableAttrs.TableFootDisplay = val; this.updateAttr(); }
    },
    TableFootTitle: {
      get() { return this.localTableAttrs.TableFootTitle },
      set(val) { this.localTableAttrs.TableFootTitle = val; this.updateAttr(); }
    },
    TableFootLineDisplay: {
      get() { return this.localTableAttrs.TableFootLineDisplay },
      set(val) { this.localTableAttrs.TableFootLineDisplay = val; this.updateAttr(); }
    },
    TableFootHeight: {
      get() { return this.localTableAttrs.TableFootHeight },
      set(val) { this.localTableAttrs.TableFootHeight = parseFloat(val || 0); this.updateAttr(); }
    },
    TableFootFillColor: {
      get() { return this.localTableAttrs.TableFootFillColor },
      set(val) { this.localTableAttrs.TableFootFillColor = val; this.updateAttr(); }
    },
    TableFootTextStyle: {
      get() { return this.localTableAttrs.TableFootTextStyle },
      set(val) { this.localTableAttrs.TableFootTextStyle = val; this.updateAttr(); }
    },
    TableFootTextSize: {
      get() { return this.localTableAttrs.TableFootTextSize },
      set(val) { this.localTableAttrs.TableFootTextSize = parseFloat(val || 0); this.updateAttr(); }
    },
    TableFootTextColor: {
      get() { return this.localTableAttrs.TableFootTextColor },
      set(val) { this.localTableAttrs.TableFootTextColor = val; this.updateAttr(); }
    },
    TableFootMergeCell: {
      get() { return this.localTableAttrs.TableFootMergeCell },
      set(val) { this.localTableAttrs.TableFootMergeCell =  parseFloat(val || 0); this.updateAttr(); }
    },
    TableFootSQL: {
      get() { return this.localTableAttrs.TableFootSQL },
      set(val) { this.localTableAttrs.TableFootSQL = val; this.updateAttr(); }
    },
    TableFootRefIcon: {
      get() { return this.localTableAttrs.TableFootRefIcon },
      set(val) { this.localTableAttrs.TableFootRefIcon = val; this.updateAttr(); }
    },
    TableFootRefBarChart: {
      get() { return this.localTableAttrs.TableFootRefBarChart },
      set(val) { this.localTableAttrs.TableFootRefBarChart = val; this.updateAttr(); }
    },
    FootBorderTop: { 
      get() { return this.localTableAttrs.FootBorderTop },
    },
    FootBorderBottom: {
      get() { return this.localTableAttrs.FootBorderBottom },
    },
    FootBorderLeft: {
      get() { return this.localTableAttrs.FootBorderLeft },
    },
    FootBorderRight: {
      get() { return this.localTableAttrs.FootBorderRight },
    },
  },
  watch: {
    'chartItem.Table': {
      handler(val) {
        if (!val) return
        this.localTableAttrs = JSON.parse(JSON.stringify(val))

        this.setBorderValue()
      },
      deep: true
    }
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    surfix(i) {
      let surfix_ = ['st', 'nd', 'rd', 'th']
      if (i < 3) return surfix_[i]
      return surfix_[3]
    },
    appendAttr() {
      this.arrayNames.forEach(k => { this.localTableAttrs[k].push(null) })
      this.updateAttr()
    },
    removeAttr(index) {
      let tableAttrs_ = {}

      this.arrayNames.forEach(k => { 
        tableAttrs_[k] = []
        this.localTableAttrs[k].forEach((v, i) => { if(index !== i) tableAttrs_[k].push(v) })
      })

      this.localTableAttrs = { ...this.localTableAttrs, ...tableAttrs_ }
      this.updateAttr()
    },
    updateAttr(val, i, el, type) {
      if(type != 'object') {
        const strAttr = ['TableHeadFontStyle']
        if(el) this.localTableAttrs[el][i] = strAttr.indexOf(el) !== -1 ? val : parseFloat(val)
      } else if(type == 'object') {
        val == true ? this.localTableAttrs[el] = 'Y' : this.localTableAttrs[el] = 'N'
      }
      
      this.setChartItem({ Table: this.localTableAttrs })
    },
    onSave() {
      this.$emit('save')
    },
    onValidate(typeName) {
      if(typeName == 'headerJson') {
        this.validated.headerJson = this.validateJson(this.headerJson)
        if(this.validated.headerJson.valid) this.headerJson = this.validated.headerJson.json
      } else {
        this.validateSql(this[typeName]).then(res => {
          this.validated[typeName] = res
        })
      }
    },
    setBorderValue() {
      this.clicked['borderHeader']['top'] = this.localTableAttrs.HeadBorderTop == 'Y' ?  true : false
      this.clicked['borderHeader']['bottom'] = this.localTableAttrs.HeadBorderBottom == 'Y' ?  true : false 
      this.clicked['borderHeader']['left'] = this.localTableAttrs.HeadBorderLeft == 'Y' ?  true :  false 
      this.clicked['borderHeader']['right'] = this.localTableAttrs.HeadBorderRight == 'Y' ?  true : false
      
      this.clicked['borderBottom']['top'] = this.localTableAttrs.FootBorderTop == 'Y' ?  true : false
      this.clicked['borderBottom']['bottom'] = this.localTableAttrs.FootBorderBottom == 'Y' ?  true : false
      this.clicked['borderBottom']['left'] = this.localTableAttrs.FootBorderLeft == 'Y' ?  true :  false
      this.clicked['borderBottom']['right'] = this.localTableAttrs.FootBorderRight == 'Y' ?  true : false 
    },
    setBorder(group, type, el) {
      this.clicked[group][type] = !this.clicked[group][type]
      this.updateAttr(this.clicked[group][type], null, el,'object')
    },
  }
}
</script>
