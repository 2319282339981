<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Radar</span>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Chart
        <!-- <small class="type_ref03">(Name)</small> -->
        <a class="close" @click="collapsed['chart'] = !collapsed['chart']"></a>
      </div>
      <j-alert
        v-model="msgOpen"
        :title="msgInfo.title"
        :type="msgInfo.type"
        :message="msgInfo.message"
        :button="msgInfo.button"
        :button-text="msgInfo.buttonText"
        @yes="yes()"
        @cancel="msgOpen = false"
      ></j-alert>
      <v-flex class="control" v-show="collapsed['chart']">
        <div class="type_common_input">
          <label class="type_gray">
            Coordinate
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="chartX" type="number" class="type_mgl0 type_double" />
          <input v-model="chartY" type="number" />
        </div>
        <div class="type_common_input">
          <label class="type_gray">
            Size
          </label>
          <input v-model="chartSize" type="number" />
        </div>
        <div class="type_common_input">
          <label class="type_gray">
            Data No.
          </label>
          <input v-model="chartData" type="number" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Level
        <a class="close" @click="collapsed['level'] = !collapsed['level']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['level']">
        <div class="type_common_input">
          <label class="type_gray">
            Level Count
          </label>
          <input v-model="levelNo" type="number" />
        </div>
        <span class="label">Line</span>
        <div class="type_inner">
          <div class="type_common_input">
            <label class="type_gray second_depth">Stroke / Color</label>
            <div class="row">
              <input v-model="levelStWidth" class="type_double" type="number" />
              <j-color-picker v-model="levelStColor"/>
            </div>
          </div>
          <div class="type_common_input">
            <label class="type_gray second_depth">
              Opacity
            </label>
            <input v-model="levelOpacity" type="number" />
          </div>
        </div>
        <span class="label">Last Line</span>
        <div class="type_inner">
          <div class="type_common_input">
            <label class="type_gray second_depth">Stroke / Color</label>
            <div class="row">
              <input v-model="levelLastStWidth" class="type_double" type="number" />
              <j-color-picker v-model="levelLastStColor"/>
            </div>
          </div>
          <div class="type_common_input">
            <label class="type_gray second_depth">
              Opacity
            </label>
            <input v-model="levelLastOpacity" type="number" />
          </div>
        </div>
        <span class="label">Text</span>
        <div class="type_inner">
          <div class="type_common_select">
            <label class="type_gray second_depth">Font</label>
            <select v-model="levelFont" dir="rtl">
              <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
            </select>
          </div>
          <div class="type_common_input">
            <label class="type_gray second_depth">Size / Color</label>
            <div class="row">
              <input v-model="levelTSize" class="type_double" type="number" />
              <j-color-picker v-model="levelTColor"/>
            </div>
          </div>
          <div class="type_common_input">
            <label class="type_gray second_depth">
              Max Value
            </label>
            <input v-model="levelMaxValue" type="number" />
          </div>
          <div class="type_common_input">
            <label class="type_gray second_depth">
              Level Space
            </label>
            <input v-model="levelSpace" type="number" />
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Axes
        <a class="close" @click="collapsed['axes'] = !collapsed['axes']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['axes']">
        <div class="type_common_input">
          <label class="type_gray">Stroke / Color</label>
          <div class="row">
            <input v-model="axesStWidth" class="type_double" type="number" />
            <j-color-picker v-model="axesStColor"/>
          </div>
        </div>
        <div class="type_common_input">
          <label class="type_gray">
            Opacity
          </label>
          <input v-model="axesOpacity" type="number" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Series
        <a class="close" @click="collapsed['series'] = !collapsed['series']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['series']">
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="seriesFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label class="type_gray">Size / Color</label>
          <div class="row">
            <input v-model="seriesTSize" class="type_double" type="number" />
            <j-color-picker v-model="seriesTColor"/>
          </div>
        </div>
        <div class="type_common_input">
          <label class="type_gray">
            Factor
          </label>
          <input v-model="seriesFactor" type="number" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Value
        <a class="close" @click="collapsed['value'] = !collapsed['value']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['value']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="valueDisplay" type="radio" name="valueDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="valueFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label class="type_gray">Size / Color</label>
          <div class="row">
            <input v-model="valueTSize" class="type_double" type="number" />
            <j-color-picker v-model="valueTColor"/>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        <span>
          Area
          <span data-grid-quickbtn="btn" class="label jcon_temp_plus type_header" style="color: #4298c6;" @click="appendAttr()"><small style="padding-left: 0.1rem;">Add</small></span>
        </span>
        <a class="close" @click="collapsed['area'] = !collapsed['area']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['area']">
        <div v-for="(attr, i) in polygonAttrs" :key="`_header_${i}`">
          <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i)">{{ i + 1 }}{{ surfix(i) }}</span>
          <div class="type_inner">
            <div class="type_common_input">
            <label class="type_gray second_depth">Stroke / Color</label>
            <div class="row">
              <input v-model="areaStWidth[i]" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'stWidth', 'area', 'num')" />
              <j-color-picker v-model="areaStColor[i]" @input="updateAttr"/>
            </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Fill Color</label>
              <div class="row picker_gradient">
                <j-color-picker 
                  v-model="areaFColor[i]" 
                  :gradation="true"
                  :gradation-type="areaColorType[i]"
                  @gradate="v => areaColorType[i] = v" 
                  @input="updateAttr"
                />
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">
                Opacity
              </label>
              <input v-model="areaOpacity[i]" type="number" class="type_mgl0" @input="(e) => updateAttr(e.target.value, i, 'opacity', 'area', 'num')"/>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        <span>
          Node
          <span data-grid-quickbtn="btn" class="label jcon_temp_plus type_header" style="color: #4298c6;" @click="appendAttr()"><small style="padding-left: 0.1rem;">Add</small></span>
        </span>
        <a class="close" @click="collapsed['node'] = !collapsed['node']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['node']">
        <div v-for="(attr, i) in polygonAttrs" :key="`_header_${i}`">
          <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i)">{{ i + 1 }}{{ surfix(i) }}</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">
                Node Size
              </label>
              <input v-model="nodeSize[i]" type="number" class="type_mgl0" @input="(e) => updateAttr(e.target.value, i, 'size', 'node', 'num')"/>
            </div>
            <div class="type_common_input">
            <label class="type_gray second_depth">Stroke / Color</label>
            <div class="row">
              <input :value="nodeStWidth[i]" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'stWidth', 'node', 'num')" />
              <j-color-picker v-model="nodeStColor[i]" @input="updateAttr"/>
            </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Fill Color</label>
              <div class="row">
                <j-color-picker v-model="nodeFColor[i]" @input="updateAttr"/>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">
                Opacity
              </label>
              <input v-model="nodeOpacity[i]" type="number" class="type_mgl0" @input="(e) => updateAttr(e.target.value, i, 'opacity', 'node', 'num')"/>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Legend
        <a class="close" @click="collapsed['legend'] = !collapsed['legend']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['legend']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="legendDisplay" type="radio" name="DisplayTabMenu" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label class="type_gray">
            Coordinate
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="legendX" type="number" class="type_mgl0 type_double" />
          <input v-model="legendY" type="number" />
        </div>  
        <div class="type_common_input" style="display: block;">
          <label class="type_gray">
            Text
            <span data-grid-quickbtn="btn" class="label jcon_temp_plus right" @click="appendAttr()"></span>
          </label>
          <div v-for="(text, i) in polygonAttrs" :key="`legend_text_${i}`" class="row flex_end" >
            <span data-grid-quickbtn="btn" class="label jcon_temp_minus left_icon_middle" @click="clickRemoveBtn(i)"></span>
            <input v-model="legendText[i]" type="text" class="type120" style="text-align: left"  @input="(e) => updateAttr(e.target.value, i, 'text', 'legend', 'string')"/>
          </div>
        </div>
        <div class="type_common_input">
          <label class="type_gray">Bullet Size</label>
          <div class="row">
            <input v-model="legendTSize" type="number" />
          </div>
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="legendFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label class="type_gray">Size / Color</label>
          <div class="row">
            <input v-model="legendTSize" class="type_double" type="number" />
            <j-color-picker v-model="legendTColor"/>
          </div>
        </div>
      </v-flex>
    </div>
    <div style="height: 10rem;"/>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import _ChartDataDefault from '@/primitives/_chartRadarDefault'
import { mapState, mapMutations } from "vuex"
import StaticOptions from '@/mixins/staticOptions'


export default {
  name: 'j-chart-tab-radar',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      chart: true,
      level: true,
      axes: true,
      series: true,
      value: true,
      area: true,
      node: true,
      legend: true,
    },
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    localRadarAttrs: {},
    localChartAttrs: {},
    localLevelAttrs: {},
    localAxesAttrs: {},
    localSeriesAttrs: {},
    localValueAttrs: {},
    localLegendAttrs: {},
    localAreaAttrs: {},
    localNodeAttrs: {},
    tabActivator: null,

    msgOpen: false,
    yes: null
  }),
  watch: {
    'chartItem.Radar': {
      handler(val) {
        if (!val) return
        this.localRadarAttrs = JSON.parse(JSON.stringify(val))
        this.localChartAttrs = this.localRadarAttrs.chart
        this.localLevelAttrs = this.localRadarAttrs.level
        this.localAxesAttrs = this.localRadarAttrs.axes
        this.localSeriesAttrs = this.localRadarAttrs.series
        this.localValueAttrs = this.localRadarAttrs.value
        this.localLegendAttrs = this.localRadarAttrs.legend

        this.localAreaAttrs = this.localRadarAttrs.area
        this.localNodeAttrs = this.localRadarAttrs.node
        this.localLegendTextAttrs = this.localRadarAttrs.legend.text
      },
      deep: true
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    
    polygonAttrs() {
      let length_ = this.localAreaAttrs.stWidth ? this.localAreaAttrs.stWidth.length : 0
      return Array.from({ length: length_  }, (_, i) => i) 
    },

    areaAttrArr() {
      let length_ = this.localAreaAttrs.stWidth ? this.localAreaAttrs.stWidth.length : 0
      return Array.from({ length: length_  }, (_, i) => i) 
    },

    nodeAttrArr() {
      let length_ = this.localNodeAttrs.stWidth ? this.localNodeAttrs.stWidth.length : 0
      return Array.from({ length: length_  }, (_, i) => i) 
    },

    textAttrs() {
      let length_ = this.localLegendAttrs.text ? this.localLegendAttrs.text.length : 0
      return Array.from({ length: length_  }, (_, i) => i) 
    },

    chartX: {
      get() { return this.localChartAttrs.x || 0 },
      set(val) { this.localChartAttrs.x = parseFloat(val); this.updateAttr();}
    },
    chartY: {
      get() {return this.localChartAttrs.y || 0 },
      set(val) { this.localChartAttrs.y = parseFloat(val); this.updateAttr();}
    },
    chartSize: {
      get() {return this.localChartAttrs.size || 0 },
      set(val) { this.localChartAttrs.size = parseFloat(val); this.updateAttr();}
    },
    chartData: {
      get() {return this.localChartAttrs.data || 0 },
      set(val) { this.localChartAttrs.data = parseFloat(val); this.updateAttr();}
    },
    
    levelNo: {
      get() { return this.localLevelAttrs.no || 0},
      set(val) { this.localLevelAttrs.no = parseFloat(val); this.updateAttr();}
    },
    levelStWidth: {
      get() { return this.localLevelAttrs.stWidth || 0},
      set(val) { this.localLevelAttrs.stWidth = parseFloat(val); this.updateAttr();}
    },
    levelStColor: {
      get() { return this.localLevelAttrs.stColor || 0},
      set(val) { this.localLevelAttrs.stColor = val; this.updateAttr();}
    },
    levelOpacity: {
      get() { return this.localLevelAttrs.opacity || 0},
      set(val) { this.localLevelAttrs.opacity = parseFloat(val); this.updateAttr();}
    },
    levelLastStWidth: {
      get() { return this.localLevelAttrs.lastStWidth || 0},
      set(val) { this.localLevelAttrs.lastStWidth = parseFloat(val); this.updateAttr();}
    },
    levelLastStColor: {
      get() { return this.localLevelAttrs.lastStColor || 0},
      set(val) { this.localLevelAttrs.lastStColor = val; this.updateAttr();}
    },
    levelLastOpacity: {
      get() { return this.localLevelAttrs.lastOpacity || 0},
      set(val) { this.localLevelAttrs.lastOpacity = parseFloat(val); this.updateAttr();}
    },
    levelFont: {
      get() { return this.localLevelAttrs.font || 0},
      set(val) { this.localLevelAttrs.font = val; this.updateAttr();}
    },
    levelTSize: {
      get() { return this.localLevelAttrs.tSize || 0},
      set(val) { this.localLevelAttrs.tSize = parseFloat(val); this.updateAttr();}
    },
    levelTColor: {
      get() { return this.localLevelAttrs.tColor || 0},
      set(val) { this.localLevelAttrs.tColor = val; this.updateAttr();}
    },
    levelMaxValue: {
      get() { return this.localLevelAttrs.maxValue || 0},
      set(val) { this.localLevelAttrs.maxValue = parseFloat(val); this.updateAttr();}
    },
    levelSpace: {
      get() { return this.localLevelAttrs.space || 0},
      set(val) { this.localLevelAttrs.space = parseFloat(val); this.updateAttr();}
    },
    
    axesStWidth: { 
      get() { return this.localAxesAttrs.stWidth || 0},
      set(val) { this.localAxesAttrs.stWidth = parseFloat(val); this.updateAttr()}
    },
    axesStColor: { 
      get() { return this.localAxesAttrs.stColor || 0},
      set(val) { this.localAxesAttrs.stColor = val; this.updateAttr()}
    },
    axesOpacity: { 
      get() { return this.localAxesAttrs.opacity || 0},
      set(val) { this.localAxesAttrs.opacity = parseFloat(val); this.updateAttr()}
    },

    seriesFont: {
      get() { return this.localSeriesAttrs.font },
      set(val) { this.localSeriesAttrs.font = val; this.updateAttr()}
    },
    seriesTSize: {
      get() { return this.localSeriesAttrs.tSize || 0},
      set(val) { this.localSeriesAttrs.tSize = parseFloat(val); this.updateAttr()}
    },
    seriesTColor: {
      get() { return this.localSeriesAttrs.tColor },
      set(val) { this.localSeriesAttrs.tColor = val; this.updateAttr()}
    },
    seriesFactor: {
      get() { return this.localSeriesAttrs.factor || 0},
      set(val) { this.localSeriesAttrs.factor = parseFloat(val); this.updateAttr()}
    },

    valueDisplay: {
      get() { return this.localValueAttrs.display },
      set(val) { this.localValueAttrs.display = val; this.updateAttr(); }
    },
    valueFont: {
      get() { return this.localValueAttrs.font || 0},
      set(val) { this.localValueAttrs.font = val; this.updateAttr(); }
    },
    valueTSize: {
      get() { return this.localValueAttrs.tSize || 0},
      set(val) { this.localValueAttrs.tSize  = parseFloat(val); this.updateAttr(); }
    },
    valueTColor: {
      get() { return this.localValueAttrs.tColor || 0},
      set(val) { this.localValueAttrs.tColor  = val; this.updateAttr(); }
    },

    areaStWidth() { return this.localAreaAttrs.stWidth || [] },
    areaStColor() { return this.localAreaAttrs.stColor || [] },
    areaFColor() { return this.localAreaAttrs.fColor || [] },
    areaOpacity() { return this.localAreaAttrs.opacity || [] },
    areaColorType() { return this.localAreaAttrs.colorType || [] },

    nodeSize() { return this.localNodeAttrs.size || [] },
    nodeStWidth() { return this.localNodeAttrs.stWidth || [] },
    nodeStColor() { return this.localNodeAttrs.stColor || [] },
    nodeFColor() { return this.localNodeAttrs.fColor || [] },
    nodeOpacity() { return this.localNodeAttrs.opacity || [] },

    legendDisplay: {
      get() { return this.localLegendAttrs.display || 0},
      set(val) { this.localLegendAttrs.display = val; this.updateAttr(); }
    },
    legendX: {
      get() { return this.localLegendAttrs.x || 0},
      set(val) { this.localLegendAttrs.x = parseFloat(val); this.updateAttr(); }
    },
    legendY: {
      get() { return this.localLegendAttrs.y || 0},
      set(val) { this.localLegendAttrs.y = parseFloat(val); this.updateAttr(); }
    },
    legendText() { return this.localLegendAttrs.text || [] },
    legendBullet: {
      get() { return this.localLegendAttrs.bullet || 0},
      set(val) { this.localLegendAttrs.bullet = parseFloat(val); this.updateAttr(); }
    },
    legendFont: {
      get() { return this.localLegendAttrs.font || 0},
      set(val) { this.localLegendAttrs.font = val; this.updateAttr(); }
    },
    legendTSize: {
      get() { return this.localLegendAttrs.tSize || 0},
      set(val) { this.localLegendAttrs.tSize = parseFloat(val); this.updateAttr(); }
    },
    legendTColor: {
      get() { return this.localLegendAttrs.tColor || 0},
      set(val) { this.localLegendAttrs.tColor = val; this.updateAttr(); }
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
    
    appendAttr() {
      Object.keys(this.localAreaAttrs).forEach(key => {
        this.localAreaAttrs[key].push(_ChartDataDefault.Radar.area[key][0])
      })

      Object.keys(this.localNodeAttrs).forEach(key => {
        this.localNodeAttrs[key].push(_ChartDataDefault.Radar.node[key][0])
      })

      this.localLegendAttrs.text.push(_ChartDataDefault.Radar.legend.text[0])
      
      if(this.polygonAttrs.length === 1 && this.chartData === 0) {
        this.chartData = 1
      }

      this.updateAttr()
    },
    clickRemoveBtn(i, j, el) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    removeAttr(index) {
      Object.keys(this.localAreaAttrs).forEach(key => {
        this.localAreaAttrs[key][index] = null
        this.localAreaAttrs[key] = this.localAreaAttrs[key].filter(attr => !!attr)
      })

      Object.keys(this.localNodeAttrs).forEach(key => {
        this.localNodeAttrs[key][index] = null
        this.localNodeAttrs[key] = this.localNodeAttrs[key].filter(attr => !!attr)
      })
      this.localLegendAttrs['text'][index] = null
      this.localLegendAttrs['text'] = this.localLegendAttrs['text'].filter(attr => !!attr)

      if(this.chartData > this.polygonAttrs.length) {
        this.chartData = this.polygonAttrs.length
      }

      this.updateAttr()
    },
    surfix(i) {
      let surfix_ = ['st', 'nd', 'rd', 'th']
      if (i < 3) return surfix_[i]
      return surfix_[3]
    },
    updateAttr(val, i, el, type, dataType) {
      if(el) {
        if(type == 'area') {
          if(dataType == 'num') {
            this.localAreaAttrs[el][i] = parseFloat(val)
          } else {
            this.localAreaAttrs[el][i] = val
          }
        } else if (type == 'node') {
          if(dataType == 'num') { 
            this.localNodeAttrs[el][i] = parseFloat(val)
          } else {
            this.localNodeAttrs[el][i] = val
          }
        } else if (type == 'legend') {
          if(dataType == 'num') { 
            this.localLegendAttrs[el][i] = parseFloat(val)
          } else {
            this.localLegendAttrs[el][i] = val
          }
        }
      }

      this.localRadarAttrs.area = null
      this.localRadarAttrs.node = null
      this.localRadarAttrs.legend = null

      this.setChartItem({
        Radar: {
          ...JSON.parse(JSON.stringify(this.localRadarAttrs)), 
          area: {...JSON.parse(JSON.stringify(this.localAreaAttrs))},
          node: {...JSON.parse(JSON.stringify(this.localNodeAttrs))},
          legend: {...JSON.parse(JSON.stringify(this.localLegendAttrs)),},
        }
      })
    }
  }
}
</script>
