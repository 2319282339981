<template>
  <v-flex class="chart_properties" wrap>

    <div class="chart_properties_header">
      <span>Types</span>
      <small class="type_ref01">Properties</small>
    </div>

    <div class="chart_properties_contents">

      <div class="header">
        Title
        <a class="close" v-on:click="collapsed['title'] = !collapsed['title']"></a>
      </div>

      <v-flex class="control" v-show="collapsed['title']">

        <span class="label">Font</span>

        <div class="type_inner">

          <div class="type_common_input">
            <label class="type_gray second_depth">Size</label>
            <input v-model="ChtTypeTitletSize" type="number" />
          </div>

          <div class="type_common_input type_color_float">
            <label class="type_gray second_depth">Last Week</label>
            <div class="input_flex_wrap_end">
              <j-color-picker v-model="ChtTypeTitleLastColor"/>
            </div>
          </div>
          
          <div class="type_common_input type_color_float">
            <label class="type_gray second_depth">This Week</label>
            <div class="input_flex_wrap_end">
              <j-color-picker v-model="ChtTypeTitleThisColor"/>
            </div>
          </div>

        </div>

      </v-flex>

    </div>

    <div class="chart_properties_contents">

      <div class="header">
        Weekend
        <a class="close" v-on:click="collapsed['weekend'] = !collapsed['weekend']"></a>
      </div>

      <v-flex class="control" v-show="collapsed['weekend']">

        <div class="type_common_input">
          <label >Width</label>
          <input v-model="ChtTypeWeekendWidth" type="number" />
        </div>

        <div class="type_common_input type_color_float">
            <label>Font Size / Color</label>
            <div class="input_flex_wrap_end">
              <input v-model="ChtTypeWeekendTSize" type="number"/>
              <j-color-picker v-model="ChtTypeWeekendTColor"></j-color-picker>
            </div>
        </div>

        <span class="label">Stroke</span>

        <div class="type_inner">

          <div class="type_common_input type_color_float">
            <label class="type_gray second_depth">Color</label>
            <div class="input_flex_wrap_end">
              <j-color-picker v-model="ChtTypeWeekendStroke"/>
            </div>
          </div>

          <div class="type_common_input">
            <label class="type_gray second_depth">Size</label>
            <input v-model="ChtTypeWeekendSWidth" type="number"/>
          </div>

          <div class="type_common_input">
            <label class="type_gray second_depth">Opacity</label>
            <input v-model="ChtTypeWeekendOpacity" type="number"/>
          </div>

        </div>

      </v-flex>
    </div>
    
    <div class="chart_properties_contents">

      <div class="header">
        Weekday
        <a class="close" v-on:click="collapsed['weekday'] = !collapsed['weekday']"></a>
      </div>

      <v-flex class="control" v-show="collapsed['weekday']">

        <div class="type_common_input">
          <label >Width</label>
          <input v-model="ChtTypeWeekdayWidth" type="number" />
        </div>

        <div class="type_common_input type_color_float">
            <label>Font Size / Color</label>
            <div class="input_flex_wrap_end">
              <input v-model="ChtTypeWeekdayTSize" type="number"/>
              <j-color-picker v-model="ChtTypeWeekdayTColor"></j-color-picker>
            </div>
        </div>

        <span class="label">Stroke</span>

        <div class="type_inner">

          <div class="type_common_input type_color_float">
            <label class="type_gray second_depth">Color</label>
            <div class="input_flex_wrap_end">
              <j-color-picker v-model="ChtTypeWeekdayStroke"/>
            </div>
          </div>

          <div class="type_common_input">
            <label class="type_gray second_depth">Size</label>
            <input v-model="ChtTypeWeekdaySWidth" type="number"/>
          </div>

          <div class="type_common_input">
            <label class="type_gray second_depth">Opacity</label>
            <input v-model="ChtTypeWeekdayOpacity" type="number"/>
          </div>

        </div>

      </v-flex>

    </div>
    
    <div class="chart_properties_contents">

      <div class="header">
        Value
        <a class="close" v-on:click="collapsed['value'] = !collapsed['value']"></a>
      </div>

      <v-flex class="control" v-show="collapsed['value']">

        <div class="type_common_input type_color_float">
            <label>Font Size / Color</label>
            <div class="input_flex_wrap_end">
              <input v-model="ChtTypeValueTSize" type="number"/>
              <j-color-picker v-model="ChtTypeValueTColor"></j-color-picker>
            </div>
        </div>

      </v-flex>

    </div> 

  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-type',
  mixins: [
    StaticOptions
  ],
  components: {
  },
  data: () => ({
    collapsed: {
      title: true,
      weekend: true,
      weekday:true,
      value:true,
    },
    localChttype: {
      title:{},
      weekend:{},
      weekday:{},
      value:{},
    }
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    
    // Title
    ChtTypeTitletSize: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.title.tSize ) },
      set(val) { 
        this.localChttype.title.tSize = parseFloat(val || 0) 
        this.updateAttr() 
      }
    },
    ChtTypeTitleLastColor: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.title.lastColor ) },
      set(val) { 
        this.localChttype.title.lastColor = val
        this.updateAttr() 
      }
    },
    ChtTypeTitleThisColor: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.title.thisColor ) },
      set(val) { 
        this.localChttype.title.thisColor  = val
        this.updateAttr() 
      }
    },

    // weekend
    ChtTypeWeekendWidth: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.weekend.width },
      set(val) { 
        this.localChttype.weekend.width  = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    ChtTypeWeekendTSize: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.weekend.tSize },
      set(val) { 
        this.localChttype.weekend.tSize = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    ChtTypeWeekendTColor: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.weekend.tColor },
      set(val) { 
        this.localChttype.weekend.tColor  = val
        this.updateAttr() 
      }
    },
    ChtTypeWeekendStroke: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.weekend.stroke },
      set(val) { 
        this.localChttype.weekend.stroke  = val
        this.updateAttr() 
      }
    },
    ChtTypeWeekendSWidth: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.weekend.sWidth },
      set(val) { 
        this.localChttype.weekend.sWidth  = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    ChtTypeWeekendOpacity: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.weekend.opacity },
      set(val) { 
        this.localChttype.weekend.opacity  = parseFloat(val || 0)
        this.updateAttr() 
      }
    },

    // weekday
    ChtTypeWeekdayWidth: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.weekday.width },
      set(val) { 
        this.localChttype.weekday.width = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    ChtTypeWeekdayTSize: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.weekday.tSize },
      set(val) { 
        this.localChttype.weekday.tSize = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    ChtTypeWeekdayTColor: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.weekday.tColor },
      set(val) { 
        this.localChttype.weekday.tColor  = val
        this.updateAttr() 
      }
    },
    ChtTypeWeekdayStroke: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.weekday.stroke },
      set(val) { 
        this.localChttype.weekday.stroke  = val
        this.updateAttr() 
      }
    },
    ChtTypeWeekdaySWidth: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.weekday.sWidth },
      set(val) { 
        this.localChttype.weekday.sWidth  = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    ChtTypeWeekdayOpacity: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.weekday.opacity },
      set(val) { 
        this.localChttype.weekday.opacity  = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    ChtTypeWeekdayGap: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.weekday.gap },
      set(val) { 
        this.localChttype.weekday.gap  = parseFloat(val || 0)
        this.updateAttr() 
      }
    },

    // value
    ChtTypeValueTSize: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.value.tSize },
      set(val) { 
        this.localChttype.value.tSize = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    ChtTypeValueTColor: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.value.tColor },
      set(val) { 
        this.localChttype.value.tColor = val
        this.updateAttr() 
      }
    },
  },
  watch: {
    'chartItem.Chttype': {
      handler(val) {
        if (!val) return
        // console.log('Chttype', val)
        this.localChttype = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    updateAttr() {
      this.setChartItem({ Chttype: JSON.parse(JSON.stringify(this.localChttype)) })
    },
  },
}
</script>
