<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Commons</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Commons
        <a class="close" v-on:click="collapsed['header'] = !collapsed['header']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['header']">
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="ChtcommX" type="number" />
          <input v-model="ChtCommY" type="number" />
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="ChtCommFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-comm',
  mixins: [
    StaticOptions
  ],
  components: {
  },
  data: () => ({
    collapsed: {
      header: true,
      table: true,
      chart: true,
    },

    localCommons: {},

  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    // ChtComm Header
    ChtcommX: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.x ) },
      set(val) { 
        this.localCommons.x = parseFloat(val || 0) 
        this.updateAttr() 
      }
    },
    ChtCommY: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.y ) },
      set(val) { 
        this.localCommons.y = parseFloat(val ||0) 
        this.updateAttr() 
      }
    },
    ChtCommFont: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.font ) || 'roboto' },
      set(val) { 
        this.localCommons.font = val || 'roboto' 
        this.updateAttr() 
      }
    },
    
  },
  watch: {
    'chartItem.Chtcomm': {
      handler(val) {
        if (!val) return
        // console.log('Chtcomm', val)
        this.localCommons = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    updateAttr() {
      this.setChartItem({ Chtcomm: JSON.parse(JSON.stringify(this.localCommons)) })
    },
  },
}
</script>
