<template>
  <div>
    <v-dialog 
      fullscreen 
      hide-overlay 
      transition="slide-full-modal-transition" 
      v-model="formOpened" content-class="j_modal" persistent>
      <v-card>
        <div class="modal__header full_title pd_left_3">
          <button class="hamberger_menu" type="button"  @click="onAction('cancel')">
            <v-icon>mdi-menu</v-icon>
          </button>        
          <span>Format Skyline Chart <span class="sub_title">{{ `${idxNum} ${subTitle}` }}</span></span>
        </div>

        <v-card-text :class="classTab">
          <!-- <v-form v-model="valid" ref="form"> -->
            <v-container>
              <v-card-actions class="modal_toolbar">
                <div class="tool_menus">
                  <div>
                    <div
                      data-skyline-quickbtn="skyline"
                      title="skyline"
                      :class="chartItem.ChartType == __C_.TYPE_CODE_PAGESVG ? 'selected': ''"
                      @click="onChartType(__C_.TYPE_CODE_PAGESVG)"
                    />
                  </div>
                  <div>
                    <div
                      data-skyline-quickbtn="skyline"
                      title="skyline"
                      :class="chartItem.ChartType == __C_.TYPE_CODE_NORMAL ? 'selected': ''"
                      @click="onChartType(__C_.TYPE_CODE_NORMAL)"
                    />
                  </div>
                  <div>
                    <div data-skyline-quickbtn="empty" />
                  </div>
                </div>
              </v-card-actions>
              <div class="modal_dark_btn">
                <j-button
                  class="type01 sky  type_full"
                  :class="{ disabled: !resetable }"
                  :disabled="!resetable"
                  @click="onAction('save')"
                  >Save
                </j-button>
                <j-button
                  class="type01 sky  type_full"
                  :class="{ disabled: !resetable }"
                  :disabled="!resetable"
                  @click="onAction('saveas')"
                  >SaveAs
                </j-button>
                <j-button v-if="modeMod" 
                  class="type01 delete  type_full" 
                  @click="onAction('delete')"
                  >Delete
                </j-button>
                <div class="check_wrapper">
                  <v-checkbox v-model="update" :label="'Update'"/>
                  <v-checkbox :label="'Page Svg'" />
                  <v-checkbox :label="'Fixed'" />
                </div>
                <button class="help" @click="(e) => { helper=!helper }"></button>
              </div>
              <div class="modal__formControl_inside">
                <v-tabs v-model="stepper" class="wrap__modal_wfix">
                  <v-tab data-type="navigation" title="navigation"></v-tab>
                  <v-tab data-type="database" title="database"></v-tab>
                  <v-tab data-type="canvas" title="canvas"></v-tab>
                  <v-tab data-type="timeline" title="timeline"></v-tab>
                  <v-tab data-type="axis" title="axis"></v-tab>
                  <v-tab data-type="skyline_table" title="boxes"></v-tab>
                  <v-tab data-type="line" title="line"></v-tab>
                  <v-tab data-type="milestone" title="milestone"></v-tab>
                  <v-tab data-type="title" title="title"></v-tab>
                  <v-tab data-type="filter" title="status"></v-tab>
                  <v-tab data-type="legend" title="legend"></v-tab>
                  <v-tab data-type="note" title="note"></v-tab>
                  <v-tab data-type="action" title="action" v-if="modePageSvg"><v-icon>mdi-send</v-icon></v-tab>

                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-navigation ref="general" @import-item="onImport" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-database ref="database" :config="modePageSvg" @save="onSave()" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-canvas />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-timeline />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-axis />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-box />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-line />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-milestone @save="onSave()" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-title @save="onSave()" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-status />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-legend />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-note />
                  </v-tab-item>
                  <v-tab-item v-if="modePageSvg" :transition="false" :reverse-transition="false">
                    <j-chart-tab-action />
                  </v-tab-item>
                </v-tabs>

                <j-chart-skyline
                  zoomable

                  class="canvasAreaLine"

                  :style="`--area-width: ${this.canvas.CanvasWidth + 1}px; --area-height: ${this.canvas.CanvasHeight + 1}px;`"
                  :ChartType="chartItem.ChartType"
                  :Database="database"
                  :Canvas="canvas"
                  :Timeline="timeline"
                  :AxisY="axis"
                  :Boxes="box"
                  :Line="line"
                  :Milestone="milestone"
                  :Title="title"
                  :Status="status"
                  :Legends="legend"
                  :Note="note"
                  :DataItems="chartData"
                  :Values="sqlValues"

                  @moved="onMoved"
                  @zoomed="onZoomed"
                  @prop-value-change="onPropValueChaged"
                  @to-xml-string="onXmlString"
                />

                <div v-if="!isPreviewChart" id="j-chart-table">
                  <div style="display: inline-block;">
                    <j-data-grid
                      hide-actions
                      :headers="previewHeaders"
                      :items="previewItems"
                    >
                      <template #items="props">
                        <tr :active="props.selected">
                          <td v-for="h_ in previewHeaders" :key="h_.index" style="text-align: center;">{{ props.item[h_.value] }}</td>
                        </tr>
                      </template>
                    </j-data-grid>
                  </div>
                </div>
                  <div v-if="helper" id="j-chart-helper">
                    <img :src="helperUrl" />
                  </div>
              </div>
            </v-container>
          <!-- </v-form> -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :title-description="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import _ChartDataDefault from '@/primitives/_chartSkylineDefault'
import ChartModalMixin from "@/mixins/chart.modal.common"
import JChartTabProps from './SysenvChartlibSkyline'

export default {
  name: 'sysenv-chartlib-skyline-modal',
  mixins: [
    ChartModalMixin
  ],
  components: {
    ...JChartTabProps
  },
  data: () => ({
    previewHeaders: [],
    idxNum: '',
    subTitle: ''
  }),
  computed: {
    helperUrl() { return this.chartItem.ChartNo && this.helper ? require(`../../../assets/helpDoc/logo.png`) : '' },

    modePageSvg() { return this.chartItem.ChartType == __C.CHART.TYPE_CODE_PAGESVG },

    previewItems() {
      if(!this.isChartDataAvailable) return []
      
      let chartData_ = this.chartData.slice(0, 30)
      let items_ = []
      this.setPreviewHeader(items_, chartData_)

      return items_.slice(0, 30)
    },
    status() { return this.chartItem.StyleAttrs || {} },
    
    classTab() { return `modal__formControl dark_theme type3 properties${this.modePageSvg?13:12}` },
  },
  watch: {
    formOpened(val) {
      if (!val) return

      this.stepper = 0
      this.setEmpty()

      if(this.modeNew) {
        this.setChartItem({
          ChartDb: 'Chart',
          CatCode: __C.CHART.CAT_CODE_SKYLINE,
          ChartType: __C.CHART.TYPE_CODE_NORMAL,
          JsonString: JSON.stringify(_ChartDataDefault.DataItems),

          ..._ChartDataDefault.Database,
          ..._ChartDataDefault.Canvas,
          ..._ChartDataDefault.Timeline,
          ..._ChartDataDefault.AxisY,
          ..._ChartDataDefault.Boxes,
          ..._ChartDataDefault.Line,
          ..._ChartDataDefault.Milestone,
          ..._ChartDataDefault.Title,
          ..._ChartDataDefault.Legends,
          ..._ChartDataDefault.Note,
          
          QApplied: 'J',
        })
        
        this.setChartItem({ 
          MilestoneQApplied: 'J',
          MilestoneQJson: JSON.stringify(_ChartDataDefault.Values.milestone),
          StyleAttrs: _ChartDataDefault.Status,
        })
        this.setSqlValueMilestone(JSON.stringify(_ChartDataDefault.Values.milestone))
        this.setSqlValueNote(_ChartDataDefault.Values.note)
        this.setChartData(_ChartDataDefault.DataItems)
        this.$refs.general.setData(this.formMode)

      } else {
        this.getChart()
      }
    }
  },
  methods: {
    itemIdx(Idx, chartNo) {
      this.idxNum = `(${Idx} / ${chartNo})`
    },
    itemName(val) {
      this.subTitle = val
    },
    onPropValueChaged(data) {
      if(!data || Object.keys(data).length === 0) return
      this.setChartItem(data)
    },
    onZoomed(transform) {
      this.setChartItem(transform)
    },

    // CRUD Process
    getChart(code=null) {
      return new Promise(resolve => {
        this.chartLibraryService.getChartSkyline(code || this.keyValue, res => {
          this.setChartItem(res)
          this.setSqlValueMilestone(
            this.chartItem.MilestoneQApplied == 'J' ? 
            (this.chartItem.MilestoneQJson ? JSON.parse(this.chartItem.MilestoneQJson) : []) : 
            this.chartItem.QResultMilestone
          )
          this.setChartData(this.chartItem.QApplied == 'J' ? JSON.parse(this.chartItem.JsonString) : this.chartItem.QResultSummary)

          this.onChartType(this.chartItem.ChartType)
          this.$refs.general.setData(this.formMode)

          this.itemIdx(res.Idx, res.ChartNo)
          this.itemName(res.Name)

          resolve(res)
        })
      })
    },
    putChart(reqItem, callback) {
      this.chartLibraryService.putChartSkyline(reqItem, callback)
    },
    sasChart(reqItem, callback) {
      this.chartLibraryService.sasChartSkyline(reqItem, callback)
    },
    updChart(reqItem, callback) {
      this.chartLibraryService.updChartSkyline(reqItem, callback)
    },
    delChart(chartNo, callback) {
      this.chartLibraryService.delChartSkyline(chartNo, callback)
    },

    setPreviewHeader(items_, chartData_) {
      chartData_.forEach(data => {
        let lines_ = Object.keys(data).filter(k => k.includes('line'))

        data.values.forEach(value => {
          let item_ = { cDate: data.cDate, }
          lines_.forEach(k => { item_[k] = data[k] })
          item_ = { ...item_, ...value }
          items_.push(item_)
        })
      })

      let colNames = []
      items_.forEach(item => { colNames = Array.from(new Set([ ...colNames, ...Object.keys(item)])) })
      this.previewHeaders = colNames.map(colName_ => ({ type: 'text', text: colName_, value: colName_, align: 'center', sortable: false }))
    }
  }
}
</script>
