<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Box</span>
    </div>
    <j-alert
      v-model="msgOpen"
      :title="msgInfo.title"
      :type="msgInfo.type"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
    <div class="chart_properties_contents">
      <div class="header">
        <span>
          Box
          <span data-grid-quickbtn="btn" class="label jcon_temp_plus type_header" style="color: #4298c6;" @click="appendAttr('Box')"><small style="padding-left: 0.1rem;">Add</small></span>
        </span>
        <a class="close" @click="collapsed['box'] = !collapsed['box']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['box']">
        <div v-for="(attr, i) in localFloatBoxAttrs" :key="`_header_${i}`">
          <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'Box')">{{ i + 1 }}{{ surfix(i) }}</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">X / Y</label>
              <div class="row">
                <input v-model="attr.BoxX" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BoxX', null, 'num')" />
                <input v-model="attr.BoxY" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BoxY', null, 'num')" />
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Width / Height</label>
              <div class="row">
                <input v-model="attr.BoxWidth" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BoxWidth', null, 'num')" />
                <input v-model="attr.BoxHeight" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BoxHeight', null, 'num')" />
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Fill</label>
              <div class="row">
                <j-color-picker v-model="attr.BoxColor" @input="updateAttr"/>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Border / Border Color</label>
              <div class="row">
                <input v-model="attr.BoxStrokeWidth" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BoxStrokeWidth', null, 'num')" />
                <j-color-picker v-model="attr.BoxStrokeColor" @input="updateAttr"/>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Radius/ Opacity</label>
              <div class="row">
                <input v-model="attr.BoxRadius" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BoxRadius', null, 'num')" />
                <input v-model="attr.BoxOpacity" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'BoxOpacity', null, 'num')" />
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div style="height: 10rem;"/>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import _ChartDataDefault from '@/primitives/_chartFloatingDefault'
import { mapState, mapMutations } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-bar',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      box: true,
    },
    msgInfo: {
      type: "",
      title: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"],
    },

    localFloatBoxAttrs: [],

    tabActivator: null,
    msgOpen: false,
  }),
  watch: {
    'chartItem.FloatBox': {
      handler(val) {
        if (!val) return
        this.localFloatBoxAttrs = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
    
    appendAttr(type) {
      this.localFloatBoxAttrs.push(_ChartDataDefault.FloatBox[0])
      
      this.updateAttr()
    },
    clickRemoveBtn(i, type) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    removeAttr(index, type) {
      this.localFloatBoxAttrs[index] = null
      this.localFloatBoxAttrs = this.localFloatBoxAttrs.filter(attr => !!attr)

      this.updateAttr()
    },
    surfix(i) {
      let surfix_ = ['st', 'nd', 'rd', 'th']
      if (i < 3) return surfix_[i]
      return surfix_[3]
    },
    updateAttr(val, i, el, key, dataType) {
      if(el) {
        dataType == 'num' ? this.localFloatBoxAttrs[i][el] = parseFloat(val) : this.localFloatBoxAttrs[i][el] = val
      }

      this.setChartItem({
        FloatBox: JSON.parse(JSON.stringify(this.localFloatBoxAttrs)),
      })
    },
  }
}
</script>
