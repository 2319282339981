import JChartTabNavigation from '@/components/chart/tabs/JChartTabNavigation'
import JChartTabDatabase from '@/components/chart/tabs/JChartTabDatabase'
import JChartTabCanvas from '@/components/chart/tabs/JChartTabCanvas'
import JChartTabTimeline from '@/components/chart/tabs/JChartTabTimeline'
import JChartTabMilestone from '@/components/chart/tabs/JChartTabMilestone'
import JChartTabTitle from '@/components/chart/tabs/JChartTabTitle'
import JChartTabNote from '@/components/chart/tabs/JChartTabNote'
import JChartTabAction from '@/components/chart/tabs/JChartTabAction'

import JChartTabLegend from './JChartTabLegend'
import JChartTabAxis from './JChartTabAxis.vue'
import JChartTabBox from './JChartTabBox'
import JChartTabLine from './JChartTabLine'
import JChartTabStatus from './JChartTabStatus'

export default {
  JChartTabNavigation,
  JChartTabDatabase,
  JChartTabCanvas,
  JChartTabTimeline,
  JChartTabAxis,
  JChartTabBox,
  JChartTabLine,
  JChartTabMilestone,
  JChartTabTitle,
  JChartTabStatus,
  JChartTabLegend,
  JChartTabNote,
  JChartTabAction,
}
