import JChartTabNavigation from '@/components/chart/tabs/JChartTabNavigation'
import JChartTabDatabase from '@/components/chart/tabs/JChartTabDatabase'
import JChartTabCanvas from '@/components/chart/tabs/JChartTabCanvas'
import JChartTabNote from '@/components/chart/tabs/JChartTabNote'
import JChartTabText from '@/components/chart/tabs/JChartTabText'
import JChartTabAction from '@/components/chart/tabs/JChartTabAction'
import JChartTabUrl from './JChartTabUrl'

export default {
  JChartTabNavigation,
  JChartTabDatabase,
  JChartTabCanvas,
  JChartTabText,
  JChartTabNote,
  JChartTabAction,
  JChartTabUrl
}
