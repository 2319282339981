import __C from '../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasWidth: 300,
      CanvasHeight: 330,
      CanvasBorderWeight: 0,
      CanvasBorderColor: 'Transparent',
      CanvasFillColor: 'Transparent',  //Transparent
      CanvasOpacity: 0.5,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 250,
      CanvasChartHeight: 330,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    // ----------------------------------------------- TITLE
    Title: {
      TitleMainDisplay: 'N',
      TitleMainText: 'Spool Installation',
      TitleMainX: 80,
      TitleMainY: 20,
      TitleMainFont: 'roboto',
      TitleMainStyle: 'regular',
      TitleMainSize: 14,
      TitleMainColor: '#44A9DF',
      TitleMainAlign: 'start',

      TitleSubDisplay: 'N',
      TitleSubText: 'MD2 Project',
      TitleSubX: 350,
      TitleSubY: 70,
      TitleSubFont: 'roboto',
      TitleSubStyle: 'regular',
      TitleSubSize: 12,
      TitleSubColor: '#BDBCBC',
      TitleSubAlign: 'middle',

      TitleExtraDisplay: 'N',
      TitleExtraText: 'Discipline',
      TitleExtraX: 350,
      TitleExtraY: 430,
      TitleExtraFont: 'roboto',
      TitleExtraStyle: 'regular',
      TitleExtraSize: 14,
      TitleExtraColor: 'gray',
      TitleExtraAlign: 'middle',

      TitleSqlDisplay: 'N',
      TitleSqlText: 'SQL Query...',
      TitleSqlQuery: 'SQL Query...',
      TitleSqlX: 400,
      TitleSqlY: 90,
      TitleSqlFont: 'roboto',
      TitleSqlStyle: 'regular',
      TitleSqlSize: 11,
      TitleSqlColor: 'pink',
      TitleSqlAlign: 'middle',

      TitleLineDisplay: 'N',
      TitleLineX: 30,
      TitleLineY: 30,
      TitleLineLength: 100,
      TitleLineWeight: 1,
      TitleLineColor: 'black'
    },

    Note: {
      NoteDisplay: 'N',
      NoteX: 50,
      NoteY: 420,
      NoteAlign: 'start',
      NoteTitleText: 'Notes.',
      NoteTitleFont: 'roboto',
      NoteTitleStyle: 'regular',
      NoteTitleSize: 11,
      NoteTitleColor: '#757575',

      NoteContentsText: 'Note Contents Here!',
      NoteContentsFont: 'roboto',
      NoteContentsStyle: 'regular',
      NoteContentsSize: 10,
      NoteContentsColor: '#BDBCBC',
    },

    Delta: {
      DeltaChartX: 0,
      DeltaChartY: 0,
      DeltaChartFont: 'roboto',
      DeltaChartShape: 'arrow', // arrow / triangle 

      DeltaBarLength  : 150,
      DeltaBarHeight  : 15,
      DeltaBarDistance: 35,
      DeltaBarRadius  : 7,
      DeltaBarPlan    : '#E0E0DF',
      DeltaBarAhead   : '#CDDC37',
      DeltaBarBehind  : '#F7BACF',

      DeltaTitleSize  : 11,
      DeltaTitleColor : '#000000',
      DeltaPlanSize   : 10,
      DeltaPlanColor  : '#BDBCBC',
      DeltaActualSize : 10,
      DeltaActualColor: '#fff',
      DeltaTextSize   : 10,
      DeltaAheadColor : '#CDDC37',
      DeltaBehindColor: '#F7BACF',

      DeltaGridSize   : 10,
      DeltaGridSpace  : 15,
      DeltaGridStroke : 0.2,
      DeltaGridColor  : '#757575',
    },

    DataItems: [
      { title:'Structural',     plan: 100,    actual: 97.14, },
      { title:'Piping',         plan: 60.89,  actual: 27.38, },
      { title:'Electrical',     plan: 26.72,  actual: 32.84, },
      { title:'Mechanical',     plan: 54.16,  actual: 90.59, },
      { title:'HVAC',           plan: 62.71,  actual: 67.41, },
      { title:'Architectural',  plan: 33.42,  actual: 44.58, },
      { title:'Paint',          plan: 85.13,  actual: 87.11, },
      { title:'Insulation',     plan: 4.01,   actual: 0.03, },
    
    ],
}


