export default {
  Text: [
    {
      text: 'MC Completion',  
      x: 50,
      y: 50,
      font: 'roboto',
      class: 'regular',
      size: 11,
      color: '#757575',
      align: 'start',
      direction: 'Horizontal'
    },
  ],
  Filter: [
    {
      name: 'MOD',
      x:50,
      y:50,
      font: 'roboto',  
      class: 'bold',
      size: 22,
      color: '#333333',
      align: 'start',
      direction: 'Horizontal'
    },
  ],
  Sql: [
    {
      query: '', 
      text: 'PBF COMPESSOR AIR COOLER - TRAIN 4',
      x: 50,
      y: 50,
      font: 'roboto',
      style: 'regular',
      size: 13,
      color: '#BCBCBC',
      align: 'start',
      direction: 'Horizontal'
    },
  ],
}
