import __C from '../../../../includes/primitives/_constant_'
export default {
  // ----------------------------------------------- DATABASE
  Database: {
    DatabasePreviewMode: 'Chart',
    DatabaseAccessMode: 'Json',
    DatabaseJson: '',
    DatabaseQuery: '',
    DatabaseSP: ''
  },

  // ----------------------------------------------- CANVAS
  Canvas: {
    CanvasWidth: 1000,
    CanvasHeight: 600 ,
    CanvasBorderWeight: 0.1,
    CanvasBorderColor: '#757575',
    CanvasFillColor: '#fff',
    CanvasOpacity: 0.2,

    CanvasChartX: 30,
    CanvasChartY: 50,
    CanvasChartWidth: 400,
    CanvasChartHeight: 170,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 10,

    CanvasFontFamily: 'roboto'
  },


  // ----------------------------------------------- TABLE
  Table: {
    TableX                : 20,
    TableY                : 20,
    TableStroke           : 0.3,
    TableStrokeColor      : '#333', 

    // TableHeadJson         : '',
    AutoApplyToColumns    : 'Y',
    TableHeadFont         : 'roboto',
    TableHeadHeight       : [22,18, 15],
    TableHeadFontStyle    : ['regular', 'regular', 'regular'],
    TableHeadFontSize     : [11, 10.5, 9.5],
    TableHeadFontColor    : ['#333', '#757575', '#757575'],
    TableHeadStroke       : [1, 0.75, 0.5],
    TableHeadStrokeColor  : ['#BCBCBC', '#BCBCBC', '#BCBCBC'],
    TableHeadOpacity      : [0.3, 0.1, 0.1],
    TableHeadFillColor    : ['LightBlue', '#FFDA00', 'LightGray'],
    TableHeadColorType    : ['', '', ''],

    HeaderBorderAuto      : 'Y',

    HeadBorderTop         : 'Y',
    HeadBorderBottom      : 'Y',
    HeadBorderLeft        : 'Y',
    HeadBorderRight       : 'Y',
    

    TableCellFont         : 'roboto',
    TableCellStyle         : 'regular',
    TableCellPadding      : 5,
    TableCellHeight       : 20,
    TableCellStroke       : 0.4,
    TableCellStrokeColor  : '#BDBCBC',
    TableCellHLineDisplay  : 'Y',
    TableCellVLineDisplay  : 'Y',
    TableCellOutlineDisplay  : 'Y',    // <------------------ new
    TableCellHoverOpacity : 0.5,
    TableCellHoverColor   : '#E0E0DF',

    TableFootDisplay      : 'N',
    TableFootTitle        : 'TITLE',
    TableFootLineDisplay  : 'N',    // <------------------ new
    TableFootHeight       : 22,
    TableFootFillColor    : '#F4F5F5',
    TableFootTextStyle    : 'bold',
    TableFootTextSize     : 10.5,
    TableFootTextColor    : '#333',
    TableFootMergeCell    : 4,
    TableFootSQL          : '',
    TableFootRefIcon      : 1,
    TableFootRefBarChart  : 1,

    FootBorderTop         : 'Y',
    FootBorderBottom      : 'N',
    FootBorderLeft        : 'N',
    FootBorderRight       : 'N',
  },

  // Header's properties value should be entered from DOM 
  // by service administrator.

  Header: [ // Depth 3
    { group: 1,   depth: 1,   name: 'AREA',             colspan: 1,   rowspan: 3,  width: 80,  border: 0, align: 'middle'},
    { group: 2,   depth: 1,   name: 'Block Structure',  colspan: 3,   rowspan: 2,  width: 0,   border: 0, align: 'middle'},
    { group: 2,   depth: 3,   name: 'EREC',             colspan: 1,   rowspan: 1,  width: 65,  border: 3, align: 'middle'},
    { group: 2,   depth: 3,   name: 'PE2',              colspan: 1,   rowspan: 1,  width: 55,  border: 3, align: 'middle'},
    { group: 2,   depth: 3,   name: 'PE1',              colspan: 1,   rowspan: 1,  width: 55,  border: 0, align: 'middle'},
    { group: 3,   depth: 1,   name: 'Steel Cutting',    colspan: 5,   rowspan: 1,  width: 0,   border: 0, align: 'middle'},
    { group: 3,   depth: 2,   name: 'Plan',             colspan: 2,   rowspan: 1,  width: 0,   border: 2, align: 'middle'},
    { group: 3,   depth: 3,   name: 'Start',            colspan: 1,   rowspan: 1,  width: 50,  border: 3, align: 'middle'},
    { group: 3,   depth: 3,   name: 'Finish',           colspan: 1,   rowspan: 1,  width: 50,  border: 2, align: 'middle'},
    { group: 3,   depth: 2,   name: 'Actual',           colspan: 2,   rowspan: 1,  width: 0,   border: 2, align: 'middle'},
    { group: 3,   depth: 3,   name: 'Start',            colspan: 1,   rowspan: 1,  width: 50,  border: 3, align: 'middle'},
    { group: 3,   depth: 3,   name: 'Finish',           colspan: 1,   rowspan: 1,  width: 50,  border: 2, align: 'middle'},
    { group: 3,   depth: 2,   name: 'Delta',            colspan: 1,   rowspan: 2,  width: 60,  border: 0, align: 'middle'},
    { group: 4,   depth: 1,   name: 'Progress',         colspan: 1,   rowspan: 3,  width: 150, border: 0, align: 'middle'},
  ],

  // Header: [ // Depth 1
  //   { group: 1,   depth: 1, border: 0, colspan: 1, rowspan: 1, name: 'AREA',      width: 80, },
  //   { group: 2,   depth: 1, border: 0, colspan: 1, rowspan: 1, name: 'EREC',      width: 55, },
  //   { group: 3,   depth: 1, border: 0, colspan: 1, rowspan: 1, name: 'PE1',       width: 55, },
  //   { group: 4,   depth: 1, border: 0, colspan: 1, rowspan: 1, name: 'PE2',       width: 55, },
  //   { group: 5,   depth: 1, border: 0, colspan: 1, rowspan: 1, name: 'Start',     width: 50, },
  //   { group: 6,   depth: 1, border: 0, colspan: 1, rowspan: 1, name: 'Finish',    width: 50, },
  //   { group: 7,   depth: 1, border: 0, colspan: 1, rowspan: 1, name: 'Start',     width: 50, },
  //   { group: 8,   depth: 1, border: 0, colspan: 1, rowspan: 1, name: 'Finish',    width: 50, },
  //   { group: 9,   depth: 1, border: 0, colspan: 1, rowspan: 1, name: 'Delta',     width: 60, },
  //   { group: 10,  depth: 1, border: 0, colspan: 1, rowspan: 1, name: 'Progress',  width: 100,},    
  // ],

  // Header: [ // Depth 2
  //   { group: 1, depth: 1, border: 0, colspan: 1, rowspan: 2, name: 'AREA', width: 80, },
  //   { group: 2, depth: 1, border: 0, colspan: 3, rowspan: 1, name: 'Block Structure', width: 0, },
  //   { group: 2, depth: 2, border: 2, colspan: 1, rowspan: 1, name: 'EREC', width: 55, },
  //   { group: 2, depth: 2, border: 2, colspan: 1, rowspan: 1, name: 'PE1', width: 55, },
  //   { group: 2, depth: 2, border: 0, colspan: 1, rowspan: 1, name: 'PE2', width: 55, },
  //   { group: 3, depth: 1, border: 0, colspan: 2, rowspan: 1, name: 'Plan', width: 0, },
  //   { group: 3, depth: 2, border: 2, colspan: 1, rowspan: 1, name: 'Start', width: 50, },
  //   { group: 3, depth: 2, border: 0, colspan: 1, rowspan: 1, name: 'Finish', width: 50, },
  //   { group: 4, depth: 1, border: 0, colspan: 2, rowspan: 1, name: 'Actual', width: 0, },
  //   { group: 4, depth: 2, border: 2, colspan: 1, rowspan: 1, name: 'Start', width: 50, },
  //   { group: 4, depth: 2, border: 0, colspan: 1, rowspan: 1, name: 'Finish', width: 50, },
  //   { group: 5, depth: 1, border: 0, colspan: 1, rowspan: 2, name: 'Delta', width: 60, },
  //   { group: 6, depth: 1, border: 0, colspan: 1, rowspan: 2, name: 'Progress', width: 150, },
  // ],

  Header2: [ // Depth 2
    { "group": 1, "depth": 1, "border": 0, "colspan": 1, "rowspan": 2, "name": "Action Group", "width": 80 },
    { "group": 2, "depth": 1, "border": 0, "colspan": 1, "rowspan": 2, "name": "TOTAL", "width": 80 },
    
    { "group": 3, "depth": 1, "border": 0, "colspan": 4, "rowspan": 1, "name": "A Punch", "width": 0 },
    { "group": 3, "depth": 2, "border": 2, "colspan": 1, "rowspan": 1, "name": "Created", "width": 55 },
    { "group": 3, "depth": 2, "border": 2, "colspan": 1, "rowspan": 1, "name": "Verified", "width": 55 },
    { "group": 3, "depth": 2, "border": 2, "colspan": 1, "rowspan": 1, "name": "PCOW", "width": 55 },
    { "group": 3, "depth": 2, "border": 0, "colspan": 1, "rowspan": 1, "name": "Open", "width": 55 },

    { "group": 4, "depth": 1, "border": 0, "colspan": 4, "rowspan": 1, "name": "B Punch", "width": 0 },
    { "group": 4, "depth": 2, "border": 2, "colspan": 1, "rowspan": 1, "name": "Created", "width": 55 },
    { "group": 4, "depth": 2, "border": 2, "colspan": 1, "rowspan": 1, "name": "Verified", "width": 55 },
    { "group": 4, "depth": 2, "border": 2, "colspan": 1, "rowspan": 1, "name": "PCOW", "width": 55 },
    { "group": 4, "depth": 2, "border": 0, "colspan": 1, "rowspan": 1, "name": "Open", "width": 55 },

    { "group": 4, "depth": 1, "border": 0, "colspan": 4, "rowspan": 1, "name": "C Punch", "width": 0 },
    { "group": 4, "depth": 2, "border": 2, "colspan": 1, "rowspan": 1, "name": "Created", "width": 55 },
    { "group": 4, "depth": 2, "border": 2, "colspan": 1, "rowspan": 1, "name": "Verified", "width": 55 },
    { "group": 4, "depth": 2, "border": 2, "colspan": 1, "rowspan": 1, "name": "PCOW", "width": 55 },
    { "group": 4, "depth": 2, "border": 0, "colspan": 1, "rowspan": 1, "name": "Open", "width": 55 }
  ],



  _dataForm: {
    Columns: { 
      column: 'AREA',
      type: 'Coloring', 
      series: 1,
      align: 'middle',
      linked: ''
    },
  
    Styles: {
      Text: {
        type:         'Text', 
        series:        1,
        textFont:     'roboto',
        textStyle:    'regular',
        textSize:     10,
        tColor:    '#757575', 
        linked:       'Y',
        hovorText:    3,
        hovorColor:   '#83D2F5',
        zeroDisplay:  'Y',
      },
      Coloring: {
        type:         'Coloring', 
        series:        1,
        refColumn:    'STATUS',
        dataColumn:   'Y',
        progress:     'N', 
        radius:       3,
        strokeWidth:  0.3,
        strokeColor:  '#bcbcbc',
        textSize:     10,
        paddingX:     15,
        paddingY:     4,
        linked:       'N',
        hovorText:    1.5,
        codes: [
                { code: '',           tColor: '#757575', bColor: 'LightGray', colorType: ''},
                { code: 'Started',    tColor: '#83D2F5', bColor: 'LightGray', colorType: ''},
                { code: 'Progress',   tColor: '#757575', bColor: 'LightGray', colorType: ''},
                { code: 'Completed',  tColor: '#757575', bColor: 'LightGray', colorType: ''},
              ],
      },
      Bar: {
        type:           'Bar',
        series:         1,
        refColumn:      'STATUS', 
        dataColumn:     'PROG', 
        barLength:      100,
        barThickness:   12,
        barRadius:      0,
        textPosition:   'inside',
        textSize:       8.5,
        unit:           '%',
        round:          1,
        bounds:         30,
        codes: [
                { code: '',           tColor: '#757575', bColor: 'LightBlue', colorType: ''},
                { code: 'Started',    tColor: '#757575', bColor: 'Pink',      colorType: ''},
                { code: 'Progress',   tColor: '#757575', bColor: 'LightBlue', colorType: ''}, 
                { code: 'Completed',  tColor: '#757575', bColor: 'LightBlue', colorType: ''},
              ],
      },
      Icon: {
        type:       'Icon',
        series:     1,
        refColumn:  'DEV',
        align:      'start',
        textSize:   10,
        paddingX:   15,
        iconSize:   10,
        codes: [
                { code: 'up',     name: 'Prog_Up',    tColor: '#4CAE4E', bColor: '', url: ''},
                { code: 'zero',   name: 'Prog_Zero',  tColor: '#757575', bColor: '', url: ''},
                { code: 'down',   name: 'Prog_Down',  tColor: '#EC407A', bColor: '', url: ''},
              ], 
      },
    }
  },

  // Conlumn's properties value should be entered from DOM 
  // by service administrator.
  Styles: [
    {
      type:         'Text', 
      series:        1,
      textFont:     'roboto', 
      textStyle:    'regular',
      textSize:     10,
      tColor:    '#757575', 
      linked:       'Y',
      hovorText:    3,
      hovorColor:   '#83D2F5',
      zeroDisplay:  'Y',
    },
    {
      type:         'Coloring', 
      series:        1,
      refColumn:    'STATUS',
      dataColumn:   '',
      progress:     'N', 
      radius:       3,
      strokeWidth:  0.3,
      strokeColor:  '#bcbcbc',
      textSize:     10,
      paddingX:     15,
      paddingY:     4,
      linked:       'N',
      hovorText:    1.5,
      codes: [
              { code: '',           tColor: '#757575', bColor: 'LightGray', colorType: ''},
              { code: 'Started',    tColor: '#83D2F5', bColor: 'LightGray', colorType: ''},
              { code: 'Progress',   tColor: '#757575', bColor: 'LightGray', colorType: ''},
              { code: 'Completed',  tColor: '#757575', bColor: 'LightGray', colorType: ''},
            ],
    },
    {
      type:         'Coloring', 
      series:       2,
      refColumn:    'STATUS',
      progress:     'N',
      dataColumn:   '',
      radius:       6,
      strokeWidth:  0,
      strokeColor:  '#',
      textSize:     10,
      paddingX:     10,
      paddingY:     3,
      linked:       'N',
      hovorText:    3,
      codes: [
              { code: '',           tColor: '#757575', bColor: '#E0E0DF',   colorType: ''},
              { code: 'Started',    tColor: '#757575', bColor: '#FCE3EB',   colorType: ''  },
              { code: 'Progress',   tColor: '#757575', bColor: 'LightBlue', colorType: ''},
              { code: 'Completed',  tColor: '#757575', bColor: '#F4F5F5',   colorType: ''  },
            ],
    },
        {
      type:         'Coloring',
      series:       3,
      refColumn:    'STATUS', 
      progress:     'N',
      dataColumn:   '',
      radius:       5,
      strokeWidth:  0,
      strokeColor:  '',
      textSize:     10,
      paddingX:     3,
      paddingY:     3,
      linked:       'N',
      hovorText:    3,
      codes: [
              { code: '',           tColor: '#FFF',    bColor: 'transparent', colorType: ''},
              { code: 'Started',    tColor: '#EC407A', bColor: 'transparent', colorType: ''},
              { code: 'Progress',   tColor: '#757575', bColor: 'transparent', colorType: ''},
              { code: 'Completed',  tColor: '#44A9DF', bColor: 'transparent', colorType: ''}, //transparent
            ],
    },
    {
      type:         'Coloring',
      series:       4,
      refColumn:    'STATUS', 
      progress:     'Y',
      dataColumn:   'PROG',
      radius:       3,
      strokeWidth:  0,
      strokeColor:  '',
      textSize:     10,
      paddingX:     0,
      paddingY:     6,
      linked:       'N',
      hovorText:    3,
      codes: [
              { code: '',           tColor: '#FFF',      bColor: '#FFF',      colorType: ''},
              { code: 'Started',    tColor: '#EC407A',   bColor: '#FEED57',   colorType: ''},
              { code: 'Progress',   tColor: '#757575',   bColor: 'LightBlue', colorType: ''},
              { code: 'Completed',  tColor: '#44A9DF',   bColor: '#F4F5F5',   colorType: ''},
            ],
    },

    {
      type:           'Bar',
      series:         1,
      refColumn:      'STATUS', 
      dataColumn:     'PROG', 
      barLength:      100,
      barThickness:   12,
      barRadius:      0,
      textPosition:   'inside',
      textSize:       8.5,
      unit:           '%',
      round:          1,
      bounds:         30,

      codes: [
              { code: '',           tColor: '#757575', bColor: 'LightBlue',   colorType: ''},
              { code: 'Started',    tColor: '#757575', bColor: 'Pink',        colorType: ''},
              { code: 'Progress',   tColor: '#757575', bColor: 'LightBlue',   colorType: ''}, 
              { code: 'Completed',  tColor: '#757575', bColor: 'LightBlue',   colorType: ''},
            ],
    },
    {
      type:       'Icon',
      series:     1,
      refColumn:  'DEV',
      align:      'start',
      textSize:   10,
      paddingX:   15,
      iconSize:   10,  

      codes: [
              { code: 'up',     name: 'Prog_Up',    tColor: '#4CAE4E', bColor: '', url: ''},
              { code: 'zero',   name: 'Prog_Zero',  tColor: '#757575', bColor: '', url: ''},
              { code: 'down',   name: 'Prog_Down',  tColor: '#EC407A', bColor: '', url: ''},
            ], 
    },
  ],
  
  Columns: [],
  

  DataItems: [
    {AREA:'Area 01',  EREC: 'BK342' , PE2: 'BK43' , PE1: 'B276' , PLAN_START: 154, PLAN_FINISH: 35, ACT_START: 132, ACT_FINISH: 40, DELTA: -24, PROG: 84.7, STAGE: 'PE1',  STATUS: 'Started',   DEV: 'down', },
    {AREA:'Area 02',  EREC: 'BK345' , PE2: 'BK43' , PE1: 'B654' , PLAN_START: 214, PLAN_FINISH: 45, ACT_START: 154, ACT_FINISH: 54, DELTA: -2,  PROG: 24.5, STAGE: 'PE2',  STATUS: 'Completed', DEV: 'down', },
    {AREA:'Area 03',  EREC: 'BK346' , PE2: 'BK43' , PE1: 'B341' , PLAN_START: 342, PLAN_FINISH: 45, ACT_START: 134, ACT_FINISH: 64, DELTA: 10,  PROG: 57.4, STAGE: '',     STATUS: 'Progress',  DEV: 'up', },
    {AREA:'Area 04',  EREC: 'BK349' , PE2: 'BK43' , PE1: ''     , PLAN_START: 234, PLAN_FINISH: 44, ACT_START: 232, ACT_FINISH: 42, DELTA: 7,   PROG: 45.3, STAGE: '',     STATUS: 'Completed', DEV: 'up', },
    {AREA:'Area 05',  EREC: 'BK442' , PE2: 'BK23' , PE1: ''     , PLAN_START: 200, PLAN_FINISH: 32, ACT_START: 123, ACT_FINISH: 65, DELTA: 9,   PROG: 54.0, STAGE: 'EREC', STATUS: 'Started',   DEV: 'up', },
    {AREA:'Area 06',  EREC: 'BK372' , PE2: ''     , PE1: 'AP234', PLAN_START: 187, PLAN_FINISH: 37, ACT_START: 156, ACT_FINISH: 100, DELTA: 0,   PROG: 100.0, STAGE: '',    STATUS: 'Progress',  DEV: 'zero', },
    {AREA:'Area 07',  EREC: 'BK362' , PE2: ''     , PE1: 'AP765', PLAN_START: 156, PLAN_FINISH: 87, ACT_START: 154, ACT_FINISH: 88, DELTA: -2,  PROG: 87.7, STAGE: '',     STATUS: 'Started',   DEV: 'down', },
    {AREA:'Area 08',  EREC: 'BK371' , PE2: ''     , PE1: 'AP987', PLAN_START: 187, PLAN_FINISH: 69, ACT_START: 178, ACT_FINISH: 41, DELTA: -14, PROG: 74.4, STAGE: 'PE1',  STATUS: 'Progress',  DEV: 'down', },
    {AREA:'Area 09',  EREC: 'BK341' , PE2: ''     , PE1: ''     , PLAN_START: 111, PLAN_FINISH: 54, ACT_START: 186, ACT_FINISH: 46, DELTA: -9,  PROG: 71.2, STAGE: 'PE2',  STATUS: 'Progress',  DEV: 'down', },
    {AREA:'Area 10',  EREC: 'BK312' , PE2: ''     , PE1: 'AE23' , PLAN_START: 134, PLAN_FINISH: 87, ACT_START: 110, ACT_FINISH: 56, DELTA: 14,  PROG: 25.1, STAGE: 'PE1',  STATUS: 'Progress',  DEV: 'up', },
    {AREA:'Area 11',  EREC: 'BK322' , PE2: ''     , PE1: 'AE34' , PLAN_START: 109, PLAN_FINISH: 45, ACT_START: 187, ACT_FINISH: 28, DELTA: 13,  PROG: 39.9, STAGE: '',     STATUS: 'Completed', DEV: 'up', },
    {AREA:'Area 12',  EREC: 'BK332' , PE2: ''     , PE1: 'AE33' , PLAN_START: 198, PLAN_FINISH: 61, ACT_START: 165, ACT_FINISH: 39, DELTA: 45,  PROG: 49.6, STAGE: '',     STATUS: 'Completed', DEV: 'up', },
  ],

  Footer: [
    {PLAN_START: 154, PLAN_FINISH: 35, ACT_START: 132, ACT_FINISH: 40, DELTA: -24, PROG: 84.7, STATUS: 'Started', DEV: 'down', REF_BAR: 1, REF_ICON: 1},
]

}
