<template>
  <v-flex v-model="bar_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Timeline</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Time Line
        <a class="close" v-on:click="collapsed['timeline'] = !collapsed['timeline']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['timeline']">
        <div class="type_common_input">
          <span class="label">Type</span>
          <div class="row">
            <label v-for="option in typeOptions" :key="option.index" class="type_many">
              <input v-model="TimelineType" type="radio" name="TimelineType" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <span class="label">Type</span>
          <div class="row">
            <label v-for="option in typeOptions" :key="option.index" class="type_many">
              <input v-model="TimelineType" type="radio" name="TimelineType" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div>
          <span class="label">Period</span>
          <div class="type_inner">
            <div class="type_common_input">
              <div>
                <label class="type_many">
                  <input v-model="TimelinePeriodStartSelected" type="radio" name="periodSelected" value="Y" />
                  {{ periodOptions[0].text }}
                  <input v-model="TimelinePeriodStart" class="type_right_input" type="number" />
                </label>
                <label class="type_many">
                  <input v-model="TimelinePeriodCutofftSelected" type="radio" name="periodSelected" value="Y" />
                  {{ periodOptions[1].text }}
                  <input v-model="TimelinePeriodCutoff" class="type_right_input" type="number"
                  />
                </label>
                <label class="type_many">
                  <input v-model="TimelinePeriodFixedSelected" type="radio" name="periodSelected" value="Y" />
                  {{ periodOptions[2].text }}
                </label>
              </div>
            </div>
            <div class="label_block">
              <input v-model="TimelinePeriodFixedStart" class="type2_right_input" type="text" />
              <input v-model="TimelinePeriodFixedEnd" class="type2_right_input" type="text" />
            </div>
          </div>
        </div>
        <div>
          <span class="label">Line</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">Stroke</label>
              <input v-model="TimelineLineStroke" type="text" />
            </div>
            <div class="type_common_input type_gray">
              <j-color-picker v-model="TimelineLineColor" title="Color"></j-color-picker>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Day/Week
        <a class="close" v-on:click="collapsed['day'] = !collapsed['day']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['day']">
        <div>
          <span class="label">Line</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">Height</label>
              <input v-model="TimelineWeekLineHeight" type="text" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Stroke</label>
              <input v-model="TimelineWeekLineStroke" type="text" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Space</label>
              <input v-model="TimelineWeekLineSpace" type="text" />
            </div>
            <div class="type_common_input type_gray">
              <j-color-picker v-model="TimelineWeekLineColor" title="Color"></j-color-picker>
            </div>
          </div>
          <span class="label">Text</span>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <span class="label">Display</span>
              <div class="row">
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input
                    v-model="TimelineWeekTextDisplay"
                    type="radio"
                    name="TimelineWeekTextDisplay"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Font</label>
              <select v-model="TimelineWeekTextFont" dir="rtl">
                <option v-for="option in fontOptions" :key="option.index" :value="option.value"
                >{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Style</label>
              <select v-model="TimelineWeekTextStyle" dir="rtl" class="type70">
                <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value"
                >{{ option.text }}</option>
              </select>
            </div>
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Size / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="TimelineWeekTextSize" type="text" />
                <j-color-picker v-model="TimelineWeekTextColor"></j-color-picker>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Month
        <a class="close" v-on:click="collapsed['month'] = !collapsed['month']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['month']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="TimelineMonthDisplay"
                type="radio"
                name="TimelineMonthDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div>
          <span class="label">Line</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">Display</label>
              <div class="row">
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input
                    v-model="TimelineMonthLineDisplay"
                    type="radio"
                    name="TimelineMonthLineDisplay"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Height</label>
              <input v-model="TimelineMonthLineHeight" type="number" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Stroke</label>
              <input v-model="TimelineMonthLineStroke" type="number" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Space</label>
              <input v-model="TimelineMonthLineSpace" type="number" />
            </div>
            <div class="type_common_input type_gray">
              <j-color-picker v-model="TimelineMonthLineColor" title="Color"></j-color-picker>
            </div>
          </div>
          <span class="label">Text</span>
          <div class="type_inner">
            <div class="type_common_select">
              <label class="type_gray second_depth">Font</label>
              <select v-model="TimelineMonthTextFont" dir="rtl">
                <option
                  v-for="option in fontOptions"
                  :key="option.index"
                  :value="option.value"
                >{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Style</label>
              <select v-model="TimelineMonthTextStyle" dir="rtl" class="type70">
                <option
                  v-for="option in fontStyleOptions"
                  :key="option.index"
                  :value="option.value"
                >{{ option.text }}</option>
              </select>
            </div>
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Size / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="TimelineMonthTextSize" type="number" />
                <j-color-picker v-model="TimelineMonthTextColor"></j-color-picker>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Year
        <a class="close" v-on:click="collapsed['year'] = !collapsed['year']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['year']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="TimelineYearDisplay"
                type="radio"
                name="TimelineYearDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div>
          <span class="label">Line</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">Display</label>
              <div class="row">
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input
                    v-model="TimelineYearLineDisplay"
                    type="radio"
                    name="TimelineYearLineDisplay"
                    :value="option.value"
                  />
                  {{option.text}}
                </label>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Height</label>
              <input v-model="TimelineYearLineHeight" type="text" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Stroke</label>

              <input v-model="TimelineYearLineStroke" type="text" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Space</label>
              <input v-model="TimelineYearLineSpace" type="text" />
            </div>
            <div class="type_common_input type_gray">
              <j-color-picker v-model="TimelineYearLineColor" title="Color"></j-color-picker>
            </div>
          </div>
          <span class="label">Text</span>
          <div class="type_inner">
            <div class="type_common_select">
              <label class="type_gray second_depth">Font</label>
              <select v-model="TimelineYearTextFont" dir="rtl">
                <option
                  v-for="option in fontOptions"
                  :key="option.index"
                  :value="option.value"
                >{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Style</label>
              <select v-model="TimelineYearTextStyle" dir="rtl" class="type70">
                <option
                  v-for="option in fontStyleOptions"
                  :key="option.index"
                  :value="option.value"
                >{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Size / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="TimelineYearTextSize" type="text" />
                <j-color-picker v-model="TimelineYearTextColor"></j-color-picker>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-timeline',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      timeline: true,
      day: false,
      month: false,
      year: false,
    },
    bar_general: null,
    currentIcon: null,
    periodOptions: [
      { text: "Start Date", value: "Y" },
      { text: "Cutoff Date", value: "N" },
      { text: "Fixed Date", value: "N" }
    ],
    typeOptions: [
      { text: "TL-A", value: "TL-A" },
      { text: "TL-B", value: "TL-B" }
    ],
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    TimelinePeriodStartSelected: {
      get() { return this.TimelinePeriodStart ? 'Y' : 'N' },
      set() {
        this.TimelinePeriodCutoff = 0
        this.TimelinePeriodFixedStart = ''
        this.TimelinePeriodFixedEnd = ''
      }
    },
    TimelinePeriodCutofftSelected: {
      get() { return this.TimelinePeriodCutoff ? 'Y' : 'N' },
      set() {
        this.TimelinePeriodStart = 0
        this.TimelinePeriodFixedStart = ''
        this.TimelinePeriodFixedEnd = ''
      }
    },
    TimelinePeriodFixedSelected: {
      get() { return this.TimelinePeriodFixedStart && this.TimelinePeriodFixedEnd ? 'Y' : 'N' },
      set() {
        this.TimelinePeriodStart = 0
        this.TimelinePeriodCutoff = 0
      }
    },

    TimelineType: {
      get() { return this.chartItem.TimelineType },
      set(val) { this.setChartItem({ TimelineType: val }) }
    },
    TimelinePeriodStart: {
      get() { return this.chartItem.TimelinePeriodStart || 0 },
      set(val) { 
        if(val && Number(val) > 0) {
          this.TimelinePeriodCutoff = 0
          this.TimelinePeriodFixedStart = ''
          this.TimelinePeriodFixedEnd = ''
        }
        this.setChartItem({ TimelinePeriodStart: parseFloat(val || 0) }) 
      }
    },
    TimelinePeriodCutoff: {
      get() { return this.chartItem.TimelinePeriodCutoff || 0 },
      set(val) { 
        if(val && Number(val) > 0) {
          this.TimelinePeriodStart = 0
          this.TimelinePeriodFixedStart = ''
          this.TimelinePeriodFixedEnd = ''
        }
        this.setChartItem({ TimelinePeriodCutoff: parseFloat(val || 0) }) 
      }
    },
    TimelinePeriodFixedStart: {
      get() { return this.chartItem.TimelinePeriodFixedStart },
      set(val) { 
        if(val && Number(val) > 0) {
          this.TimelinePeriodStart = 0
          this.TimelinePeriodCutoff = 0
        }
        this.setChartItem({ TimelinePeriodFixedStart: val }) 
      }
    },
    TimelinePeriodFixedEnd: {
      get() { return this.chartItem.TimelinePeriodFixedEnd },
      set(val) { 
        if(val && Number(val) > 0) {
          this.TimelinePeriodStart = 0
          this.TimelinePeriodCutoff = 0
        }
        this.setChartItem({ TimelinePeriodFixedEnd: val }) 
      }
    },
    TimelineLineStroke: {
      get() { return this.chartItem.TimelineLineStroke || 0 },
      set(val) { this.setChartItem({ TimelineLineStroke: parseFloat(val || 0) }) }
    },
    TimelineLineColor: {
      get() { return this.chartItem.TimelineLineColor },
      set(val) { this.setChartItem({ TimelineLineColor: val }) }
    },
    TimelineWeekLineHeight: {
      get() { return this.chartItem.TimelineWeekLineHeight || 0 },
      set(val) { this.setChartItem({ TimelineWeekLineHeight: parseFloat(val || 0) }) }
    },
    TimelineWeekLineStroke: {
      get() { return this.chartItem.TimelineWeekLineStroke || 0 },
      set(val) { this.setChartItem({ TimelineWeekLineStroke: parseFloat(val || 0) }) }
    },
    TimelineWeekLineSpace: {
      get() { return this.chartItem.TimelineWeekLineSpace || 0 },
      set(val) { this.setChartItem({ TimelineWeekLineSpace: parseFloat(val || 0) }) }
    },
    TimelineWeekLineColor: {
      get() { return this.chartItem.TimelineWeekLineColor },
      set(val) { this.setChartItem({ TimelineWeekLineColor: val }) }
    },
    TimelineWeekTextDisplay: {
      get() { return this.chartItem.TimelineWeekTextDisplay },
      set(val) { this.setChartItem({ TimelineWeekTextDisplay: val }) }
    },
    TimelineWeekTextFont: {
      get() { return this.chartItem.TimelineWeekTextFont },
      set(val) { this.setChartItem({ TimelineWeekTextFont: val }) }
    },
    TimelineWeekTextStyle: {
      get() { return this.chartItem.TimelineWeekTextStyle },
      set(val) { this.setChartItem({ TimelineWeekTextStyle: val }) }
    },
    TimelineWeekTextSize: {
      get() { return this.chartItem.TimelineWeekTextSize || 0 },
      set(val) { this.setChartItem({ TimelineWeekTextSize: parseFloat(val || 0) }) }
    },
    TimelineWeekTextColor: {
      get() { return this.chartItem.TimelineWeekTextColor },
      set(val) { this.setChartItem({ TimelineWeekTextColor: val }) }
    },
    TimelineMonthDisplay: {
      get() { return this.chartItem.TimelineMonthDisplay },
      set(val) { this.setChartItem({ TimelineMonthDisplay: val }) }
    },
    TimelineMonthLineDisplay: {
      get() { return this.chartItem.TimelineMonthLineDisplay || 'Y' },
      set(val) { this.setChartItem({ TimelineMonthLineDisplay: val || 'Y' }) }
    },
    TimelineMonthLineHeight: {
      get() { return this.chartItem.TimelineMonthLineHeight || 0 },
      set(val) { this.setChartItem({ TimelineMonthLineHeight: parseFloat(val || 0) }) }
    },
    TimelineMonthLineStroke: {
      get() { return this.chartItem.TimelineMonthLineStroke || 0 },
      set(val) { this.setChartItem({ TimelineMonthLineStroke: parseFloat(val || 0) }) }
    },
    TimelineMonthLineSpace: {
      get() { return this.chartItem.TimelineMonthLineSpace || 0 },
      set(val) { this.setChartItem({ TimelineMonthLineSpace: parseFloat(val || 0) }) }
    },
    TimelineMonthLineColor: {
      get() { return this.chartItem.TimelineMonthLineColor },
      set(val) { this.setChartItem({ TimelineMonthLineColor: val }) }
    },
    TimelineMonthTextFont: {
      get() { return this.chartItem.TimelineMonthTextFont },
      set(val) { this.setChartItem({ TimelineMonthTextFont: val }) }
    },
    TimelineMonthTextStyle: {
      get() { return this.chartItem.TimelineMonthTextStyle },
      set(val) { this.setChartItem({ TimelineMonthTextStyle: val }) }
    },
    TimelineMonthTextSize: {
      get() { return this.chartItem.TimelineMonthTextSize || 0 },
      set(val) { this.setChartItem({ TimelineMonthTextSize: parseFloat(val || 0) }) }
    },
    TimelineMonthTextColor: {
      get() { return this.chartItem.TimelineMonthTextColor },
      set(val) { this.setChartItem({ TimelineMonthTextColor: val }) }
    },
    TimelineYearDisplay: {
      get() { return this.chartItem.TimelineYearDisplay },
      set(val) { this.setChartItem({ TimelineYearDisplay: val }) }
    },
    TimelineYearLineDisplay: {
      get() { return this.chartItem.TimelineYearLineDisplay || 'Y' },
      set(val) { this.setChartItem({ TimelineYearLineDisplay: val || 'Y' }) }
    },
    TimelineYearLineHeight: {
      get() { return this.chartItem.TimelineYearLineHeight || 0 },
      set(val) { this.setChartItem({ TimelineYearLineHeight: parseFloat(val || 0) }) }
    },
    TimelineYearLineSpace: {
      get() { return this.chartItem.TimelineYearLineSpace || 0 },
      set(val) { this.setChartItem({ TimelineYearLineSpace: parseFloat(val || 0) }) }
    },
    TimelineYearLineStroke: {
      get() { return this.chartItem.TimelineYearLineStroke || 0 },
      set(val) { this.setChartItem({ TimelineYearLineStroke: parseFloat(val || 0) }) }
    },
    TimelineYearLineColor: {
      get() { return this.chartItem.TimelineYearLineColor },
      set(val) { this.setChartItem({ TimelineYearLineColor: val }) }
    },
    TimelineYearTextFont: {
      get() { return this.chartItem.TimelineYearTextFont },
      set(val) { this.setChartItem({ TimelineYearTextFont: val }) }
    },
    TimelineYearTextStyle: {
      get() { return this.chartItem.TimelineYearTextStyle },
      set(val) { this.setChartItem({ TimelineYearTextStyle: val }) }
    },
    TimelineYearTextSize: {
      get() { return this.chartItem.TimelineYearTextSize || 0 },
      set(val) { this.setChartItem({ TimelineYearTextSize: parseFloat(val || 0) }) }
    },
    TimelineYearTextColor: {
      get() { return this.chartItem.TimelineYearTextColor },
      set(val) { this.setChartItem({ TimelineYearTextColor: val }) }
    },

  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
  }

}
</script>
