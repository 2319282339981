import JChartTabNavigation from '@/components/chart/tabs/JChartTabNavigation'
import JChartTabDatabase from '@/components/chart/tabs/JChartTabDatabase'
import JChartTabCanvas from '@/components/chart/tabs/JChartTabCanvas'
import JChartTabTimeline from '@/components/chart/tabs/JChartTabTimeline'
import JChartTabMilestone from '@/components/chart/tabs/JChartTabMilestone'
import JChartTabText from '@/components/chart/tabs/JChartTabText'
import JChartTabLegend from '@/components/chart/tabs/JChartTabLegend'
import JChartTabBar from '@/components/chart/tabs/JChartTabBar'

import JChartTabAxis from './JChartTabAxis'
import JChartTabLine from './JChartTabLine'
import JChartTabFloatingTable from './JChartHistogramFloatingTable'
import JChartTabFilter from './JChartHistogramFilter'
import JChartTabTableSummary from './JChartHistogramTableSummary'

export default {
  JChartTabNavigation,
  JChartTabDatabase,
  JChartTabCanvas,
  JChartTabTimeline,
  JChartTabAxis,
  JChartTabText,
  JChartTabLine,
  JChartTabBar,
  JChartTabLegend,
  JChartTabMilestone,
  JChartTabFloatingTable,
  JChartTabFilter,
  JChartTabTableSummary
}
