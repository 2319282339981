<template>
  <v-flex v-model="bar3d_general" class="chart_properties dark_theme" wrap>
    <div class="chart_properties_header">
      <span class="pink__">URL</span>
      <small class="type_ref01">Custom SVG Component</small>
    </div>
    <div class="chart_properties_contents type_noborder">
      <div class="header">Component Name (Start/Index File)</div>
      <v-flex class="control">
        <div class="column align_end">
          <input v-model="entrance" type="text" placeholder="Chart Name" class="type100" />
          <a class="type01" @click="onUrlExecute">Import Chart</a>
        </div>
      </v-flex>
    </div>
    <!-- <div class="chart_properties_contents type_noborder">
      <div class="header">Default Data Name</div>
      <v-flex class="control">
        <div class="column align_end">
          <input v-model="defaultData" type="text" placeholder="Data Name" class="type100" />
          <div style="height: 15px;" />
          <a class="type01" @click="onUrlExecute">Import Chart</a>
        </div>
      </v-flex>
    </div> -->
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from "vuex"

export default {
  name: 'j-chart-tab-url',
  data: () => ({
    entrance: '',
    defaultData: '',

    // temporal data
    bar3d_general: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    UrlEntrance: {
      get() { return this.chartItem.UrlEntrance },
      set(val) { this.setChartItem({ UrlEntrance: val }) }
    },
    UrlDefaultData: {
      get() { return this.chartItem.UrlDefaultData },
      set(val) { this.setChartItem({ UrlDefaultData: val }) }
    },
  },
  watch: {
    UrlEntrance(val) { this.entrance = val },
    UrlDefaultData(val) { this.defaultData = val }
  },
  created() {

  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    onUrlExecute() {
      this.UrlEntrance = this.entrance
      this.UrlDefaultData = `${this.entrance}_Data`
      // this.UrlDefaultData = this.defaultData

      this.$emit('import-chart')
    }
  }
}
</script>
