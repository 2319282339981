import JChartTabNavigation from '@/components/chart/tabs/JChartTabNavigation'
import JChartTabDatabase from '@/components/chart/tabs/JChartTabDatabase'
import JChartTabCanvas from '@/components/chart/tabs/JChartTabCanvas'
import JChartTabText from '@/components/chart/tabs/JChartTabText'
import JChartTabBar from './JChartTabBar.vue'
import JChartTabLine from './JChartTabLine.vue'
import JChartTabBox from './JChartTabBox.vue'

export default {
  JChartTabNavigation,
  JChartTabDatabase,
  JChartTabCanvas,
  JChartTabText,
  JChartTabBar,
  JChartTabLine,
  JChartTabBox
}
