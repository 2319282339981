import storeMethods from '@/mixins/storeMethods'

export default {
  mixins: [
    storeMethods
  ],
  methods: {
    setUndef2zero(key, value) {
      let validated = this.setStore(this.setChartItem, { [key]: value }).undefNumber2zero()
      return validated[key] != value
    }
  }
}