<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Column</span>
      <div>
        <span data-grid-quickbtn="btn" class="label jcon_temp_plus type_header btn_after_more bigger" style="color: #4298c6;" @click="appendAttr()"><small class="type_ref01 ">Add Style</small></span>
      </div>
    </div>
    <div class="chart_properties_contents">
      <div class="chart_properties_wrapper">
        <div v-for="(col, i) in localColumnAttrs" :key="i">
          <div class="jcon_temp_minus float" @click="removeAttr(i)"></div>
          <div class="header_input">
            <input :value="col.column" class="header type100 type_header" type="text" @input="(e) => updateAttr(e.target.value, i, 'column')" />
          </div>
          <v-flex class="control">
            <div class="type_common_select">
              <div class="row">
                <div v-for="option in columnOptions" :key="option.index" :class="option.text" :title="option.text">
                  <v-btn 
                    class="btn_dark" 
                    :class="{'is_active': columnOptionSelected[i].type == option.value}"
                    
                    @click="setStyle(i, option.value)"
                  >
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="type_common_select">
              <div class="row" style="height: 2.5rem">
                <div v-for="style in getStyleSerie(columnOptionSelected[i].type)" class="btn_wrapper" :key="style.index">
                  <v-btn
                    class="btn_square"
                    :class="{'is_active': columnOptionSelected[i].series == style.series}" 
                    @click="setStyle(i, columnOptionSelected[i].type, style.series)"
                  >
                    {{ style.series }}
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="type_common_input">
              <div class="row align">
                <div :value="col.align" v-for="option in alignOptions" :key="option.index" :class="option.text">
                  <v-btn
                    class="btn_dark"
                    :class="{'is_active': col.align == option.value}"
                    @click="updateAttr(option.value, i, 'align')"
                  />
                </div>
              </div>
            </div>
            <div class="type_common_input mg_t_3">
              <div class="row">
                <input :value="parseFloat(col.padding)" class="type30 dark_input" title="padding" type="number" @input="(e) => updateAttr(e.target.value, i, 'padding', 'num')" />
              </div>
            </div>
            
          </v-flex>
        </div>
      </div>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from 'vuex'
import _ChartDataDefault from '@/primitives/_chartTableSummaryDefault'
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-summary-column',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      column: true,
    },
    localColumnAttrs: [],
    copiedlocalColumnAttrs: [],
    copiedColumn: null,
    copiedSeries: null,
    arrayNames: [
      'ColumnLinkedField',
    ],
    tabActivator: null,
    localHeaderAttrs: {
      item: [],
    },
    columnOptions: [
      {text: 'Text', value: 'Text'},
      {text: 'Color', value: 'Coloring'},
      {text: 'Bar', value: 'Bar'},
      {text: 'Icon', value: 'Icon'},
    ],
    alignOptions: [
      {text: 'Left', value: 'start'},
      {text: 'Middle', value: 'middle'},
      {text: 'Right', value: 'end'},
    ],
    columnOptionSelected: [],
    styleNums: [
      {text: 1, value: false},
      {text: 2, value: false},
      {text: 3, value: false},
      {text: 4, value: false},
    ],
    booleanNum: 0,
  }),
  watch: {
    'chartItem.Columns': {
      handler(val) {
        if (!val) return
        if(this.booleanNum === 0) {
          this.copiedlocalColumnAttrs = JSON.parse(JSON.stringify(val))
          this.booleanNum++
        }
        this.localColumnAttrs = JSON.parse(JSON.stringify(val))
        this.columnOptionSelected = this.localColumnAttrs.map(attr => ({type: attr.type  , series: attr.series}))
      },
      deep: true
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    linkedAttrs() {
      let length_ = 1
      // let length_ = this.localColumnAttrs.ColumnLinkedField ? this.localColumnAttrs.ColumnLinkedField.length : 0
      return Array.from({ length: length_  }, (_, i) => i) 
    },

    ColumnLinkedField() { return this.localColumnAttrs.ColumnLinkedField || [] },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    /* ### Control the array properties ### */
    appendAttr() {
      this.localColumnAttrs.push(_ChartDataDefault._dataForm.Columns)
      this.columnOptionSelected.push({type: '', series: 0})
      this.updateAttr()
    },
    removeAttr(i) {
      this.localColumnAttrs[i] = null
      this.localColumnAttrs = this.localColumnAttrs.filter(column => !!column)

      this.columnOptionSelected[i] = null
      this.columnOptionSelected = this.columnOptionSelected.filter(selected => !!selected)

      this.updateAttr()
    },
    updateAttr(val, i, el, type) {
      if(el) {
        type == 'num' ? this.localColumnAttrs[i][el] = parseFloat(val) : this.localColumnAttrs[i][el] = val
      }
      this.setChartItem({ Columns: this.localColumnAttrs })
    },
    /* ### ------------------------------------- ### */

    getStyleSerie(_type) {
      return this.chartItem.Style.filter(style => style.type == _type)
    },

    setNumVisible(val) {
      if(val.value == true) {
        val.value = false
      } else {
        this.styleNums.forEach((v) => {
          v.value = false
        })
        val.value = true
      }
    },
    setStyle(i, _type, _series=1) {
      this.columnOptionSelected[i].type = _type
      this.columnOptionSelected[i].series = _series

      this.localColumnAttrs[i] = { ...this.localColumnAttrs[i], ...this.columnOptionSelected[i] }
      this.updateAttr()
    },
  }
}
</script>
