<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Actions</span>
    </div>
    <div class="chart_properties_contents">
      <div class="header">{{ chartItem.Name }}</div>
      <v-flex class="control">
        <div class="type_common_select">
          <label>Target</label>
          <select v-model="localActionAttrs.action.target" style="width: 13rem;" @input="(e) => { updateAttr(e.target.value, 'target') }">
            <option v-for="option in targetOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Type</label>
          <select v-model="localActionAttrs.action.component" style="width: 13rem;" @input="(e) => { onComponentChanged(e.target.value) }">
            <option v-for="option in targetCompOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Name</label>
          <select v-model="localActionAttrs.action.id" style="width: 13rem;" @input="(e) => { onComponentItemChanged(e.target.value) }">
            <option v-for="option in targetCompItems" :key="option.index" :value="option.id">{{option.name}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label>Filter String</label>
          <input v-model="localActionAttrs.action.filterString" type="text" style="width: 13rem; text-align: left;" />
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from "vuex"
import { SystemCodeService } from "@/services"
import StaticOptions from '@/mixins/staticOptions'
import _dataformAction from '@/primitives/_dataformAction'

export default {
  name: 'j-sysenv-tab-action',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    // Service Instances -------------
    systemCodeService: null,

    localActionAttrs: {},
    targetCompItems: []
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

  },
  watch: {
    'chartItem.ActionAttrs': {
      handler(val) {
        if (!val) return
        this.localActionAttrs = JSON.parse(JSON.stringify(val))
        if(this.targetCompItems.length === 0) this.onComponentChanged(this.localActionAttrs.action.component)
      },
      deep: true
    }
  },
  created() {
    this.systemCodeService = new SystemCodeService()
    this.localActionAttrs = _dataformAction
    this.localActionAttrs.action.component = 'ServiceSvgTabs'
  },
  mounted() {
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    onComponentChanged(val) {
      this.getComponentOptions(val).then(res => {
        this.targetCompItems = res
        this.updateAttr(val, 'component') 
      })
    },
    onComponentItemChanged(val) {
      let item__ = this.targetCompItems.find(item_ => item_.id == val)

      if(!item__) return

      this.localActionAttrs['action']['id'] = item__.id
      this.localActionAttrs['action']['no'] = item__.code
      this.updateAttr()
    },

    getComponentOptions(name) {
      return new Promise(resolve => {
        if(name == 'ServiceDashboard') {
          this.systemCodeService.dashboardOptions(__C.PAGE_COMPONENT.TYPE_MODAL, (res) => {
            resolve(res)
          })
        } else if(name == 'ServiceDatatable') {
          this.systemCodeService.datatableOptions(__C.PAGE_COMPONENT.TYPE_MODAL, (res) => {
            resolve(res)
          })
        } else {
          this.systemCodeService.chartOptions(__C.CHART.CAT_CODE_COMPLIB_SVG_TAB, 'ALL', (res) => {
            resolve(res)
          })
        }
      })
    },
    updateAttr(v, el) {
      if(el) this.localActionAttrs.action[el] = v
      this.setChartItem({ ActionAttrs: JSON.parse(JSON.stringify(this.localActionAttrs)) })
    },
  }
}
</script>
