export default {
  // ----------------------------------------------- CANVAS
  Canvas: {
    CanvasWidth             : 980,
    CanvasHeight            : 600,
    CanvasBorderWeight      : 0.3,
    CanvasBorderColor       : '#f2f3f3',
    // CanvasFillColor: 'T  ransparent',
    CanvasFillColor         : '#fff',
    CanvasOpacity           : 0.2,

    CanvasChartAreaAllowed  : 'Y',
    CanvasChartX            : 160,
    CanvasChartY            : 120,
    CanvasChartWidth        : 700,
    CanvasChartHeight       : 400,
    CanvasChartBorderWeight : 0,
    CanvasChartBorderColor  : '#757575',
    CanvasChartFillColor    : '#fff',
    CanvasChartOpacity      : 0.02,
    CanvasChartSpace        : 10,
    CanvasFontFamily        : 'roboto', 
  },

  // ----------------------------------------------- TIMELINE
  Timeline: {
    TimelineType            : 'TL-B',     //TL-A,TL-B
    // 0 value will not be processedl.
    TimelinePeriodStart     : 0,
    // 0 value will not be processedl.
    TimelinePeriodCutoff    : 0,
    // If both of the TimelinePeriodStart & TimelinePeriodCutoff are 0,
    // and if both of the TimelinePeriodFixedStart & TimelinePeriodFixedEnd 
    // has date value, it will be processed.
    TimelinePeriodFixedStart: '',
    TimelinePeriodFixedEnd  : '',
    // TimelinePeriodFixedStart: '2019-08-30',
    // TimelinePeriodFixedEnd: '2019-10-18',
    // If all the conditions are not matched with, the process
    // of truncating the data will not be executed.

    TimelineLineStroke      : 0.5,
    TimelineLineColor       : '#626262',
    TimelineWeekLineHeight  : 3,
    TimelineWeekLineStroke  : 0.3,
    TimelineWeekLineSpace   : 5,
    TimelineWeekLineColor   : '#626262',
    TimelineWeekTextDisplay : 'Y',
    TimelineWeekTextFont    : 'roboto',
    TimelineWeekTextStyle   : 'regular',
    TimelineWeekTextSize    : 9,
    TimelineWeekTextColor   : '#000',

    TimelineMonthDisplay    : 'Y',
    TimelineMonthLineDisplay: 'Y',
    TimelineMonthLineHeight : 0,
    TimelineMonthLineStroke : 0.3,
    TimelineMonthLineSpace  : 5,
    TimelineMonthLineColor  : '#626262',
    TimelineMonthTextFont   : 'roboto',
    TimelineMonthTextStyle  : 'regular',
    TimelineMonthTextSize   : 10,
    TimelineMonthTextColor  : 'gray',

    TimelineYearDisplay     : 'Y',
    TimelineYearLineDisplay : 'Y',
    TimelineYearLineHeight  : 0,
    TimelineYearLineSpace   : 5,
    TimelineYearLineStroke  : 0.3,
    TimelineYearLineColor   : '#626262',
    TimelineYearTextFont    : 'roboto',
    TimelineYearTextStyle   : 'regular',
    TimelineYearTextSize    : 11,
    TimelineYearTextColor   : '#000',
  },

  // ----------------------------------------------- AXES
  Axis: {
    AxisYLineWeight       : 0.3,
    AxisYLineColor        : '#626262',

    AxisPrLevelDisplay    : 'Y',
    AxisPrLevelSpace      : 10,
    AxisPrLevelFont       : 'roboto',
    AxisPrLevelStyle      : 'regular',
    AxisPrLevelSize       : 11,
    AxisPrLevelWeight     : 500,
    AxisPrLevelColor      : '#626262',
    AxisPrLevelUnitSize   : 10,
    AxisPrLevelUnitFormat : '%',

    AxisPrBoundsCenter    : 'N',
    AxisPrAllowMinusValue : 'N',
    AxisPrBoundsMinAuto   : 'Y',
    AxisPrBoundsMin       : 0,
    AxisPrBoundsMaxAuto   : 'N',
    AxisPrBoundsMax       : 100,

    AxisSeLevelDisplay    : 'Y',
    AxisSeLevelSpace      : 10,
    AxisSeLevelFont       : 'roboto',
    AxisSeLevelStyle      : 'regular',
    AxisSeLevelSize       : 11,
    AxisSeLevelWeight     : 500,
    AxisSeLevelColor      : '#626262',
    AxisSeLevelUnitSize   : 10,
    AxisSeLevelUnitFormat : 'K',

    AxisSeBoundsCenter    : 'N',
    AxisSeAllowMinusValue : 'N',
    AxisSeBoundsMinAuto   : 'Y',
    AxisSeBoundsMin       : 0,
    AxisSeBoundsMaxAuto   : 'Y',
    AxisSeBoundsMax       : 1000,    
    
    AxisGridDisplay       : 'Y',
    AxisGridNum           : 5,
    AxisGridWeight        : 0.3,
    AxisGridColor         : '#ccc',
    AxisGridOpacity       : 1,

    AxisZeroLineDisplay   : 'Y',
    AxisZeroLineWeight    : 0.3,
    AxisZeroLineColor     : '#ccc',
    AxisZeroLineOpacity   : 1,
  },

  // ----------------------------------------------- BAR
  Bar: {
    BarAutoSize       : 'Y',
    BarThickness      : 5,
    BarDistance       : 1,
    BarRadius         : 0, // Embossed only
    // BarBasedCylin  der: 30, // Tube only
    // BarColorType:   'LinearA9',
    BarColorSet       : ['#d9d9d8','#6ccaf4','#87b633'],
    // BarReflection  : 'Y',

    BarBorder         : {
      display : 'Y',
      weight  : [0.5, 0.5, 0.5],
      color   : ['#d9d9d8','#6ccaf4','#87b633'],
      opacity : [1, 1, 1],
    },

    BarBackDisplay    : 'Y',
    BarBackColor      : '#efefef',

    BarSeriesDisplay  : 'Y',
    BarSeriesSpace    : 5,
    BarSeriesFont     : 'roboto',
    BarSeriesStyle    : 'regular',
    BarSeriesSize     : 12,    
    BarSeriesAutoColor: 'Y',
    BarSeriesColor    : '#757575',

    BarValueDisplay   : 'Y',
    BarValuePosition  : 'top',    // top | middle | bottom
    BarValueFont      : 'roboto',
    BarValueStyle     : 'regular',
    BarValueSize      : 9,
    BarValueRound     : 1,
    BarValueAutoColor : 'Y',
    BarValueColor     : '#757575',
    BarValueSpace     : 0, // Tube only

    BarUnitDisplay    : 'Y',
    BarUnitFormat     : '%',
    BarUnitSize       : 11,
    BarUnitColor      : '#757575'
  },

  // ----------------------------------------------- LINE
  Line: {
    LineDisplay       : 'Y',
    LineDash          : [0, 0, 2],
    LineWeight        : [0.3, 0.3, 0.3],
    LineColorSet      : ['#626262','#e22767','#87b633'],

    LineMarkerDisplay : 'Y',
    LineMarkerType    : 'circle',   // circle | square | none
    LineMarkerSize    : 5,
    LineMarkerStroke  : 0.5,
    
    LineValueDisplay  : 'N',
    LineValuePosition : 'top',    // top | middle | bottom
    LineValueFont     : 'roboto',
    LineValueStyle    : 'regular',
    LineValueSize     : 8,
    LineValueColor    : '#626262',
    LineValueSpace    : 10,
    LineValueFormat   : '%',
  },

  // ----------------------------------------------- LEGENDS
  Legends: {
    LegendDisplay       : 'N',
    LegendDirection     : 'Horizontal',
    LegendBasepoint     : 'end',
    LegendX             : 10,
    LegendY             : 50,

    LegendTitleDisplay  : 'Y',
    LegendTitleFont     : 'roboto',
    LegendTitleStyle    : 'regular',
    LegendTitleSize     : 12,
    LegendTitleColor    : '#757575',

    LegendBulletDisplay : 'Y',
    LegendBulletType    : 'square', // square, circle
    LegendBulletSize    : 5,
    LegendBulletSpace   : 10,

    LegendSeriesFont    : 'roboto',
    LegendSeriesStyle   : 'regular',
    LegendSeriesSize    : 10,
    LegendSeriesColor   : '#757575',
    LegendSeriesSpace   : 10,

    LegendValueDisplay  : 'Y',
    LegendValueSize     : 10,
    LegendValueColor    : '#44A9DF',
    LegendValueRound    : 1,

    LegendUnitDisplay   : 'Y',
    LegendUnitFormat    : '%',
    LegendUnitSize      : 8,
    LegendUnitColor     : '#757575',

    LegendLineSpace     : 5
  },

  // ----------------------------------------------- MILESTONE
  Milestone: {
    MilestoneDisplay    : 'Y',           // Y, N
    MilestoneLineStyle  : 0,             // length of dash (0, 1, 2, 3, ... : 0 = solid), 
    MilestoneLineWeight : 0.5,
    MilestoneLineHeight : 350,
    MilestoneLineColor  : '#ff8a80',

    MilestoneTextFont   : 'roboto',
    MilestoneTextStyle  : 'regular',
    MilestoneTextSize   : 9,
    MilestoneTextColor  : '#ff8a80',
    
    MilestoneQApplied   : '',   // json, sql, sp
    MilestoneQJson      : '',
    MilestoneQSql       : '',
    MilestoneQSp        : '',
  },
  TableSummary: {
    display       : 'Y' ,       // Y, N
    shareData     : 'N' ,       // Y, N
    x             : 0   ,
    y             : 400   ,
    headers       : [
      { 
        text: 'Plan',
        value: 'BAR//PL//Plan',
      },
      { 
        text: 'Actual',
        value: 'BAR//AC//Actual',
      },
    ],
    items         : [
      { CDATE: '2019-08-02', 'BAR//PL//Plan': 0,	  'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 0,	    'LINE//PL//Plan': 0,	        'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 0,	       },
      { CDATE: '2019-08-09', 'BAR//PL//Plan': 260,	'BAR//AC//Actual': 260,	  'BAR//FC//Forecast': 260,	  'LINE//PL//Plan': 0.6401506,	'LINE//AC//Actual': 0.6401506,	'LINE//FC//Forecast': 0.7041657, },
      { CDATE: '2019-08-16', 'BAR//PL//Plan': 330,	'BAR//AC//Actual': 303,	  'BAR//FC//Forecast': 330,	  'LINE//PL//Plan': 1.564613,	  'LINE//AC//Actual': 1.564613,	  'LINE//FC//Forecast': 1.7210743, },
      { CDATE: '2019-08-23', 'BAR//PL//Plan': 599,	'BAR//AC//Actual': 551,	  'BAR//FC//Forecast': 599,	  'LINE//PL//Plan': 1.6577107,	'LINE//AC//Actual': 1.6577107,	'LINE//FC//Forecast': 1.8234817, },
      { CDATE: '2019-08-30', 'BAR//PL//Plan': 671,	'BAR//AC//Actual': 617,	  'BAR//FC//Forecast': 671,	  'LINE//PL//Plan': 1.9183277,	'LINE//AC//Actual': 1.9183277,	'LINE//FC//Forecast': 2.1101604, },
      { CDATE: '2019-09-06', 'BAR//PL//Plan': -846,	'BAR//AC//Actual': -676,	'BAR//FC//Forecast': -846,	'LINE//PL//Plan': 1.6310504,	'LINE//AC//Actual': 1.759939,	  'LINE//FC//Forecast': 1.9359329, },
      { CDATE: '2019-09-13', 'BAR//PL//Plan': -1209,'BAR//AC//Actual': -1100,	'BAR//FC//Forecast': -1209,	'LINE//PL//Plan': 2.6143343,	'LINE//AC//Actual': 3.5657802,	'LINE//FC//Forecast': 3.9223582, },
      { CDATE: '2019-09-20', 'BAR//PL//Plan': 1508,	'BAR//AC//Actual': 1372,	'BAR//FC//Forecast': 1508,	'LINE//PL//Plan': 3.3837908,	'LINE//AC//Actual': 4.7549786,	'LINE//FC//Forecast': 5.2304765, },
      { CDATE: '2019-09-27', 'BAR//PL//Plan': 2189,	'BAR//AC//Actual': 1991,	'BAR//FC//Forecast': 2189,	'LINE//PL//Plan': 4.4998273,	'LINE//AC//Actual': 5.81,	      'LINE//FC//Forecast': 6.391,	   },
      { CDATE: '2019-10-04', 'BAR//PL//Plan': 2967,	'BAR//AC//Actual': 2699,	'BAR//FC//Forecast': 2967,	'LINE//PL//Plan': 6.055897,	  'LINE//AC//Actual': 7.35,	      'LINE//FC//Forecast': 8.085,	   },
      { CDATE: '2019-10-11', 'BAR//PL//Plan': 4365,	'BAR//AC//Actual': 3972,	'BAR//FC//Forecast': 4365,	'LINE//PL//Plan': 10.5696765,	'LINE//AC//Actual': 12.74,	    'LINE//FC//Forecast': 14.014,	   },
      { CDATE: '2019-10-18', 'BAR//PL//Plan': 5998,	'BAR//AC//Actual': 5458,	'BAR//FC//Forecast': 5998,	'LINE//PL//Plan': 12.8302552,	'LINE//AC//Actual': 14.25,	    'LINE//FC//Forecast': 15.675,	   },
      { CDATE: '2019-10-25', 'BAR//PL//Plan': 8895,	'BAR//AC//Actual': 8094,	'BAR//FC//Forecast': 8895,	'LINE//PL//Plan': 16.8184942,	'LINE//AC//Actual': 19.02,	    'LINE//FC//Forecast': 20.922,	   },
      { CDATE: '2019-11-01', 'BAR//PL//Plan': 8987,	'BAR//AC//Actual': 8178,	'BAR//FC//Forecast': 8987,	'LINE//PL//Plan': 20.5430938,	'LINE//AC//Actual': 22.06,	    'LINE//FC//Forecast': 24.277,	   },
      { CDATE: '2019-11-08', 'BAR//PL//Plan': 9187,	'BAR//AC//Actual': 8845,	'BAR//FC//Forecast': 9187,	'LINE//PL//Plan': 28.6212889,	'LINE//AC//Actual': 28.37,	    'LINE//FC//Forecast': 31.218,	   },
      { CDATE: '2019-11-15', 'BAR//PL//Plan': 8685,	'BAR//AC//Actual': 9119,	'BAR//FC//Forecast': 8685,	'LINE//PL//Plan': 33.320811,	'LINE//AC//Actual': 32.24,	    'LINE//FC//Forecast': 35.475,	   },
      { CDATE: '2019-11-22', 'BAR//PL//Plan': 8798,	'BAR//AC//Actual': 9237,	'BAR//FC//Forecast': 8798,	'LINE//PL//Plan': 36.5473193,	'LINE//AC//Actual': 34.51,	    'LINE//FC//Forecast': 37.972,	   },
      { CDATE: '2019-11-29', 'BAR//PL//Plan': 7815,	'BAR//AC//Actual': 8205,	'BAR//FC//Forecast': 7815,	'LINE//PL//Plan': 42.1529536,	'LINE//AC//Actual': 38.57,	    'LINE//FC//Forecast': 42.438,	   },
      { CDATE: '2019-12-06', 'BAR//PL//Plan': 6335,	'BAR//AC//Actual': 5638,	'BAR//FC//Forecast': 6335,	'LINE//PL//Plan': 49.9241399,	'LINE//AC//Actual': 43.26,	    'LINE//FC//Forecast': 47.597,	   },
      { CDATE: '2019-12-13', 'BAR//PL//Plan': 6754,	'BAR//AC//Actual': 6011,	'BAR//FC//Forecast': 6754,	'LINE//PL//Plan': 58.2224595,	'LINE//AC//Actual': 48.65,	    'LINE//FC//Forecast': 53.526,	   },
      { CDATE: '2019-12-20', 'BAR//PL//Plan': 3675,	'BAR//AC//Actual': 360,	  'BAR//FC//Forecast': 3675,	'LINE//PL//Plan': 67.1465583,	'LINE//AC//Actual': 59.51,	    'LINE//FC//Forecast': 65.461,	   },
      { CDATE: '2019-12-27', 'BAR//PL//Plan': 3165,	'BAR//AC//Actual': 310,	  'BAR//FC//Forecast': 3165,	'LINE//PL//Plan': 72.7401246,	'LINE//AC//Actual': 65.21,	    'LINE//FC//Forecast': 71.742,	   },
      { CDATE: '2020-01-03', 'BAR//PL//Plan': 2891,	'BAR//AC//Actual': 2891,	'BAR//FC//Forecast': 2891,	'LINE//PL//Plan': 77.3707679,	'LINE//AC//Actual': 69.36,	    'LINE//FC//Forecast': 69.36,	   },
      { CDATE: '2020-01-10', 'BAR//PL//Plan': 1540,	'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 1540,	'LINE//PL//Plan': 81.1585366,	'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 75.512546, },
      { CDATE: '2020-01-17', 'BAR//PL//Plan': 1254,	'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 1417,	'LINE//PL//Plan': 85.3632596,	'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 80.24887,	 },
      { CDATE: '2020-01-24', 'BAR//PL//Plan': 1876,	'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 2119,	'LINE//PL//Plan': 88.2243751,	'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 85.57892,	 },
      { CDATE: '2020-01-31', 'BAR//PL//Plan': 654,	'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 788,	  'LINE//PL//Plan': 93.1707212,	'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 90.5245,	 },
      { CDATE: '2020-02-07', 'BAR//PL//Plan': 412,	'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 546,	  'LINE//PL//Plan': 96.1126488,	'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 93.96587,	 },
      { CDATE: '2020-02-14', 'BAR//PL//Plan': 312,	'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 343,	  'LINE//PL//Plan': 98.383963,	'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 97.00365,	 },
      { CDATE: '2020-02-21', 'BAR//PL//Plan': 200,	'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 360,	  'LINE//PL//Plan': 99.5900943,	'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 99.03456,	 },
      { CDATE: '2020-02-28', 'BAR//PL//Plan': 122,	'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 219,	  'LINE//PL//Plan': 100,	      'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 100,	     },
    ],
  },


  // ----------------------------------------------- Text
  Text: {
    Text: [
      { text: 'Module', x: 130, y: 25, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start', direction: 'Horizontal' },
    ],
    Filter: [
      { name: 'MOD'   , x: 50, y: 25, font: 'roboto', class: 'regular', size: 20, color: '#44A9DF', align: 'start', direction: 'Horizontal' },
    ],
    Sql: [
      {
        query: '', 
        text: 'PBF COMPESSOR AIR COOLER - TRAIN 4',
        x: 50,
        y: 50,
        font: 'roboto',
        style: 'regular',
        size: 14,
        color: '#757575',
        align: 'start',
        direction: 'Horizontal'
      },
    ]
  },

  // Method of that the embeded query result is sent.
  // While the main query result is set to the 'DataItems',
  // Milestone, Note, or Floating table's embeded query result
  // will be sent to the chart through the 'Values'. It could 
  // be a explicit way to know where the result of the additional
  // queries come. 
  Values: {
    milestone: [
      { CDATE: '2019-08-16', desc:	'Steel Cutting' },
      { CDATE: '2019-09-06', desc:	'Assembly' },
      { CDATE: '2019-10-25', desc:	'Pipe Piece Fabrication' },
    ],
    note: '2019-10-11'
  },
  
  DataItems: [
    { CDATE: '2019-08-02', 'BAR//PL//Plan': 0,	  'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 0,	    'LINE//PL//Plan': 0,	        'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 0,	       },
    { CDATE: '2019-08-09', 'BAR//PL//Plan': 260,	'BAR//AC//Actual': 260,	  'BAR//FC//Forecast': 260,	  'LINE//PL//Plan': 0.6401506,	'LINE//AC//Actual': 0.6401506,	'LINE//FC//Forecast': 0.7041657, },
    { CDATE: '2019-08-16', 'BAR//PL//Plan': 330,	'BAR//AC//Actual': 303,	  'BAR//FC//Forecast': 330,	  'LINE//PL//Plan': 1.564613,	  'LINE//AC//Actual': 1.564613,	  'LINE//FC//Forecast': 1.7210743, },
    { CDATE: '2019-08-23', 'BAR//PL//Plan': 599,	'BAR//AC//Actual': 551,	  'BAR//FC//Forecast': 599,	  'LINE//PL//Plan': 1.6577107,	'LINE//AC//Actual': 1.6577107,	'LINE//FC//Forecast': 1.8234817, },
    { CDATE: '2019-08-30', 'BAR//PL//Plan': 671,	'BAR//AC//Actual': 617,	  'BAR//FC//Forecast': 671,	  'LINE//PL//Plan': 1.9183277,	'LINE//AC//Actual': 1.9183277,	'LINE//FC//Forecast': 2.1101604, },
    { CDATE: '2019-09-06', 'BAR//PL//Plan': -846,	'BAR//AC//Actual': -676,	'BAR//FC//Forecast': -846,	'LINE//PL//Plan': 1.6310504,	'LINE//AC//Actual': 1.759939,	  'LINE//FC//Forecast': 1.9359329, },
    { CDATE: '2019-09-13', 'BAR//PL//Plan': -1209,'BAR//AC//Actual': -1100,	'BAR//FC//Forecast': -1209,	'LINE//PL//Plan': 2.6143343,	'LINE//AC//Actual': 3.5657802,	'LINE//FC//Forecast': 3.9223582, },
    { CDATE: '2019-09-20', 'BAR//PL//Plan': 1508,	'BAR//AC//Actual': 1372,	'BAR//FC//Forecast': 1508,	'LINE//PL//Plan': 3.3837908,	'LINE//AC//Actual': 4.7549786,	'LINE//FC//Forecast': 5.2304765, },
    { CDATE: '2019-09-27', 'BAR//PL//Plan': 2189,	'BAR//AC//Actual': 1991,	'BAR//FC//Forecast': 2189,	'LINE//PL//Plan': 4.4998273,	'LINE//AC//Actual': 5.81,	      'LINE//FC//Forecast': 6.391,	   },
    { CDATE: '2019-10-04', 'BAR//PL//Plan': 2967,	'BAR//AC//Actual': 2699,	'BAR//FC//Forecast': 2967,	'LINE//PL//Plan': 6.055897,	  'LINE//AC//Actual': 7.35,	      'LINE//FC//Forecast': 8.085,	   },
    { CDATE: '2019-10-11', 'BAR//PL//Plan': 4365,	'BAR//AC//Actual': 3972,	'BAR//FC//Forecast': 4365,	'LINE//PL//Plan': 10.5696765,	'LINE//AC//Actual': 12.74,	    'LINE//FC//Forecast': 14.014,	   },
    { CDATE: '2019-10-18', 'BAR//PL//Plan': 5998,	'BAR//AC//Actual': 5458,	'BAR//FC//Forecast': 5998,	'LINE//PL//Plan': 12.8302552,	'LINE//AC//Actual': 14.25,	    'LINE//FC//Forecast': 15.675,	   },
    { CDATE: '2019-10-25', 'BAR//PL//Plan': 8895,	'BAR//AC//Actual': 8094,	'BAR//FC//Forecast': 8895,	'LINE//PL//Plan': 16.8184942,	'LINE//AC//Actual': 19.02,	    'LINE//FC//Forecast': 20.922,	   },
    { CDATE: '2019-11-01', 'BAR//PL//Plan': 8987,	'BAR//AC//Actual': 8178,	'BAR//FC//Forecast': 8987,	'LINE//PL//Plan': 20.5430938,	'LINE//AC//Actual': 22.06,	    'LINE//FC//Forecast': 24.277,	   },
    { CDATE: '2019-11-08', 'BAR//PL//Plan': 9187,	'BAR//AC//Actual': 8845,	'BAR//FC//Forecast': 9187,	'LINE//PL//Plan': 28.6212889,	'LINE//AC//Actual': 28.37,	    'LINE//FC//Forecast': 31.218,	   },
    { CDATE: '2019-11-15', 'BAR//PL//Plan': 8685,	'BAR//AC//Actual': 9119,	'BAR//FC//Forecast': 8685,	'LINE//PL//Plan': 33.320811,	'LINE//AC//Actual': 32.24,	    'LINE//FC//Forecast': 35.475,	   },
    { CDATE: '2019-11-22', 'BAR//PL//Plan': 8798,	'BAR//AC//Actual': 9237,	'BAR//FC//Forecast': 8798,	'LINE//PL//Plan': 36.5473193,	'LINE//AC//Actual': 34.51,	    'LINE//FC//Forecast': 37.972,	   },
    { CDATE: '2019-11-29', 'BAR//PL//Plan': 7815,	'BAR//AC//Actual': 8205,	'BAR//FC//Forecast': 7815,	'LINE//PL//Plan': 42.1529536,	'LINE//AC//Actual': 38.57,	    'LINE//FC//Forecast': 42.438,	   },
    { CDATE: '2019-12-06', 'BAR//PL//Plan': 6335,	'BAR//AC//Actual': 5638,	'BAR//FC//Forecast': 6335,	'LINE//PL//Plan': 49.9241399,	'LINE//AC//Actual': 43.26,	    'LINE//FC//Forecast': 47.597,	   },
    { CDATE: '2019-12-13', 'BAR//PL//Plan': 6754,	'BAR//AC//Actual': 6011,	'BAR//FC//Forecast': 6754,	'LINE//PL//Plan': 58.2224595,	'LINE//AC//Actual': 48.65,	    'LINE//FC//Forecast': 53.526,	   },
    { CDATE: '2019-12-20', 'BAR//PL//Plan': 3675,	'BAR//AC//Actual': 360,	  'BAR//FC//Forecast': 3675,	'LINE//PL//Plan': 67.1465583,	'LINE//AC//Actual': 59.51,	    'LINE//FC//Forecast': 65.461,	   },
    { CDATE: '2019-12-27', 'BAR//PL//Plan': 3165,	'BAR//AC//Actual': 310,	  'BAR//FC//Forecast': 3165,	'LINE//PL//Plan': 72.7401246,	'LINE//AC//Actual': 65.21,	    'LINE//FC//Forecast': 71.742,	   },
    { CDATE: '2020-01-03', 'BAR//PL//Plan': 2891,	'BAR//AC//Actual': 2891,	'BAR//FC//Forecast': 2891,	'LINE//PL//Plan': 77.3707679,	'LINE//AC//Actual': 69.36,	    'LINE//FC//Forecast': 69.36,	   },
    { CDATE: '2020-01-10', 'BAR//PL//Plan': 1540,	'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 1540,	'LINE//PL//Plan': 81.1585366,	'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 75.512546, },
    { CDATE: '2020-01-17', 'BAR//PL//Plan': 1254,	'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 1417,	'LINE//PL//Plan': 85.3632596,	'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 80.24887,	 },
    { CDATE: '2020-01-24', 'BAR//PL//Plan': 1876,	'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 2119,	'LINE//PL//Plan': 88.2243751,	'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 85.57892,	 },
    { CDATE: '2020-01-31', 'BAR//PL//Plan': 654,	'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 788,	  'LINE//PL//Plan': 93.1707212,	'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 90.5245,	 },
    { CDATE: '2020-02-07', 'BAR//PL//Plan': 412,	'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 546,	  'LINE//PL//Plan': 96.1126488,	'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 93.96587,	 },
    { CDATE: '2020-02-14', 'BAR//PL//Plan': 312,	'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 343,	  'LINE//PL//Plan': 98.383963,	'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 97.00365,	 },
    { CDATE: '2020-02-21', 'BAR//PL//Plan': 200,	'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 360,	  'LINE//PL//Plan': 99.5900943,	'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 99.03456,	 },
    { CDATE: '2020-02-28', 'BAR//PL//Plan': 122,	'BAR//AC//Actual': 0,	    'BAR//FC//Forecast': 219,	  'LINE//PL//Plan': 100,	      'LINE//AC//Actual': 0,	        'LINE//FC//Forecast': 100,	     },
  ],
}
