<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Icons</span>
      <div>
        <span data-grid-quickbtn="btn" class="label jcon_temp_plus type_header btn_after_more" style="color: #4298c6;" @click="appendAttr()"><small class="type_ref01 ">Add Style</small></span>
      </div>
    </div>
    <div class="chart_properties_contents">
      <div class="chart_properties_wrapper">
        <j-alert
          v-model="msgOpen"
          :title="msgInfo.title"
          :type="msgInfo.type"
          :message="msgInfo.message"
          :button="msgInfo.button"
          :button-text="msgInfo.buttonText"
          @yes="yes()"
          @cancel="msgOpen = false"
        ></j-alert>
        <div v-for="(attr, i) in localIconAttrs" class="content_list" :key="`_header_${i}`">
          <div class="header">
            <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header align_center" style="color: #4298c6;" @click="clickRemoveBtn(i)">{{ i + 1 }}{{ surfix(i) }}</span>
            <a class="close" @click.stop="editorOpen(i)"></a>
          </div>
          <v-flex class="control" v-show="editorOpended[i]">
            <div>
              <div class="type_inner">
                <span class="label">Ref. Column <small class="type_ref03">(code column)</small></span>
                <div class="type_common_input">
                  <label></label>
                  <input class="type147" type="text" placeholder="select file...." v-model="attr.refColumn" @input="(e) => { updateAttr(e.target.value, i, 'refColumn') }">
                </div>
                <span class="label">Alignment</span>
                <div class="type_common_input">
                  <label></label>
                  <div class="row">
                    <label v-for="option in alignHOptionsLR" :key="option.index" class="type_many">
                      <input v-model="attr.align"  type="radio" name="align" :value="option.value" @input="(e) => { updateAttr(e.target.value, i, 'align') }" />
                      {{option.text}}
                    </label>
                  </div>
                </div>
                <div class="type_common_input">
                  <label class="type_gray">Text Size</label>
                  <input type="number" v-model="attr.textSize" @input="(e) => { updateAttr(e.target.value, i, 'textSize', 'num') }"/>
                </div>
                <div class="type_common_input">
                  <label class="type_gray">Padding(x)</label>
                  <div class="row">
                    <input type="number" v-model="attr.paddingX" @input="(e) => { updateAttr(e.target.value, i, 'paddingX', 'num') }"/>
                  </div>
                </div>
                <div class="type_common_input">
                  <label class="type_gray">Icon Size</label>
                  <div class="row">
                    <input v-model="attr.iconSize" type="number" @input="(e) => { updateAttr(e.target.value, i, 'iconSize', 'num') }"/>
                  </div>
                </div>
                <label>Codes & Icons</label>
                <div class="type_inner_more_over">
                  <div class="input_flex_wrap code_title_wrapper">
                    <div class="jcon_temp_plus" data-grid-quickbtn="btn" @click="appendAttr(i, 'codes')">
                      <label class="default_code type_gray">Default Icon</label>
                    </div>
                  </div>
                  <div
                    class="type_common_input type_color_float"
                    v-for="(code, j) in attr.codes"
                    :key="`_cell_${i}_${j}`"
                  >
                    <div class="input_flex_wrap picker_gradient">
                      <div class="jcon_temp_minus" data-grid-quickbtn="btn" @click="clickRemoveBtn(i, j, 'codes')"></div>
                      <input class="type_gray w100_rem" v-model="code.code" type="text" @focusout="updateAttr" />
                      <j-color-picker v-model="code.tColor" @input="updateAttr" title>
                        <template #action="{ action }">
                          <div
                            v-ripple
                            v-on="action.on"
                            class="j_button_color_picker w34"
                            :style="`color: ${code.tColor}; background: linear-gradient(to right, ${code.bColor} 50%, #fff 50%); margin-left: 3px;`"
                            @click="action.open"
                          >TEXT</div>
                        </template>
                      </j-color-picker>
                      <j-icon-picker 
                        cat-code="SVG" 
                        :value="code.name" 
                        :offset-x="true" 
                        :data-type="code.name"
                        @input="(v) => { setIcon(code, v) }"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-flex>
        </div>
      </div>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from "vuex"
import _ChartDataDefault from '@/primitives/_chartTableSummaryDefault'
import StaticOptions from '@/mixins/staticOptions'
import JIconPicker from "@/components/floating/JIconPicker"

export default {
  name: 'j-chart-tab-summary-icon',
  mixins: [
    StaticOptions
  ],
  components: {
    JIconPicker
  },
  data: () => ({
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    editorOpended: [],
    localStyleAttrs: [],
    localIconAttrs: [],
    tabActivator: null,
    firstOpened : true,

    msgOpen: false,
    yes: null
  }),
  watch: {
    'chartItem.Style': {
      handler(val) {
        if (!val) return
        this.localStyleAttrs = JSON.parse(JSON.stringify(val))
        this.localIconAttrs = this.localStyleAttrs.filter(attr => attr.type == 'Icon')
        if(this.firstOpened) {
          this.editorOpended.push(true)
          this.editorOpended.push(...Array.from({ length: this.localIconAttrs.length - 1 }, _ => false))
        }
        this.firstOpened = false
      },
      deep: true
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

      headerAttrs() {
      return Array.from({ length: this.localIconAttrs.length  }, (_, i) => i)
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
    
    appendAttr(i, el) {
      if(el) this.localIconAttrs[i][el].push(_ChartDataDefault._dataForm.Styles.Icon.codes[0])
      else {
        this.localIconAttrs.push(_ChartDataDefault._dataForm.Styles.Icon)
        this.editorOpended.push(false)
        this.reSerializing(this.localIconAttrs)
      }
      this.updateAttr()
    },
    clickRemoveBtn(i, j, el) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    editorOpen(i) {
      let opened_ = JSON.parse(JSON.stringify(this.editorOpended))
      opened_[i] = !opened_[i]

      // // For updating the changes on validating, because of auto closing
      // // after updateAttr.
      // Fix Me
      if(!opened_[i]) this.updateAttr()
      
      this.editorOpended = opened_

      return this.editorOpended[i]
    },
    removeAttr(i, j, el) {
      if(el) {
        this.localIconAttrs[i][el][j] = null
        this.localIconAttrs[i][el] = this.localIconAttrs[i][el].filter(code => !!code)
      } else {
        this.localIconAttrs[i] = null
        this.editorOpended[i] = null
        this.localIconAttrs = this.localIconAttrs.filter(attr => !!attr)
        this.editorOpended = this.editorOpended.filter( v => v !== null)
        this.reSerializing(this.localIconAttrs)
      }
      this.updateAttr()
    },
    reSerializing(datas) {
      datas.forEach((data, i_) => { data.series = i_+1 })
    },
    surfix(i) {
      let surfix_ = ['st', 'nd', 'rd', 'th']
      if (i < 3) return surfix_[i]
      return surfix_[3]
    },
    setIcon(code, v){
      code.url =  `${__C.HOST_NAME}${v.filePath}`
      code.name = v.name
      this.updateAttr()
    },
    updateAttr(val, i, el, type) {
      if(el) { 
        if(type == 'num') {
          this.localIconAttrs[i][el] = parseFloat(val);
        } else {
          this.localIconAttrs[i][el] = val
        }
      }

      this.localStyleAttrs = this.localStyleAttrs.map(attr => attr.type == 'Icon' ? null : attr)
      this.localStyleAttrs = this.localStyleAttrs.filter(attr => !!attr)
      this.setChartItem({ Style: [ 
        ...JSON.parse(JSON.stringify(this.localStyleAttrs)), 
        ...JSON.parse(JSON.stringify(this.localIconAttrs))
      ]})
    }
  }
}
</script>
