export default {
  // ----------------------------------------------- DATABASE
  Database: {
    DatabasePreviewMode: 'Chart',
    DatabaseAccessMode: 'Json',
    DatabaseJson: '',
    DatabaseQuery: '',
    DatabaseSP: ''
  },

  // ----------------------------------------------- CANVAS
  Canvas: {
    CanvasWidth: 800,
    CanvasHeight: 470,
    CanvasBorderWeight: 0.5,
    CanvasBorderColor: '#757575',
    // CanvasFillColor: 'Transparent',
    CanvasFillColor: '#fff',
    CanvasOpacity: 0.2,

    CanvasChartAreaAllowed: 'Y',
    CanvasChartX: 100,
    CanvasChartY: 120,
    CanvasChartWidth: 480,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0.02,
    CanvasChartSpace: 10,

    CanvasFontFamily: 'roboto'
  },

  // ----------------------------------------------- AXIS
  Axis: {
    AxisXLineDisplay: 'N',
    AxisXLineWeight: 0.5,
    AxisXLineColor: '#37474f',

    AxisXLevelDisplay: 'Y',
    AxisXLevelPosition: '',
    AxisXLevelSpace: 10,
    AxisXLevelFont: 'roboto',
    AxisXLevelStyle: 'regular',
    AxisXLevelSize: 12,
    AxisXLevelWeight: 500,
    AxisXLevelAutoColor: 'Y',
    AxisXLevelColor: '#37474f',
    AxisXLevelUnitSize: 12,
    AxisXLevelUnitFormat: '%',
    AxisXLevelDirection: 'Horizontal',

    AxisYLineDisplay: 'N',
    AxisYLineWeight: 0.5,
    AxisYLineColor: '#37474f',

    AxisYLevelDisplay: 'Y',
    AxisYLevelPosition: 'left',
    AxisYLevelSpace: 10,
    AxisYLevelFont: 'roboto',
    AxisYLevelStyle: 'regular',
    AxisYLevelSize: 12,
    AxisYLevelWeight: 500,
    AxisYLevelAutoColor: 'Y',
    AxisYLevelColor: '#37474f',
    AxisYLevelUnitSize: 12,
    AxisYLevelUnitFormat: '%',

    AxisYBoundsAuto: 'Y',
    AxisYBoundsMax: 100,

    AxisYGridDisplay: 'Y',
    AxisYGridWeight: 0.5,
    AxisYGridColor: '#757575'
  },

  // ----------------------------------------------- TITLE
  Title: {
    TitleMainDisplay: 'Y',
    TitleMainText: 'Spool Installation',
    TitleMainX: 400,
    TitleMainY: 50,
    TitleMainFont: 'roboto',
    TitleMainStyle: 'regular',
    TitleMainSize: 24,
    TitleMainColor: '#44A9DF',
    TitleMainAlign: 'middle',

    TitleSubDisplay: 'Y',
    TitleSubText: 'MD2 Project',
    TitleSubX: 400,
    TitleSubY: 70,
    TitleSubFont: 'roboto',
    TitleSubStyle: 'regular',
    TitleSubSize: 12,
    TitleSubColor: '#BDBCBC',
    TitleSubAlign: 'middle',

    TitleExtraDisplay: 'N',
    TitleExtraText: 'Discipline',
    TitleExtraX: 400,
    TitleExtraY: 430,
    TitleExtraFont: 'roboto',
    TitleExtraStyle: 'regular',
    TitleExtraSize: 14,
    TitleExtraColor: 'gray',
    TitleExtraAlign: 'middle',

    TitleSqlDisplay: 'N',
    TitleSqlText: 'SQL Query...',
    TitleSqlQuery: 'SQL Query...',
    TitleSqlX: 400,
    TitleSqlY: 90,
    TitleSqlFont: 'roboto',
    TitleSqlStyle: 'regular',
    TitleSqlSize: 11,
    TitleSqlColor: 'pink',
    TitleSqlAlign: 'middle',

    TitleLineDisplay: 'N',
    TitleLineX: 30,
    TitleLineY: 30,
    TitleLineLength: 100,
    TitleLineWeight: 1,
    TitleLineColor: 'black'
  },

  // ----------------------------------------------- BAR
  Bar: {
    BarAutoSize: 'Y',
    BarThickness: 100,
    BarDistance: 40,
    BarRadius: 10, // Embossed only
    // BarColorType: "LinearA9",
    // BarColorSet: ["Gold", "Orange", "Pink", "Gray", "SkyBlue", "LightBlue", "YellowGreen", "Red", "Thistle", "Purple", "Indigo", "LightBlue", "SkyBlue", "SteelBlue", "YellowGreen","DarkGreen"],
    BarColorType: 'LinearA2',
    BarColorSet: ['#ffee58', '#ffeb3b', '#fdd835', '#fbc02d', '#f9a825', '#f57f17'],
    BarReflection: 'Y',
    // ["#ffee58","#ffeb3b","#fdd835","#fbc02d","#f9a825","#f57f17"]

    BarBackDisplay: 'Y',
    BarBackColor: '#efefef',

    BarSeriesDisplay: 'Y',
    BarSeriesSpace: 5,
    BarSeriesFont: 'roboto',
    BarSeriesStyle: 'regular',
    BarSeriesSize: 12,
    BarSeriesAutoColor: 'Y',
    BarSeriesColor: '#757575',

    BarValueDisplay: 'Y',
    BarValueFont: 'roboto',
    BarValueStyle: 'regular',
    BarValueSize: 12,
    BarValueRound: 1,
    BarValueAutoColor: 'N',
    BarValueColor: '#757575',
    BarValuePosition: 'top',
    BarValueSpace: 7, // Tube only

    BarUnitDisplay: 'Y',
    BarUnitFormat: '%',
    BarUnitSize: 11,
    BarUnitColor: '#757575',

    BarSeriesPosition: 'above' 
  },

  // ----------------------------------------------- LEGENDS
  Legends: {
    LegendDisplay: 'Y',
    LegendX: 633,
    LegendY: 138,
    LegendDirection: 'Vertical',
    LegendBasepoint: 'end',

    LegendTitleDisplay: 'Y',
    LegendTitleFont: 'roboto',
    LegendTitleStyle: 'regular',
    LegendTitleSize: 12,
    LegendTitleColor: '#546e7a',

    LegendBulletDisplay: 'Y',
    LegendBulletType: 'square',
    LegendBulletSize: 10,
    LegendBulletSpace: 5,

    LegendSeriesFont: 'roboto',
    LegendSeriesStyle: 'regular',
    LegendSeriesSize: 10,
    LegendSeriesColor: '#546e7a',
    LegendSeriesSpace: 10,

    LegendValueDisplay: 'Y',
    LegendValueSize: 10,
    LegendValueColor: '#546e7a',
    LegendValueRound: 1,

    LegendUnitDisplay: 'Y',
    LegendUnitFormat: '%',
    LegendUnitSize: 8,
    LegendUnitColor: '#546e7a',

    LegendLineSpace: 5
  },

  // ----------------------------------------------- NOTE
  Note: {
    NoteDisplay: 'Y',
    NoteX: 633,
    NoteY: 358,
    NoteAlign: 'start',
    NoteTitleText: 'Notes.',
    NoteTitleFont: 'roboto',
    NoteTitleStyle: 'regular',
    NoteTitleSize: 11,
    NoteTitleColor: '#757575',

    NoteContentsText: 'Note Contents Here!',
    NoteContentsFont: 'roboto',
    NoteContentsStyle: 'regular',
    NoteContentsSize: 10,
    NoteContentsColor: '#BDBCBC'
  },

  DataItems: [
    { title: 'Thistle', value1: 75.4, value2: 81.5, value3: 41.5 },
    { title: 'Purple', value1: 45.7, value2: 12.5, value3: 64.5 },
    { title: 'Indigo.', value1: 20.1, value2: 34.5, value3: 23.5 },
    { title: 'LightBlue', value1: 60.5, value2: 7.5, value3: 41.5 },
    { title: 'SkyBlue.', value1: 100, value2: 65.5, value3: 72.5 },
    { title: 'SteelBlue.', value1: 75.4, value2: 15.5, value3: 75.5 }
    // { title: 'YellowGreen', value1: 45.7, value2: 61.5, value3: 65.5 },
    // { title: 'DarkGreen', value1: 45.7, value2: 42.5, value3: 41.5 }
  ]
}
