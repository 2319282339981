import JChartTabNavigation from '@/components/chart/tabs/JChartTabNavigation'
import JChartTabDatabase from '@/components/chart/tabs/JChartTabDatabase'
import JChartTabCanvas from '@/components/chart/tabs/JChartTabCanvas'
import JChartTabTitle from '@/components/chart/tabs/JChartTabTitle'

import JChartTabRadar from './JChartTabRadar'

export default {
  JChartTabNavigation,
  JChartTabDatabase,
  JChartTabCanvas,
  JChartTabTitle,

  JChartTabRadar
}
