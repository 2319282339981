import { easeElastic } from 'd3';

export default {
  // ----------------------------------------------- DATABASE
  Database: {
    DatabasePreviewMode: 'Chart',
    DatabaseAccessMode: 'Json',
    DatabaseJson: '',
    DatabaseQuery: '',
    DatabaseSP: ''
  },

  // ----------------------------------------------- CANVAS
  Canvas: {
    CanvasWidth: 300,
    CanvasHeight: 350,
    CanvasBorderWeight: 1,
    CanvasBorderColor: '#757575',
    // CanvasFillColor: 'Transparent',
    CanvasFillColor: '#fff',
    CanvasOpacity: 0,

    CanvasChartAreaAllowed: 'N',
    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 0,
    CanvasChartHeight: 0,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: 'transparent',
    CanvasChartFillColor: 'transparent',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto'
  },

  // ----------------------------------------------- BAR
  FloatBar: [
    {
      BarX: 24,
      BarY: 63,
      BarWidth: 250,
      BarHeight: 40,
      BarColorType: 'LinearA2',
      BarColor: 'YellowGreen',
      BarBgColor: '#efefef',
      BarBorder: 0,
      BarBorderColor: '#ffee58',
      BarRadius: 0,
      BarOpacity: 1,
    }
  ],
  FloatBarSeries: [
    {
      total: {
        BarSeriesDisplay: 'Y',
        BarSeriesX: 200,
        BarSeriesY: 124,
        BarSeriesFont: 'roboto',
        BarSeriesClass: 'regular',
        BarSeriesSize: 12,
        BarSeriesColor: '#000000',
        BarSeriesAlign: 'start',
        BarSeriesUnit: '%',
      },
      actual: {
        BarSeriesDisplay: 'Y',
        BarSeriesX: 24,
        BarSeriesY: 57,
        BarSeriesFont: 'roboto',
        BarSeriesClass: 'regular',
        BarSeriesSize: 12,
        BarSeriesColor: '#000000',
        BarSeriesAlign: 'start',
        BarSeriesUnit: '%',
      },
      prog: { 
        BarSeriesDisplay: 'Y',
        BarSeriesX: 30,
        BarSeriesY: 25,
        BarSeriesFont: 'roboto',
        BarSeriesClass: 'regular',
        BarSeriesSize: 12,
        BarSeriesColor: '#000000',
        BarSeriesAlign: 'start',
        BarSeriesUnit: '%',
      },
    }
  ],
  FloatLine: [
    {
      LineX1: 149,
      LineY1: 140,
      LineWidth: 140,
      LineDirection: 'Vertical',
      LineStrokeColor: '#000',
      LineStrokeWidth: 1,
      LineOpacity: 0,
    }
  ],
  FloatBox: [
    {
      BoxX: 250,
      BoxY: 9,
      BoxWidth: 40,
      BoxHeight: 40,
      BoxColor: '#eee',
      BoxStrokeColor: '#000',
      BoxStrokeWidth: 1,
      BoxRadius:10,
      BoxOpacity: 1,
    }
  ],
  Text: {
    Text: [
      { text: 'ITRs Completed', x: 64, y: 43, font: 'roboto', class: 'regular', size: 12, color: '#000', align: 'start'},
      { text: 'Total', x: 166, y: 110, font: 'roboto', class: 'regular', size: 12, color: '#000', align: 'start'},
      { text: 'ITRs', x: 243, y: 110, font: 'roboto', class: 'regular', size: 12, color: '#000', align: 'start'},
      { text: 'Average ITRs Completed per Week (Last 4Weeks)', x: 27, y: 168, font: 'roboto', class: 'regular', size: 11, color: '#000', align: 'start'},
      { text: '2,535 ITRs / Week', x: 56, y: 191, font: 'roboto', class: 'regular', size: 22, color: '#4b7bec', align: 'start'},
      { text: 'Overdue ITRs Qty (Walkdown date basis)', x: 27, y: 261, font: 'roboto', class: 'regular', size: 11, color: '#000', align: 'start'},
      { text: '1,534 ITRs Delayed', x: 55, y: 287, font: 'roboto', class: 'regular', size: 22, color: '#ff4336', align: 'start'},
    ],
    Filter: [
    ],
    Sql: [
      // {
      //   query: '', 
      //   text: 'PBF COMPESSOR AIR COOLER - TRAIN 4',
      //   x: 50,
      //   y: 50,
      //   font: 'roboto',
      //   style: 'regular',
      //   size: 14,
      //   color: '#757575',
      //   align: 'start',
      //   direction: 'Horizontal'
      // },
    ]
  },

  DataItems: [
    {total: 157697, actual: 83908, },
    {total: 100, actual: 50, },
  ]
}
