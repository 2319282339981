<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Line</span>
    </div>
    <j-alert
      v-model="msgOpen"
      :title="msgInfo.title"
      :type="msgInfo.type"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
    <div class="chart_properties_contents">
      <div class="header">
        <span>
          Line
          <span data-grid-quickbtn="btn" class="label jcon_temp_plus type_header" style="color: #4298c6;" @click="appendAttr('Line')"><small style="padding-left: 0.1rem;">Add</small></span>
        </span>
        <a class="close" @click="collapsed['line'] = !collapsed['line']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['line']">
        <div v-for="(attr, i) in localFloatLineAttrs" :key="`_header_${i}`">
          <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'Line')">{{ i + 1 }}{{ surfix(i) }}</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">X1 / Y1</label>
              <div class="row">
                <input v-model="attr.LineX1" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'LineX1', null, 'num')" />
                <input v-model="attr.LineY1" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'LineY1', null, 'num')" />
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Length</label>
              <div class="row">
                <input v-model="attr.LineWidth" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'LineWidth', null, 'num')" />
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Direction</label>
              <div class="row">
                <label v-for="option in directionOptions" :key="option.index" class="type_many">
                  <input v-model="attr.LineDirection" type="radio" dir="rtl" :value="option.value" :name="`attr_align_${i}`" @input="(e) => updateAttr(e.target.value, i, 'LineDirection', null)"/>
                  {{option.text}}
                </label>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Stroke / Stroke Color</label>
              <div class="row">
                <input v-model="attr.LineStrokeWidth" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'LineStrokeWidth', null, 'num')" />
                <j-color-picker v-model="attr.LineStrokeColor" @input="updateAttr"/>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Opacity</label>
              <div class="row">
                <input v-model="attr.LineOpacity" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'LineOpacity', null, 'num')" />
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div style="height: 10rem;"/>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import _ChartDataDefault from '@/primitives/_chartFloatingDefault'
import { mapState, mapMutations } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-line',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      line: true,
    },
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    localFloatLineAttrs: [],

    tabActivator: null,

    msgOpen: false,
    yes: null
  }),
  watch: {
    'chartItem.FloatLine': {
      handler(val) {
        if (!val) return
        this.localFloatLineAttrs = JSON.parse(JSON.stringify(val))
      },
      deep: true
    },
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
    
    appendAttr(type) {
      this.localFloatLineAttrs.push(_ChartDataDefault.FloatLine[0])
      this.updateAttr()
    },
    clickRemoveBtn(i, type) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    removeAttr(index) {
      this.localFloatLineAttrs[index] = null
      this.localFloatLineAttrs = this.localFloatLineAttrs.filter(attr => !!attr)

      this.updateAttr()
    },
    surfix(i) {
      let surfix_ = ['st', 'nd', 'rd', 'th']
      if (i < 3) return surfix_[i]
      return surfix_[3]
    },
    updateAttr(val, i, el, key, dataType) {
      if(el) {
        if(dataType == 'num') { 
          this.localFloatLineAttrs[i][el] = parseFloat(val)
        }
        else {
          this.localFloatLineAttrs[i][el] = val
        }
      }
      this.setChartItem({ FloatLine: JSON.parse(JSON.stringify(this.localFloatLineAttrs)) })
    },
  }
}
</script>
