export default {
  // ----------------------------------------------- DATABASE
  Database: {
    DatabasePreviewMode: 'Chart',
    DatabaseAccessMode: 'Json',
    DatabaseJson: '',
    DatabaseQuery: '',
    DatabaseSP: ''
  },

  // ----------------------------------------------- CANVAS
  Canvas: {
    CanvasWidth: 800,
    CanvasHeight: 500,
    CanvasBorderWeight: 0.5,
    CanvasBorderColor: '#bcbcbc',
    // CanvasFillColor: 'Transparent',
    CanvasFillColor: '#fff',
    CanvasOpacity: 0.2,

    CanvasChartAreaAllowed: 'Y',
    CanvasChartX: 200,
    CanvasChartY: 100,
    CanvasChartWidth: 520,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: 'Transparent',
    CanvasChartOpacity: 0.02,
    CanvasChartSpace: 20,

    CanvasFontFamily: 'roboto'
  },

  // ----------------------------------------------- AXES
  Axis: {
    AxisYLevelDisplay: 'Y',
    AxisYLevelPosition: 'left',
    AxisYLevelSpace: 10,
    AxisYLevelFont: 'roboto',
    AxisYLevelStyle: 'regular',
    AxisYLevelSize: 12,
    AxisYLevelWeight: 0.5,
    AxisYLevelAutoColor: 'Y',

    AxisYLevelColor: '#E0E0E0',
    AxisYLevelUnitSize: 12,
    AxisYLevelUnitFormat: '%',

    AxisYLineDisplay: 'N',
    AxisYLineWeight: 1,
    AxisYLineColor: '#ddd',

    AxisYGridDisplay: 'N',
    AxisYGridWeight: 1,
    AxisYGridColor: '#ddd',

    AxisYBoundsAuto: 'N',
    AxisYBoundsMax: 100
  },

  // ----------------------------------------------- TITLE
  Title: {
    TitleMainDisplay: 'Y',
    TitleMainText: 'Spool Installation',
    TitleMainX: 400,
    TitleMainY: 50,
    TitleMainFont: 'roboto',
    TitleMainStyle: 'regular',
    TitleMainSize: 24,
    TitleMainColor: '#44A9DF',
    TitleMainAlign: 'middle',

    TitleSubDisplay: 'Y',
    TitleSubText: 'MD2 Project',
    TitleSubX: 400,
    TitleSubY: 70,
    TitleSubFont: 'roboto',
    TitleSubStyle: 'regular',
    TitleSubSize: 12,
    TitleSubColor: '#BDBCBC',
    TitleSubAlign: 'middle',

    TitleExtraDisplay: 'N',
    TitleExtraText: 'Discipline',
    TitleExtraX: 400,
    TitleExtraY: 430,
    TitleExtraFont: 'roboto',
    TitleExtraStyle: 'regular',
    TitleExtraSize: 14,
    TitleExtraColor: 'gray',
    TitleExtraAlign: 'middle',

    TitleSqlDisplay: 'N',
    TitleSqlText: 'SQL Query...',
    TitleSqlQuery: 'SQL Query...',
    TitleSqlX: 400,
    TitleSqlY: 90,
    TitleSqlFont: 'roboto',
    TitleSqlStyle: 'regular',
    TitleSqlSize: 11,
    TitleSqlColor: 'pink',
    TitleSqlAlign: 'middle',

    TitleLineDisplay: 'N',
    TitleLineX: 30,
    TitleLineY: 30,
    TitleLineLength: 100,
    TitleLineWeight: 1,
    TitleLineColor: 'black'
  },

  // ----------------------------------------------- BAR
  Bar: {
    BarAutoSize: 'Y',
    BarThickness: 100,
    BarDistance: 40,
    BarRadius: 0, // Embossed only
    BarBasedCylinder: 30, // Tube only
    BarColorType: 'LinearA9',
    BarColorSet: ['Gold', 'Orange', 'Pink', 'Gray', 'SkyBlue', 'LightBlue', 'YellowGreen', 'Red', 'Thistle', 'Purple', 'Indigo', 'LightBlue', 'SkyBlue', 'SteelBlue', 'YellowGreen', 'DarkGreen'],
    BarReflection: 'Y',

    BarBackDisplay: 'Y',
    BarBackColor: '#efefef',

    BarSeriesDisplay: 'Y',
    BarSeriesSpace: 5,
    BarSeriesFont: 'roboto',
    BarSeriesStyle: 'regular',
    BarSeriesSize: 12,
    BarSeriesAutoColor: 'Y',
    BarSeriesColor: '#757575',

    BarValueDisplay: 'Y',
    BarValueFont: 'roboto',
    BarValueStyle: 'regular',
    BarValueSize: 12,
    BarValueRound: 0,
    BarValueAutoColor: 'Y',
    BarValueColor: '#757575',
    BarValuePosition: 'top',
    BarValueSpace: 7, // Tube only

    BarUnitDisplay: 'Y',
    BarUnitFormat: '%',
    BarUnitSize: 11,
    BarUnitColor: '#757575'
  },

  // ----------------------------------------------- LEGENDS
  Legends: {
    LegendDisplay: 'N',
    LegendX: 50,
    LegendY: 133,
    LegendDirection: 'Vertical',
    LegendBasepoint: 'end',

    LegendTitleDisplay: 'Y',
    LegendTitleFont: 'roboto',
    LegendTitleStyle: '',
    LegendTitleSize: 12,
    LegendTitleColor: '#757575',

    LegendBulletDisplay: 'Y',
    LegendBulletType: 'square',
    LegendBulletSize: 10,
    LegendBulletSpace: 5,

    LegendSeriesFont: 'roboto',
    LegendSeriesStyle: 'regular',
    LegendSeriesSize: 10,
    LegendSeriesColor: '#757575',
    LegendSeriesSpace: 10,

    LegendValueDisplay: 'N',
    LegendValueSize: 10,
    LegendValueColor: '#44A9DF',
    LegendValueRound: 1,

    LegendUnitDisplay: 'N',
    LegendUnitFormat: '%',
    LegendUnitSize: 8,
    LegendUnitColor: '#757575',

    LegendLineSpace: 5
  },

  // ----------------------------------------------- NOTE
  Note: {
    NoteDisplay: 'N',
    NoteX: 50,
    NoteY: 361,
    NoteAlign: 'start',
    NoteTitleText: 'Notes.',
    NoteTitleFont: 'roboto',
    NoteTitleStyle: 'regular',
    NoteTitleSize: 11,
    NoteTitleColor: '#757575',

    NoteContentsText: 'Note Contents Here!',
    NoteContentsFont: 'roboto',
    NoteContentsStyle: 'regular',
    NoteContentsSize: 10,
    NoteContentsColor: '#BDBCBC'
  },

  DataItems: [
    { title: 'Thistle',     value: 75.4,  delta: 0.5 },
    { title: 'Purple',      value: 45.7,  delta: -0.5 },
    { title: 'Indigo.',     value: 20.1,  delta: 1.2 },
    { title: 'LightBlue',   value: 60.5,  delta: 2.4 },
    { title: 'SkyBlue.',    value: 100,   delta: -1.5 },
    { title: 'SteelBlue.',  value: 75.4,  delta: -30 },
    { title: 'YellowGreen', value: 45.7,  delta: 1.8 },
    { title: 'DarkGreen',   value: 45.7,  delta: 1.2 }
  ]
}
