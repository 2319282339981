<template>
  <v-flex v-model="bar3d_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Legend</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        General
        <a class="close" v-on:click="collapsed['general'] = !collapsed['general']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['general']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="LegendDisplay" type="radio" name="LegendDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input column">
          <span class="label">Direction</span>
          <div class="row justify_end">
            <label v-for="option in directionOptions" :key="option.index" class="type_many">
              <input
                v-model="LegendDirection"
                type="radio"
                name="LegendDirection"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="LegendX" type="text" class="type_double" />
          <input v-model="LegendY" type="text" />
        </div>
        <div>
          <span class="label">Title</span>
          <div class="type_inner">
            <div class="type_common_select type_gray">
              <label>Font</label>
              <select v-model="LegendTitleFont" dir="rtl">
                <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_select type_gray">
              <label>Style</label>
              <select v-model="LegendTitleStyle" dir="rtl" class="type70">
                <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_input type_color_float type_gray">
              <label>Size / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="LegendTitleSize"  type="text" />
                <j-color-picker v-model="LegendTitleColor"></j-color-picker>
              </div>
            </div>
            <div class="type_common_input type_gray">
              <label>Space</label>
              <input v-model="LegendTitleSpace" type="text" />
            </div>
          </div>
          <div class="type_common_input">
            <label>Tap Size</label>
            <input v-model="LegendTapSpace" type="text" />
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Group
        <a class="close" v-on:click="collapsed['group'] = !collapsed['group']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['group']">
        <div class="type_common_input">
          <span class="label">Category</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="LegendGroupCatDisplay"
                type="radio"
                name="LegendGroupCatDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_inner">
          <input
            v-model="LegendGroupCatText"
            class="type100 type_gray"
            placeholder="Category Text"
            type="text"
          />
        </div>
        <div class="type_common_input">
          <span class="label">Status</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="LegendGroupStatusDisplay"
                type="radio"
                name="LegendGroupStatusDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_inner">
          <input
            v-model="LegendGroupStatusText"
            class="type100 type_gray"
            placeholder="Category Text"
            type="text"
          />
        </div>
        <div class="spacer_v_10"></div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="LegendGroupFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="LegendGroupStyle" dir="rtl" class="type70">
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input type_color_float">
          <label>Size / Color</label>
          <div class="input_flex_wrap_end">
            <input v-model="LegendGroupSize"  type="text" />
            <j-color-picker v-model="LegendGroupColor"></j-color-picker>
          </div>
        </div>
        <div class="type_common_input">
          <label>Space</label>
          <input v-model="LegendGroupSpace" type="text" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Bullets
        <a class="close" v-on:click="collapsed['bullet'] = !collapsed['bullet']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['bullet']">
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="LegendBulletSize" type="text" />
        </div>
        <div class="type_common_input">
          <label>Space</label>
          <input v-model="LegendBulletSpace" type="text" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Text
        <a class="close" v-on:click="collapsed['text'] = !collapsed['text']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['text']">
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="LegendTextFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="LegendTextStyle" dir="rtl" class="type70">
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input type_color_float">
          <label>Size / Color</label>
          <div class="input_flex_wrap_end">
            <input v-model="LegendTextSize"  type="text" />
            <j-color-picker v-model="LegendTextColor"></j-color-picker>
          </div>
        </div>
        <div class="type_common_input">
          <label>Space</label>
          <input v-model="LegendTextSpace" type="text" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Value
        <a class="close" v-on:click="collapsed['value'] = !collapsed['value']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['value']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="LegendValueDisplay" type="radio" name="LegendValueDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="LegendValueStyle" dir="rtl" class="type70">
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input type_color_float">
          <label>Size / Color</label>
          <div class="input_flex_wrap_end">
            <input v-model="LegendValueSize"  type="text" />
            <j-color-picker v-model="LegendValueColor"></j-color-picker>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-legend',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      general: true,
      group: false,
      bullet: false,
      text: false,
      value: false,
    },

    bar3d_general: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    LegendDisplay: {
      get() { return this.chartItem.LegendDisplay },
      set(val) { this.setChartItem({ LegendDisplay: val }) }
    },
    LegendDirection: {
      get() { return this.chartItem.LegendDirection },
      set(val) { this.setChartItem({ LegendDirection: val }) }
    },
    LegendX: {
      get() { return this.chartItem.LegendX || 0 },
      set(val) { this.setChartItem({ LegendX: parseFloat(val || 0) }) }
    },
    LegendY: {
      get() { return this.chartItem.LegendY || 0 },
      set(val) { this.setChartItem({ LegendY: parseFloat(val || 0) }) }
    },
    LegendTitleFont: {
      get() { return this.chartItem.LegendTitleFont },
      set(val) { this.setChartItem({ LegendTitleFont: val }) }
    },
    LegendTitleStyle: {
      get() { return this.chartItem.LegendTitleStyle },
      set(val) { this.setChartItem({ LegendTitleStyle: val }) }
    },
    LegendTitleSize: {
      get() { return this.chartItem.LegendTitleSize || 0 },
      set(val) { this.setChartItem({ LegendTitleSize: parseFloat(val || 0) }) }
    },
    LegendTitleColor: {
      get() { return this.chartItem.LegendTitleColor },
      set(val) { this.setChartItem({ LegendTitleColor: val }) }
    },
    LegendTitleSpace: {
      get() { return this.chartItem.LegendTitleSpace || 0 },
      set(val) { this.setChartItem({ LegendTitleSpace: parseFloat(val || 0) }) }
    },
    LegendTapSpace: {
      get() { return this.chartItem.LegendTapSpace || 0 },
      set(val) { this.setChartItem({ LegendTapSpace: parseFloat(val || 0) }) }
    },
    LegendGroupCatDisplay: {
      get() { return this.chartItem.LegendGroupCatDisplay },
      set(val) { this.setChartItem({ LegendGroupCatDisplay: val }) }
    },
    LegendGroupCatText: {
      get() { return this.chartItem.LegendGroupCatText },
      set(val) { this.setChartItem({ LegendGroupCatText: val }) }
    },
    LegendGroupStatusDisplay: {
      get() { return this.chartItem.LegendGroupStatusDisplay },
      set(val) { this.setChartItem({ LegendGroupStatusDisplay: val }) }
    },
    LegendGroupStatusText: {
      get() { return this.chartItem.LegendGroupStatusText },
      set(val) { this.setChartItem({ LegendGroupStatusText: val }) }
    },
    LegendGroupFont: {
      get() { return this.chartItem.LegendGroupFont },
      set(val) { this.setChartItem({ LegendGroupFont: val }) }
    },
    LegendGroupStyle: {
      get() { return this.chartItem.LegendGroupStyle },
      set(val) { this.setChartItem({ LegendGroupStyle: val }) }
    },
    LegendGroupSize: {
      get() { return this.chartItem.LegendGroupSize || 0 },
      set(val) { this.setChartItem({ LegendGroupSize: parseFloat(val || 0) }) }
    },
    LegendGroupColor: {
      get() { return this.chartItem.LegendGroupColor || 0 },
      set(val) { this.setChartItem({ LegendGroupColor: val }) }
    },
    LegendGroupSpace: {
      get() { return this.chartItem.LegendGroupSpace || 0 },
      set(val) { this.setChartItem({ LegendGroupSpace: parseFloat(val || 0) }) }
    },
    LegendBulletSize: {
      get() { return this.chartItem.LegendBulletSize || 0 },
      set(val) { this.setChartItem({ LegendBulletSize: parseFloat(val || 0) }) }
    },
    LegendBulletSpace: {
      get() { return this.chartItem.LegendBulletSpace || 0 },
      set(val) { this.setChartItem({ LegendBulletSpace: parseFloat(val || 0) }) }
    },
    LegendTextFont: {
      get() { return this.chartItem.LegendTextFont },
      set(val) { this.setChartItem({ LegendTextFont: val }) }
    },
    LegendTextStyle: {
      get() { return this.chartItem.LegendTextStyle },
      set(val) { this.setChartItem({ LegendTextStyle: val }) }
    },
    LegendTextSize: {
      get() { return this.chartItem.LegendTextSize || 0 },
      set(val) { this.setChartItem({ LegendTextSize: parseFloat(val || 0) }) }
    },
    LegendTextColor: {
      get() { return this.chartItem.LegendTextColor },
      set(val) { this.setChartItem({ LegendTextColor: val }) }
    },
    LegendTextSpace: {
      get() { return this.chartItem.LegendTextSpace || 0 },
      set(val) { this.setChartItem({ LegendTextSpace: parseFloat(val || 0) }) }
    },
    LegendValueDisplay: {
      get() { return this.chartItem.LegendValueDisplay },
      set(val) { this.setChartItem({ LegendValueDisplay: val }) }
    },
    LegendValueStyle: {
      get() { return this.chartItem.LegendValueStyle },
      set(val) { this.setChartItem({ LegendValueStyle: val }) }
    },
    LegendValueSize: {
      get() { return this.chartItem.LegendValueSize || 0 },
      set(val) { this.setChartItem({ LegendValueSize: parseFloat(val || 0) }) }
    },
    LegendValueColor: {
      get() { return this.chartItem.LegendValueColor },
      set(val) { this.setChartItem({ LegendValueColor: val }) }
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
  }
}
</script>
