<template>
  <v-flex v-model="bar3d_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Package</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Package
        <a class="close" v-on:click="collapsed['package'] = !collapsed['package']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['package']">
        <div class="type_common_input">
          <span class="label" :style="modeMulti ? 'margin-bottom: 7px;' : ''">Apply</span>
          <div v-if="modeMulti">
            <small class="comment">Not available on the 'Multi' mode.</small>
          </div>
          <div v-else class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="PackageApply" type="radio" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Left Space</label>
          <input v-model="PackageSpace" type="number" />
        </div>
        <div class="type_common_input">
          <label>Chart Gap</label>
          <input v-model="PackageGap" type="number" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Title
        <small class="type_ref03">(Legends)</small>
        <a class="close" v-on:click="collapsed['title'] = !collapsed['title']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['title']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="PackageTitleDisplay" type="radio" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Coordinate
            <label class="type_gray">(y)</label>
            </label>
          <input v-model="PackageTitleY" type="text" />
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="PackageTitleFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="PackageTitleStyle" dir="rtl" class="type70">
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input type_color_float">
          <label>Size / Color</label>
          <div class="input_flex_wrap_end">
            <input v-model="PackageTitleSize" type="number" />
            <j-color-picker v-model="PackageTitleColor"></j-color-picker>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Text Area
        <a class="close" v-on:click="collapsed['textArea'] = !collapsed['textArea']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['textArea']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="PackageTextAreaDisplay" type="radio" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div>
          <span class="label">Header</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">Text</label>
              <input class="type120" v-model="PackageHeadText" type="text" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Coordinate(y)</label>
              <input v-model="PackageHeadY" type="number" />
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Font</label>
              <select v-model="PackageHeadFont" dir="rtl">
                <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Style</label>
              <select v-model="PackageHeadStyle" dir="rtl" class="type70">
                <option
                  v-for="option in fontStyleOptions"
                  :key="option.index"
                  :value="option.value"
                >{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Size / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="PackageHeadSize" type="number" />
                <j-color-picker v-model="PackageHeadColor"></j-color-picker>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span class="label">Subject</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">Text</label>
              <input class="type120" v-model="PackageSubjectText" type="text" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Coordinate(y)</label>
              <input v-model="PackageSubjectY" type="number" />
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Font</label>
              <select v-model="PackageSubjectFont" dir="rtl">
                <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Style</label>
              <select v-model="PackageSubjectStyle" dir="rtl" class="type70">
                <option
                  v-for="option in fontStyleOptions"
                  :key="option.index"
                  :value="option.value"
                >{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Size / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="PackageSubjectSize" type="number" />
                <j-color-picker v-model="PackageSubjectColor"></j-color-picker>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span class="label">Grid</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">Coordinate(y)</label>
              <input v-model="PackageGridY" type="number" />
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Length</label>
              <input v-model="PackageGridLength" type="number" />
            </div>
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Stroke / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="PackageGridStroke" type="number" />
                <j-color-picker v-model="PackageGridColor"></j-color-picker>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span class="label">Value</span>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">Coordinate(y)</label>
              <input v-model="PackageValueY" type="number" />
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Font</label>
              <select v-model="PackageValueFont" dir="rtl">
                <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Style</label>
              <select v-model="PackageValueStyle" dir="rtl" class="type70">
                <option
                  v-for="option in fontStyleOptions"
                  :key="option.index"
                  :value="option.value"
                >{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Size / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="PackageValueSize" type="number" />
                <j-color-picker v-model="PackageValueColor"></j-color-picker>
              </div>
            </div>
            <label class="label type_gray">SQL (Query)</label>
            <div class="type_common_input" style="justify-content: flex-end;">
              <div class="row">
                <div justify="center" style="align-items: center">
                  <v-btn
                    @click.stop="exp['sql'] = true"
                    class="type_openTextarea"
                  >Spool Intallaition</v-btn>
                  <v-dialog v-model="exp['sql']" max-width="800" content-class="j_modal editor">
                    <v-card>
                      <v-card-title class="modal__header type_gray">
                        <span>
                          Access Database
                          <small>sql</small>
                        </span>
                        <j-button class="modal_close" @click="exp['sql'] = false">
                          <v-icon>mdi-close</v-icon>
                        </j-button>
                      </v-card-title>
                      <v-card-text>
                        <textarea
                          v-model="PackageValueSQL"
                          name
                          class="type_exp"
                          placeholder="sql"
                        ></textarea>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <j-button class="type01 sky" text @click="exp['sql'] = false">Save</j-button>
                        <j-button class="type01" text @click="exp['sql'] = false">Cancel</j-button>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>
<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-package',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      package: true,
      title: false,
      textArea: false,
      sql: false,
    },
    exp: {
      sql: false,
    },

    bar3d_general: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    modeMulti() { return this.chartItem.CircleType == __C.CHART.TYPE_CODE_MULTI },

    PackageApply: {
      get() { return this.chartItem.PackageApply },
      set(val) { this.setChartItem({ PackageApply: val })}
    },
    PackageSpace: {
      get() { return this.chartItem.PackageSpace || 0 },
      set(val) { this.setChartItem({ PackageSpace: parseFloat(val || 0) })}
    },
    PackageGap: {
      get() { return this.chartItem.PackageGap || 0 },
      set(val) { this.setChartItem({ PackageGap: parseFloat(val || 0) })}
    },
    PackageTitleDisplay: {
      get() { return this.chartItem.PackageTitleDisplay },
      set(val) { this.setChartItem({ PackageTitleDisplay: val })}
    },
    PackageTitleY: {
      get() { return this.chartItem.PackageTitleY || 0 },
      set(val) { this.setChartItem({ PackageTitleY: parseFloat(val || 0) })}
    },
    PackageTitleFont: {
      get() { return this.chartItem.PackageTitleFont },
      set(val) { this.setChartItem({ PackageTitleFont: val })}
    },
    PackageTitleStyle: {
      get() { return this.chartItem.PackageTitleStyle },
      set(val) { this.setChartItem({ PackageTitleStyle: val })}
    },
    PackageTitleSize: {
      get() { return this.chartItem.PackageTitleSize || 0 },
      set(val) { this.setChartItem({ PackageTitleSize: parseFloat(val || 0) })}
    },
    PackageTitleColor: {
      get() { return this.chartItem.PackageTitleColor },
      set(val) { this.setChartItem({ PackageTitleColor: val })}
    },
    PackageTextAreaDisplay: {
      get() { return this.chartItem.PackageTextAreaDisplay },
      set(val) { this.setChartItem({ PackageTextAreaDisplay: val })}
    },
    PackageHeadText: {
      get() { return this.chartItem.PackageHeadText },
      set(val) { this.setChartItem({ PackageHeadText: val })}
    },
    PackageHeadY: {
      get() { return this.chartItem.PackageHeadY || 0 },
      set(val) { this.setChartItem({ PackageHeadY: parseFloat(val || 0) })}
    },
    PackageHeadFont: {
      get() { return this.chartItem.PackageHeadFont },
      set(val) { this.setChartItem({ PackageHeadFont: val })}
    },
    PackageHeadStyle: {
      get() { return this.chartItem.PackageHeadStyle },
      set(val) { this.setChartItem({ PackageHeadStyle: val })}
    },
    PackageHeadSize: {
      get() { return this.chartItem.PackageHeadSize || 0 },
      set(val) { this.setChartItem({ PackageHeadSize: parseFloat(val || 0) })}
    },
    PackageHeadColor: {
      get() { return this.chartItem.PackageHeadColor },
      set(val) { this.setChartItem({ PackageHeadColor: val })}
    },
    PackageSubjectText: {
      get() { return this.chartItem.PackageSubjectText },
      set(val) { this.setChartItem({ PackageSubjectText: val })}
    },
    PackageSubjectY: {
      get() { return this.chartItem.PackageSubjectY || 0 },
      set(val) { this.setChartItem({ PackageSubjectY: parseFloat(val || 0) })}
    },
    PackageSubjectFont: {
      get() { return this.chartItem.PackageSubjectFont },
      set(val) { this.setChartItem({ PackageSubjectFont: val })}
    },
    PackageSubjectStyle: {
      get() { return this.chartItem.PackageSubjectStyle },
      set(val) { this.setChartItem({ PackageSubjectStyle: val })}
    },
    PackageSubjectSize: {
      get() { return this.chartItem.PackageSubjectSize || 0 },
      set(val) { this.setChartItem({ PackageSubjectSize: parseFloat(val || 0) })}
    },
    PackageSubjectColor: {
      get() { return this.chartItem.PackageSubjectColor },
      set(val) { this.setChartItem({ PackageSubjectColor: val })}
    },
    PackageGridY: {
      get() { return this.chartItem.PackageGridY || 0 },
      set(val) { this.setChartItem({ PackageGridY: parseFloat(val || 0) })}
    },
    PackageGridLength: {
      get() { return this.chartItem.PackageGridLength || 0 },
      set(val) { this.setChartItem({ PackageGridLength: parseFloat(val || 0) })}
    },
    PackageGridStroke: {
      get() { return this.chartItem.PackageGridStroke || 0 },
      set(val) { this.setChartItem({ PackageGridStroke: parseFloat(val || 0) })}
    },
    PackageGridColor: {
      get() { return this.chartItem.PackageGridColor },
      set(val) { this.setChartItem({ PackageGridColor: val })}
    },
    PackageValueY: {
      get() { return this.chartItem.PackageValueY || 0 },
      set(val) { this.setChartItem({ PackageValueY: parseFloat(val || 0) })}
    },
    PackageValueFont: {
      get() { return this.chartItem.PackageValueFont },
      set(val) { this.setChartItem({ PackageValueFont: val })}
    },
    PackageValueStyle: {
      get() { return this.chartItem.PackageValueStyle },
      set(val) { this.setChartItem({ PackageValueStyle: val })}
    },
    PackageValueSize: {
      get() { return this.chartItem.PackageValueSize || 0 },
      set(val) { this.setChartItem({ PackageValueSize: parseFloat(val || 0) })}
    },
    PackageValueColor: {
      get() { return this.chartItem.PackageValueColor },
      set(val) { this.setChartItem({ PackageValueColor: val })}
    },
    PackageValueSQL: {
      get() { return this.chartItem.PackageValueSQL },
      set(val) { this.setChartItem({ PackageValueSQL: val })}
    },

  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
  }
}
</script>