<template>
  <v-flex v-model="pie_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Circle</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        General
        <a class="close" v-on:click="collapsed['general'] = !collapsed['general']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['general']">
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="CircleX" type="text" class="type_double" />
          <input v-model="CircleY" type="text" />
        </div>
        <div class="type_common_input">
          <label>Diameter</label>
          <input v-model="CircleDiameter" type="number" />
        </div>
        <div class="type_common_input">
          <label>Radius</label>
          <input v-model="CircleRadius" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker
            v-model="CircleColorSet"
            multi
            gradation
            title="Front Color"
            :gradation-type="CircleColorType"
            @gradate="v => CircleColorType = v"
          ></j-color-picker>
        </div>
      </v-flex>
    </div>
    <v-flex v-if="!chartTypeNormal" class="chart_properties_contents">
      <div class="header">
        Hole
        <small class="type_ref03">(inside)</small>
        <a class="close" v-on:click="collapsed['hole'] = !collapsed['hole']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['hole']">
        <div class="type_common_input">
          <span class="label">Radius Size</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input type="radio" v-model="circleHole" name="circleHole" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label></label>
          <input v-model="CircleHoleSize" type="number" />
        </div>
      </v-flex>
    </v-flex>
    <div v-if="chartTypeStack" class="chart_properties_contents">
      <div class="header">
        Stepped
        <a class="close" v-on:click="collapsed['step'] = !collapsed['step']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['step']">
        <div class="type_common_input">
          <span class="label">Size</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input type="radio" v-model="circleStack" name="circleStack" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label></label>
          <input v-model="CircleStackSize" type="number" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Slice
        <a class="close" v-on:click="collapsed['slice'] = !collapsed['slice']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['slice']">
        <div class="type_common_input">
          <span class="label">Size</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input type="radio" v-model="circleSlice" name="circleSlice" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label></label>
          <input v-model="CircleSliceSize" type="number" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        DropShadow
        <a class="close" v-on:click="collapsed['dropShadow'] = !collapsed['dropShadow']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['dropShadow']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                type="radio"
                v-model="CircleDropshadowDisplay"
                name="CircleDropshadowDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Deviation</label>
          <input v-model="CircleDropshadowDeviation" type="number" />
        </div>
        <div class="type_common_input">
          <label>Opacity</label>
          <input v-model="CircleDropshadowOpacity" type="number" />
        </div>
        <div class="type_common_input">
          <label for>X / Y</label>
          <input v-model="CircleDropshadowX" type="text" class="type_double" />
          <input v-model="CircleDropshadowY" type="text" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Shadow
        <a class="close" v-on:click="collapsed['shadow'] = !collapsed['shadow']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['shadow']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                type="radio"
                v-model="CircleShadowDisplay"
                name="CircleShadowDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import ChartlibMethods from '@/mixins/chartlibMethods'
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-circle',
  mixins: [
    StaticOptions,
    ChartlibMethods
  ],
  data: () => ({
    pie_general: null,
    collapsed: {
      general: false,
      hole: false,
      step: false,
      slice: false,
      dropShadow: false,
      shadow: false,
    },
    currentIcon: null,
    circleHole: 'N',
    circleStack: 'N',
    circleSlice: 'Y',
  }),
  watch: {
    circleHole(val) { this.CircleHoleSize = val == 'N' ? 0 : (this.circle.CircleHoleSize == 0 ? 1 : this.circle.CircleHoleSize) },
    circleStack(val) { this.CircleStackSize = val == 'N' ? 0 : (this.circle.CircleStackSize == 0 ? 1 : this.circle.CircleStackSize) },
    circleSlice(val) { this.CircleSliceSize = val == 'N' ? 0 : (this.circle.CircleSliceSize == 0 ? 1 : this.circle.CircleSliceSize) }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['circle']),

    chartTypeNormal() { return this.chartItem.ChartType == __C.CHART.TYPE_CODE_NORMAL },
    chartTypeDonut() { return this.chartItem.ChartType == __C.CHART.TYPE_CODE_DONUT },
    chartTypeStack() { return this.chartItem.ChartType == __C.CHART.TYPE_CODE_STEPPED },

    CircleX: {
      get() { return this.circle.CircleX || 0 },
      set(val) { this.setChartItem({ CircleX: parseFloat(val || 0) }) }
    },
    CircleY: {
      get() { return this.circle.CircleY || 0 },
      set(val) { this.setChartItem({ CircleY: parseFloat(val || 0) }) }
    },
    CircleDiameter: {
      get() { return this.circle.CircleDiameter || 0 },
      set(val) { this.setChartItem({ CircleDiameter: parseFloat(val || 0) }) }
    },
    CircleRadius: {
      get() { return this.circle.CircleRadius || 0 },
      set(val) { this.setChartItem({ CircleRadius: parseFloat(val || 0) }) }
    },
    CircleColorType: {
      get() { return this.circle.CircleColorType },
      set(val) { this.setChartItem({ CircleColorType: val }) }
    },
    CircleColorSet: {
      get() { return this.circle.CircleColorSet },
      set(val) { this.setChartItem({ CircleColorSet: val }) }
    },
    CircleHoleSize: {
      get() {
        this.circleHole == this.circle.CircleHoleSize > 0 ? 'Y' : 'N'
        return this.circle.CircleHoleSize || 0
      },
      set(val) { this.setChartItem({ CircleHoleSize: parseFloat(val || 0) }) }
    },
    CircleStackSize: {
      get() {
        this.circleStack == this.circle.CircleStackSize > 0 ? 'Y' : 'N'
        return this.circle.CircleStackSize || 0
      },
      set(val) { this.setChartItem({ CircleStackSize: parseFloat(val || 0) }) }
    },
    CircleSliceSize: {
      get() {
        if (this.setUndef2zero('CircleSliceSize', this.circle.CircleSliceSize)) return

        this.circleSlice == this.circle.CircleSliceSize > 0 ? 'Y' : 'N'
        return this.circle.CircleSliceSize || 0
      },
      set(val) { this.setChartItem({ CircleSliceSize: parseFloat(val || 0) }) }
    },
    CircleDropshadowDisplay: {
      get() { return this.circle.CircleDropshadowDisplay },
      set(val) { this.setChartItem({ CircleDropshadowDisplay: val }) }
    },
    CircleDropshadowDeviation: {
      get() { return this.circle.CircleDropshadowDeviation || 0 },
      set(val) { this.setChartItem({ CircleDropshadowDeviation: parseFloat(val || 0) }) }
    },
    CircleDropshadowOpacity: {
      get() { return this.circle.CircleDropshadowOpacity || 0 },
      set(val) { this.setChartItem({ CircleDropshadowOpacity: parseFloat(val || 0) }) }
    },
    CircleDropshadowX: {
      get() { return this.circle.CircleDropshadowX || 0 },
      set(val) { this.setChartItem({ CircleDropshadowX: parseFloat(val || 0) }) }
    },
    CircleDropshadowY: {
      get() { return this.circle.CircleDropshadowY || 0 },
      set(val) { this.setChartItem({ CircleDropshadowY: parseFloat(val || 0) }) }
    },
    CircleShadowDisplay: {
      get() { return this.circle.CircleShadowDisplay },
      set(val) { this.setChartItem({ CircleShadowDisplay: val }) }
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

  }
}
</script>


