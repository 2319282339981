export default {
  
  ChartType: 'WEEK',

  Database: {
    DatabasePreviewMode: 'Chart',
    DatabaseAccessMode: 'Json',
    DatabaseJson: '',
    DatabaseQuery: '',
    DatabaseSP: '',
  },

  Canvas: {
    CanvasWidth: 330,
    CanvasHeight: 70,
    CanvasBorderWeight: .5,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 330,
    CanvasChartHeight: 75,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  

  Common: {
    area: {
      x:                25,
      y:                10,
      link:             'Y',
      font:             'roboto',
    },
  },

  CTypes: {
    title: {
      tSize:        11,
      lastColor:    '#333',
      thisColor:    '#44A9DF',
    },

    weekend: {
      width:        35,
      tSize:        9,
      tColor:       '#333',
      stroke:       '#44A9DF',
      sWidth:       1,
      opacity:      0.3,
    },

    weekday: {
      width:        25,
      tSize:        9,
      tColor:       '#757575',
      stroke:       '#bcbcbc',
      sWidth:       1,
      opacity:      0.3,
      gap:          4,
    },
    
    value: {
      tSize:        10,
      tColor:       '#757575',
    },
    
  },


  DataItems: [
    {WEEK:"LAST", VALUE:1}, // 'LAST' 수정하지말것
    {WEEK:"SAT",  VALUE:2},
    {WEEK:"SUN",  VALUE:0},
    {WEEK:"MON",  VALUE:4},
    {WEEK:"TUE",  VALUE:5},
    {WEEK:"WEN",  VALUE:6},
    {WEEK:"TUE",  VALUE:7},
    {WEEK:"FRI",  VALUE:8},
    {WEEK:"THIS", VALUE:9}, // 'THIS' 수정하지말것
  ],

}