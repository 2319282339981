<template>
  <v-flex v-model="pie_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>GuideLine</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        General
        <a class="close" v-on:click="collapsed['general'] = !collapsed['general']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['general']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in guideDisplayOptions" :key="option.index" class="type_many">
              <input v-model="GuideDisplay" type="radio" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Weight</label>
          <input v-model="GuideWeight" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker title="Color" v-model="GuideColor"></j-color-picker>
        </div>
        <label>Length</label>
        <div class="type_inner">
          <div class="type_common_input">
            <label class="type_gray">Inside line</label>
            <input v-model="GuideInside" type="number" />
          </div>
          <div class="type_common_input">
            <label class="type_gray">Outside line</label>
            <input v-model="GuideOutside" type="number" />
          </div>
          <div class="type_common_input">
            <label class="type_gray">Extent line</label>
            <input v-model="GuideExtend" type="number" />
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Bullet
        <a class="close" v-on:click="collapsed['bullet'] = !collapsed['bullet']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['bullet']">
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="GuideBulletSize" type="number" />
        </div>
        <div class="type_common_input">
          <label class="type_auto type_ab right30">
            <j-check-box v-model="GuideBulletAutoColor" true-value="Y" false-value="N" />Auto
          </label>
          <j-color-picker v-model="GuideBulletColor" title="Color" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Text
        <a class="close" v-on:click="collapsed['text'] = !collapsed['text']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['text']">
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="GuideTextFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="GuideTextStyle" dir="rtl" class="type70">
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="GuideTextSize" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="GuideTextColor" title="Color" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Value
        <a class="close" v-on:click="collapsed['value'] = !collapsed['value']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['value']">
        <div class="type_common_input">
          <label>Round</label>
          <input v-model="GuideValueRound" type="number" />
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="GuideValueSize" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="GuideValueColor" title="Color" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Unit
        <a class="close" v-on:click="collapsed['unit'] = !collapsed['unit']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['unit']">
        <div class="type_common_input">
          <label>Format</label>
          <input v-model="GuideUnitFormat" type="text" class="type22" />
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="GuideUnitSize" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="GuideUnitColor" title="Color" />
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-pie-guideline',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    pie_general: null,
    collapsed: {
      general: false,
      bullet: false,
      text: false,
      value: false,
      unit: false,
    },
    currentIcon: null,
    guideDisplayOptions: [
      { text: "Yes", value: "Y" },
      { text: "No", value: "N" }
    ],
    guideTypeOptions: [
      { text: "Straight", value: "Straight" },
      { text: "Broken", value: "Broken" }
    ],
    guideBulletDisplayOptions: [
      { text: "Yes", value: "Y" },
      { text: "No", value: "N" }
    ],
    guideBulletOptions: [
      { text: "Square", value: "Square" },
      { text: "Circle", value: "Circle" }
    ],
    guideTextDisplayOptions: [
      { text: "Yes", value: "Y" },
      { text: "No", value: "N" }
    ],
    guideValueDisplayOptions: [
      { text: "Yes", value: "Y" },
      { text: "No", value: "N" }
    ],
    guideUnitDisplayOptions: [
      { text: "Yes", value: "Y" },
      { text: "No", value: "N" }
    ],
    guideAutoColorOptions: [
      { text: "Yes", value: "Y" },
      { text: "No", value: "N" }
    ]
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['guideline']),

    GuideDisplay: {
      get() { return this.guideline.GuideDisplay },
      set(val) { this.setChartItem({ GuideDisplay: val }) }
    },
    GuideWeight: {
      get() { return this.guideline.GuideWeight || 0 },
      set(val) { this.setChartItem({ GuideWeight: parseFloat(val || 0) }) }
    },
    GuideColor: {
      get() { return this.guideline.GuideColor },
      set(val) { this.setChartItem({ GuideColor: val }) }
    },
    GuideInside: {
      get() { return this.guideline.GuideInside || 0 },
      set(val) { this.setChartItem({ GuideInside: parseFloat(val || 0) }) }
    },
    GuideOutside: {
      get() { return this.guideline.GuideOutside || 0 },
      set(val) { this.setChartItem({ GuideOutside: parseFloat(val || 0) }) }
    },
    GuideExtend: {
      get() { return this.guideline.GuideExtend || 0 },
      set(val) { this.setChartItem({ GuideExtend: parseFloat(val || 0) }) }
    },
    GuideBulletSize: {
      get() { return this.guideline.GuideBulletSize || 0 },
      set(val) { this.setChartItem({ GuideBulletSize: parseFloat(val || 0) }) }
    },
    GuideBulletAutoColor: {
      get() { return this.guideline.GuideBulletAutoColor },
      set(val) { this.setChartItem({ GuideBulletAutoColor: val }) }
    },
    GuideBulletColor: {
      get() { return this.guideline.GuideBulletColor },
      set(val) { this.setChartItem({ GuideBulletColor: val }) }
    },
    GuideTextFont: {
      get() { return this.guideline.GuideTextFont },
      set(val) { this.setChartItem({ GuideTextFont: val }) }
    },
    GuideTextStyle: {
      get() { return this.guideline.GuideTextStyle },
      set(val) { this.setChartItem({ GuideTextStyle: val }) }
    },
    GuideTextSize: {
      get() { return this.guideline.GuideTextSize || 0 },
      set(val) { this.setChartItem({ GuideTextSize: parseFloat(val || 0) }) }
    },
    GuideTextColor: {
      get() { return this.guideline.GuideTextColor },
      set(val) { this.setChartItem({ GuideTextColor: val }) }
    },
    GuideValueRound: {
      get() { return this.guideline.GuideValueRound || 0 },
      set(val) { this.setChartItem({ GuideValueRound: parseFloat(val || 0) }) }
    },
    GuideValueSize: {
      get() { return this.guideline.GuideValueSize || 0 },
      set(val) { this.setChartItem({ GuideValueSize: parseFloat(val || 0) }) }
    },
    GuideValueColor: {
      get() { return this.guideline.GuideValueColor },
      set(val) { this.setChartItem({ GuideValueColor: val }) }
    },
    GuideUnitFormat: {
      get() { return this.guideline.GuideUnitFormat },
      set(val) { this.setChartItem({ GuideUnitFormat: val }) }
    },
    GuideUnitSize: {
      get() { return this.guideline.GuideUnitSize || 0 },
      set(val) { this.setChartItem({ GuideUnitSize: parseFloat(val || 0) }) }
    },
    GuideUnitColor: {
      get() { return this.guideline.GuideUnitColor },
      set(val) { this.setChartItem({ GuideUnitColor: val }) }
    },

  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
  }

}
</script>
