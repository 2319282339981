<template>
  <v-flex v-model="activator__" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Line Chart</span>
      <small class="type_ref01">Properties</small>
    </div>

    <div class="chart_properties_contents">
      <div class="header">
        Lines
        <a class="close" v-on:click="collapsed['lines'] = !collapsed['lines']"></a>
      </div>
      <v-flex wrap class="control chart_form" style="padding:0; padding-left: 2rem;" v-show="collapsed['lines']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="LineDisplay" type="radio" name="LineDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="form_content historgram_line_form">
          <v-flex xs12 class="label">Weights</v-flex>
          <v-flex>
            <v-flex xs12 class="wrap__input column_input">
              <v-text-field
                v-for="(n, i) in 5"
                :label="`${n}`"
                :key="i"
                :value="LineWeight[i]"
                @input="v => { updateAttr(Number(v), i, 'LineWeight') }"
              ></v-text-field>
            </v-flex>
          </v-flex>
          <v-flex xs12 class="label">Styles</v-flex>
          <v-flex>
            <v-flex xs12 class="wrap__input column_input">
              <v-text-field
                v-for="(n, i) in 5"
                :label="`${n}`"
                :key="i"
                :value="LineDash[i]"
                @input="v => { updateAttr(Number(v), i, 'LineDash') }"
              ></v-text-field>
            </v-flex>
          </v-flex>
          <div class="type_common_input color-pick">
            <j-color-picker v-model="LineColorSet" multi title="Color"></j-color-picker>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Marker
        <a class="close" v-on:click="collapsed['marker'] = !collapsed['marker']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['marker']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="LineMarkerDisplay" type="radio" name="LineMarkerDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <span class="label">Type</span>
          <div class="row">
            <label v-for="option in bulletTypeOptions" :key="option.index" class="type_many">
              <input
                v-model="LineMarkerType"
                type="radio"
                name="LineMarkerType"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="LineMarkerSize" type="text" />
        </div>
        <div class="type_common_input">
          <label>Stroke</label>
          <input v-model="LineMarkerStroke" type="text" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Value
        <a class="close" v-on:click="collapsed['value'] = !collapsed['value']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['value']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="LineValueDisplay" type="radio" name="LineValueDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="LineValueFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="LineValueStyle" dir="rtl" class="type70">
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input type_color_float">
          <label>Size / Color</label>
          <div class="input_flex_wrap_end">
            <input v-model="LineValueSize" type="text" />
            <j-color-picker v-model="LineValueColor"></j-color-picker>
          </div>
        </div>
        <div class="type_common_input">
          <label>Space</label>
          <input v-model="LineValueSpace" type="text" />
        </div>
        <div class="type_common_input">
          <label>Unit Format</label>
          <input v-model="LineValueFormat" type="text" />
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-line',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      lines: true,
      marker: false,
      value: false,
    },
    localLineAttrs: [],
    activator__: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    LineDisplay: {
      get() { return this.localLineAttrs.LineDisplay },
      set(val) { 
        this.localLineAttrs.LineDisplay = val
        this.updateAttr()
      }
    },
    LineDash() { return this.localLineAttrs.LineDash || Array.from({ length: 5 }, () => 0) },
    LineWeight() { return this.localLineAttrs.LineWeight || Array.from({ length: 5 }, () => 0) },
    LineColorSet: {
      get() { return this.localLineAttrs.LineColorSet || Array.from({ length: 5 }, () => '') },
      set(val) {
        this.localLineAttrs.LineColorSet = val
        this.updateAttr()
      }
    },
    LineMarkerDisplay: {
      get() { return this.localLineAttrs.LineMarkerDisplay },
      set(val) { 
        this.localLineAttrs.LineMarkerDisplay = val
        this.updateAttr()
      }
    },
    LineMarkerType: {
      get() { return this.localLineAttrs.LineMarkerType },
      set(val) { 
        this.localLineAttrs.LineMarkerType = val
        this.updateAttr()
      }
    },
    LineMarkerSize: {
      get() { return this.localLineAttrs.LineMarkerSize || 0 },
      set(val) { 
        this.localLineAttrs.LineMarkerSize = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    LineMarkerStroke: {
      get() { return this.localLineAttrs.LineMarkerStroke || 0 },
      set(val) { 
        this.localLineAttrs.LineMarkerStroke = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    LineValueDisplay: {
      get() { return this.localLineAttrs.LineValueDisplay },
      set(val) { 
        this.localLineAttrs.LineValueDisplay = val
        this.updateAttr()
      }
    },
    LineValueFont: {
      get() { return this.localLineAttrs.LineValueFont },
      set(val) { 
        this.localLineAttrs.LineValueFont = val
        this.updateAttr()
      }
    },
    LineValueStyle: {
      get() { return this.localLineAttrs.LineValueStyle },
      set(val) { 
        this.localLineAttrs.LineValueStyle = val
        this.updateAttr()
      }
    },
    LineValueSize: {
      get() { return this.localLineAttrs.LineValueSize || 0 },
      set(val) { 
        this.localLineAttrs.LineValueSize = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    LineValueColor: {
      get() { return this.localLineAttrs.LineValueColor },
      set(val) { 
        this.localLineAttrs.LineValueColor = val
        this.updateAttr()
      }
    },
    LineValueSpace: {
      get() { return this.localLineAttrs.LineValueSpace || 0 },
      set(val) { 
        this.localLineAttrs.LineValueSpace = parseFloat(val || 0)
        this.updateAttr()
      }
    },
    LineValueFormat: {
      get() { return this.localLineAttrs.LineValueFormat },
      set(val) { 
        this.localLineAttrs.LineValueFormat = val
        this.updateAttr()
      }
    },
  },
  watch: {
    'chartItem.Line': {
      handler(val) {
        if (!val) return
        this.localLineAttrs = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    updateAttr(val, i, el) {
      if(el) this.localLineAttrs[el][i] = val
      this.setChartItem({ Line: JSON.parse(JSON.stringify(this.localLineAttrs)) })
    },
  }

}
</script>
