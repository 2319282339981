<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Table</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents form_type_normal">
      <div class="header">General</div>
      <v-flex class="control">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="display" type="radio" name="display" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="x" type="text" class="type_double" />
          <input v-model="y" type="text" />
        </div>
        <div class="type_common_input">
          <span class="label">Use Chart Data</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="shareData" type="radio" name="shareData" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label for>
            Header
            <!-- <small class="type_ref03">(JSON)</small> -->
          </label>
          <div class="row">
            <div justify="center" style="align-items: center">
              <v-btn @click.stop="exp['H']=true" class="type_openTextarea">{{ getQueryParts('headers') }}</v-btn>
              <j-modal-scripter 
                v-model="headers" 
                title="Table Header JSON"
                sub-title="JSON Properties"
                :buttons="{validate: true, save:false}"
                :message="validated.headers.message"
                :opened="exp.H"
                :valid="validated.headers.valid"
                @close="exp.H=false;updateAttr();$emit('json-updated')"
                @save="exp.H=false"
                @validate="onValidate('headers')"
              />
            </div>
          </div>
        </div>
        <div class="type_common_input">
          <label>
            Data
            <!-- <small class="type_ref03">(SQL)</small> -->
          </label>
          <div class="row">
            <div justify="center" style="align-items: center">
              <v-btn @click.stop="exp['Q']=true" class="type_openTextarea">{{ getQueryParts('query') }}</v-btn>
              <j-modal-scripter 
                v-model="query" 
                title="Table Footer SQL Query"
                sub-title="Access Database"
                :buttons="{validate: true, save:false}"
                :message="validated.query.message"
                :opened="exp.Q"
                :valid="validated.query.valid"
                @close="exp.Q=false;updateAttr();"
                @save="exp.Q=false"
                @validate="onValidate('query')"
              />
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import { QueryValidationMixin } from "@/mixins/sqlValidator"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-table-summary',
  mixins: [
    QueryValidationMixin,
    StaticOptions
  ],
  watch: {
    'chartItem.TableSummary': {
      handler(val) {
        if(!val) return
        this.setLocalProps()
      },
      deep: true,
    }
  },
  created() {
  },
  mounted() {
    this.setLocalProps()
  },
  data: () => ({
    // Service Instances -------------
    chartLibraryService: null,
    systemService: null,
    currentIcon: null,

    tableOptions: [],

    exp: {
      H: false,
      Q: false,
    },
    validated: {
      headers: { valid: true, message: '' },
      query: { valid: true, message: '' }
    },

    localTableSummary: {}
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    display: {
      get() { return this.localTableSummary.display || '' },
      set(val) { 
        this.localTableSummary.display = val
        this.updateAttr()
      }
    },
    shareData: {
      get() { return this.localTableSummary.shareData || '' },
      set(val) { 
        this.localTableSummary.shareData = val
        this.updateAttr()
      }
    },
    x: {
      get() { return this.localTableSummary.x },
      set(val) { 
        this.localTableSummary.x = typeof val == 'number' ? parseFloat(val) : val
        this.updateAttr()
      }
    },
    y: {
      get() { return this.localTableSummary.y },
      set(val) { 
        this.localTableSummary.y = typeof val == 'number' ? parseFloat(val) : val
        this.updateAttr()
      }
    },
    headers: {
      get() { 
        if(!this.localTableSummary.headers) return ''
        let jsonString_ = JSON.stringify(this.localTableSummary.headers)
        let json_ = this.validateJson(jsonString_)
        return !json_.valid ? jsonString_ : json_.json
      },
      set(val) { this.localTableSummary.headers = JSON.parse(val || '[]') }
    },
    query: {
      get() { return this.localTableSummary.query || '' },
      set(val) { this.localTableSummary.query = val }
    }
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    onValidate(typeName) {
      if(['headers'].includes(typeName)) {
        this.validated[typeName] = this.validateJson(this[typeName])
        if(this.validated[typeName].valid) this[typeName] = this.validated[typeName].json
      } else {
        this.validateSql(this[typeName]).then(res => {
          this.validated[typeName] = res
        })
      }
    },
    setLocalProps() {
      this.localTableSummary = JSON.parse(JSON.stringify(this.chartItem.TableSummary || {}))
    },
    updateAttr() {
      this.setChartItem({ TableSummary: JSON.parse(JSON.stringify(this.localTableSummary)) })
    },
  }
}
</script>