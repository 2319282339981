<template>
  <v-flex v-model="bar_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Select Option</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">filter</div>
      <v-flex class="control form_type_normal">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input type="radio" name="LegendDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <!-- <v-select
          v-model="PhaseCode"
          content-class="single_select"
          item-text="phaseName"
          item-value="phaseCode"
          placeholder="Spool Installation"
          required
          attach
          :items="phaseOptions"
          :rules="[v => !!v || 'Item is required']"
          @input="onPhaseChanged"
          class="tree_node"
        ></v-select> -->
        <v-select
          item-text="phaseName"
          item-value="phaseCode"
          placeholder="Spool Installation"
          required
          attach
          class="tree_node"
          :menu-props="{contentClass:'single_select'}"
        ></v-select>
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input type="text" class="type_double" />
          <input type="text" />
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-filter',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      lines: true,
    },
    bar_general: null,
    currentIcon: null,
    filterSelectOptionOnoff: 'Y'
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_HISTOGRAM, ['chartItem']),
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_HISTOGRAM, ['filter']),

    filterOnoff: {
      get() { return this.filter.filterOnoff },
      set(val) { this.setChartItem({ filterOnoff: val }) }
    },
    filterId: {
      get() { return this.filter.filterId },
      set(val) { this.setChartItem({ filterId: val }) }
    },
    filterPx: {
      get() { return this.filter.filterPx || 0 },
      set(val) { this.setChartItem({ filterPx: parseFloat(val || 0) }) }
    },
    filterPy: {
      get() { return this.filter.filterPy || 0 },
      set(val) { this.setChartItem({ filterPy: parseFloat(val || 0) }) }
    },

  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_HISTOGRAM, ['setChartItem']),
  }
}
</script>
