<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Delta Bar</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Chart
        <a class="close" v-on:click="collapsed['chart'] = !collapsed['chart']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['chart']">
        <div class="type_common_input">
          <label>
            Coordinates
          </label>
          <input v-model="DeltaChartX" type="number" class="type_mgl0 type_double" />
          <input v-model="DeltaChartY" type="number" />
        </div>
        <div class="type_common_select">
          <label class="type_gray">Font Family</label>
          <select v-model="DeltaChartFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <span class="label">Bar Shape</span>
          <div class="row">
            <label class="type_many image_wrapper">
              <input v-model="DeltaChartShape" type="radio" name="DeltaChartShape" :value="'triangle'">
              <svg xmlns="http://www.w3.org/2000/svg" width="10.871" height="9.007" viewBox="0 0 10.871 9.007">
                <path id="path_11693" data-name="path 11693" d="M10.868,0C10.917.087-.054.087.008,0c-.249,0,5.416,8.993,5.43,9.007C5.424,8.993,11.026,0,10.868,0Z" transform="translate(10.871 9.007) rotate(180)" fill="#f4f5f5"/>
              </svg>
            </label>
            <label class="type_many image_wrapper">
              <input v-model="DeltaChartShape" type="radio" name="DeltaChartShape" :value="'arrow'">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                <g id="group_7636" data-name="group 7636" transform="translate(10.216 9.713) rotate(180)">
                  <g id="group_7542" data-name="group 7542">
                    <path id="path_11633" data-name="path 11633" d="M6.671,0l.006,4.517h2.33l-4.5,4.49L0,4.517H2.331L2.326,0Z" fill="#f4f5f5" stroke="rgba(0,0,0,0)" stroke-width="1"/>
                  </g>
                </g>
              </svg>
            </label>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Progress Bar
        <a class="close" v-on:click="collapsed['progress'] = !collapsed['progress']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['progress']">
        <div class="type_common_input">
          <label class="type_gray">Length</label>
          <input v-model="DeltaBarLength" type="number" />
        </div>
        <div class="type_common_input">
          <label class="type_gray">Height</label>
          <input v-model="DeltaBarHeight" type="number" />
        </div>
        <div class="type_common_input">
          <label class="type_gray">Distance</label>
          <input v-model="DeltaBarDistance" type="number" />
        </div>
        <div class="type_common_input">
          <label class="type_gray">Radius</label>
          <input v-model="DeltaBarRadius" type="number" />
        </div>
        <div class="type_common_input pickers">
          <j-color-picker v-model="DeltaBarPlan" title="Bar Color(Plan/Ahead/Behind)"></j-color-picker>
          <j-color-picker v-model="DeltaBarAhead" class="type_middle"></j-color-picker>
          <j-color-picker v-model="DeltaBarBehind" class="type_middle"></j-color-picker>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Text
        <a class="close" v-on:click="collapsed['text'] = !collapsed['text']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['text']">
        <div class="type_common_input type_color_float">
          <label class="type_gray">Bar Title</label>
          <div class="input_flex_wrap_end">
            <input v-model="DeltaTitleSize" class=" type_middle_left" type="number"/>
            <j-color-picker v-model="DeltaTitleColor"></j-color-picker>
          </div>
        </div>
        <div class="type_common_input type_color_float">
          <label class="type_gray">Plan Bar</label>
          <div class="input_flex_wrap_end">
            <input v-model="DeltaPlanSize" class=" type_middle_left" type="number"/>
            <j-color-picker v-model="DeltaPlanColor"></j-color-picker>
          </div>
        </div>
        <div class="type_common_input type_color_float">
          <label class="type_gray">Actual Bar</label>
          <div class="input_flex_wrap_end">
            <input v-model="DeltaActualSize" class=" type_middle_left" type="number"/>
            <j-color-picker v-model="DeltaActualColor"></j-color-picker>
          </div>
        </div>
        <div class="type_common_input type_color_float" style="margin-top: 3rem;">
          <label class="type_gray">Delta</label>
          <div class="input_flex_wrap_end">
            <input v-model="DeltaTextSize"  type="number"/>
          </div>
        </div>
        <div class="type_common_input pickers">
          <j-color-picker v-model="DeltaAheadColor" title="Ahead/Behind"></j-color-picker>
          <j-color-picker v-model="DeltaBehindColor"></j-color-picker>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Grid
        <a class="close" v-on:click="collapsed['grid'] = !collapsed['grid']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['grid']">
        <div class="type_common_input">
          <label class="type_gray">Font Size</label>
          <input v-model="DeltaGridSize" type="number" />
        </div>
        <div class="type_common_input">
          <label class="type_gray">Space</label>
          <input v-model="DeltaGridSpace" type="number" />
        </div>
        <div class="type_common_input">
          <label>
            Stroke / Color
          </label>
          <input v-model="DeltaGridStroke" type="number" class="type_mgl0 type_double" />
          <j-color-picker v-model="DeltaGridColor"></j-color-picker>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import _ChartDataDefault from '@/primitives/_chartBarDeltaDefault'
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-delta-bar',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      chart: true,
      progress: true,
      text: true,
      grid: true,
    },
    tabActivator: null,
    
    dataform: {}
  }),
  watch: {
    'chartItem.Delta': {
      handler(val) {
        if (!val) return
        this.dataform = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    DeltaChartX: {
      get() { return this.dataform.DeltaChartX },
      set(val) {
        this.dataform.DeltaChartX = parseFloat(val)
        this.updateAttr()
      }
    },
    DeltaChartY: {
      get() { return this.dataform.DeltaChartY },
      set(val) {
        this.dataform.DeltaChartY = parseFloat(val)
        this.updateAttr()
      }
    },
    DeltaChartFont: {
      get() { return this.dataform.DeltaChartFont },
      set(val) {
        this.dataform.DeltaChartFont = val
        this.updateAttr()
      }
    },
    DeltaChartShape: {
      get() { return this.dataform.DeltaChartShape },
      set(val) {
        this.dataform.DeltaChartShape = val
        this.updateAttr()
      }
    },
    DeltaBarLength: {
      get() { return this.dataform.DeltaBarLength },
      set(val) {
        this.dataform.DeltaBarLength = parseFloat(val)
        this.updateAttr()
      }
    },
    DeltaBarHeight: {
      get() { return this.dataform.DeltaBarHeight },
      set(val) {
        this.dataform.DeltaBarHeight = parseFloat(val)
        this.updateAttr()
      }
    },
    DeltaBarDistance: {
      get() { return this.dataform.DeltaBarDistance },
      set(val) {
        this.dataform.DeltaBarDistance = parseFloat(val)
        this.updateAttr()
      }
    },
    DeltaBarRadius: {
      get() { return this.dataform.DeltaBarRadius },
      set(val) {
        this.dataform.DeltaBarRadius = parseFloat(val)
        this.updateAttr()
      }
    },
    DeltaBarPlan: {
      get() { return this.dataform.DeltaBarPlan },
      set(val) {
        this.dataform.DeltaBarPlan = val
        this.updateAttr()
      }
    },
    DeltaBarAhead: {
      get() { return this.dataform.DeltaBarAhead },
      set(val) {
        this.dataform.DeltaBarAhead = val
        this.updateAttr()
      }
    },
    DeltaBarBehind: {
      get() { return this.dataform.DeltaBarBehind },
      set(val) {
        this.dataform.DeltaBarBehind = val
        this.updateAttr()
      }
    },
    DeltaTitleSize: {
      get() { return this.dataform.DeltaTitleSize },
      set(val) {
        this.dataform.DeltaTitleSize = parseFloat(val)
        this.updateAttr()
      }
    },
    DeltaTitleColor: {
      get() { return this.dataform.DeltaTitleColor },
      set(val) {
        this.dataform.DeltaTitleColor = val
        this.updateAttr()
      }
    },
    DeltaPlanSize: {
      get() { return this.dataform.DeltaPlanSize },
      set(val) {
        this.dataform.DeltaPlanSize = parseFloat(val)
        this.updateAttr()
      }
    },
    DeltaPlanColor: {
      get() { return this.dataform.DeltaPlanColor },
      set(val) {
        this.dataform.DeltaPlanColor = val
        this.updateAttr()
      }
    },
    DeltaActualSize: {
      get() { return this.dataform.DeltaActualSize },
      set(val) {
        this.dataform.DeltaActualSize = parseFloat(val)
        this.updateAttr()
      }
    },
    DeltaActualColor: {
      get() { return this.dataform.DeltaActualColor },
      set(val) {
        this.dataform.DeltaActualColor = val
        this.updateAttr()
      }
    },
    DeltaTextSize: {
      get() { return this.dataform.DeltaTextSize },
      set(val) {
        this.dataform.DeltaTextSize = parseFloat(val)
        this.updateAttr()
      }
    },
    DeltaAheadColor: {
      get() { return this.dataform.DeltaAheadColor },
      set(val) {
        this.dataform.DeltaAheadColor = val
        this.updateAttr()
      }
    },
    DeltaBehindColor: {
      get() { return this.dataform.DeltaBehindColor },
      set(val) {
        this.dataform.DeltaBehindColor = val
        this.updateAttr()
      }
    },
    DeltaGridSize: {
      get() { return this.dataform.DeltaGridSize },
      set(val) {
        this.dataform.DeltaGridSize = parseFloat(val)
        this.updateAttr()
      }
    },
    DeltaGridSpace: {
      get() { return this.dataform.DeltaGridSpace },
      set(val) {
        this.dataform.DeltaGridSpace = parseFloat(val)
        this.updateAttr()
      }
    },
    DeltaGridStroke: {
      get() { return this.dataform.DeltaGridStroke },
      set(val) {
        this.dataform.DeltaGridStroke = parseFloat(val)
        this.updateAttr()
      }
    },
    DeltaGridColor: {
      get() { return this.dataform.DeltaGridColor },
      set(val) {
        this.dataform.DeltaGridColor = val
        this.updateAttr()
      }
    },

  },
  created() {
    this.dataform = JSON.parse(JSON.stringify(_ChartDataDefault.Delta))
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    updateAttr() {
      this.setChartItem({ Delta: JSON.parse(JSON.stringify(this.dataform)) })
    }
  }
}
</script>
