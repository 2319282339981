import SysenvChartlibDonut from '@/views/system/modal/SysenvChartlibDonut.modal'
import SysenvChartlibPie from '@/views/system/modal/SysenvChartlibPie.modal'
import SysenvChartlibBarNormal from '@/views/system/modal/SysenvChartlibBarNormal.modal'
import SysenvChartlibBarSolid from '@/views/system/modal/SysenvChartlibBarSolid.modal'
import SysenvChartlibBarProgress from '@/views/system/modal/SysenvChartlibBarProgress.modal'
import SysenvChartlibBarDelta from '@/views/system/modal/SysenvChartlibBarDelta.modal'
import SysenvChartlibHistogram from '@/views/system/modal/SysenvChartlibHistogram.modal'
import SysenvChartlibRadar from '@/views/system/modal/SysenvChartlibRadar.modal'
import SysenvChartlibSkyline from '@/views/system/modal/SysenvChartlibSkyline.modal'
import SysenvChartlibTableSummary from '@/views/system/modal/SysenvChartlibTableSummary.modal'
import SysenvChartlibCustom from '@/views/system/modal/SysenvChartlibCustom.modal'
import SysenvChartlibFloating from '@/views/system/modal/SysenvChartlibFloating.modal'
import SysenvSvglibTab from '@/views/system/modal/SysenvSvglibTab.modal'
import SysenvChartlibSummary from '@/views/system/modal/SysenvChartlibSummary.modal'
import SysenvChartlibWeekly from '@/views/system/modal/SysenvChartlibWeekly.modal'

export default {
  SysenvChartlibDonut,
  SysenvChartlibPie,
  SysenvChartlibBarNormal,
  SysenvChartlibBarSolid,
  SysenvChartlibBarProgress,
  SysenvChartlibBarDelta,
  SysenvChartlibHistogram,
  SysenvChartlibRadar,
  SysenvChartlibSkyline,
  SysenvChartlibTableSummary,
  SysenvChartlibCustom,
  SysenvChartlibFloating,
  SysenvSvglibTab,
  SysenvChartlibSummary,
  SysenvChartlibWeekly,
}
