<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Draw</span>
    </div>
    <div class="chart_properties_contents">
      <j-alert
          v-model="msgOpen"
          :title="msgInfo.title"
          :type="msgInfo.type"
          :message="msgInfo.message"
          :button="msgInfo.button"
          :button-text="msgInfo.buttonText"
          @yes="yes()"
          @cancel="msgOpen = false"
        />
      <div class="header">
        Modal Container
        <small class="type_ref03">(Name)</small>
        <a class="close" v-on:click="collapsed['container'] = !collapsed['container']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['container']">
        <div class="type_common_input">
          <label class="type_gray second_depth">
            Width / Height
          </label>
          <input v-model="Width" type="number" class="type_mgl0 type_double" />
          <input v-model="Height" type="number" />
        </div>
        <div class="type_common_input">
          <label class="type_gray second_depth">Background</label>
          <div class="row">
            <j-color-picker v-model="Color" @input="updateAttr"/>
          </div>
        </div>
        <div class="type_common_input">
          <label class="type_gray second_depth">Opacity</label>
          <input v-model="Opacity" type="text" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        <span data-grid-quickbtn="btn" class="label jcon_temp_plus type_header btn_after"  style="font-family: roboto !important; color: #83d2f5;" @click="appendAttr('line')">Line</span>
        <a class="close" v-on:click="collapsed['line'] = !collapsed['line']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['line']">
        <div v-for="(attr, i) in localLineAttrs" class="content_list" :key="attr.index">
          <div class="header">
            <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'line')">No.{{ i + 1 }}</span>
          </div>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray second_depth">
                From
                <small class="type_ref03">(x,y)</small>
              </label>
              <input v-model="attr.x1" type="number" class="type_mgl0 type_double" @input="(e) => updateAttr(e.target.value, i, 'x1', 'line')"/>
              <input v-model="attr.y1" type="number" @input="(e) => updateAttr(e.target.value, i, 'y1')"/>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">
                To
                <small class="type_ref03">(x,y)</small>
              </label>
              <input v-model="attr.x2" type="number" class="type_mgl0 type_double"  @input="(e) => updateAttr(e.target.value, i, 'x2', 'line')"/>
              <input v-model="attr.y2" type="number"  @input="(e) => updateAttr(e.target.value, i, 'y2')"/>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Stroke / Color</label>
              <div class="row">
                <input v-model="attr.stroke" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'stroke', 'line')" />
                <j-color-picker v-model="attr.color" @input="updateAttr"/>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        <span data-grid-quickbtn="btn" class="label jcon_temp_plus type_header btn_after"  style="font-family: roboto !important; color: #83d2f5;" @click="appendAttr('rect')">Rect</span>
        <a class="close" v-on:click="collapsed['rect'] = !collapsed['rect']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['rect']">
        <div v-for="(attr, i) in localRectAttrs" class="content_list" :key="attr.index">
          <div class="header">
            <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'rect')">No.{{ i + 1 }}</span>
          </div>
            <div class="type_inner">
              <div class="type_common_input">
                <label class="type_gray second_depth">
                  Coordinates
                  <small class="type_ref03">(x,y)</small>
                </label>
                <input v-model="attr.x" type="number" class="type_mgl0 type_double"  @input="(e) => updateAttr(e.target.value, i, 'x', 'rect')"/>
                <input v-model="attr.y" type="number"  @input="(e) => updateAttr(e.target.value, i, 'y')"/>
              </div>
              <div class="type_common_input">
                <label class="type_gray second_depth">
                  Width / Height
                </label>
                <input v-model="attr.width" type="number" class="type_mgl0 type_double" @input="(e) => updateAttr(e.target.value, i, 'width', 'rect')"/>
                <input v-model="attr.height" type="number" @input="(e) => updateAttr(e.target.value, i, 'height', 'rect')"/>
              </div>
              <div class="type_common_input">
                <label class="type_gray second_depth">
                  Radius
                </label>
                <input v-model="attr.radius" type="number" class="type_mgl0" @input="(e) => updateAttr(e.target.value, i, 'radius', 'rect')"/>
              </div>
              <div class="type_common_input">
                <label class="type_gray second_depth">Stroke / Color</label>
                <div class="row">
                  <input v-model="attr.strokeWidth" class="type_double" type="number" @input="(e) => updateAttr(e.target.value, i, 'strokeWidth', 'rect')" />
                  <j-color-picker v-model="attr.strokeColor" @input="updateAttr"/>
                </div>
              </div>
              <div class="type_common_input">
                <label class="type_gray second_depth">Fill Color</label>
                <div class="row">
                  <j-color-picker v-model="attr.fillColor" @input="updateAttr"/>
                </div>
              </div>
            </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        <span data-grid-quickbtn="btn" class="label jcon_temp_plus type_header btn_after"  style="font-family: roboto !important; color: #83d2f5;" @click="appendAttr('icon')">Icon</span>
        <a class="close" v-on:click="collapsed['icon'] = !collapsed['icon']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['icon']">
        <div v-for="(attr, i) in localIconAttrs" class="content_list" :key="attr.index">
          <div class="header">
            <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'icon')">No.{{ i + 1 }}</span>
          </div>
            <div class="type_inner">
              <div class="type_common_input">
                <label class="type_gray second_depth">
                  Coordinates
                  <small class="type_ref03">(x,y)</small>
                </label>
                <input v-model="attr.x" type="number" class="type_mgl0 type_double" @input="(e) => updateAttr(e.target.value, i, 'x', 'icon')"/>
                <input v-model="attr.y" type="number" @input="(e) => updateAttr(e.target.value, i, 'y', 'icon')"/>
              </div>
              <div class="type_common_select">
                <label class="type_gray second_depth">Size</label>
                <div class="row">
                  <input v-model="attr.size" type="number" @input="(e) => updateAttr(e.target.value, i, 'size', 'icon')" />
                </div>
              </div>
              <j-icon-picker cat-code="SVG" :offset-x="true" :value="attr.name" @input="(v) => { attr.name = v.name }" />
            </div>
        </div>
      </v-flex>
    </div>
    <div style="height: 10rem;"/>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from "vuex"
import _ChartDataDefault from '@/primitives/_pageSvglibTabDefault'
import StaticOptions from '@/mixins/staticOptions'
import JIconPicker from "@/components/floating/JIconPicker"


export default {
  name: 'j-svglib-tab-draw',
  mixins: [
    StaticOptions
  ],
  components: {
    JIconPicker
  },
  data: () => ({
    collapsed: {
      container: true,
      line: true,
      rect: true,
      icon: true,
    },
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    localTabAttrs: {},
    localDraws: {},
    localLineAttrs: [],
    localRectAttrs: [],
    localIconAttrs: [],
    tabActivator: null,

    msgOpen: false,
    yes: null
  }),
  watch: {
    'chartItem.TabAttrs': {
      handler(val) {
        if (!val) return
        this.localTabAttrs = JSON.parse(JSON.stringify(val))
        this.localDraws= this.localTabAttrs.draw
        this.localLineAttrs = this.localTabAttrs.draw.Lines
        this.localRectAttrs = this.localTabAttrs.draw.Rects
        this.localIconAttrs = this.localTabAttrs.draw.Icons
      },
      deep: true
    },
  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    headerAttrs() {
      return Array.from({ length: this.localLineAttrs.length  }, (_, i) => i) 
    },
    Width: {
      get() {return this.localDraws.Width || 0 },
      set(val) { this.localDraws.Width = parseFloat(val); this.updateAttr();}
    },
    Height: {
      get() {return this.localDraws.Height || 0 },
      set(val) { this.localDraws.Height = parseFloat(val); this.updateAttr();}
    },
    Color: {
      get() {return this.localDraws.Color || 0 },
      set(val) { this.localDraws.Color = val; this.updateAttr();}
    },
    Opacity: {
      get() {return this.localDraws.Opacity || 0 },
      set(val) { this.localDraws.Opacity = parseFloat(val); this.updateAttr();}
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
    
    appendAttr(type) {
      if(type == 'line') {
        this.localLineAttrs.push(Object.assign({},...Object.keys(_ChartDataDefault.ModalDraw.Lines[0]).map(key => ({[key]: null}))));
      } else if(type == 'rect') {
        this.localRectAttrs.push(Object.assign({},...Object.keys(_ChartDataDefault.ModalDraw.Rects[0]).map(key => ({[key]: null}))));
      } else if(type == 'icon') {
        this.localIconAttrs.push(Object.assign({},...Object.keys(_ChartDataDefault.ModalDraw.Icons[0]).map(key => ({[key]: null}))));
      }
      this.updateAttr()
    },
    clickRemoveBtn(i, j, el) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    removeAttr(index, type) {
      if(type == 'line') {
        this.localLineAttrs[index] = null
        this.localLineAttrs = this.localLineAttrs.filter(attr => !!attr)
      } else if (type == 'rect') {
        this.localRectAttrs[index] = null
        this.localRectAttrs = this.localRectAttrs.filter(attr => !!attr)
      } else if (type == 'icon') {
        this.localIconAttrs[index] = null
        this.localIconAttrs = this.localIconAttrs.filter(attr => !!attr)
      }
      
      this.updateAttr()
    },
    updateAttr(val, i, el, type) {
      if(el) {
        if(type == 'line') {
          this.localLineAttrs[i][el] = val
        } else if (type == 'rect') {
          this.localRectAttrs[i][el] = val
        } else if (type == 'icon') {
          this.localIconAttrs[i][el] = val
        }
      }
      this.localTabAttrs.draw = null

      this.setChartItem({
        TabAttrs: {
          ...JSON.parse(JSON.stringify(this.localTabAttrs)), 
          draw: {
            ...JSON.parse(JSON.stringify(this.localDraws)),
            Lines: [...JSON.parse(JSON.stringify(this.localLineAttrs))],
            Rects: [...JSON.parse(JSON.stringify(this.localRectAttrs))],
            Icons: [...JSON.parse(JSON.stringify(this.localIconAttrs))],
          }
        }
      })
    },
  }
}
</script>
