<template>
  <v-flex class="chart_properties" wrap>

    <div class="chart_properties_header">
      <span>Columns</span>
      <small class="type_ref01">Properties</small>
    </div>

    <div class="chart_properties_contents">      
      <j-alert
        v-model="msgOpen"
        :title="msgInfo.title"
        :type="msgInfo.type"
        :message="msgInfo.message"
        :button="msgInfo.button"
        :button-text="msgInfo.buttonText"
        @yes="yes()"
        @cancel="msgOpen = false"
      />

      <div class="header">
        <span 
          data-grid-quickbtn="btn" 
          class="label jcon_temp_plus type_header btn_after_55" 
          style="font-family: roboto !important; color: #83d2f5;" 
          @click="appendAttr('Columns')"
        >Columns</span>
        <a class="close" v-on:click="collapsed['columns'] = !collapsed['columns']"></a>
      </div>

      <v-flex class="control" v-show="collapsed['columns']">

        <div v-for="(attr, i) in localColumns.RealData.Columns" class="content_list"  :key="attr.index">

          <div class="header" style="margin-bottom: 2px;">
            <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'Columns')">Col. {{ i + 1 }}</span>
          </div>

          <div class="type_inner">

            <div class="type_common_input">
              <label class="type_gray second_depth">Ref.Code</label>
              <input v-model="attr.code" type="text" class="type70" @input="updateAttr" />
            </div>

            <div class="type_common_input">
              <label class="type_gray second_depth">Title Name</label>
              <input v-model="attr.name" type="text" class="type70" @input="updateAttr" />
            </div>

            <div class="type_common_input">
              <label class="type_gray second_depth">Width</label>
              <input v-model.number="attr.width" type="number" @input="updateAttr" />
            </div>

            <div class="type_common_input">
              <label class="type_gray second_depth">Align</label>
              <div class="row">
                <label v-for="option in alignHLongOptions" :key="option.index" class="type_many">
                  <input v-model="attr.align" type="radio" :name="`align_text_${i}`" :value="option.value" @input="(e) => { updateAttr(e.target.value, i, 'align', 'Columns') }"/>
                  {{option.text}}
                </label>
              </div>
            </div>

            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Font Color</label>
              <div class="input_flex_wrap_end">
                <j-color-picker v-model="attr.tColor" @input="updateAttr"/>
              </div>
            </div>

            <div class="type_common_input">
              <label class="type_gray label second_depth">Link</label>
              <div class="row">
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input v-model="attr.link" type="radio" :name="`link_text_${i}`" :value="option.value" @input="(e) => { updateAttr(e.target.value, i, 'link', 'Columns') }"/>
                  {{option.text}}
                </label>
              </div>
            </div>

            <div class="type_common_input">
              <label class="type_gray label second_depth">Json Map</label>
              <input v-model="attr.jMap" type="text" class="type70" @input="updateAttr" />
            </div>

            <div class="type_common_input">
                <label class="type_gray label second_depth">Json Link</label>
                <input v-model="attr.jLink" type="text" class="type70" @input="updateAttr" />
            </div>

            <!-- <div class="type_common_input">
                <label class="type_gray label second_depth">Filter</label>
                <input v-model="attr.filter" type="text" class="type100" @input="updateAttr" />
            </div> -->
            <div class="type_common_input">
              <label class="type_gray label second_depth">Filter</label>
              <div class="row">
                <v-btn class="type_openTextarea margin_t_5 margin_bt_2" @click.stop="openTextarea(i, true)">{{ getQueryParts(i) }}</v-btn>
                <j-modal-scripter 
                  v-model="attr.filter" 
                  title="Tab FILTER Query"
                  sub-title="FILTER Query"
                  :buttons="{validate: true, save:false}"
                  :message="validated[`FILTER_${i}`].message"
                  :opened="exp[`F_${i}`]"
                  :valid="validated[`FILTER_${i}`].valid"
                  @close="closeScripter(`F_${i}`)"
                  @validate="onValidate(i)"
                />
              </div>
            </div>

          </div>

        </div>

      </v-flex>
    
    </div>

    <div class="chart_properties_contents">
      <div class="header">
        <span 
          data-grid-quickbtn="btn" 
          class="label jcon_temp_plus type_header btn_after_55" 
          style="font-family: roboto !important; color: #83d2f5;" 
          @click="appendAttr('Filters')"
        >Filters</span>
        <a class="close" v-on:click="collapsed['filters'] = !collapsed['filters']"></a>
      </div>

      <v-flex class="control" v-show="collapsed['filters']">

        <div v-for="(attr, i) in localColumns.RealData.Filters" class="content_list"  :key="attr.index">

          <div class="header" style="margin-bottom: 2px;">
            <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'Filters')">Col. {{ i + 1 }}</span>
          </div>

          <div class="type_inner">

            <div class="type_common_input">
              <label class="type_gray second_depth">Ref.Code</label>
              <input v-model="attr.code" type="text" class="type70" @input="updateAttr" />
            </div>

            <div class="type_common_input">
              <label class="type_gray label second_depth">Json Map</label>
              <input v-model="attr.jMap" type="text" class="type70" @input="updateAttr" />
            </div>

          </div>

        </div>

      </v-flex>

    </div>

  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import __F from '@/primitives/_function_'
import { mapState, mapMutations, mapGetters } from "vuex"
import { ChartLibraryService } from "@/services"
import { QueryValidationMixin } from "@/mixins/sqlValidator"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-col',
  mixins: [
    QueryValidationMixin,
    StaticOptions
  ],
  data: () => ({
    chartLibraryService: null,
    
    collapsed: {
      columns: true,
      filters: false,
    },
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    localColumns: {
      Default: {
        Columns: [
          // DISC
          {code: 'DISC',    name: 'Disc.',    width: 25,  tColor: '#EC407A',  align: 'middle',  link: 'N', jMap: '',      jLink: '',      filter: ''},
          {code: 'TOTAL',   name: 'Total.',   width: 45,  tColor: '#757575',  align: 'end',     link: 'Y', jMap: 'KEY1',  jLink: 'JSON1', filter: ''},
          {code: 'ACTUAL',  name: 'Closed',   width: 45,  tColor: '#757575',  align: 'end',     link: 'Y', jMap: 'KEY1',  jLink: 'JSON1', filter: ''},
          {code: 'REMAIN',  name: 'Remain',   width: 45,  tColor: '#757575',  align: 'end',     link: 'Y', jMap: 'KEY1',  jLink: 'JSON1', filter: ''},
          {code: 'PROG',    name: 'Progress', width: 100, tColor: '#EC407A',  align: 'middle',  link: 'N', jMap: '',      jLink: '',      filter: ''},
        ],
        Filters: [
          {code: 'UNIT',   jMap: 'FKEY1'},
        ],
      },
      RealData:{
        Columns: [],
        Filters: [],
      },
    },
    exp: {},
    validated: {},

    yes: null,
    msgOpen: false,

  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    __F_() { return __F }
  },
  watch: {
    'chartItem.Chtcol': {
      handler(val) {
        if (!val) return
        // console.log('Chtcol', val)
        this.localColumns.RealData = JSON.parse(JSON.stringify(val))
        
        let exp_ = {}
        let validated_ = {}
        this.localColumns.RealData.Columns.forEach((_, i) => {
          exp_[`F_${i}`] = false
          validated_[`FILTER_${i}`] = { valid: true, message: ''}
        })

        this.exp = exp_
        this.validated = validated_
      },
      deep: true
    }
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
    
    appendAttr(name) {
      // console.log('appendAttr', name)
      // console.log('appendAttr', this.localColumns,this.localColumns.Default[name])
      this.localColumns.RealData[name].push(this.localColumns.Default[name][0])
      this.updateAttr()
    },
    clickRemoveBtn(i, j, el) {
      // console.log('clickRemoveBtn', i, j, el)
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    closeScripter(name) {
      this.exp[name]=false
      this.updateAttr()
    },
    
    getQueryParts(i) {
      return this.localColumns.RealData.Columns[i].filter ? this.localColumns.RealData.Columns[i].filter.substring(0, 50) + ' ...' : ''
    },
    openTextarea(i, opened) {
      this.exp[`F_${i}`] = opened
    },
    removeAttr(index, name) {
      // console.log('removeAttr', index, name)
      // console.log('removeAttr', this.localColumns,this.localColumns[name])
      this.localColumns.RealData[name][index] = null
      this.localColumns.RealData[name] = this.localColumns.RealData[name].filter(attr => !!attr)
      this.updateAttr()
    },
    updateAttr(val, i, el, name) {
      if(el) this.localColumns.RealData[name][i][el] = val
      this.setChartItem({ Chtcol: JSON.parse(JSON.stringify(this.localColumns.RealData)) })
    },
    onSave() {
      this.$emit('save')
    },
    onValidate(i) {
      // this.validateSql(this.localColumns.Columns[i].filter).then(res => {
      //   this.validated[`FILTER_${i}`] = res
      // })
    },
  }
}
</script>
