
import __F from '@/primitives/_function_'

export default {
  methods: {
    // vObject: Value Object
    setStore(setter, vObject) {

      // ---

      return {
        undefNumber2zero: () => {
          let undefined_ = false

          Object.keys(vObject).forEach(k => {
            let validate_ = __F.undefined2zero(vObject[k])
            vObject[k] = validate_.value

            if(!undefined_ && validate_.undefined) undefined_ = true
          })
          
          if(undefined_) setter(vObject)
          return vObject
        }
      }
    }
  }
}