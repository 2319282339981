import JChartTabNavigation from '@/components/chart/tabs/JChartTabNavigation'
import JChartTabDatabase from '@/components/chart/tabs/JChartTabDatabase'
import JChartTabCanvas from '@/components/chart/tabs/JChartTabCanvas'
import JChartTabTitle from '@/components/chart/tabs/JChartTabTitle'
import JChartTabNote from '@/components/chart/tabs/JChartTabNote'
import JChartTabDeltaBar from './JChartTabDeltaBar.vue'

export default {
  JChartTabNavigation,
  JChartTabDatabase,
  JChartTabCanvas,
  JChartTabTitle,
  JChartTabNote,
  JChartTabDeltaBar
}
