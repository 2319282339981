<template>
  <v-flex v-model="bar_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Axis-Y</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">General</div>
      <v-flex class="control">
        <div class="type_common_input">
          <label>Bounds</label>
          <label class="type_auto type_ab right45">
            <j-check-box v-model="AxisYBoundsAuto" true-value="Y" false-value="N" />Auto
          </label>
          <input v-model="AxisYBoundsMax" type="number" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Text
        <a class="close" v-on:click="collapsed['general'] = !collapsed['general']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['general']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="AxisYLevelDisplay" type="radio" name="AxisYLevelDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <span class="label">Position</span>
          <div class="row">
            <label v-for="option in axisYPosition" :key="option.index" class="type_many">
              <input v-model="AxisYLevelPosition" name="AxisYLevelPosition" type="radio" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="AxisYLevelFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="AxisYLevelStyle" dir="rtl" class="type70">
            <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input type_color_float">
          <label>Size / Color</label>
          <div class="input_flex_wrap_end">
            <input v-model="AxisYLevelSize"  type="number" />
            <j-color-picker v-model="AxisYLevelColor"></j-color-picker>
          </div>
        </div>
        <div class="type_common_input">
          <label>Space</label>
          <input v-model="AxisYLevelSpace" type="number" />
        </div>
        <div>
          <span class="label">Unit</span>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <label>Size</label>
              <input v-model="AxisYLevelUnitSize" type="number" />
            </div>
            <div class="type_common_input type_gray">
              <label>Format</label>
              <input v-model="AxisYLevelUnitFormat"  type="text" />
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Line
        <a class="close" v-on:click="collapsed['others'] = !collapsed['others']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['others']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="AxisYLineDisplay" name="AxisYLineDisplay" type="radio" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Stroke</label>
          <input v-model="AxisYLineWeight" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="AxisYLineColor" title="Color"></j-color-picker>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Grids
        <a class="close" v-on:click="collapsed['grid'] = !collapsed['grid']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['grid']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="AxisYGridDisplay"
                type="radio"
                name="AxisYGridDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Stroke</label>
          <input v-model="AxisYGridWeight" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="AxisYGridColor" title="Color"></j-color-picker>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-axis',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      general: true,
      others: false,
      grid: false,
    },
    bar_general: null,
    currentIcon: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    AxisYLevelDisplay: {
      get() { return this.chartItem.AxisYLevelDisplay },
      set(val) { this.setChartItem({ AxisYLevelDisplay: val })}
    },
    AxisYLevelPosition: {
      get() { return this.chartItem.AxisYLevelPosition },
      set(val) { this.setChartItem({ AxisYLevelPosition: val })}
    },
    AxisYLevelSpace: {
      get() { return this.chartItem.AxisYLevelSpace || 0 },
      set(val) { this.setChartItem({ AxisYLevelSpace: parseFloat(val || 0) })}
    },
    AxisYLevelFont: {
      get() { return this.chartItem.AxisYLevelFont },
      set(val) { this.setChartItem({ AxisYLevelFont: val })}
    },
    AxisYLevelStyle: {
      get() { return this.chartItem.AxisYLevelStyle },
      set(val) { this.setChartItem({ AxisYLevelStyle: val })}
    },
    AxisYLevelSize: {
      get() { return this.chartItem.AxisYLevelSize || 0 },
      set(val) { this.setChartItem({ AxisYLevelSize: parseFloat(val || 0) })}
    },
    AxisYLevelWeight: {
      get() { return this.chartItem.AxisYLevelWeight || 0 },
      set(val) { this.setChartItem({ AxisYLevelWeight: parseFloat(val || 0) })}
    },
    AxisYLevelColor: {
      get() { return this.chartItem.AxisYLevelColor },
      set(val) { this.setChartItem({ AxisYLevelColor: val })}
    },
    AxisYLevelUnitSize: {
      get() { return this.chartItem.AxisYLevelUnitSize || 0 },
      set(val) { this.setChartItem({ AxisYLevelUnitSize: parseFloat(val || 0) })}
    },
    AxisYLevelUnitFormat: {
      get() { return this.chartItem.AxisYLevelUnitFormat },
      set(val) { this.setChartItem({ AxisYLevelUnitFormat: val })}
    },
    AxisYLineDisplay: {
      get() { return this.chartItem.AxisYLineDisplay },
      set(val) { this.setChartItem({ AxisYLineDisplay: val })}
    },
    AxisYLineWeight: {
      get() { return this.chartItem.AxisYLineWeight || 0 },
      set(val) { this.setChartItem({ AxisYLineWeight: parseFloat(val || 0) })}
    },
    AxisYLineColor: {
      get() { return this.chartItem.AxisYLineColor },
      set(val) { this.setChartItem({ AxisYLineColor: val })}
    },
    AxisYBoundsAuto: {
      get() { return this.chartItem.AxisYBoundsAuto },
      set(val) { this.setChartItem({ AxisYBoundsAuto: val })}
    },
    AxisYBoundsMax: {
      get() { return this.chartItem.AxisYBoundsMax || 0 },
      set(val) { this.setChartItem({ AxisYBoundsMax: parseFloat(val || 0) })}
    },
    AxisYGridDisplay: {
      get() { return this.chartItem.AxisYGridDisplay },
      set(val) { this.setChartItem({ AxisYGridDisplay: val })}
    },
    AxisYGridWeight: {
      get() { return this.chartItem.AxisYGridWeight || 0 },
      set(val) { this.setChartItem({ AxisYGridWeight: parseFloat(val || 0) })}
    },
    AxisYGridColor: {
      get() { return this.chartItem.AxisYGridColor },
      set(val) { this.setChartItem({ AxisYGridColor: val })}
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
  }

}
</script>
