<template>
  <v-flex class="chart_properties" wrap>

    <div class="chart_properties_header">
      <span>Commons</span>
      <small class="type_ref01">Properties</small>
    </div>

    <div class="chart_properties_contents">

      <div class="header">
        Area
        <a class="close" v-on:click="collapsed['area'] = !collapsed['area']"></a>
      </div>

      <v-flex class="control" v-show="collapsed['area']">

        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="ChtcommAreaX" type="number" />
          <input v-model="ChtCommAreaY" type="number" />
        </div>

        <div class="type_common_select">
          <label>Font</label>
          <select v-model="ChtCommAreaFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>

      </v-flex>

    </div>

    <div class="chart_properties_contents">

      <div class="header">
        Texts
        <a class="close" v-on:click="collapsed['texts'] = !collapsed['texts']"></a>
      </div>

      <v-flex class="control" v-show="collapsed['texts']">

        <div v-if="ChartType == 'CUBE-A'">

          <span class="label">Title</span>

          <div class="type_inner">

            <div class="type_common_input" >
              <label class="type_gray second_depth">Display</label>
              <div class="row" >
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input v-model="ChtCommTitleVisible" type="radio" name="ChtCommTitleVisible" :value="option.value" />
                  {{option.text}}
                </label>
              </div>
            </div>

            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Font Size / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="ChtCommTitleSize" type="number"/>
                <j-color-picker v-model="ChtCommTitleColor"/>
              </div>
            </div>

          </div>

        </div>

        <div v-if="ChartType == 'TUBE'">

          <span class="label">Header</span>

          <div class="type_inner">
            
            <div class="type_common_input" >
              <label class="type_gray second_depth">Display</label>
              <div class="row" >
                <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
                  <input v-model="ChtCommHeaderVisible" type="radio" name="ChtCommHeaderVisible" :value="option.value" />
                  {{option.text}}
                </label>
              </div>
            </div>

            <div class="type_common_input">
              <label class="type_gray second_depth">y</label>
              <div class="input_flex_wrap_end">
                <input v-model="ChtCommHeaderY" type="number"/>
              </div>
            </div>

            <div class="type_common_input type_color_float">
              <label class="type_gray second_depth">Font Size / Color</label>
              <div class="input_flex_wrap_end">
                <input v-model="ChtCommHeaderSize" type="number"/>
                <j-color-picker v-model="ChtCommHeaderColor"/>
              </div>
            </div>

          </div>

        </div>

        <span class="label">Value</span>
        <div class="type_inner">
          <div class="type_common_input" >
            <label class="type_gray second_depth">y</label>
            <div class="input_flex_wrap_end">
              <input v-model="ChtCommValueY" type="number"/>
            </div>
          </div>
        </div>

      </v-flex>

    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-comm',
  mixins: [
    StaticOptions
  ],
  props: {
    ChartType : String
  },
  components: {
  },
  data: () => ({
    collapsed: {
      area: true,
      texts: true,
    },

    localCommons: {
      area:{},
      texts:{
        title:{},
        header:{},
        value:{}
      },
    },

  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    // ChtComm Area
    ChtcommAreaX: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.area.x ) },
      set(val) { 
        this.localCommons.area.x = parseFloat(val || 0) 
        this.updateAttr() 
      }
    },
    ChtCommAreaY: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.area.y ) },
      set(val) { 
        this.localCommons.area.y = parseFloat(val || 0) 
        this.updateAttr() 
      }
    },
    ChtCommAreaFont: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.area.font ) || 'roboto' },
      set(val) { 
        this.localCommons.area.font = val || 'roboto' 
        this.updateAttr() 
      }
    },
    
    ChtCommTitleVisible: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.texts.title.visible )  },
      set(val) { 
        this.localCommons.texts.title.visible = val 
        this.updateAttr() 
      }
    },
    
    ChtCommTitleSize: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.texts.title.tSize )  },
      set(val) { 
        this.localCommons.texts.title.tSize = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    
    ChtCommTitleColor: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.texts.title.tColor )  },
      set(val) { 
        this.localCommons.texts.title.tColor = val
        this.updateAttr() 
      }
    },
    
    ChtCommHeaderVisible: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.texts.header.visible )  },
      set(val) { 
        this.localCommons.texts.header.visible = val 
        this.updateAttr() 
      }
    },
    
    ChtCommHeaderY: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.texts.header.y )  },
      set(val) { 
        this.localCommons.texts.header.y = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    
    ChtCommHeaderSize: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.texts.header.tSize )  },
      set(val) { 
        this.localCommons.texts.header.tSize = parseFloat(val || 0)
        this.updateAttr() 
      }
    },
    
    ChtCommHeaderColor: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.texts.header.tColor )  },
      set(val) { 
        this.localCommons.texts.header.tColor = val
        this.updateAttr() 
      }
    },
    
    ChtCommValueY: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.texts.value.y )  },
      set(val) { 
        this.localCommons.texts.value.y =  parseFloat(val || 0)
        this.updateAttr() 
      }
    },
  },
  watch: {
    'chartItem.Chtcomm': {
      handler(val) {
        if (!val) return
        // console.log('Chtcomm', val)
        this.localCommons = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    updateAttr() {
      this.setChartItem({ Chtcomm: JSON.parse(JSON.stringify(this.localCommons)) })
    },
  },
}
</script>
