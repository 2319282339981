<template>
  <v-flex v-model="bar3d_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>General</span>
      <small class="type_ref01">Properties</small>
    </div>

    <div class="chart_properties_contents">
      <div class="header">
        Circles
        <a class="close" v-on:click="collapsed['general'] = !collapsed['general']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['general']">
        <div class="type_common_input">
          <label>Center Coordinates</label>
          <input v-model="CircleCenterX" type="text" class="type_double" />
          <input v-model="CircleCenterY" type="text" />
        </div>
        <div class="type_common_input">
          <span class="label">Cap Style</span>
          <div class="row">
            <label v-for="option in capStyleOptions" :key="option.index" class="type_many">
              <input v-model="CircleCapStyle" type="radio" name="CircleCapStyle" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Radius</label>
          <input v-model="CircleMultiRadius" type="number" />
        </div>
        <div class="type_common_input">
          <label>Distance</label>
          <input v-model="CircleMultiDistance" type="number" />
        </div>
        <div class="type_common_input">
          <label for>Stroke</label>
          <input v-model="CircleMultiStroke" type="number" />
        </div>
        <div class="type_common_input column align_end">
          <span class="label alignself_start">Legend Position</span>
          <div class="row">
            <label v-for="option in legendPositionOptions" :key="option.index" class="type_many">
              <input
                v-model="CircleMultiLegends"
                type="radio"
                name="CircleMultiLegends"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div>
          <label class="label">Color Set</label>
          <div class="type_inner">
            <div class="type_common_input">
              <label class="type_gray" style="padding-top: 1.2rem;">Back</label>
              <j-color-picker v-model="CircleMultiBkColors" multi />
            </div>
            <div class="type_common_input">
              <label class="type_gray" style="padding-top: 1.2rem;">Front</label>
              <j-color-picker v-model="CircleMultiFtColors" multi />
            </div>
          </div>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import _ChartDataDefault from '@/primitives/_chartDonutDefault'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-circle',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      general: true,
    },
    elementsNumByType: {
      Single: 1,
      Double: 2,
      Triple: 3,
    },
    exp: {
      sql: false,
    },
    // local static options
    capStyleOptions: [],
    legendPositionOptions: [
      { text: "Inside", value: "Inside" },
      { text: "Outside", value: "Outside" },
    ],

    bar3d_general: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    CircleCenterX: {
      get() { return this.chartItem.CircleCenterX || 0 },
      set(val) { this.setChartItem({ CircleCenterX: parseFloat(val || 0) })}
    },
    CircleCenterY: {
      get() { return this.chartItem.CircleCenterY || 0 },
      set(val) { this.setChartItem({ CircleCenterY: parseFloat(val || 0) })}
    },
    CircleCapStyle: {
      get() { return this.chartItem.CircleCapStyle },
      set(val) { this.setChartItem({ CircleCapStyle: val })}
    },
    CircleMultiRadius: {
      get() { return this.chartItem.CircleMultiRadius || 0 },
      set(val) { this.setChartItem({ CircleMultiRadius: parseFloat(val || 0) })}
    },
    CircleMultiDistance: {
      get() { return this.chartItem.CircleMultiDistance || 0 },
      set(val) { this.setChartItem({ CircleMultiDistance: parseFloat(val || 0) })}
    },
    CircleMultiStroke: {
      get() { return this.chartItem.CircleMultiStroke || 0 },
      set(val) { this.setChartItem({ CircleMultiStroke: parseFloat(val || 0) })}
    },
    CircleMultiLegends: {
      get() { return this.chartItem.CircleMultiLegends },
      set(val) { this.setChartItem({ CircleMultiLegends: val })}
    },
    CircleMultiBkColors: {
      get() { return this.chartItem.CircleMultiBkColors },
      set(val) { this.setChartItem({ CircleMultiBkColors: val })}
    },
    CircleMultiFtColors: {
      get() { return this.chartItem.CircleMultiFtColors },
      set(val) { this.setChartItem({ CircleMultiFtColors: val })}
    },
  },
  created() {
    this.capStyleOptions = [
      { text: 'Section', value: 'section' },
      { text: 'Round', value: __C.SVG.STROKE_LINECAP_ROUND },
    ]
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem'], ['setDatagridItem']),

  },
}
</script>
