<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Text</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <j-alert
          v-model="msgOpen"
          :title="msgInfo.title"
          :type="msgInfo.type"
          :message="msgInfo.message"
          :button="msgInfo.button"
          :button-text="msgInfo.buttonText"
          @yes="yes()"
          @cancel="msgOpen = false"
        />
      <div class="header">
        <span 
          data-grid-quickbtn="btn" 
          class="label jcon_temp_plus type_header btn_after" 
          style="font-family: roboto !important; color: #83d2f5;" 
          @click="appendAttr('Text')"
        >Text</span>
        <a class="close" v-on:click="collapsed['text'] = !collapsed['text']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['text']">
        <div v-for="(attr, i) in localText.Text" class="content_list" :key="attr.index">
          <div class="header" style="margin-bottom: 2px;">
            <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'Text')">No. {{ i + 1 }}</span>
          </div>
          <div>
            <div class="type_inner">
              <div class="type_common_input">
                <textarea 
                  v-model="attr.text" 
                  class="as_input_text" 
                  @blur="(e) => { __F_.taDefaultHeight(e.target) }"
                  @focus="(e) => { __F_.taAutoHeight(e.target) }"
                  @input="(e) => { __F_.taAutoHeight(e.target);updateAttr(); }"
                />
              </div>
              <div class="type_common_input">
                <label class="type_gray second_depth">
                  Coordinates
                  <small class="type_ref03">(x,y)</small>
                </label>
                <input v-model="attr.x" type="number" class="type_mgl0 type_double" @input="updateAttr" />
                <input v-model="attr.y" type="number" @input="updateAttr" />
              </div>
              <div class="type_common_select">
                <label class="type_gray second_depth">Font</label>
                <select v-model="attr.font" dir="rtl" @input="(e) => { updateAttr(e.target.value, i, 'font', 'Text') }">
                  <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                </select>
              </div>
              <div class="type_common_select">
                <label class="type_gray second_depth">Style</label>
                <select v-model="attr.class" class="type70" dir="rtl" @input="(e) => { updateAttr(e.target.value, i, 'class', 'Text') }">
                  <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                </select>
              </div>
              <div class="type_common_input">
                <label class="type_gray second_depth">Size / Color</label>
                <div class="row">
                  <input v-model="attr.size" class="type_double" type="number" @input="updateAttr" />
                  <j-color-picker v-model="attr.color" @input="updateAttr"/>
                </div>
              </div>
              <div class="type_common_input">
                <label class="type_gray label second_depth">Alignment</label>
                <div class="row">
                  <label v-for="option in alignHOptions" :key="option.index" class="type_many">
                    <input v-model="attr.align" type="radio" :name="`align_text_${i}`" :value="option.value" @input="(e) => { updateAttr(e.target.value, i, 'align', 'Text') }"/>
                    {{option.text}}
                  </label>
                </div>
              </div>
              <div class="type_common_input">
                <label class="type_gray label second_depth">Direction</label>
                <div class="row">
                  <label v-for="option in directionOptions" :key="option.index" class="type_many">
                    <input v-model="attr.direction" type="radio" :name="`direction_text_${i}`" :value="option.value" @input="(e) => { updateAttr(e.target.value, i, 'direction', 'Text') }"/>
                    {{option.text}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        <span 
          data-grid-quickbtn="btn" 
          class="label jcon_temp_plus type_header btn_after_55" 
          style="font-family: roboto !important; color: #83d2f5;" 
          @click="appendAttr('Filter')"
        >Filter Text</span>
        <a class="close" v-on:click="collapsed['filter'] = !collapsed['filter']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['filter']">
        <div v-for="(attr, i) in localText.Filter" class="content_list" :key="attr.index">
          <div class="header">
            <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'Filter')">No. {{ i + 1 }}</span>
          </div>
          <div>
            <div class="type_inner">
              <div class="type_common_input">
                <input class="text_input type_100" v-model="attr.name" type="text" @input="updateAttr" />
              </div>
              <div class="type_common_input">
                <label class="type_gray second_depth">
                  Coordinates
                  <small class="type_ref03">(x,y)</small>
                </label>
                <input v-model="attr.x" type="number" class="type_mgl0 type_double" @input="updateAttr" />
                <input v-model="attr.y" type="number" @input="updateAttr" />
              </div>
              <div class="type_common_select">
                <label class="type_gray second_depth">Font</label>
                <select v-model="attr.font" dir="rtl" @input="(e) => { updateAttr(e.target.value, i, 'font', 'Filter') }">
                  <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                </select>
              </div>
              <div class="type_common_select">
                <label class="type_gray second_depth">Style</label>
                <select v-model="attr.class" class="type70" dir="rtl" @input="(e) => { updateAttr(e.target.value, i, 'class', 'Filter') }">
                  <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
                </select>
              </div>
              <div class="type_common_input">
                <label class="type_gray second_depth">Size / Color</label>
                <div class="row">
                  <input v-model="attr.size" class="type_double" type="number" @input="updateAttr" />
                  <j-color-picker v-model="attr.color" @input="updateAttr"/>
                </div>
              </div>
              <div class="type_common_input">
                <label class="type_gray label second_depth">Alignment</label>
                <div class="row">
                  <label v-for="option in alignHOptions" :key="option.index" class="type_many">
                    <input v-model="attr.align" type="radio" :name="`align_filter_text_${i}`" :value="option.value" @input="(e) => { updateAttr(e.target.value, i, 'align', 'Filter') }"/>
                    {{option.text}}
                  </label>
                </div>
              </div>
              <div class="type_common_input">
                <label class="type_gray label second_depth">Direction</label>
                <div class="row">
                  <label v-for="option in directionOptions" :key="option.index" class="type_many">
                    <input v-model="attr.direction" type="radio" :name="`direction_filter_text_${i}`" :value="option.value" @input="(e) => { updateAttr(e.target.value, i, 'direction', 'Filter') }"/>
                    {{option.text}}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        <span 
          data-grid-quickbtn="btn" 
          class="label jcon_temp_plus type_header btn_after_more" 
          style="font-family: roboto !important; color: #83d2f5;" 
          @click="appendAttr('Sql')"
        >SQL Text</span>
        <a class="close" v-on:click="collapsed['sql'] = !collapsed['sql']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['sql']">
        <div v-for="(attr, i) in localText.Sql" class="content_list" :key="attr.index">
          <div class="header">
            <span data-grid-quickbtn="btn" class="label jcon_temp_minus type_header_before" @click="clickRemoveBtn(i, 'Sql')">No.{{ i + 1 }}</span>
          </div>
          <div class="type_inner">
            <v-btn class="type_openTextarea type100 margin_t_5 margin_bt_2" @click.stop="openTextarea(i, true)">{{ getQueryParts(i) }}</v-btn>
            <j-modal-scripter 
              v-model="attr.query" 
              title="Tab SQL Query"
              sub-title="Access Database"
              :buttons="{validate: true, save:false}"
              :message="validated[`SQL_${i}`].message"
              :opened="exp[`F_${i}`]"
              :valid="validated[`SQL_${i}`].valid"
              @close="closeScripter(`F_${i}`)"
              @validate="onValidate(i)"
            />
            <div class="type_common_input">
              <label class="type_gray second_depth">
                Coordinates
                <small class="type_ref03">(x,y)</small>
              </label>
              <input v-model="attr.x" type="text" class="type_mgl0 type_double" @input="updateAttr" />
              <input v-model="attr.y" type="text" @input="updateAttr" />
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Font</label>
              <select v-model="attr.font" dir="rtl" @input="(e) => { updateAttr(e.target.value, i, 'font', 'Sql') }">
                <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_select">
              <label class="type_gray second_depth">Style</label>
              <select v-model="attr.style" class="type70" dir="rtl" @input="(e) => { updateAttr(e.target.value, i, 'style', 'Sql') }">
                <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
              </select>
            </div>
            <div class="type_common_input">
              <label class="type_gray second_depth">Size / Color</label>
              <div class="row">
                <input v-model="attr.size" class="type_double" type="number" @input="updateAttr" />
                <j-color-picker v-model="attr.color" @input="updateAttr"/>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray label second_depth">Alignment</label>
              <div class="row">
                <label v-for="option in alignHOptions" :key="option.index" class="type_many">
                  <input v-model="attr.align" type="radio" :name="`align_sql_${i}`" :value="option.value" @input="(e) => updateAttr(e.target.value, i, 'align', 'Sql')"/>
                  {{option.text}}
                </label>
              </div>
            </div>
            <div class="type_common_input">
              <label class="type_gray label second_depth">Direction</label>
              <div class="row">
                <label v-for="option in directionOptions" :key="option.index" class="type_many">
                  <input v-model="attr.direction" type="radio" :name="`direction_sql_text_${i}`" :value="option.value" @input="(e) => { updateAttr(e.target.value, i, 'direction', 'Sql') }"/>
                  {{option.text}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div style="height: 10rem;"/>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import __F from '@/primitives/_function_'
import _DataDefault from '@/primitives/_dataformText'
import { mapState, mapMutations } from "vuex"
import { ChartLibraryService } from "@/services"
import { QueryValidationMixin } from "@/mixins/sqlValidator"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-text',
  mixins: [
    QueryValidationMixin,
    StaticOptions
  ],
  data: () => ({
    // Service Instances -------------
    chartLibraryService: null,

    collapsed: {
      text: true,
      filter: true,
      sql: true,
    },
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Delete", "No", "Cancel"]
    },
    localText: {
      Text: [],
      Filter: [],
      Sql: []
    },
    exp: {},
    validated: {},

    yes: null,
    msgOpen: false,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    __F_() { return __F }

  },
  watch: {
    'chartItem.Text': {
      handler(val) {
        if (!val) return
        this.localText = JSON.parse(JSON.stringify(val))
        
        let exp_ = {}
        let validated_ = {}
        this.localText.Sql.forEach((_, i) => {
          exp_[`F_${i}`] = false
          validated_[`SQL_${i}`] = { valid: true, message: ''}
        })

        this.exp = exp_
        this.validated = validated_
      },
      deep: true
    }
  },
  created() {
    this.chartLibraryService = new ChartLibraryService()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
    
    appendAttr(name) {
      this.localText[name].push(_DataDefault[name][0])
      this.updateAttr()
    },
    clickRemoveBtn(i, j, el) {
      this.yes = () => {
        this.msgOpen = false
        this.removeAttr(i, j, el)
        this.yes = () => {}
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Remove Style"
      this.msgInfo.message = "Do you want to delete this style?"
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    closeScripter(name) {
      this.exp[name]=false
      this.updateAttr()
    },
    
    getQueryParts(i) {
      return this.localText.Sql[i].query ? this.localText.Sql[i].query.substring(0, 50) + ' ...' : ''
    },
    openTextarea(i, opened) {
      this.exp[`F_${i}`] = opened
    },
    removeAttr(index, name) {
      this.localText[name][index] = null
      this.localText[name] = this.localText[name].filter(attr => !!attr)
      this.updateAttr()
    },
    updateAttr(val, i, el, name) {
      if(el) this.localText[name][i][el] = val
      this.setChartItem({ Text: JSON.parse(JSON.stringify(this.localText)) })
    },
    onSave() {
      this.$emit('save')
    },
    onValidate(i) {
      this.validateSql(this.localText.Sql[i].query).then(res => {
        this.validated[`SQL_${i}`] = res
      })
    },
  }
}
</script>
