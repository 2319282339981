<template>
  <v-flex v-model="bar3d_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Bar</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Bar
        <a class="close type_critical" v-on:click="collapsed['barChart'] = !collapsed['barChart']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['barChart']">
        <div class="type_common_input">
          <span class="label">Auto Size</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="BarAutoSize" type="radio" name="BarAutoSize" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>
            Thickness
            <small class="type_ref03">(Fixed)</small>
          </label>
          <input v-model="BarThickness" type="number" />
        </div>
        <div class="type_common_input">
          <label>
            Distance
            <small class="type_ref03">(Fixed)</small>
          </label>
          <input v-model="BarDistance" type="number" />
        </div>
        <div v-if="radiusAva" class="type_common_input">
          <label>
            Raduis
            <small class="type_ref03">(Embossed only)</small>
          </label>
          <input v-model="BarRadius" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker
            v-model="BarColorSet"
            multi
            gradation
            title="Color"
            :gradation-type="BarColorType"
            @gradate="v => BarColorType = v"
          ></j-color-picker>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Border
        <a class="close" v-on:click="collapsed['border'] = !collapsed['border']"></a>
      </div>
      <v-flex wrap class="control chart_form" style="padding:0; padding-left: 2rem;" v-show="collapsed['border']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input 
                v-model="BarBorderDisplay" 
                type="radio" 
                name="BarBorderDisplay" 
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="form_content historgram_line_form">
          <v-flex xs12 class="label">Weights</v-flex>
          <v-flex>
            <v-flex xs12 class="wrap__input column_input">
              <v-text-field
                v-for="(weight, i) in BarBorderWeight"
                :label="`${i+1}`"
                :key="`weight${i}`"
                :value="weight"
                @input="v => { updateAttr(Number(v), i, 'weight') }"
              ></v-text-field>
            </v-flex>
          </v-flex>
          <v-flex xs12 class="label">Opacity</v-flex>
          <v-flex>
            <v-flex xs12 class="wrap__input column_input">
              <v-text-field
                v-for="(opacity, i) in BarBorderOpacity"
                :label="`${i+1}`"
                :key="`opacity${i}`"
                :value="opacity"
                @input="v => { updateAttr(Number(v), i, 'opacity') }"
              ></v-text-field>
            </v-flex>
          </v-flex>
          <div class="type_common_input color-pick">
            <j-color-picker v-model="BarBorderColor" multi title="Color"></j-color-picker>
          </div>
        </div>
      </v-flex>
    </div>
    <div v-if="backgroundAva" class="chart_properties_contents">
      <div class="header">
        Background
        <a class="close" v-on:click="collapsed['background'] = !collapsed['background']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['background']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="BarBackDisplay" type="radio" name="BarBackDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="BarBackColor" title="Color"></j-color-picker>
        </div>
      </v-flex>
    </div>
    <div v-if="seriesAva" class="chart_properties_contents">
      <div class="header">
        Series
        <small class="type_ref03">(Name)</small>
        <a class="close" v-on:click="collapsed['series'] = !collapsed['series']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['series']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="BarSeriesDisplay"
                type="radio"
                name="BarSeriesDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>
            Space
            <small class="type_ref03">(from the chart)</small>
          </label>
          <input v-model="BarSeriesSpace" type="text" />
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="BarSeriesFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="BarSeriesStyle" dir="rtl" class="type70">
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="BarSeriesSize" type="text" />
        </div>
        <div class="type_common_input">
          <label class="type_auto type_ab right30">
            <j-check-box v-model="BarSeriesAutoColor" true-value="Y" false-value="N" />Auto
          </label>
          <j-color-picker title="Color" v-model="BarSeriesColor"></j-color-picker>
        </div>
      </v-flex>
    </div>
    <div v-if="valueAva" class="chart_properties_contents">
      <div class="header">
        Value
        <a class="close" v-on:click="collapsed['value'] = !collapsed['value']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['value']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="BarValueDisplay"
                type="radio"
                name="BarValueDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>
            Space
            <small class="type_ref03">(from the bar, Tube only)</small>
          </label>
          <input v-model="BarValueSpace" type="number" />
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="BarValueFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="BarValueStyle" dir="rtl" class="type70">
            <option
              v-for="option in fontStyleOptions"
              :key="option.index"
              :value="option.value"
            >{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="BarValueSize" type="number" />
        </div>
        <div class="type_common_input">
          <label class="type_auto type_ab right30">
            <j-check-box v-model="BarValueAutoColor" true-value="Y" false-value="N" />Auto
          </label>
          <j-color-picker title="Color" v-model="BarValueColor"></j-color-picker>
        </div>
        <div class="type_common_input">
          <label>Round</label>
          <input v-model="BarValueRound" type="number" />
        </div>
        <div v-if="chartTube" class="type_common_input">
          <span class="label">Position</span>
          <div class="row">
            <label v-for="option in valuePositionOptions" :key="option.index" class="type_many">
              <input
                v-model="BarValuePosition"
                type="radio"
                name="BarValuePosition"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
      </v-flex>
    </div>
    <div v-if="unitsAva" class="chart_properties_contents">
      <div class="header">
        Units
        <a class="close" v-on:click="collapsed['units'] = !collapsed['units']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['units']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input
                v-model="BarUnitDisplay"
                type="radio"
                name="BarUnitDisplay"
                :value="option.value"
              />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Format</label>
          <input v-model="BarUnitFormat" type="text" class="type22" />
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="BarUnitSize" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="BarUnitColor" title="Color"></j-color-picker>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-l-chart-bar-solid',
  mixins: [
    StaticOptions
  ],
  props: {

    // -------------------------------------------------------   Elements displayed received from modal
    background: { type: Boolean, default: true },
    chartName: {type: String, default: 'solid'  },
    chartType: {type: String, default: 'Box'  },
    series: { type: Boolean, default: true },
    value: { type: Boolean, default: true },
    units: { type: Boolean, default: true },
  },
  data: () => ({
    bar3d_general: null,
    collapsed: {
      barChart: true,
      border: true,
      background: false,
      series: false,
      value: false,
      units: false,
    },
    barValueOption: [
      { text: "None", val: "none" },
      { text: "Max", val: "max" },
      { text: "Min/Max", val: "minmax" },
      { text: "Last", val: "last" }
    ],
    barSeriesOptionsOption: [
      { text: "Left", val: "left" },
      { text: "Above", val: "above" },
    ],
    localBarBorder: {
      // dash      : [],
      // weight    : [],
      // color     : [],
      // colorType : '',
      // opacity   : [],
    }
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['bar']),

    chartTube() { return this.chartType != 'Tube' },
    backgroundAva() {
      if (!this.chartItem.ChartType) return
      if (this.chartItem.ChartNo === void 0)
        this.background ? this.setChartItem({ BarBackDisplay: 'Y' }) : this.setChartItem({ BarBackDisplay: 'N' })
      return this.background
    },
    seriesAva() {
      if (!this.chartItem.ChartType) return
      if (this.chartItem.ChartNo === void 0)
        this.series ? this.setChartItem({ BarSeriesDisplay: 'Y' }) : this.setChartItem({ BarSeriesDisplay: 'N' })
      return this.series
    },
    unitsAva() {
      if (!this.chartItem.ChartType) return
      if (this.chartItem.ChartNo === void 0)
        this.units ? this.setChartItem({ BarUnitDisplay: 'Y' }) : this.setChartItem({ BarUnitDisplay: 'N' })
      return this.units
    },
    valueAva() {
      if (!this.chartItem.ChartType) return
      if (this.chartItem.ChartNo === void 0)
        this.value ? this.setChartItem({ BarValueDisplay: 'Y' }) : this.setChartItem({ BarValueDisplay: 'N' })
      return this.value
    },
    radiusAva() {
      if(this.chartName == 'history' || this.chartName == 'progress') {
        return false
      } else {
        return true
      }
    },
    valuePositionOptions() {
      return this.valuePositionType == 'Standard' ? this.alignTBOptions : this.alignVOptions
    },
    valuePositionType() {
      if(!this.chartItem.BarValuePosition) return 'A'
      if(this.chartType == 'Stacked Vertical' || (this.chartName == 'history' && this.chartType == 'Stack')) return 'Stack'
      return 'Standard'

      // if (this.chartItem.ChartNo === void 0)
      // this.value ? this.setChartItem({ BarValueDisplay: 'Y' }) : this.setChartItem({ BarValueDisplay: 'N' })
      // return this.value
    },
    valueOpsitionType() {
      if(!this.chartItem.BarValuePosition) return 'A'
      if(['top', 'middle', 'bottom'].includes(this.chartItem.BarValuePosition)) return __C.TYPE_STANDARD
      return __C.TYPE_SEPARATE
    
      // if (this.chartItem.ChartNo === void 0)
      // this.value ? this.setChartItem({ BarValueDisplay: 'Y' }) : this.setChartItem({ BarValueDisplay: 'N' })
      // return this.value
    },

    BarAutoSize: {
      get() { return this.bar.BarAutoSize },
      set(val) { this.setChartItem({ BarAutoSize: val }) }
    },
    BarThickness: {
      get() { return this.bar.BarThickness || 0 },
      set(val) { this.setChartItem({ BarThickness: parseFloat(val || 0) }) }
    },
    BarDistance: {
      get() { return this.bar.BarDistance || 0 },
      set(val) { this.setChartItem({ BarDistance: parseFloat(val || 0) }) }
    },
    BarRadius: {
      get() { return this.bar.BarRadius || 0 },
      set(val) { this.setChartItem({ BarRadius: parseFloat(val || 0) }) }
    },
    BarBasedCylinder: {
      get() { return this.bar.BarBasedCylinder || 0 },
      set(val) { this.setChartItem({ BarBasedCylinder: parseFloat(val || 0) }) }
    },
    BarColorType: {
      get() { return this.bar.BarColorType },
      set(val) { this.setChartItem({ BarColorType: val }) }
    },
    BarColorSet: {
      get() { return this.bar.BarColorSet || [] },
      set(val) { this.setChartItem({ BarColorSet: val }) }
    },
    BarReflection: {
      get() { return this.bar.BarReflection },
      set(val) { this.setChartItem({ BarReflection: val }) }
    },

    BarBorderDisplay: {
      get() { return this.localBarBorder.display || 'Y' },
      set(val) { this.localBarBorder.display = val; this.updateAttr() } 
    },
    BarBorderColor: {
      get() { return this.localBarBorder.color || Array.from({ length: 5 }, () => 0.5) },
      set(val) { this.localBarBorder.color = val; this.updateAttr() }
    },
    BarBorderWeight() { return this.localBarBorder.weight || Array.from({ length: 5 }, () => 0.5) },
    BarBorderOpacity() { return this.localBarBorder.opacity || Array.from({ length: 5 }, () => 1) },

    BarBackDisplay: {
      get() { return this.bar.BarBackDisplay },
      set(val) { this.setChartItem({ BarBackDisplay: val }) }
    },
    BarBackColor: {
      get() { return this.bar.BarBackColor },
      set(val) { this.setChartItem({ BarBackColor: val }) }
    },
    BarSeriesDisplay: {
      get() { return this.bar.BarSeriesDisplay },
      set(val) { this.setChartItem({ BarSeriesDisplay: val }) }
    },
    BarSeriesSpace: {
      get() { return this.bar.BarSeriesSpace || 0 },
      set(val) { this.setChartItem({ BarSeriesSpace: parseFloat(val || 0) }) }
    },
    BarSeriesFont: {
      get() { return this.bar.BarSeriesFont },
      set(val) { this.setChartItem({ BarSeriesFont: val }) }
    },
    BarSeriesStyle: {
      get() { return this.bar.BarSeriesStyle },
      set(val) { this.setChartItem({ BarSeriesStyle: val }) }
    },
    BarSeriesSize: {
      get() { return this.bar.BarSeriesSize || 0 },
      set(val) { this.setChartItem({ BarSeriesSize: parseFloat(val || 0) }) }
    },
    BarSeriesAutoColor: {
      get() { return this.bar.BarSeriesAutoColor },
      set(val) { this.setChartItem({ BarSeriesAutoColor: val }) }
    },
    BarSeriesColor: {
      get() { return this.bar.BarSeriesColor },
      set(val) { this.setChartItem({ BarSeriesColor: val }) }
    },
    BarValueDisplay: {
      get() { return this.bar.BarValueDisplay },
      set(val) { this.setChartItem({ BarValueDisplay: val }) }
    },
    BarValueSpace: {
      get() { return this.bar.BarValueSpace || 0 },
      set(val) { this.setChartItem({ BarValueSpace: parseFloat(val || 0) }) }
    },
    BarValueFont: {
      get() { return this.bar.BarValueFont },
      set(val) { this.setChartItem({ BarValueFont: val }) }
    },
    BarValueStyle: {
      get() { return this.bar.BarValueStyle },
      set(val) { this.setChartItem({ BarValueStyle: val }) }
    },
    BarValueSize: {
      get() { return this.bar.BarValueSize || 0 },
      set(val) { this.setChartItem({ BarValueSize: parseFloat(val || 0) }) }
    },
    BarValueAutoColor: {
      get() { return this.bar.BarValueAutoColor },
      set(val) { this.setChartItem({ BarValueAutoColor: val }) }
    },
    BarValueColor: {
      get() { return this.bar.BarValueColor },
      set(val) { this.setChartItem({ BarValueColor: val }) }
    },
    BarValueRound: {
      get() { return this.bar.BarValueRound || 0 },
      set(val) { this.setChartItem({ BarValueRound: parseFloat(val || 0) }) }
    },
    BarValuePosition: {
      get() { return this.bar.BarValuePosition },
      set(val) { this.setChartItem({ BarValuePosition: val }) }
    },
    BarUnitDisplay: {
      get() { return this.bar.BarUnitDisplay },
      set(val) { this.setChartItem({ BarUnitDisplay: val }) }
    },
    BarUnitFormat: {
      get() { return this.bar.BarUnitFormat },
      set(val) { this.setChartItem({ BarUnitFormat: val }) }
    },
    BarUnitSize: {
      get() { return this.bar.BarUnitSize || 0 },
      set(val) { this.setChartItem({ BarUnitSize: parseFloat(val || 0) }) }
    },
    BarUnitColor: {
      get() { return this.bar.BarUnitColor },
      set(val) { this.setChartItem({ BarUnitColor: val }) }
    },
  },
  watch: {
    'chartItem.BarBorder': {
      handler(val) {
        if (!val) return
        this.localBarBorder = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    updateAttr(val, i, el) {
      if(el) {
        if(typeof this.localBarBorder[el] == 'undefined') this.localBarBorder[el] = Array.from({ length: 5 }, () => val)
        this.localBarBorder[el][i] = val
      }
      this.setChartItem({ BarBorder: JSON.parse(JSON.stringify(this.localBarBorder)) })
    },
  }
}
</script>

<style>
</style>
