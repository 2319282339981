import __C from '../../../../includes/primitives/_constant_'

export default {

  // ----------------------------------------------- DATABASE
  Database: {
    DatabasePreviewMode: 'Chart',
    DatabaseAccessMode: 'Json',
    DatabaseJson: '',
    DatabaseQuery: '',
    DatabaseSP: '',
  },


  // ----------------------------------------------- CANVAS
  Canvas: {
    CanvasWidth: 200,
    CanvasHeight: 200,
    CanvasBorderWeight: 0.3,
    CanvasBorderColor: '#F4F5F5',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 0.2,

    CanvasChartX: 150,
    CanvasChartY: 120,
    CanvasChartWidth: 600,
    CanvasChartHeight: 250,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#757575',
    CanvasChartOpacity: 0.02,
    CanvasChartSpace: 10,

    CanvasFontFamily: 'roboto',
  },


  // ----------------------------------------------- LEGENDS
  Legends: {
    LegendDisplay: 'Y',
    LegendX: 50,
    LegendY: 324,
    LegendSpaceY: 80,
    LegendLineSpace: 15.5,
  
    LegendBulletDisplay: 'Y',
    LegendBulletSize: 10,
    LegendBulletSpace: 5,

    LegendSeriesFont: 'roboto',
    LegendSeriesStyle: 'regular',
    LegendSeriesSize: 11,
    LegendSeriesColor: '#757575',
    LegendSeriesSpace: 10,

    LegendValueData: 'Percentage',
    LegendValueSize: 10,
    LegendValueColor: '#44A9DF',
    LegendValueRound: 1,

    LegendUnitFormat: '%',
    LegendUnitSize: 9,
    LegendUnitColor: '#44A9DF',
  },


   // ----------------------------------------------- Circle
   Circle: {
    CircleType          : 'Single', // Single, Double, Triple,
    CircleCenterX       : 100,
    CircleCenterY       : 90,
    CircleRadius        : [57, 50, 53],
    CircleStroke        : [5, 10, 3],
    CircleBkColor       : ['#EFEFEF', '#EFEFEF', '#fff',],
    CircleFtColor       : ['#B4E3FA', '#CDDC37', '#F7BACF',],
    CircleCapStyle      : 'round',

    CircleGaugeDisplay  : 'Y',
    CircleGaugeRadius   : [62],
    CircleGaugeCount    : [50],
    CircleGaugeLength   : [5],
    CircleGaugeStroke   : [1],
    CircleGaugeColor    : ['#E0E0DF'],

    CircleGridDisplay   : 'Y',
    CircleGridY         : 240, // to be Deleted
    CircleGridLenH      : 60,
    CircleGridLenV      : 20,
    CircleGridStroke    : 1,
    CircleGridColor     : '#E0E0DF',

    CircleIconDisplay   : 'Y',
    CircleIconUrl       : `${__C.HOST_NAME_RESOURCE}/images/icons/fnt-047-1562551398.svg`, // Gear Font Icon
    CircleIconName      : 'FNT-047',
    CircleIconY         : 140,
    CircleIconSize      : 50,
  },

  // ----------------------------------------------- Value
  Value: [
    {
      ValueDisplay    : 'Y',
      ValueData       : 0,
      ValueType       : 'value',
      ValueY          : 85,
      ValueFont       : 'roboto',
      ValueStyle      : 'regular',
      ValuetSize      : 18,
      ValueAlign      : 'center',
      ValueColor      : '#F7BACF',
      ValueUnitFormat : '',
      ValueUnitSize   : 16,
    },
    {
      ValueDisplay    : 'Y',
      ValueData       : 1,
      ValueType       : 'value',
      ValueY          : 105,
      ValueFont       : 'roboto',
      ValueStyle      : 'regular',
      ValuetSize      : 13,
      ValueAlign      : 'right',
      ValueColor      : '#BDBCBC',
      ValueUnitFormat : '',
      ValueUnitSize   : 12,
    },
    {
      ValueDisplay    : 'Y',
      ValueData       : 2,
      ValueType       : 'value',
      ValueY          : 105,
      ValueFont       : 'roboto',
      ValueStyle      : 'regular',
      ValuetSize      : 13,
      ValueAlign      : 'left',
      ValueColor      : '#BDBCBC',
      ValueUnitFormat : '',
      ValueUnitSize   : 12,
    },
  ],

  DataItems: [

              {title: 'Plan',   percentage: 25, value:300,},
              {title: 'Actual', percentage: 50, value:200,},
              {title: 'Delta',  percentage: 75, value:100,},
              {title: 'Extra',  percentage: 100, value:300,},
  ],




}


