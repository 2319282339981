<template>
  <v-flex class="chart_properties" wrap>

    <div class="chart_properties_header">
      <span>Commons</span>
      <small class="type_ref01">Properties</small>
    </div>

    <div class="chart_properties_contents">

      <div class="header">
        Area
        <a class="close" v-on:click="collapsed['area'] = !collapsed['area']"></a>
      </div>

      <v-flex class="control" v-show="collapsed['area']">

        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input v-model="ChtcommAreaX" type="number" />
          <input v-model="ChtCommAreaY" type="number" />
        </div>

        <div class="type_common_input" >
          <label>Link</label>
          <div class="row" >
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="ChtCommAreaLink" type="radio" name="ChtCommAreaLink" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>

        <div class="type_common_select">
          <label>Font</label>
          <select v-model="ChtCommAreaFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>

      </v-flex>

    </div>

  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-comm',
  mixins: [
    StaticOptions
  ],
  props: {
    ChartType : String
  },
  components: {
  },
  data: () => ({
    collapsed: {
      area: true,
      texts: true,
    },

    localCommons: {
      area:{},
    },

  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    // ChtComm Area
    ChtcommAreaX: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.area.x ) },
      set(val) { 
        this.localCommons.area.x = parseFloat(val || 0) 
        this.updateAttr() 
      }
    },
    ChtCommAreaY: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.area.y ) },
      set(val) { 
        this.localCommons.area.y = parseFloat(val || 0) 
        this.updateAttr() 
      }
    },
    ChtCommAreaFont: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.area.font ) || 'roboto' },
      set(val) { 
        this.localCommons.area.font = val || 'roboto' 
        this.updateAttr() 
      }
    },
    
    ChtCommAreaLink: {
      get() { return ( this.chartItem.Chtcomm && this.chartItem.Chtcomm.area.link )  },
      set(val) { 
        this.localCommons.area.link = val 
        this.updateAttr() 
      }
    },

  },
  watch: {
    'chartItem.Chtcomm': {
      handler(val) {
        if (!val) return
        // console.log('Chtcomm', val)
        this.localCommons = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    updateAttr() {
      this.setChartItem({ Chtcomm: JSON.parse(JSON.stringify(this.localCommons)) })
    },
  },
}
</script>
