<template>
  <div>
    <v-dialog 
      fullscreen 
      hide-overlay 
      transition="slide-full-modal-transition" 
      v-model="formOpened" content-class="j_modal" persistent>
      <v-card>
        <div class="modal__header full_title pd_left_3">
          <button class="hamberger_menu" type="button"  @click="onAction('cancel')">
            <v-icon>mdi-menu</v-icon>
          </button>
          <span>Format Bar Chart <span class="sub_title">{{ `${idxNum} ${subTitle}` }}</span></span>
        </div>

        <v-card-text class="modal__formControl properties8 type4 dark_theme">
          <!-- <v-form v-model="valid" ref="form"> -->
            <v-container>
              <v-card-actions class="modal_toolbar">
                <div class="tool_menus">
                  <div>
                    <div
                      data-bar-quickbtn="vert"
                      @click="onChartType(__C_.TYPE_CODE_VERTICAL)"
                      title="vertical"
                      :class="chartItem.ChartType == __C_.TYPE_CODE_VERTICAL ? 'selected': ''"
                    >
                    </div>
                  </div>
                  <div>
                    <div
                      data-bar-quickbtn="horz"
                      @click="onChartType(__C_.TYPE_CODE_HORIZONTAL)"
                      title="horizontal"
                      :class="chartItem.ChartType == __C_.TYPE_CODE_HORIZONTAL ? 'selected': ''"
                    >
                    </div>
                  </div>
                  <div>
                    <div
                      data-bar-quickbtn="stack"
                      @click="onChartType(__C_.TYPE_CODE_STACK_VERTICAL)"
                      title="stack"
                      :class="chartItem.ChartType == __C_.TYPE_CODE_STACK_VERTICAL ? 'selected': ''"
                    >
                    </div>
                  </div>
                  <div>
                    <div
                      data-bar-quickbtn="group"
                      @click="onChartType(__C_.TYPE_CODE_GROUP_VERTICAL)"
                      title="group"
                      :class="chartItem.ChartType == __C_.TYPE_CODE_GROUP_VERTICAL ? 'selected': ''"
                    >
                    </div>
                  </div>
                </div>
              </v-card-actions>
              <div class="modal_dark_btn">
                <j-button
                  class="type01 sky  type_full"
                  :class="{ disabled: !resetable }"
                  :disabled="!resetable"
                  @click="onAction('save')"
                  >Save
                </j-button>
                <j-button
                  class="type01 sky  type_full"
                  :class="{ disabled: !resetable }"
                  :disabled="!resetable"
                  @click="onAction('saveas')"
                  >SaveAs
                </j-button>
                <j-button v-if="modeMod" 
                  class="type01 delete  type_full" 
                  @click="onAction('delete')"
                  >Delete
                </j-button>
                <div class="check_wrapper">
                  <v-checkbox v-model="update" :label="'Update'"/>
                  <v-checkbox :label="'Page Svg'" />
                  <v-checkbox :label="'Fixed'" />
                </div>
                <button class="help" @click="(e) => { helper=!helper }"></button>
              </div>
              <div class="modal__formControl_inside">
                <v-tabs v-model="stepper" class="wrap__modal_wfix">
                  <v-tab data-type="navigation" title="navigation"></v-tab>
                  <v-tab data-type="database" title="database"></v-tab>
                  <v-tab data-type="canvas" title="canvas"></v-tab>
                  <v-tab data-type="axis" title="axis"></v-tab>
                  <v-tab data-type="title" title="title"></v-tab>
                  <v-tab data-type="bar" title="bar"></v-tab>
                  <v-tab data-type="legend" title="legend"></v-tab>
                  <v-tab data-type="note" title="note"></v-tab>

                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-navigation ref="general" @import-item="onImport" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-database ref="database" @save="onSave()" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-canvas />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-axis
                      :axisXLevelUnit="axisXLevelUnit"
                      :axisXLevelDirection="axisXLevelDirection"
                      :axisXLevelAutoColor="axisXLevelAutoColor"
                      :axisYLevelUnit="axisYLevelUnit"
                      :axisYLevelAutoColor="axisYLevelAutoColor"
                    />
                    <!-- :axisAva="axisAva" -->
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-title @save="onSave()" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-bar :background="background" :chart-type="chartItem.ChartType" :series="series" />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-legend />
                  </v-tab-item>
                  <v-tab-item :transition="false" :reverse-transition="false">
                    <j-chart-tab-note />
                  </v-tab-item>
                </v-tabs>

                <j-chart-bar-normal
                  class="canvasAreaLine"
                  ref="chart"
                  :style="`--area-width: ${this.canvas.CanvasWidth + 1}px; --area-height: ${this.canvas.CanvasHeight +1}px;`"
                  :ChartType="chartItem.ChartType"
                  :Database="database"
                  :Canvas="canvas"
                  :Axis="axis"
                  :Title="title"
                  :Bar="bar"
                  :Legends="legend"
                  :Note="note"
                  :DataItems="chartData"
                  @moved="onMoved"
                  @to-xml-string="onXmlString"
                />

                <div v-if="!isPreviewChart" id="j-chart-table">
                  <div style="display: inline-block;">
                    <j-data-grid
                      hide-actions
                      :headers="previewHeaders"
                      :items="previewItems"
                    >
                      <template #items="props">
                        <tr :active="props.selected">
                          <td v-for="h_ in previewHeaders" :key="h_.index" :style="h_.value == 'title' ? '' : 'text-align: center;'">{{ props.item[h_.value] }}</td>
                        </tr>
                      </template>
                    </j-data-grid>
                  </div>
                </div>

                <div v-if="helper" id="j-chart-helper">
                  <img :src="helperUrl" />
                </div>
                <!-- <div class="modal__formControl_note">Comment!</div> -->
              </div>
            </v-container>
          <!-- </v-form> -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :title-description="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import _ChartDataDefault from '@/primitives/_chartBarNormalDefault'
import ChartModalMixin from "@/mixins/chart.modal.common"
import JChartTabProps from './SysenvChartlibBarNormal'

// const _f_chartType = (typeCode) => (typeCode.substr(typeCode.length - 5))

export default {
  name: 'sysenv-chartlib-bar-normal-modal',
  mixins: [
    ChartModalMixin,
  ],
  components: {
    ...JChartTabProps,
  },
  data: () => ({
    previewHeaders: [],

// --------------------------------------------   Elements displayed on the Axis tab
    axisXLevelUnit: false,
    axisXLevelDirection: false,
    axisXLevelAutoColor: false,
    axisYLevelUnit: false,
    axisYLevelAutoColor: false,
    background: true,
    series: true,
    idxNum: '',
    subTitle: ''
  }),
  computed: {
    helperUrl() { return this.chartItem.ChartNo && this.helper ? require(`../../../assets/helpDoc/logo.png`) : '' },
    previewItems() {
      if(this.chartData.length === 0) return []
      this.setPreviewHeader()
      return this.chartData
    },
  },
  watch: {
    // for the default chart-type setting
    chartData: {
      handler(val) {
        if (!val || val.length === 0) return
        // if the chart-type is not selected yet, 
        // set the default chart-type with 'SGL-A'.
        if (this.modeNew && !this.chartItem['ChartType']) this.onChartType(__C.CHART.TYPE_CODE_VERTICAL)
      },
      deep: true
    },
    formOpened(val) {
      if (!val) return

      this.stepper = 0
      // every time came into the modal, either the mode new or edit,
      // whole the store's data should be initialized.
      this.setEmpty()
      this.$refs.chart.clear()

      if (this.modeNew) {
        this.setChartItem({
          ChartDb: 'Axis',
          CatCode: __C.CHART.CAT_CODE_BAR_AXIS,
          JsonString: JSON.stringify(_ChartDataDefault.DataItems),

          ..._ChartDataDefault.Database,
          ..._ChartDataDefault.Canvas,
          ..._ChartDataDefault.Axis,
          ..._ChartDataDefault.Title,
          ..._ChartDataDefault.Bar,
          ..._ChartDataDefault.Legends,
          ..._ChartDataDefault.Note,
          
          QApplied: 'J',
        })
        this.setChartData(_ChartDataDefault.DataItems)
        this.onChartType(__C.CHART.TYPE_CODE_VERTICAL)
        this.$refs.general.setData(this.formMode)

      } else {
        this.getChart()
      }
    }
  },
  methods: {
    itemIdx(Idx, chartNo) {
      this.idxNum = `(${Idx} / ${chartNo})`
    },
    itemName(val) {
      this.subTitle = val
    },
    onChartType(typeName) {
      this.setChartItem({ ChartType: typeName })
      this.onDisplayElements(typeName)
    },
    onDisplayElements(typeName) {
      this.axisXLevelUnit = true
      this.axisXLevelDirection = true
      this.axisXLevelAutoColor = true
      this.axisYLevelUnit = true
      this.axisYLevelAutoColor = true

      this.background = false
      this.series = false

      switch (typeName) {
        case __C.CHART.TYPE_CODE_VERTICAL:
          this.axisXLevelUnit = false
          this.axisYLevelAutoColor = false
          break

        case __C.CHART.TYPE_CODE_HORIZONTAL:
          this.axisXLevelAutoColor = false
          this.axisXLevelDirection = false
          this.axisYLevelUnit = false
          break

        case __C.CHART.TYPE_CODE_STACK_VERTICAL:
          this.axisXLevelUnit = false
          this.axisXLevelAutoColor = false
          this.axisYLevelAutoColor = false
          break

        case __C.CHART.TYPE_CODE_GROUP_VERTICAL:
          this.axisXLevelUnit = false
          this.axisXLevelAutoColor = false
          this.axisYLevelAutoColor = false
          break

        default:
      }
    },
    getChart(code=null) {
      return new Promise(resolve => {
        this.chartLibraryService.getChartBarNormal(code || this.keyValue, res => {
          this.setChartItem(res)
          this.onChartType(this.chartItem.ChartType)
          this.setChartData(this.chartItem.QApplied == 'J' ? JSON.parse(this.chartItem.JsonString) : this.chartItem.QResultSummary)

          this.$refs.general.setData(this.formMode)

          this.itemIdx(res.Idx, res.ChartNo)
          this.itemName(res.Name)

          resolve(res)
        })
      })
    },
    putChart(reqItem, callback) {
      this.chartLibraryService.putChartBarNormal(reqItem, callback)
    },
    sasChart(reqItem, callback) {
      this.chartLibraryService.sasChartBarNormal(reqItem, callback)
    },
    updChart(reqItem, callback) {
      this.chartLibraryService.updChartBarNormal(reqItem, callback)
    },
    delChart(chartNo, callback) {
      this.chartLibraryService.delChartBarNormal(chartNo, callback)
    },

    setPreviewHeader() {
      let colNames = []
      this.chartData.forEach(item => { colNames = Array.from(new Set([ ...colNames, ...Object.keys(item)])) })
      this.previewHeaders = colNames.map(colName_ => ({ type: 'text', text: colName_, value: colName_, align: 'center', sortable: false }))
    }
  }
}
</script>
