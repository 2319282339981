import __C from '../../../../includes/primitives/_constant_'

export default {
    Canvas: {
      CanvasChartAreaAllowed: 'Y',

      CanvasWidth: 350,
      CanvasHeight: 340,
      CanvasBorderWeight: 0.3,
      CanvasBorderColor: '#bcbcbc',
      CanvasFillColor: 'Transparent',  //Transparent
      CanvasOpacity: 0.5,

      CanvasChartX: 0,
      CanvasChartY: 0,
      CanvasChartWidth: 350,
      CanvasChartHeight: 340,
      CanvasChartBorderWeight: 0,
      CanvasChartBorderColor: '#757575',
      CanvasChartFillColor: '#757575',
      CanvasChartOpacity: 0,
      CanvasChartSpace: 0,

      CanvasFontFamily: 'roboto',
    },
    
    Radar: {
      chart: {
              x:              175,
              y:              170,
              size:           200,
              data:           1,
      },
      level: {
              no:             5,
              stWidth:        0.3,
              stColor:        '#BCBCBC',
              opacity:        0.5,
              lastStWidth:    0.5,
              lastStColor:    '#757575',
              lastOpacity:    1,
              maxValue:       100,
              space:          3,
              font:           'roboto',
              tSize:           9,
              tColor:         '#757575',
      },  
      axes: { 
              stWidth:        0.5,
              stColor:        '#bcbcbc',
              opacity:        0.7,
      },  
      series: { 
              font:           'roboto',
              tSize:           10.5,
              tColor:         '#818181',
              factor:         0.65,
      },  
      value: {  
              display:        'N',
              font:           'roboto',
              tSize:           9,
              tColor:         '#000',
      },  
      area: { 
              stWidth:        [1, 1],
              stColor:        ["#44A9DF", "#EC407A"],
              fColor:         ["LightBlue", "Pink"],
              opacity:        [0.3, 0.3],
              colorType:      ['', ''],
      },  
      node: { 
              size:           [2.5, 2.5],
              stWidth:        [1, 1],
              stColor:        ["#44A9DF", "#EC407A"],
              fColor:         ["#44A9DF", "#EC407A"],
              opacity:        [0.7, 0.7],
      },
      legend: { 
              display:        'Y',
              x:              175,
              y:              310,
              text:           ['Construction', 'Engineering'],
              bullet:         10,
              font:           'roboto',
              tSize:          11,
              tColor:         '#757575',
      }, 
    },

    DataItems: [
      // { title:'Structural',     value1: 30,     },
      // { title:'Piping',         value1: 60.89,   },
      // { title:'Electrical',     value1: 26.72,   },
      // { title:'Mechanical',     value1: 54.16,   },
      // { title:'HVAC',           value1: 62.71,   },
      // { title:'Architectural',  value1: 33.42,   },
      // { title:'Paint',          value1: 85.13,   },
      // { title:'Insulation',     value1: 40.01,  },

      {SUBSYSTEM:"21-8000-8013-01",QCF:"P01A",title:"Line Walk",TOTAL:6,COMPLETED:1,REMAIN:5,value1:90},
      {SUBSYSTEM:"21-8000-8013-01",QCF:"P03A",title:"Pressure Test",TOTAL:6,COMPLETED:1,REMAIN:5,value1:50},
      {SUBSYSTEM:"21-8000-8013-01",QCF:"P05A",title:"Reinstatement",TOTAL:6,COMPLETED:0,REMAIN:6,value1:20},
      {SUBSYSTEM:"21-8000-8013-01",QCF:"P06A",title:"Joint Integrity",TOTAL:6,COMPLETED:0,REMAIN:6,value1:5}
    ],
}


