<template>
  <v-flex class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Types</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Tube
        <a class="close" v-on:click="collapsed['tube'] = !collapsed['tube']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['tube']">
        <div class="type_common_input">
          <label>Height</label>
          <input v-model="ChtTypeTubeHeight" type="number" />
        </div>
        <div class="type_common_input">
          <label>Tickness</label>
          <input v-model="ChtTypeTubeTickness" type="number" />
        </div>

        <div class="type_common_select">
          <label>Color</label>
          <select v-model="ChtTypeTubeColor" dir="rtl" class="type70">
            <option v-for="option in linearA2Options" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>

        <span class="label">Base</span>
        <div class="type_inner">
          <div class="type_common_input" >
            <label class="type_gray second_depth">Height</label>
            <input v-model="ChtTypeTubeBase" type="number" />
          </div>
        </div>
      </v-flex>
    </div>

    <div class="chart_properties_contents">
      <div class="header">
        Cubes
        <a class="close" v-on:click="collapsed['cubes'] = !collapsed['cubes']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['cubes']">
        <span class="label">Title</span>
        <div class="type_inner">
          <div class="type_common_input">
            <label class="type_gray second_depth">Name</label>
            <input v-model="ChtTypeCubeTitleName" type="text" class="type130"/>
          </div>
          <div class="type_common_input" >
            <label class="type_gray second_depth">Padding</label>
            <input v-model="ChtTypeCubeTitlePadding" type="text"/>
          </div>
          <div class="type_common_input type_color_float">
            <label class="type_gray second_depth">Box Radius / Color</label>
            <div class="input_flex_wrap_end">
              <input v-model="ChtTypeCubeTitleRadius" type="number"/>
              <j-color-picker v-model="ChtTypeCubeTitleColor"/>
            </div>
          </div>
          <div class="type_common_input">
            <label class="type_gray second_depth">Opacity</label>
            <input v-model="ChtTypeCubeTitleOpacity" type="number"/>
          </div>
        </div>
        <span class="label">Table</span>
        <div class="type_inner">
          <div class="type_common_input">
            <label class="type_gray second_depth">y</label>
            <input v-model="ChtTypeCubeTableY" type="number"/>
          </div>
          <div class="type_common_input">
            <label class="type_gray second_depth">Width / Height</label>
            <input v-model="ChtTypeCubeTableWidth" type="number"/>
            <input v-model="ChtTypeCubeTableHeight" type="number"/>
          </div>
          <div class="type_common_input type_color_float">
            <label class="type_gray second_depth">Box Radius / Color</label>
            <div class="input_flex_wrap_end">
              <input v-model="ChtTypeCubeTableRadius" type="number"/>
              <j-color-picker v-model="ChtTypeCubeTableColor"/>
            </div>
          </div>
          <div class="type_common_input">
            <label class="type_gray second_depth">Opacity</label>
            <input v-model="ChtTypeCubeTableOpacity" type="number"/>
          </div>
        </div>
      </v-flex>  
    </div>    
    
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-type',
  mixins: [
    StaticOptions
  ],
  components: {
  },
  data: () => ({
    collapsed: {
      tube: true,
      cubes: true,
    },
    localChttype: {
      tube:{},
      cubes:{
        title:{},
        table:{}
      },
    }
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),
    
    // Tube 
    // ChtType tube Header
    ChtTypeTubeHeight: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.tube.height ) },
      set(val) { 
        this.localChttype.tube.height = parseFloat(val || 0) 
        this.updateAttr() 
      }
    },

    // ChtType tube Tickness
    ChtTypeTubeTickness: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.tube.tickness ) },
      set(val) { 
        this.localChttype.tube.tickness = parseFloat(val || 0) 
        this.updateAttr() 
      }
    },
    
    // ChtType tube Color
    ChtTypeTubeColor: {
      get() { return ( this.chartItem.Chttype && this.chartItem.Chttype.tube.color ) },
      set(val) { 
        this.localChttype.tube.color  = val
        this.updateAttr() 
      }
    },

    // ChtType tube base
    ChtTypeTubeBase: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.tube.base },
      set(val) { 
        this.localChttype.tube.base  = parseFloat(val || 0)  
        this.updateAttr() 
      }
    },

    // Cube Title
    // ChtType Cube Name
    ChtTypeCubeTitleName: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.cubes.title.name },
      set(val) { 
        this.localChttype.cubes.title.name = val
        this.updateAttr() 
      }
    },

    // ChtType Cube Padding
    ChtTypeCubeTitlePadding: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.cubes.title.padding },
      set(val) { 
        this.localChttype.cubes.title.padding  = parseFloat(val || 0)  
        this.updateAttr() 
      }
    },

    // ChtType Cube Title radius
    ChtTypeCubeTitleRadius: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.cubes.title.radius },
      set(val) { 
        this.localChttype.cubes.title.radius  = parseFloat(val || 0)  
        this.updateAttr() 
      }
    },

    // ChtType Cube Title color
    ChtTypeCubeTitleColor: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.cubes.title.bgColor },
      set(val) { 
        this.localChttype.cubes.title.bgColor  = val
        this.updateAttr() 
      }
    },

    // ChtType Cube Title opacity
    ChtTypeCubeTitleOpacity: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.cubes.title.opacity },
      set(val) { 
        this.localChttype.cubes.title.opacity  = parseFloat(val || 0)  
        this.updateAttr() 
      }
    },

    // Cube Table
    // ChtType Cube table y
    ChtTypeCubeTableY: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.cubes.table.y },
      set(val) { 
        this.localChttype.cubes.table.y  = parseFloat(val || 0)  
        this.updateAttr() 
      }
    },

    ChtTypeCubeTableWidth: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.cubes.table.width },
      set(val) { 
        this.localChttype.cubes.table.width = parseFloat(val || 0)  
        this.updateAttr() 
      }
    },

    // ChtType Cube table Padding
    ChtTypeCubeTableHeight: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.cubes.table.height },
      set(val) { 
        this.localChttype.cubes.table.height  = parseFloat(val || 0)  
        this.updateAttr() 
      }
    },

    // ChtType Cube table radius
    ChtTypeCubeTableRadius: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.cubes.table.radius },
      set(val) { 
        this.localChttype.cubes.table.radius  = parseFloat(val || 0)  
        this.updateAttr() 
      }
    },

    // ChtType Cube table color
    ChtTypeCubeTableColor: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.cubes.table.bgColor },
      set(val) { 
        this.localChttype.cubes.table.bgColor  = val
        this.updateAttr() 
      }
    },

    // ChtType Cube table opacity
    ChtTypeCubeTableOpacity: {
      get() { return this.chartItem.Chttype && this.chartItem.Chttype.cubes.table.opacity },
      set(val) { 
        this.localChttype.cubes.table.opacity  = parseFloat(val || 0)  
        this.updateAttr() 
      }
    },

  },
  watch: {
    'chartItem.Chttype': {
      handler(val) {
        if (!val) return
        // console.log('Chttype', val)
        this.localChttype = JSON.parse(JSON.stringify(val))
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),

    updateAttr() {
      this.setChartItem({ Chttype: JSON.parse(JSON.stringify(this.localChttype)) })
    },
  },
}
</script>
