<template>
  <v-flex v-model="bar_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Boxes</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Chart
        <a class="close" v-on:click="collapsed['chart'] = !collapsed['chart']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['chart']">
        <div class="type_common_input">
          <span class="label">
            Type
            <small class="type_ref03">(height)</small>
          </span>
          <div class="row">
            <label v-for="option in boxtypeOptions" :key="option.index" class="type_many">
              <input v-model="BoxesChartType" type="radio" name="BoxesChartType" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_input">
          <label>Fixed Height</label>
          <input v-model="BoxesChartFixed" type="number" />
        </div>
        <div>
          <span class="label">Auto Height</span>
          <div class="type_inner">
            <div class="type_common_input type_gray">
              <label class="">Min.</label>
              <input type="text" />
            </div>
            <div class="type_common_input type_gray">
              <label class="type_gray">Max.</label>
              <input type="text" />
            </div>
          </div>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Box
        <a class="close" v-on:click="collapsed['box'] = !collapsed['box']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['box']">
        <div class="type_common_input">
          <label>Width</label>
          <input v-model="BoxesBoxWidth" type="number" />
        </div>
        <div class="type_common_input">
          <label>Height</label>
          <input v-model="BoxesBoxHeight" type="number" />
        </div>
        <div class="type_common_input">
          <label>Radius</label>
          <input v-model="BoxesBoxRadius" type="number" />
        </div>
        <div class="type_common_input">
          <label>Stroke</label>
          <input v-model="BoxesBoxStroke" type="number" />
        </div>
        <div class="type_common_input">
          <label>Gap Space</label>
          <input v-model="BoxesBoxSpace" type="number" />
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Text
        <a class="close" v-on:click="collapsed['text'] = !collapsed['text']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['text']">
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="BoxesTextFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="BoxesTextStyle" dir="rtl" class="type70">
            <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="BoxesTextSize" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="BoxesTextColor" title="Color"></j-color-picker>
        </div>
      </v-flex>
    </div>
    <div class="chart_properties_contents">
      <div class="header">
        Value
        <a class="close" v-on:click="collapsed['value'] = !collapsed['value']"></a>
      </div>
      <v-flex class="control" v-show="collapsed['value']">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input v-model="BoxesValueDisplay" type="radio" name="BoxesValueDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <div class="type_common_select">
          <label>Font</label>
          <select v-model="BoxesValueFont" dir="rtl">
            <option v-for="option in fontOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_select">
          <label>Style</label>
          <select v-model="BoxesValueStyle" dir="rtl" class="type70">
            <option v-for="option in fontStyleOptions" :key="option.index" :value="option.value">{{option.text}}</option>
          </select>
        </div>
        <div class="type_common_input">
          <label>Size</label>
          <input v-model="BoxesValueSize" type="number" />
        </div>
        <div class="type_common_input">
          <j-color-picker v-model="BoxesValueColor" title="Color"></j-color-picker>
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-box',
  mixins: [
    StaticOptions
  ],
  data: () => ({
    collapsed: {
      chart: true,
      box: false,
      text: false,
      value: false,
    },
    boxtypeOptions: [
      { text: 'Auto', value: 'Auto'},
      { text: 'Fixed', value: 'Fixed'},
    ],
    bar_general: null,
    currentIcon: null,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['chartItem']),

    BoxesChartType: {
      get() { return this.chartItem.BoxesChartType },
      set(val) { this.setChartItem({ BoxesChartType: val })}
    },
    BoxesChartFixed: {
      get() { return this.chartItem.BoxesChartFixed || 0 },
      set(val) { this.setChartItem({ BoxesChartFixed: parseFloat(val || 0) })}
    },
    BoxesBoxWidth: {
      get() { return this.chartItem.BoxesBoxWidth || 0 },
      set(val) { this.setChartItem({ BoxesBoxWidth: parseFloat(val || 0) })}
    },
    BoxesBoxHeight: {
      get() { return this.chartItem.BoxesBoxHeight || 0 },
      set(val) { this.setChartItem({ BoxesBoxHeight: parseFloat(val || 0) })}
    },
    BoxesBoxRadius: {
      get() { return this.chartItem.BoxesBoxRadius || 0 },
      set(val) { this.setChartItem({ BoxesBoxRadius: parseFloat(val || 0) })}
    },
    BoxesBoxStroke: {
      get() { return this.chartItem.BoxesBoxStroke || 0 },
      set(val) { this.setChartItem({ BoxesBoxStroke: parseFloat(val || 0) })}
    },
    BoxesBoxSpace: {
      get() { return this.chartItem.BoxesBoxSpace || 0 },
      set(val) { this.setChartItem({ BoxesBoxSpace: parseFloat(val || 0) })}
    },
    BoxesTextFont: {
      get() { return this.chartItem.BoxesTextFont },
      set(val) { this.setChartItem({ BoxesTextFont: val })}
    },
    BoxesTextStyle: {
      get() { return this.chartItem.BoxesTextStyle },
      set(val) { this.setChartItem({ BoxesTextStyle: val })}
    },
    BoxesTextSize: {
      get() { return this.chartItem.BoxesTextSize || 0 },
      set(val) { this.setChartItem({ BoxesTextSize: parseFloat(val || 0) })}
    },
    BoxesTextColor: {
      get() { return this.chartItem.BoxesTextColor },
      set(val) { this.setChartItem({ BoxesTextColor: val })}
    },
    BoxesValueDisplay: {
      get() { return this.chartItem.BoxesValueDisplay },
      set(val) { this.setChartItem({ BoxesValueDisplay: val })}
    },
    BoxesValueFont: {
      get() { return this.chartItem.BoxesValueFont },
      set(val) { this.setChartItem({ BoxesValueFont: val })}
    },
    BoxesValueStyle: {
      get() { return this.chartItem.BoxesValueStyle },
      set(val) { this.setChartItem({ BoxesValueStyle: val })}
    },
    BoxesValueSize: {
      get() { return this.chartItem.BoxesValueSize || 0 },
      set(val) { this.setChartItem({ BoxesValueSize: parseFloat(val || 0) })}
    },
    BoxesValueColor: {
      get() { return this.chartItem.BoxesValueColor },
      set(val) { this.setChartItem({ BoxesValueColor: val })}
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_CHART, ['setChartItem']),
  }

}
</script>
