<template>
  <v-flex v-model="bar_general" class="chart_properties" wrap>
    <div class="chart_properties_header">
      <span>Floating Table</span>
      <small class="type_ref01">Properties</small>
    </div>
    <div class="chart_properties_contents form_type_normal">
      <div class="header">Floating Table</div>
      <v-flex class="control">
        <div class="type_common_input">
          <span class="label">Display</span>
          <div class="row">
            <label v-for="option in yesnoOptions" :key="option.index" class="type_many">
              <input type="radio" name="LegendDisplay" :value="option.value" />
              {{option.text}}
            </label>
          </div>
        </div>
        <!-- <v-select
          v-model="PhaseCode"
          content-class="single_select"
          item-text="phaseName"
          item-value="phaseCode"
          placeholder="Table ID"
          required
          attach
          :items="phaseOptions"
          :rules="[v => !!v || 'Item is required']"
          @input="onPhaseChanged"
          class="tree_node"
        ></v-select> -->
        <v-select
          item-text="phaseName"
          item-value="phaseCode"
          placeholder="Table ID"
          required
          attach
          class="tree_node"
          :menu-props="{contentClass:'single_select'}"
        ></v-select>
        <div class="type_common_input">
          <label for>
            Coordinates
            <small class="type_ref03">(x,y)</small>
          </label>
          <input type="text" class="type_double" />
          <input type="text" />
        </div>
      </v-flex>
    </div>
  </v-flex>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters } from "vuex"
import { ChartLibraryService, SystemService } from "@/services"
import StaticOptions from '@/mixins/staticOptions'

export default {
  name: 'j-chart-tab-floating-table',
  props: {
    formMode: String,
  },
  mixins: [
    StaticOptions
  ],
  created() {
    this.chartLibraryService = new ChartLibraryService()
    this.systemService = new SystemService()
  },
  mounted() {
    this.systemService.getNavPhase(res => {
      if (!res) this.phaseOptions = []
      else this.phaseOptions = res
    })
  },
  data: () => ({
    collapsed: {
      lines: true,
    },
    // Service Instances -------------
    chartLibraryService: null,
    systemService: null,
    bar_general: null,
    currentIcon: null,

    tableOptions: []
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.CHART_LIBRARY_HISTOGRAM, ['chartMaster']),
    ...mapGetters(__C.STORE_NAMESPACE.CHART_LIBRARY_HISTOGRAM, ['table']),

    tableOnOff: {
      get() { return this.table.tableOnOff },
      set(val) { this.setChartItem({ tableOnOff: val }) }
    },
    tableId: {
      get() { return this.table.tableId },
      set(val) { this.setChartItem({ tableId: val }) }
    },
    tablePx: {
      get() { return this.table.tablePx || 0 },
      set(val) { this.setChartItem({ tablePx: parseFloat(val || 0) }) }
    },
    tablePy: {
      get() { return this.table.tablePy || 0 },
      set(val) { this.setChartItem({ tablePy: parseFloat(val || 0) }) }
    },

  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CHART_LIBRARY_HISTOGRAM, ['setChartMaster']),
  }
}
</script>